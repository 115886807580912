import {
  ADD_GATEWAY_SUCCESS,
  ADD_GATEWAY_FAILED,
  RESET_ADD_GATEWAY,
  LOADING_ADD_GATEWAY,
  ADD_GATEWAY_NETWORK_ERROR,
} from '../types'
import axios from '../../config/axios'

const resetAddGateway = () => {
  return { type: RESET_ADD_GATEWAY }
}

const addGateway = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_GATEWAY })
  axios
    .post('paymentGateway/addGateway', userData)
    .then((res) => {
      console.log(res)
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADD_GATEWAY_NETWORK_ERROR, payload: err }))
}
function success(data) {
  return {
    type: ADD_GATEWAY_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: ADD_GATEWAY_FAILED,
    payload: data,
  }
}
export { resetAddGateway, addGateway }
