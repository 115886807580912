import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Alert, Spin, Select } from 'antd'
import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'
import { resetGetCityList, getCityList } from 'redux/actions/getCityListAction'
import {
  resetGetSocietyAdmin,
  getSocietyAdmin,
} from 'redux/actions/getSocietyAdminAction'
import {
  resetupdateSocietyDetails,
  updateSocietyDetails,
} from 'redux/actions/updateSocietyDetailsAction'
import SocietyDetail from './SocietyDetail'
import ReactPaginate from 'react-paginate'
import PaginationComponent from 'views/Pagination'
import Util from '../../validations'
// import 'bootstrap/dist/css/bootstrap.min.css'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import AddSociety from './AddSociety'

import buildingImg from 'assets/img/favicon.png'
import axios from 'config/axios'
const initialState = {
  societyName: '',
  pincode: '',
  landmark: '',
}
function SocietyList() {

  // const [state, setState] = useState(initialState)
  const [societyName, setSocietyName] = useState('')
  const [pincode, setPincode] = useState('')
  const [city, setCity] = useState('')
  const [cityName, setCItyName] = useState('')
  const [landmark, setLandmark] = useState('')
  const [viewSocietyAdmin, setViewSocietyAdmin] = useState(false)
  const [societyId, setSocietyId] = useState('')
  const [page, setPage] = useState(1)
  const [limit] = useState(9)
  const [openModal, setOpenModal] = useState(false)
  const [details, setDetails] = useState('')
  const [searchCityName, setSearchCityName] = useState('')
  // const [options, setOptions] = useState([])
  const [isSearch , setIsSearch] = useState(false)

  const [searchSocietyName, setSearchSocietyName] = useState('')
  const [socName, setSocName] = useState('')

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])

  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
 
  const login = useSelector(({ login }) => login)
  const cityList = useSelector(({ getCityList }) => getCityList)
  const societyAdmin = useSelector(({ getSocietyAdmin }) => getSocietyAdmin)
  const updatedSocietyDetails = useSelector(
    ({ updateSocietyDetails }) => updateSocietyDetails
  )
  const dispatch = useDispatch()

  const totalItems = societyList.data.count
  const { Option } = Select

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSocName('')
    setSearchSocietyName('')
  }

  function onSearchCity(value) {
    setSearchCityName(value)
  }

  function onChangeSocName(value) {
    setSocName(value)
    setSearchSocietyName('')
  }

  function onSearchSocName(value) {
    setSearchSocietyName(value)
  }

  const getCityListRequest = () => {
    const getCityListReq = {
      initiatedBy: 'Society',
    }
    return dispatch(getCityList(getCityListReq))
  }
  
  useEffect(() =>{
    getCityListRequest()
  },[])
  
  const getSocietyAdminRequest = () => {
    const getSocietyAdminReq = {
      initiatedBy: login.data.user.role,
      id: societyId,
    }
    return dispatch(getSocietyAdmin(getSocietyAdminReq))
  }
  useEffect(() => {
    getSocietyAdminRequest()
  }, [societyId])

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'societyName') setSocietyName(value)
    if (name === 'pincode')
      Util.verifyMobile(value) ? setPincode(value) : setPincode(null)
    if (name === 'city') setCity(value)
    if (name === 'landmark') setLandmark(value)
  }
  // useEffect(() => {
  //   return () => {
  //     resetGetCityList()
  //     resetgetSocietyList()
  //   }
  // }, [])

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  useEffect(() => {
    if (searchSocietyName != '') {
      const userData = {
        city,
        initiatedBy: 'Society',
        societyName: searchSocietyName,
      }
      axios.post('society/getSocietyList', userData).then((res) => {
        setOptions(res.data.societyList)
      })
    }
  }, [searchSocietyName, city])

  const getSocietyListRequest = () => {
    const getSocListReq = {
      city,
      societyName: socName,
      pincode,
      initiatedBy: login.data.user.role,
      page,
      limit,
    }
    return dispatch(getSocietyList(getSocListReq))
  }

  useEffect(() => {
    getSocietyListRequest()
  }, [city, page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getSocietyListRequest()
    setSearchSocietyName('')
    setIsSearch(!isSearch)
  }

  useEffect(() =>{
    setPage(1)
  },[isSearch])

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const detailModal = (d) => {
    setDetails(d)
    setOpenModal(true)
  }

  useEffect(() => {
    if (updatedSocietyDetails.success) {
      setOpenModal(false)
      getSocietyListRequest()
    }
    return () => {
      dispatch(resetupdateSocietyDetails())
    }
  }, [updatedSocietyDetails.success])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{margin:"auto" , width:"100%"}}>
          <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="city" id="onTop">
                City
              </Label>
              <Select
                type="select"
                value={city}
                showSearch
                placeholder="Select your society name"
                optionFilterProp="children"
                onChange={onChangeCity}
                onSearch={onSearchCity}
                style={{ width: '100%' }}
              >
                <Option value="">Select your city name</Option>
                {searchCityName != '' &&
                  options2 != undefined &&
                  options2.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
                {cityList.success &&
                  searchCityName === '' &&
                  cityList.data.city.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d}>
                      {d}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="societyName">Society Name</Label>

              <Select
                type="select"
                value={socName}
                showSearch
                placeholder="Select your society name"
                optionFilterProp="children"
                onChange={onChangeSocName}
                onSearch={onSearchSocName}
                style={{ width: '100%' }}
              >
                <Option value="" >Select your society name</Option>
                {searchSocietyName != '' && options != undefined
                  ? options.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={d.societyName}
                      >
                        {d.societyName}
                      </Option>
                    ))
                  : societyList.success &&
                    societyList.data.societyList.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={d.societyName}
                      >
                        {d.societyName}
                      </Option>
                    ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="pincode">Pincode</Label>
              <Input
                type="text"
                maxLength="6"
                placeholder="Pincode"
                name="pincode"
                id="pincode"
                value={pincode || ''}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Col sm="12" style={{ textAlign: 'center' }}>
            <Button
              className="cust-button"
              onClick={handleSubmit}
              disabled={societyList.isLoading || !city}
            >
              {societyList.isLoading ? <Spin /> : 'Search'}
            </Button>
          </Col>
        </Row>
      </Form>
      <div style={{ paddingTop: '35px' }}>
        <Row>
          {societyList.success &&
            // societyList.data.length > 0 &&
            societyList.data.societyList.map((d) => (
              <Col sm="12" md="4" key={d.id}>
                <Card style={{ textAlign: 'center' }}>
                  <CardBody
                    onClick={() => {
                      setSocietyId(d.id)
                    }}
                  >
                    <CardTitle tag="h6">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {d.photo ? (
                          <img
                            src={`${Util.imageUrl}${d.photo}`}
                            className="AvatarProfile"
                            alt="Photo"
                          />
                        ) : (
                          <img
                            src={buildingImg}
                            // className="AvatarProfile"
                            style={{ width: '40px', height: '40px' }}
                            alt="Photo"
                          />
                        )}

                        <span
                          style={{
                            marginLeft: '20px',
                            textTransform: 'capitalize',
                            fontWeight: 'semi-bold',
                            color: ' #00326c',
                          }}
                        >
                          {d.societyName}
                        </span>
                      </div>
                    </CardTitle>
                    <CardSubtitle
                    // tag="h6"
                    //  className="mb-2 text-muted"
                    >
                      <div style={{ display: 'flex' }}>
                        <span>{d.societyBlock ? d.societyBlock : '-'}</span>
                        <span className="SocietyAddress">
                          {/* -{d.address.substr(0, 30)}
                          {'...'} */}
                          {d.address}                         
                        </span>
                      </div>
                    </CardSubtitle>
                    <CardBody>
                      <div style={{ display: 'flex' }}>
                        <i
                          className="fas fa-phone-square-alt"
                          style={{ color: ' #00326c' }}
                        ></i>
                        {viewSocietyAdmin && <div></div>}
                      </div>
                    </CardBody>
                    <Button
                      className="modalCustomButton"
                      onClick={() => detailModal(d)}
                    >
                      Details
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
      {openModal && (
        <SocietyDetail
          details={details}
          modal={openModal}
          toggle={toggleModal}
          societyAdmin={societyAdmin}
        />
      )}

      {societyList.success && (
        <PaginationComponent
            limit={limit}
            totalItems={totalItems}
            onSelect={handlePagination}
            defaultPage={page}
          />
      )}
      {societyList.failed && (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <Alert message={societyList.data.message} type="info" />
        </div>
      )}
    </>
  )
}

export default SocietyList
