import {
	ADDEMERGENCYNUMBER_SUCCESS,
	ADDEMERGENCYNUMBER_FAILED,
	LOADING_ADDEMERGENCYNUMBER,
	RESET_ADDEMERGENCYNUMBER,
    ADDEMERGENCYNUMBER_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 	LOADING_ADDEMERGENCYNUMBER:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case 	ADDEMERGENCYNUMBER_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case 	ADDEMERGENCYNUMBER_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case 	ADDEMERGENCYNUMBER_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case RESET_ADDEMERGENCYNUMBER:
            return (state = initialState);

        default:
            return state;
    }
};