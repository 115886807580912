import {
    GET_EXPORT_EXCEL_INVOICE_SUCCESS,
    GET_EXPORT_EXCEL_INVOICE_FAILED,
    GET_EXPORT_EXCEL_INVOICE_NETWORKERROR,
    LOADING_GET_EXPORT_EXCEL_INVOICE,
    RESET_GET_EXPORT_EXCEL_INVOICE
} from '../types'

const initialState = {
    isLoading: false,
    success: false,
    network: false,
    failed: false,
    data: {},
    message: '',
    networkError: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GET_EXPORT_EXCEL_INVOICE:
            return {
                ...state,
                isLoading: true,
                success: false,
                network: false,
                failed: false,
                data: {},
                message: '',
                networkError: '',
            }
        case GET_EXPORT_EXCEL_INVOICE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                network: false,
                failed: true,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case GET_EXPORT_EXCEL_INVOICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                network: false,
                failed: false,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case GET_EXPORT_EXCEL_INVOICE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: '',
                networkError: 'Network Error',
            }
        case RESET_GET_EXPORT_EXCEL_INVOICE:
            return (state = initialState)

        default:
            return state
    }
}
