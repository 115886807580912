import axios from '../../config/axios'
import {
  GETSOCIETYLIST_SUCCESS,
  GETSOCIETYLIST_FAILED,
  LOADING_GETSOCIETYLIST,
  RESET_GETSOCIETYLIST,
  GETSOCIETYLIST_NETWORKERROR,
} from '../types'

const resetgetSocietyList = () => {
  return {
    type: RESET_GETSOCIETYLIST,
  }
}

const getSocietyList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETSOCIETYLIST })
  console.log(userData)
  axios
    .post('society/getSocietyList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETSOCIETYLIST_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GETSOCIETYLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETSOCIETYLIST_FAILED,
    payload: data,
  }
}

export { resetgetSocietyList, getSocietyList }
