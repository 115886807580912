import axios from "../../config/axios"
import {
	GET_ALL_SECURITY_ALERT_LIST_SUCCESS,
	GET_ALL_SECURITY_ALERT_LIST_FAILED,
	LOADING_GET_ALL_SECURITY_ALERT_LIST,
	RESET_GET_ALL_SECURITY_ALERT_LIST,
	GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR
} from "../types";

const resetgetAllSecurityAlertList = () => {
    return {
        type: RESET_GET_ALL_SECURITY_ALERT_LIST
    };
};

const getAllSecurityAlert = userData => dispatch => {
    dispatch({ type: LOADING_GET_ALL_SECURITY_ALERT_LIST });
    axios
        .post("user/getAllSecurityAlertList", userData)
        .then(res => {
            switch (res.status) {
                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR, payload: err }));
};

function success(data) {
    return {
        type: GET_ALL_SECURITY_ALERT_LIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GET_ALL_SECURITY_ALERT_LIST_FAILED,
        payload: data
    };
}

export { resetgetAllSecurityAlertList, getAllSecurityAlert } ;