import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import {
  Tooltip,
  Popconfirm,
  Spin,
  Alert,
  Switch,
  List,
  Avatar,
  Skeleton,
} from 'antd'
import {
  resetGetVisitorDetails,
  getVisitorDetails,
} from 'redux/actions/getVisitorDetailsAction'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'react-reactstrap-pagination'
import moment from 'moment'
// import "./visitorstyle.css";

import DemoIcon from 'assets/img/Icons/RecentVisitor/demo.png'

import {
  resetGetRecentActivity,
  getRecentActivity,
} from 'redux/actions/getRecentActivityAction'

function RecentVisitors() {
  const [modal, setmodal] = useState(false)
  const [data, setData] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [fileModal, setFileModal] = useState(false)
  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  const visitorDetail1 = useSelector(
    ({ getVisitorDetails }) => getVisitorDetails
  )
  const visitorDetail = useSelector(
    ({ getRecentActivity }) => getRecentActivity
  )

  console.log(visitorDetail, 'visitorDetail')
  useEffect(() => {
    if (login.data.user.role === 'Society') {
      const getVisitorDetailsReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        limit,
      }
      return dispatch(getRecentActivity(getVisitorDetailsReq))
    }
  }, [page])
  // const totalItems = visitorDetail.data.count
  // console.log(visitorDetail)
  const openEditModal = (d) => {
    setmodal(!modal)
    setData(d)
  }
  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col md="12">
            {visitorDetail.success && (
              <Col md="12">
                <Table>
                  <thead>
                    <tr style={{ fontSize: '15px', fontWeight: 'bold' }}>
                      <td>Profile</td>
                      <td>Name</td>
                      <td>Company</td>
                    </tr>
                  </thead>
                  {visitorDetail.success && (
                    <tbody>
                      {visitorDetail.success &&
                        visitorDetail.data.recentVisitor.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>
                                <img
                                  src={`${Util.imageUrl}${d.photo}`}
                                  alt="Name Image"
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                  }}
                                />
                              </td>
                              <td>{d.helperName}</td>
                              <td style={{ paddingLeft: '1.5rem' }}>
                                {d.companyLogo ? (
                                  <img
                                    src={`${Util.imageUrl}${d.companyLogo}`}
                                    alt="Company Image"
                                    style={{
                                      height: '40px',
                                      width: '40px',
                                    }}
                                  />
                                ) : d.companyName ? (
                                  d.companyName
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  )}
                </Table>
              </Col>
            )}
          </Col>
        </Row>
        {visitorDetail.failed && (
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <Alert message={visitorDetail.data.message} type="info" />
          </div>
        )}
      </Col>
    </Row>
  )
}

export default RecentVisitors
