import {
	GETAREATYPE_SUCCESS,
	GETAREATYPE_FAILED,
	LOADING_GETAREATYPE,
	RESET_GETAREATYPE,
	GETAREATYPE_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    network: false,
    failed: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETAREATYPE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case 	GETAREATYPE_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case 	GETAREATYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case 	GETAREATYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
    network: true,
								failed: false,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case RESET_GETAREATYPE:
            return (state = initialState);

        default:
            return state;
    }
};