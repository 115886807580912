import {
  GET_TRANSACTION_LIST_SUCCESS,
  GET_TRANSACTION_LIST_FAILED,
  LOADING_GET_TRANSACTION_LIST,
  RESET_GET_TRANSACTION_LIST,
  GET_TRANSACTION_LIST_NETWORK_ERROR,
} from 'redux/types'

import axios from 'config/axios'

const resetGetTransactionList = () => {
  return {
    type: RESET_GET_TRANSACTION_LIST,
  }
}

const getTransactionList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_TRANSACTION_LIST })
  axios
    .post('paymentGateway/getTransactionList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: GET_TRANSACTION_LIST_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: GET_TRANSACTION_LIST_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_TRANSACTION_LIST_FAILED,
    payload: data,
  }
}
export { resetGetTransactionList, getTransactionList }
