import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import {
  resetGetProfessionType,
  getProfessionType,
} from 'redux/actions/getProfessionTypeAction'
import {
  resetAddProfessionType,
  addProfessionType,
} from 'redux/actions/addProfessionTypeAction'
import AddProfessionType from './AddProfessionType'

function ProfessionTypeList({data,onTap}) {
  const {profession} = data
  const [names, setName] = useState('')
  const [redirect, setRedirect] = useState('')
  const [modal, setmodal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState()
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getProfession = useSelector(
    ({ getProfessionType }) => getProfessionType
  )
  const addedProfessionType = useSelector(
    ({ addProfessionType }) => addProfessionType
  )

  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = getProfession.data.count

  const getProfessionTypeRequest = () => {
    const getProfessionReq = {
      initiatedBy: login.data.user.role,
      profession,
      limit,
      page,
    }
    return dispatch(getProfessionType(getProfessionReq))
  }
  useEffect(() => {
    getProfessionTypeRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'names')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
    addProfessionRequest()
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const addProfessionRequest = () => {
    const addProfessionReq = {
      initiatedBy: login.data.user.role,
      profession: names.trim(),
      id,
      isActive,
      action: 'Update',
    }
    return dispatch(addProfessionType(addProfessionReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const openEditModal = (type) => {
    console.log(type)
    setId(type.id)
    setName(type.profession)
    setmodal(!modal)
    setisActive(type.isActive)
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }

  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    if (addedProfessionType.success) {
      setName('')
      setId('')
      setisActive('')
      setmodal(false)
      setAddModal(false)
      getProfessionTypeRequest()
      NotificationPopups('success', 'Success', addedProfessionType.data.message)
    }
    if (addedProfessionType.failed) {
      NotificationPopups('error', 'Failed', addedProfessionType.data.message)
    }
    if (addedProfessionType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddProfessionType())
    }
  }, [
    addedProfessionType.success,
    addedProfessionType.failed,
    addedProfessionType.network,
  ])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading">Profession Type List</div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {getProfession.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }} id='onTop'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {getProfession.success && (
                          <tbody>
                            {getProfession.data?.professionList.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>{d.profession}</td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
                <Col>
                  {getProfession.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {getProfession.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={getProfession.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="helptype">Name</Label>
              <Input
                type="text"
                name="names"
                id="helptype"
                value={names}
                onChange={handleEditChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditFeature}
              disabled={addedProfessionType.isLoading}
            >
              {addedProfessionType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Profession </ModalHeader>
          <ModalBody>
            <AddProfessionType />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ProfessionTypeList
