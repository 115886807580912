import {
  GET_GUARD_LIST_SUCCESS,
  GET_GUARD_LIST_FAILED,
  LOADING_GET_GUARD_LIST,
  RESET_GUARD_LIST,
  GET_GUARD_LIST_NETWORK_ERROR,
} from '../types'
import axios from 'config/axios'

const resetGuardList = () => {
  return {
    type: RESET_GUARD_LIST,
  }
}

const getGuardList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_GUARD_LIST })
  axios
    .post('guard/getGuardList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: GET_GUARD_LIST_NETWORK_ERROR, payload: err })
    })
}

function success(data) {
  return {
    type: GET_GUARD_LIST_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_GUARD_LIST_FAILED,
    payload: data,
  }
}

export { resetGuardList, getGuardList }
