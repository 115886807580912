import axios from "../../config/axios"
import {
	DELETE_DOCTYPE_SUCCESS,
	DELETE_DOCTYPE_FAILED,
	LOADING_DELETE_DOCTYPE,
	RESET_DELETE_DOCTYPE,
	DELETE_DOCTYPE_NETWORK_ERROR
} from "../types";

const resetdeleteDocType = () => {
    return {
        type: RESET_DELETE_DOCTYPE
    };
};

const deleteDocType = userData => dispatch => {
    dispatch({ type: LOADING_DELETE_DOCTYPE });
		console.log(userData);
    axios
        .post("superAdmin/deleteSocietyDocumentType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: DELETE_DOCTYPE_NETWORK_ERROR, payload: err }));

};

function success(data) {
    return {
        type: DELETE_DOCTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: DELETE_DOCTYPE_FAILED,
        payload: data
    };
}

export { resetdeleteDocType, deleteDocType } ;