import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
	Label,
} from "reactstrap";
import NotificationPopups from "components/NotificationPopups/NotificationPopups";
import { Spin } from 'antd';
import { resetAddDailyHelpType, addDailyHelpType } from "redux/actions/addDailyHelpTypeAction";

function AddDailyHelperType() {

	const [dailyHelperType, setDailyHelperType] = useState("");
  const addeddailyHelpType = useSelector(({ adddailyHelpType }) => adddailyHelpType);
	const dispatch = useDispatch();

	const handleChange = ({ target: { name, value } }) => {
		if( name === "dailyHelperType" ) setDailyHelperType(value);
	}

	const addDailyHelperTypeRequest = () => {

		const addDailyHelperTypeReq = {
			dailyHelperType : {
        type: dailyHelperType.trim()
			}
		}

		console.log(addDailyHelperTypeReq)
		return dispatch(addDailyHelpType(addDailyHelperTypeReq));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		addDailyHelperTypeRequest()
	}

  useEffect(() => {
    if(addeddailyHelpType.success){
      setDailyHelperType("")
      NotificationPopups('success','Success',addeddailyHelpType.data.message)
    }
    if(addeddailyHelpType.failed){
      NotificationPopups('error','Failed',addeddailyHelpType.data.message)
    }
    if(addeddailyHelpType.network){
      NotificationPopups('error','Network Error',"Check your internet connection")
    }

    return () => {
        dispatch(resetAddDailyHelpType());
    };
	}, [addeddailyHelpType.success, addeddailyHelpType.failed, addeddailyHelpType.network]	)

	return (
		<div style={{marginTop: "20px"}}>
			<Form onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col md="4" sm="12">
                        <FormGroup>
                          <Label for="dailyHelperType">Daily Helper Type</Label>
                          <Input type="text" 
                            name="dailyHelperType"
                            placeholder="Daily Helper Type" 
                            id="dailyHelperType" 
                            value={dailyHelperType || ""}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                          <Button className='cust-button' style={{marginTop: "22px", width: "70%"}}
                          disabled={!dailyHelperType || addeddailyHelpType.isLoading}
                      onClick={handleSubmit}
                      >{addeddailyHelpType.isLoading ? <Spin/> : "Add"}</Button>
                      </Col>
                    </Row>
                  </Form>
		</div>
	)
}

export default AddDailyHelperType;