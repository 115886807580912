import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin } from 'antd'
import { Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { resetAddDocType, addDocType } from 'redux/actions/addDocTypeAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'

function AddSocietyDoc() {
  const [initiatedBy, setInitiatedBy] = useState('SuperAdmin')
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [searchDocumentType, setSearchDocumentType] = useState('')

  const [isMandatory, setisMandatory] = useState('')
  const docType = useSelector(({ getDocument }) => getDocument)
  const login = useSelector(({ login }) => login)
  const addeddocType = useSelector(({ addDocType }) => addDocType)
  const dispatch = useDispatch()

  const { Option } = Select
  function onChangeDocumentType(value) {
    setDocumentTypeId(value)
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }

  const onChangeSwitch = (checked) => {
    setisMandatory(checked)
    // console.log(`switch to ${checked}`)
  }
  const getDocTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const docTypeReq = {
        action: 'Status',
        initiatedBy,
        isActive: true,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [])

  const adddocTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const adddocTypeReq = {
        initiatedBy: login.data.user.role,

        documentTypeId,
        isMandatory,
      }

      //console.log(adddocTypeReq)
      return dispatch(addDocType(adddocTypeReq))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    adddocTypeRequest()
    setDocumentTypeId('')
  }
  const disabledButton = () => {
    return !documentTypeId && isMandatory
  }

  useEffect(() => {
    if (addeddocType.success) {
      setisMandatory(isMandatory)
      // NotificationPopups('success', 'Success', addeddocType.data.message)
    }
    if (addeddocType.failed) {
      // NotificationPopups('error', 'Failed', addeddocType.data.message)
    }
    if (addeddocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddDocType())
    }
  }, [addeddocType.success, addeddocType.failed, addeddocType.network])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="helperType">Document Type</Label>
          <Select
            type="select"
            value={documentTypeId}
            showSearch
            placeholder="Select Service Type"
            optionFilterProp="children"
            onChange={onChangeDocumentType}
            onSearch={onSearchDocumentType}
            style={{ width: '100%' }}
          >
            <Option className="loginSelect" value="">
              Select Document Type
            </Option>

            {docType.success &&
              docType.data.activeType.map((d, id) => (
                <Option key={id} className="loginSelect" value={d.id}>
                  {d.type}
                </Option>
              ))}
            {docType.failed && (
              <Option className="loginSelect" value="" disabled>
                No Data Found
              </Option>
            )}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Mandatory</Label>
          <br />
          <Switch
            defaultChecked={isMandatory}
            name="isMandatory"
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={onChangeSwitch}
            // style={{ width: '100%' }}
          />
        </FormGroup>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            disabled={!documentTypeId || addeddocType.isLoading}
            onClick={handleSubmit}
          >
            {addeddocType.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddSocietyDoc
