import {
  UPDATE_MEMBER_ROLE_NETWORK_ERROR,
  LOADING_UPDATE_MEMBER_DETAIL,
  RESET_UPDATE_MEMBER_ROLE,
  UPDATE_MEMBER_ROLE_SUCCESS,
  UPDATE_MEMBER_ROLE_FAILED,
} from '../types'
import axios from 'config/axios'

const resetUpdateMemberRole = () => {
  return {
    type: RESET_UPDATE_MEMBER_ROLE,
  }
}

const updateMemberRole = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_MEMBER_DETAIL })
  axios
    .post('society/updateMemberRole', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_MEMBER_ROLE_NETWORK_ERROR, payload: err })
    })
}

function success(data) {
  return {
    type: UPDATE_MEMBER_ROLE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_MEMBER_ROLE_FAILED,
    payload: data,
  }
}

export { resetUpdateMemberRole, updateMemberRole }
