import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'

import MembersAcceptedList from './MembersAcceptedList'
import MembersRejectedList from './MembersRejectedList'
import MembersPendingList from './MembersPendingList'
import Test from './Test'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import MembersIcon from 'assets/img/Icons/Sidebar/member.png'

function Members() {
  const [status, setStatus] = useState('pending')

  const handleStatus = (status) => {
    setStatus(status)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content members-list">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons users_single-02'} /> */}
                  <img
                    src={MembersIcon}
                    alt="MembersIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">
                  {status === 'approved' && 'Approved Members'}
                  {status === 'rejected' && 'Rejected Members'}
                  {status === 'pending' && 'Pending Members'}
                </span>
                <span style={{ float: 'right', paddingTop: '10px' }}>
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('approved')}
                  >
                    {' '}
                    Approved List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('rejected')}
                  >
                    Rejected List
                  </span>
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('pending')}
                  >
                    {' '}
                    Pending List{' '}
                  </span>
                </span>
              </CardHeader>
              <CardBody>
                {status === 'approved' && <MembersAcceptedList />}
                {status === 'rejected' && <MembersRejectedList />}
                {status === 'pending' && <MembersPendingList />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Members
