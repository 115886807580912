import axios from "../../config/axios"
import {
	DELETEBUILDING_SUCCESS,
	DELETEBUILDING_FAILED,
	LOADING_DELETEBUILDING,
	RESET_DELETEBUILDING,
	DELETEBUILDING_NETWORKERROR
} from "../types";

const resetdeleteBuilding = () => {
    return {
        type: RESET_DELETEBUILDING
    };
};

const deleteBuilding = userData => dispatch => {
    dispatch({ type: LOADING_DELETEBUILDING });
		console.log(userData);
    axios
        .post("building/deleteBuilding", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: DELETEBUILDING_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: DELETEBUILDING_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: DELETEBUILDING_FAILED,
        payload: data
    };
}

export { deleteBuilding, resetdeleteBuilding } ;