import React from 'react'
import { Spin, notification } from 'antd'

const NotificationPopups = (type, mes, des) => {
  notification[type]({
    message: `${mes}`,
    description: des,
    placement: `bottomRight`,
    duration: 5,
  })
}

export default NotificationPopups
