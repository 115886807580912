import {
	GET_SMSPROVIDER_SUCCESS,
	GET_SMSPROVIDER_FAILED,
	LOADING_GET_SMSPROVIDER,
	RESET_GET_SMSPROVIDER,
	GET_SMSPROVIDER_NETWORK_ERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GET_SMSPROVIDER:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    						network: false,
								data: {},
								message: "",
                networkError: ""
            };
				case GET_SMSPROVIDER_NETWORK_ERROR:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: false,
    						network: true,
								data: action.payload,
								message: "",
								networkError: "Network Error"          
            };
            case GET_SMSPROVIDER_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    						network: false,
								data: action.payload,
								message: "",
                networkError: ""
                
            };
				case GET_SMSPROVIDER_FAILED:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: true,
    						network: false,
								data: action.payload,
								message: "",
                networkError: ""
            };
				case RESET_GET_SMSPROVIDER:
            return (state = initialState);

        default:
            return state;
    }
};