import React, { useState, useEffect } from 'react'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

import {
  addSocietyTemplate,
  resetAddSocietyTemplate,
} from 'redux/actions/addSocietyTemplateAction'
import {
  resetGetSocietyTemplate,
  getSocietyTemplate,
} from 'redux/actions/getSocietyTemplateAction'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

const AddTNC = ({ showScreen }) => {
  const [value, setValue] = useState('')
  const [storeTncHTML, setStoreTncHTML] = useState('')

  const login = useSelector(({ login }) => login)
  const addedSocTemp = useSelector(
    ({ addSocietyTemplate }) => addSocietyTemplate
  )
  const getSocTemp = useSelector(({ getSocietyTemplate }) => getSocietyTemplate)
  const dispatch = useDispatch()
  const getValue = (value) => {
    setStoreTncHTML(value)
    setValue(value)
  }

  const addSocietyTemplateRequest = () => {
    if (showScreen === 'term') {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        termCondition: storeTncHTML,
        action: 'add',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    } else if (showScreen === 'header') {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        societyHeader: storeTncHTML,
        action: 'add',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    } else {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        societyFooter: storeTncHTML,
        action: 'add',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    }
  }

  const updateSocietyTemplateRequest = () => {
    if (showScreen === 'term') {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        termCondition: storeTncHTML,
        action: 'update',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    } else if (showScreen === 'header') {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        societyHeader: storeTncHTML,
        action: 'update',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    } else {
      const addSocietyTemplateReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        templateType: showScreen,
        societyFooter: storeTncHTML,
        action: 'update',
      }
      return dispatch(addSocietyTemplate(addSocietyTemplateReq))
    }
  }

  const getSocietyTemplateRequest = () => {
    const getSocietyTempReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      // templateType: 'term',
    }
    return dispatch(getSocietyTemplate(getSocietyTempReq))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addSocietyTemplateRequest()
  }
  const handleUpdateSubmit = (e) => {
    e.preventDefault()
    updateSocietyTemplateRequest()
  }
  useEffect(() => {
    getSocietyTemplateRequest()
    return () => {
      resetGetSocietyTemplate()
    }
  }, [])

  useEffect(() => {
    if (getSocTemp.success && getSocTemp.data.invoiceTemplate) {
      getSocietyTemplateRequest()
    } else {
      if (showScreen === 'header') {
        setStoreTncHTML('')
        if (
          getSocTemp.success &&
          !getSocTemp.data.invoiceTemplate &&
          getSocTemp.data.template.societyHeader
        ) {
          console.log('exe')
          setStoreTncHTML(getSocTemp.data.template.societyHeader)
        } else {
          setStoreTncHTML('')
        }
      } else if (showScreen === 'term') {
        if (getSocTemp.success && getSocTemp.data.template.termCondition) {
          console.log('exe')
          setStoreTncHTML(getSocTemp.data.template.termCondition)
        } else {
          setStoreTncHTML('')
        }
      } else {
        setStoreTncHTML('')
        if (getSocTemp.success && getSocTemp.data.template.societyFooter) {
          console.log('exe')
          setStoreTncHTML(getSocTemp.data.template.societyFooter)
        } else {
          setStoreTncHTML('')
        }
      }
    }
  }, [getSocTemp.success, showScreen])

  useEffect(() => {
    if (addedSocTemp.success) {
      getSocietyTemplateRequest()
      NotificationPopups('success', 'Success', addedSocTemp.data.message)
    }
    if (addedSocTemp.failed) {
      NotificationPopups('error', 'Failed', addedSocTemp.data.message)
    }
    if (addedSocTemp.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddSocietyTemplate())
    }
  }, [addedSocTemp.success, addedSocTemp.failed, addedSocTemp.network])

  return (
    <>
      <RichTextEditor initialValue={storeTncHTML} getValue={getValue} />
      {/* {value} */}
      {getSocTemp.failed && (
        <Button
          style={{ textAlign: 'center' }}
          color="secondary"
          className="modalCustomButton"
          onClick={handleSubmit}
        >
          Save
        </Button>
      )}
      {getSocTemp.success && (
        <Button
          style={{ textAlign: 'center' }}
          color="secondary"
          className="modalCustomButton"
          onClick={handleUpdateSubmit}
        >
          Save
        </Button>
      )}
    </>
  )
}
export default AddTNC
