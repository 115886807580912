import {
  ADD_HELPER_TYPE_SUCCESS,
  ADD_HELPER_TYPE_FAILED,
  LOADING_ADD_HELPER_TYPE,
  RESET_ADD_HELPER_TYPE,
  ADD_HELPER_TYPE_NETWORK_ERROR,
} from 'redux/types'
import axios from '../../config/axios'

const resetAddHelperType = () => {
  return {
    type: RESET_ADD_HELPER_TYPE,
  }
}
const addHelperType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_HELPER_TYPE })
  console.log(userData)
  axios
    .post('helper/addHelperType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_HELPER_TYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: ADD_HELPER_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_HELPER_TYPE_FAILED,
    payload: data,
  }
}

export { resetAddHelperType, addHelperType }
