import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import Util from 'validations/index'

function AmenitytRejected({ amenityList }) {
  return (
    <>
      <Table responsive style={{ textAlign: 'center' }}>
        <thead className="text-primary">
          <tr>
            <th>Flat</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Amenity</th>
            <th>Date/Time</th>
          </tr>
        </thead>
        <tbody>
          {amenityList.success &&
            amenityList.data.amenityList.map((d, id) => (
              <React.Fragment key={id}>
                <tr
                  key={id}
                  id={`toggler${id}`}
                  style={{
                    backgroundColor: '#e8f5fd',
                    color: 'black',
                    fontWeight: 'semi-bold',
                    boxShadow: 'none',
                  }}
                >
                  <td>
                    {d.buildingName} / {d.unit}
                  </td>
                  <td>{d.name}</td>
                  <td>{d.mobileNumber}</td>
                  <td>{d.areaType}</td>
                  <td>
                    {Util.formatDate(d.startDate)} {d.startTime} -{' '}
                    {Util.formatDate(d.endDate)} {d.endTime}
                  </td>
                </tr>

                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <UncontrolledCollapse toggler={`toggler${id}`}>
                      <Row className="ml-detail-row">
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          style={{ padding: '10px' }}
                        >
                          <label>Message</label>
                          <div
                            className="detail-val"
                            style={{ textAlign: 'left' }}
                          >
                            {d.description}
                          </div>
                          <div
                            className="detail-time"
                            style={{ textAlign: 'left' }}
                          >
                            {Util.formatDateTime(d.timestamp)}
                          </div>
                        </Col>
                      </Row>
                    </UncontrolledCollapse>
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </>
  )
}

export default AmenitytRejected
