import axios from 'config/axios'
import {
  DELETE_SOC_MNT_TYPE_SUCCESS,
  DELETE_SOC_MNT_TYPE_FAILED,
  DELETE_SOC_MNT_TYPE_LOADING,
  DELETE_SOC_MNT_TYPE_NETWORK_ERROR,
  DELETE_SOC_MNT_TYPE_RESET,
} from 'redux/types'

const resetDeleteSocMntType = () => {
  return { type: DELETE_SOC_MNT_TYPE_RESET }
}

const deleteSocMntType = (userData) => (dispatch) => {
  dispatch({ type: DELETE_SOC_MNT_TYPE_LOADING })
  axios
    .post('mnt/deleteSocietyMntType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: DELETE_SOC_MNT_TYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: DELETE_SOC_MNT_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: DELETE_SOC_MNT_TYPE_FAILED,
    payload: data,
  }
}

export { resetDeleteSocMntType, deleteSocMntType }
