import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
	Label,
} from "reactstrap";
import { Spin } from 'antd';
import { resetaddCompanyType, addCompanyType } from "redux/actions/addCompanyTypeAction";
import NotificationPopups from "components/NotificationPopups/NotificationPopups";
import loginReducer from "redux/reducers/loginReducer";

function AddCompanyType() {

		const [companyType, setCompanyType] = useState("");
    const addedcompanyType = useSelector(({ addcompanyType }) => addcompanyType);
    const login = useSelector(({ login }) => login);
	  const dispatch = useDispatch();

	const handleChange = ({ target: { name, value } }) => {
		if( name === "companyType" ) setCompanyType(value);
	}

	const addCompanyTypeRequest = () => {

		const addCompanyTypeReq = {
        initiatedBy: login.data.user.role,
        type: companyType.trim()			
		}

		console.log(addCompanyTypeReq)
		return dispatch(addCompanyType(addCompanyTypeReq));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		addCompanyTypeRequest()
	}

  useEffect(() => {
    if(addedcompanyType.success){
      setCompanyType("")
      NotificationPopups('success','Success',addedcompanyType.data.message)
    }

    if(addedcompanyType.failed){
      NotificationPopups('error','Failed',addedcompanyType.data.message)
    }
    if(addedcompanyType.network){
      NotificationPopups('error','Network Error',"Check your internet connection")
    }

    return () => {
        dispatch(resetaddCompanyType());
    };
	}, [addedcompanyType.success, addedcompanyType.failed, addedcompanyType.network]	)

	return (
		<div style={{marginTop: "20px"}}>
			<Form onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col md="4" sm="12">
                        <FormGroup>
                          <Label for="companyType">Company Type</Label>
                          <Input type="text" 
                            name="companyType" 
                            id="companyType"
                            placeholder="Company Type" 
                            value={companyType || ""}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                          <Button className='cust-button' style={{marginTop: "22px", width: "70%"}}
                          disabled={!companyType || addedcompanyType.isLoading}
                      onClick={handleSubmit}
                      >{addedcompanyType.isLoading ? <Spin/> : "Add"}</Button>
                      </Col>
                    </Row>
                  </Form>
		</div>
	)
}

export default AddCompanyType;
