import axios from '../../config/axios'
import {
  GETAREATYPE_SUCCESS,
  GETAREATYPE_FAILED,
  LOADING_GETAREATYPE,
  RESET_GETAREATYPE,
  GETAREATYPE_NETWORKERROR,
} from '../types'

const resetgetAreaType = () => {
  return {
    type: RESET_GETAREATYPE,
  }
}

const getAreaType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETAREATYPE })
  axios
    .post('society/getAreaType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GETAREATYPE_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: GETAREATYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETAREATYPE_FAILED,
    payload: data,
  }
}

export { resetgetAreaType, getAreaType }
