import PanelHeader from 'components/PanelHeader/PanelHeader'
import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import SearchFeeConfig from './SearchFeeConfig'
import ViewFeeConfig from './ViewFeeConfig'
import FeeConfigIcon from 'assets/img/Icons/Maintaince/feeConfig.png'

const FeeConfig = () => {
  const [isSearch , setIsSearch] = useState(false)
  const [sendData , setSendData] = useState('')

  const SearchFilter = (value)=>{
    setSendData(value)
  }
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons business_bank'} /> */}
                  <img
                    src={FeeConfigIcon}
                    alt="SeviceTypeIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Maintenance Master List </span>
              </CardHeader>
              <CardBody>
                <SearchFeeConfig searchFilter={SearchFilter} setIsSearch={()=> setIsSearch(!isSearch)}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ViewFeeConfig sendData={sendData} onTap={isSearch}/>
      </div>
    </>
  )
}

export default FeeConfig
