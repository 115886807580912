import axios from '../../config/axios'
import {
  UPDATE_SOCIETY_DETAILS_SUCCESS,
  UPDATE_SOCIETY_DETAILS_FAILED,
  UPDATE_SOCIETY_DETAILS_NETWORK_ERROR,
  LOADING_UPDATE_SOCIETY_DETAILS,
  RESET_UPDATE_SOCIETY_DETAILS,
} from '../types'

const resetupdateSocietyDetails = () => {
  return {
    type: RESET_UPDATE_SOCIETY_DETAILS,
  }
}

const updateSocietyDetails = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_SOCIETY_DETAILS })
  console.log(userData)
  axios
    .post('society/updateSociety', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATE_SOCIETY_DETAILS_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATE_SOCIETY_DETAILS_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_SOCIETY_DETAILS_FAILED,
    payload: data,
  }
}

export { resetupdateSocietyDetails, updateSocietyDetails }
