import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Spin, Switch, Select, Tooltip, Popconfirm, Alert } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import axios from 'config/axios'
import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import {
  addTypeLanguage,
  resetAddTypeLanguage,
} from 'redux/actions/addTypeLanguageAction'

function AddMutiLanguageType() {
  const [displayDropdown, setDisplayDropdown] = useState('')
  const [serviceId, setServiceId] = useState('')
  const [language, setLanguage] = useState('')
  const [en, setEn] = useState('')
  const [type, setType] = useState('')
  const [serviceName, setServiceName] = useState('')
  const [str, setStr] = useState('')
  const [hin, setHin] = useState('')
  const [mar, setMar] = useState('')
  //serviceType
  const [searchServiceType, setSearchServiceType] = useState('')
  const [optionsServType, setOptionsServType] = useState('')
  //helperType
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsHelperType, setOptionsHelperType] = useState('')
  const login = useSelector(({ login }) => login)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const addedLan = useSelector(({ addLanguage }) => addLanguage)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    // if (name === 'priorityOrder') setPriorityOrder(value)
    if (name === 'serviceName') setServiceName(value)
  }

  useEffect(() => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }, [])

  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Service Type',
    },
    {
      id: 2,
      name: 'Helper Type',
    },
  ]
  const languageOptionsDropDown = [
    {
      id: 1,
      name: 'Hindi',
      languageCode: 'HIN',
    },
    {
      id: 2,
      name: 'Marathi',
      languageCode: 'MAR',
    },
  ]

  const { Option } = Select
  function onChangeLabelOptons(value) {
    setDisplayDropdown(value)
  }

  function onSearchlabelOptions(val) {
    setDisplayDropdown(val)
  }
  function onChangeService(value) {
    const val = value.split(',')
    setServiceId(val[0])
    setEn(val[1])
  }

  function onSearchService(val) {
    setSearchServiceType(val)
    setServiceId(val)
  }
  function onChangeLan(value) {
    setLanguage(value)
  }
  function onSearchLan(val) {
    setLanguage(val)
  }

  //search service type
  useEffect(() => {
    if (searchServiceType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchServiceType,
      }
      axios.post('helper/getServiceType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsServType(res.data.activeType)
        }
      })
    }
  }, [searchServiceType])

  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType])

  const handleSubmit = (e) => {
    e.preventDefault()
    addLanguageTypeRequest()
  }
  const addLanguageTypeRequest = () => {
    const addLanguageTypeReq = {
      initiatedBy: login.data.user.role,
      action: 'add',
      serviceId,
      // LangaugeCode: type,
      eng: en,
      language,
      // type: en,
      serviceName: encodeURI(serviceName),
      type: displayDropdown,
    }
    return dispatch(addTypeLanguage(addLanguageTypeReq))
  }

  const disabledBtn = () => {}
  useEffect(() => {
    if (addedLan.success) {
      setHin('')
      setMar('')
      setLanguage('')
    }
    if (addedLan.failed) {
    }
    if (addedLan.network) {
    }
    return () => {
      dispatch(resetAddTypeLanguage())
    }
  }, [addedLan.success, addedLan.failed, addedLan.network])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Services</label>
              <Select
                type="select"
                value={displayDropdown}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeLabelOptons}
                onSearch={onSearchlabelOptions}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Type
                </Option>
                {labelOptionsDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          {displayDropdown === 'Service Type' ? (
            <Col md="4" sm="12" lg="4" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Service Type</label>
                <Select
                  type="select"
                  value={en}
                  showSearch
                  placeholder="Select service Type"
                  optionFilterProp="children"
                  onChange={onChangeService}
                  onSearch={onSearchService}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Service Type
                  </Option>
                  {searchServiceType != '' && optionsServType != undefined
                    ? optionsServType.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id},${d.type}`}
                        >
                          {d.type}
                        </Option>
                      ))
                    : servType.success &&
                      servType.data.activeType.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id},${d.type}`}
                        >
                          {d.type}
                        </Option>
                      ))}
                  {servType.failed && (
                    <Option className="loginSelect" value="">
                      No Data Found
                    </Option>
                  )}
                </Select>
              </FormGroup>
            </Col>
          ) : (
            <Col md="4" sm="12" lg="4" xs="12">
              <FormGroup style={{ margin: '10px 1px' }}>
                <label>Select Helper Type</label>
                <Select
                  type="select"
                  showSearch
                  placeholder="Select Helper Type"
                  optionFilterProp="children"
                  onChange={onChangeService}
                  onSearch={onSearchService}
                  style={{ width: '100%' }}
                >
                  <Option className="loginSelect" value="">
                    Select Helper Type
                  </Option>
                  {searchHelperType != '' && optionsHelperType != undefined
                    ? optionsHelperType.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id},${d.type}`}
                        >
                          {d.type}
                        </Option>
                      ))
                    : helperTypeList.success &&
                      helperTypeList.data.helperTypeList.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.id},${d.type}`}
                        >
                          {d.type}
                        </Option>
                      ))}
                </Select>
              </FormGroup>
            </Col>
          )}
          {/* </Row>
        <Row> */}
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Language</label>
              <Select
                type="select"
                showSearch
                placeholder="Select Language"
                optionFilterProp="children"
                onChange={onChangeLan}
                onSearch={onSearchLan}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Language
                </Option>
                {languageOptionsDropDown.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={d.languageCode}
                  >
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Translation</Label>
              <Input
                type="text"
                placeholder="Enter Translation"
                name="serviceName"
                id="serviceType"
                value={serviceName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={addedLan.isLoading}
            type="submit"
          >
            {addedLan.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddMutiLanguageType
