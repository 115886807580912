import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FileViewer from 'react-file-viewer'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  FormFeedback,
  Col,
} from 'reactstrap'
import Util from 'validations/index'
import { Spin, Switch } from 'antd'
import {
  resetupdateEmergencyNumber,
  updateEmergencyNumber,
} from 'redux/actions/updateEmergencyNoAction'

function EditEmergencyNumber({ details, modal, toggle, errMessage }) {
  const [editname, seteditname] = useState(details.name)
  const [editphone, seteditphone] = useState(details.mobileNumber)
  const [editemail, seteditemail] = useState(details.email)
  const [isActive, setIsActive] = useState(details.isActive)
  const [isEditEmailValid, setisEditEmailValid] = useState(true)
  const [editaddress, seteditaddress] = useState(details.address)
  const [editemergencytype, seteditemergencytype] = useState(details.type)
  const login = useSelector(({ login }) => login)
  const updatedEmergencyNumber = useSelector(
    ({ updateEmergencyNumber }) => updateEmergencyNumber
  )
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetupdateEmergencyNumber())
    }
  }, [])

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'editname')
      Util.alphabetsOnly(value) ? seteditname(value) : seteditname(editname)
    if (name === 'editphone') {
      Util.verifyMobile(value) ? seteditphone(value) : seteditphone(null)
    }
    if (name === 'editemail') {
      setisEditEmailValid(Util.verifyEmail(value))
      seteditemail(value.trim())
    }
    if (name === 'editaddress') seteditaddress(value)
    if (name === 'editemergencytype')
      Util.alphaNumeric(value)
        ? seteditemergencytype(value)
        : seteditemergencytype(editemergencytype)
  }
  const onChange = (checked) => {
    console.log(isActive)
    setIsActive(checked)
    console.log(`switch to ${checked}`)
  }

  const handleEditEmergencyNumber = () => {
    const editEmergencyNo = {
      isActive,
      id: details.id,
      name: editname.trim(),
      mobileNumber: parseInt(editphone),
      type: editemergencytype.trim(),
      email: editemail.trim(),
      address: editaddress.trim(),
      initiatedBy: login.data.user.role,
    }

    return dispatch(updateEmergencyNumber(editEmergencyNo))
  }

  const disabledButton = () => {
    return !(
      editname &&
      editphone &&
      editphone.length === 10 &&
      editemail &&
      isEditEmailValid &&
      editemergencytype
    )
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: '600px' }}
      >
        <ModalHeader toggle={toggle}>Emergency Contact</ModalHeader>
        <ModalBody>
          <Row style={{ justifyContent: 'center' }}>
            <Col md="6" sm="12" lg="6">
              <FormGroup>
                <Label for="editemergencytype">Contact For</Label>
                <Input
                  type="text"
                  name="editemergencytype"
                  placeholder="Contact For"
                  id="editemergencytype"
                  value={editemergencytype || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6" sm="12" lg="6">
              <FormGroup>
                <Label for="editname">Name</Label>
                <Input
                  type="text"
                  name="editname"
                  placeholder="Name"
                  id="editname"
                  value={editname || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6" sm="12" lg="6">
              <FormGroup>
                <Label for="editphone">Phone Number</Label>
                <Input
                  type="text"
                  name="editphone"
                  placeholder="Phone"
                  id="editphone"
                  maxLength="10"
                  value={editphone || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6" sm="12" lg="6">
              <FormGroup>
                <Label for="editemail">Email</Label>
                <Input
                  type="email"
                  name="editemail"
                  placeholder="Email"
                  id="editemail"
                  value={editemail || ''}
                  onChange={handleChange}
                  required
                  invalid={editemail && !isEditEmailValid}
                />
                <FormFeedback style={{ textAlign: 'center' }}>
                  {editemail && !isEditEmailValid ? 'Invalid Email' : ''}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" sm="12" lg="12">
              <FormGroup>
                <Label for="editaddress">Address</Label>
                <Input
                  type="textarea"
                  name="editaddress"
                  placeholder="Address"
                  rows="2"
                  id="editaddress"
                  value={editaddress || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12" sm="12" lg="12">
              <FormGroup>
                <Label>Status</Label>
                <br />
                <Switch
                  defaultChecked={isActive}
                  name="isActive"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={onChange}
                  // style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div style={{ textAlign: 'center' }} className="errorText">
            {errMessage}
          </div>
          <Button
            // style={{ float: 'right', margin: '5px' }}
            color="secondary"
            className="modalCustomButton emergencyEdit"
            disabled={updatedEmergencyNumber.isLoading || disabledButton()}
            onClick={handleEditEmergencyNumber}
          >
            {updatedEmergencyNumber.isLoading ? <Spin /> : 'Edit'}{' '}
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditEmergencyNumber
