import axios from '../../config/axios'
import {
  ADD_HELPER_SUCCESS,
  ADD_HELPER_FAILED,
  LOADING_ADD_HELPER,
  RESET_ADD_HELPER,
  ADD_HELPER_NETWORK_ERROR,
} from '../types'

const resetaddHelper = () => {
  return {
    type: RESET_ADD_HELPER,
  }
}

const addHelper = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_HELPER })
  console.log(userData)
  axios
    .post('helper/addHelper', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADD_HELPER_NETWORK_ERROR, payload: err }))
}

function success(data) {
  return {
    type: ADD_HELPER_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_HELPER_FAILED,
    payload: data,
  }
}

export { resetaddHelper, addHelper }
