import axios from 'config/axios'
import {
  UPDATE_SUB_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_FAILED,
  UPDATE_SUB_CATEGORY_NETWORK_ERROR,
  LOADING_UPDATE_SUB_cATEGORY,
  RESET_UPDATE_SUB_CATEGORY,
} from 'redux/types'

const resetUpdateSubCategory = () => {
  return {
    type: RESET_UPDATE_SUB_CATEGORY,
  }
}

const updateSubCategory = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_SUB_cATEGORY })
  axios
    .post('dashboard/updateSubCategory', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_SUB_CATEGORY_NETWORK_ERROR,
        payload: err,
      })
    })
}

function success(data) {
  return {
    type: UPDATE_SUB_CATEGORY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_SUB_CATEGORY_FAILED,
    payload: data,
  }
}

export { resetUpdateSubCategory, updateSubCategory }
