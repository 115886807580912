import React, { useState } from 'react'
import PanelHeader from 'components/PanelHeader/PanelHeader'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import MntBilling from 'assets/img/Icons/Maintaince/MntBilling.png'
import GatewayMaster from './GatewayMaster'
import GatewayMasterDetails from './GatewayMasterDetails'
// import SearchMntBilling from './SearchMntBilling'
// import ViewMntBilling from './ViewMntBilling'

const GatewayManagement = () => {

    // const [sendData, setSendData] = useState('')
    // const searchFilter = (value) => {
    //   setSendData(value)
    // }

    const [isSearch , setIsSearch] = useState(false)

  return (
    <>
    <GatewayMaster setIsSearch={() => setIsSearch(!isSearch)}/>
    <GatewayMasterDetails onTap={isSearch}/>
    </>
  )
}

export default GatewayManagement
