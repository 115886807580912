import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col, CardImg, CardText,
  CardTitle, CardSubtitle
} from "reactstrap";
import AddEmergencyNumber from './AddEmergencyNumber';
import EmergencyNumberList from './EmergencyNumberList';
import PanelHeader from "components/PanelHeader/PanelHeader.js";


function EmergencyNumber() {

  const [screen,setScreen] = useState("contactList");

  const handleScreen = (screen) => {
    setScreen(screen);
  }

	return (
    <> 
      <AddEmergencyNumber />
      <EmergencyNumberList />
    </>
  );
}

export default EmergencyNumber;
