import axios from '../../config/axios'
import {
  ADD_SOCIETY_MNT_TYPE_SUCCESS,
  ADD_SOCIETY_MNT_TYPE_FAILED,
  LOADING_ADD_SOCIETY_MNT_TYPE,
  RESET_ADD_SOCIETY_MNT_TYPE,
  ADD_SOCIETY_MNT_TYPE_NETWORKERROR,
} from '../types'

const resetaddSocietyMnt = () => {
  return {
    type: RESET_ADD_SOCIETY_MNT_TYPE,
  }
}

const addSocietyMnt = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_SOCIETY_MNT_TYPE })
  console.log(userData)
  axios
    .post('mnt/addSocietyMntType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_SOCIETY_MNT_TYPE_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: ADD_SOCIETY_MNT_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_SOCIETY_MNT_TYPE_FAILED,
    payload: data,
  }
}

export { resetaddSocietyMnt, addSocietyMnt }
