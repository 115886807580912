import {
	ADDAREATYPE_SUCCESS,
	ADDAREATYPE_FAILED,
	LOADING_ADDAREATYPE,
	RESET_ADDAREATYPE,
	ADDAREATYPE_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADDAREATYPE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case ADDAREATYPE_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case ADDAREATYPE_FAILED:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case ADDAREATYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case 	RESET_ADDAREATYPE:
            return (state = initialState);

        default:
            return state;
    }
};