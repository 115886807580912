import {
	ADDVEHICLETYPE_SUCCESS,
	ADDVEHICLETYPE_FAILED,
	LOADING_ADDVEHICLETYPE,
	RESET_ADDVEHICLETYPE,
	ADDVEHICLETYPE_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADDVEHICLETYPE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                networkError: ""
            };
				case 	ADDVEHICLETYPE_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: ""
                                ,
                networkError: ""
                
            };
				case 	ADDVEHICLETYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: ""
                                ,
                networkError: ""
            };
            case 	ADDVEHICLETYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                networkError: "Network Error"
            };
				case RESET_ADDVEHICLETYPE:
            return (state = initialState);

        default:
            return state;
    }
};