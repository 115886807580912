import {
  ADDSOCIETY_SUCCESS,
  ADDSOCIETY_FAILED,
  LOADING_ADDSOCIETY,
  RESET_ADDSOCIETY,
  ADDSOCIETY_NETWORKERROR,
} from '../types'

const initialState = {
  isLoading: false,
  success: false,
  network: false,
  failed: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ADDSOCIETY:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case ADDSOCIETY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case ADDSOCIETY_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case ADDSOCIETY_NETWORKERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: false,
        network: true,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }

    case RESET_ADDSOCIETY:
      return (state = initialState)

    default:
      return state
  }
}
