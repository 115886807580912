import {
  ADD_SOCIETY_TEMPLATE_SUCCESS,
  ADD_SOCIETY_TEMPLATE_FAILED,
  ADD_SOCIETY_TEMPLATE_NETWORK_ERROR,
  LOADING_ADD_SOCIETY_TEMPLATE,
  RESET_ADD_SOCIETY_TEMPLATE,
} from 'redux/types'
import axios from 'config/axios'

export const resetAddSocietyTemplate = () => {
  return { type: RESET_ADD_SOCIETY_TEMPLATE }
}

export const addSocietyTemplate = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_SOCIETY_TEMPLATE })
  axios
    .post('mnt/addSocietyTemplate', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
      }
    })
    .catch((err) => {
      dispatch({ type: ADD_SOCIETY_TEMPLATE_NETWORK_ERROR, payload: err })
    })
}

function success(data) {
  return { type: ADD_SOCIETY_TEMPLATE_SUCCESS, payload: data }
}

function failed(data) {
  return { type: ADD_SOCIETY_TEMPLATE_FAILED, payload: data }
}
