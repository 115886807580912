import axios from "../../config/axios";
import {
		PINCODE_SUCCESS,
		PINCODE_FAILED,
		RESET_PINCODE,
		LOADING_PINCODE,
		PINCODE_NETWORKERROR
	} from "../types";

const resetgetPincodeDetails = () => {
    return {
        type: RESET_PINCODE,		
    };
};

const getPincodeDetails = userData => dispatch => {
    dispatch({ type: LOADING_PINCODE });
    axios
        .get(`https://api.postalpincode.in/pincode/${userData}`)
        .then(res => {
            switch (res.data[0].Status) {

                case "Success": 
                    dispatch(success(res.data[0]));
                    break;
                default:
                    dispatch(failed(res.data[0]));
                    break;
            }
        })
        .catch(err => dispatch({ type: PINCODE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: PINCODE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: PINCODE_FAILED,
        payload: data
    };
}

export { resetgetPincodeDetails, getPincodeDetails } ;