import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from 'reactstrap'
import UserPage from 'views/Profile/UserPage.js'

import {
  withRouter,
  useHistory,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import routes from '../../routes.js'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: 'transparent',
      isModalOpen: false
    }
  }

  sidebarToggle = React.createRef()

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      })
    } else {
      this.setState({
        color: 'white',
      })
    }
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  getBrand = () => {
    var name
    routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.layout + prop.path === this.props.location.pathname) {
            name = prop.name
          }
          return null
        })
      } else {
        if (prop.redirect) {
          if (prop.layout + prop.path === this.props.location.pathname) {
            name = prop.name
          }
        } else {
          if (prop.layout + prop.path === this.props.location.pathname) {
            name = prop.name
          }
        }
      }
      return null
    })
    return name
  }

  openSidebar = () => {
    document.documentElement.classList.toggle('nav-open')
    this.sidebarToggle.current.classList.toggle('toggled')
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'white',
      })
    } else {
      this.setState({
        color: 'transparent',
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this))
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
      this.sidebarToggle.current.classList.toggle('toggled')
    }
  }

  handleLogout(e) {
    sessionStorage.removeItem('persist:root')
    window.location.href = '/'
  }
  handleCancel = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  };

  render() {
    const login = this.props.login

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <>
        <Navbar
          color={
            this.props.location.pathname.indexOf('full-screen-maps') !== -1
              ? 'white'
              : this.state.color
          }
          expand="lg"
          className={
            this.props.location.pathname.indexOf('full-screen-maps') !== -1
              ? 'navbar-absolute fixed-top'
              : 'navbar-absolute fixed-top ' +
              (this.state.color === 'transparent' ? 'navbar-transparent ' : '')
          }
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button
                  type="button"
                  ref={this.sidebarToggle}
                  className="navbar-toggler"
                  onClick={() => this.openSidebar()}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              {/* <NavbarBrand href="/">{this.getBrand()}</NavbarBrand> */}
              <Link to="/Society/dashboard">
                {login.data.user.role === 'Society' && login.data.society
                  ? login.data.society.societyName
                  : ''}
              </Link>
            </div>
            <NavbarToggler onClick={this.toggle}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="justify-content-end"
            >
              {/* <form>
              <InputGroup className="no-border">
                <Input placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_zoom-bold" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form> */}
              <Nav navbar>
                {/* <NavItem>
                <Link to="#pablo" className="nav-link">
                  <i className="now-ui-icons media-2_sound-wave" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </Link>
              </NavItem> */}
                {/* <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="now-ui-icons location_world" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a">Action</DropdownItem>
                  <DropdownItem tag="a">Another Action</DropdownItem>
                  <DropdownItem tag="a">Something else here</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
                {/* <NavItem>
                <Link to="#pablo" className="nav-link">
                  <i className="now-ui-icons users_single-02" />
                  <p>
                    <span className="d-lg-none d-md-block">Account</span>
                  </p>
                </Link>
              </NavItem> */}
                <Dropdown
                  nav
                  isOpen={this.state.dropdownOpen}
                  toggle={(e) => this.dropdownToggle(e)}
                >
                  <DropdownToggle caret nav>
                    <i className="now-ui-icons users_single-02" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      //  tag="a"
                      className="logoutProfile"
                      onClick={() => this.setState({ isModalOpen: true })}
                    >
                      Profile
                      {/* <NavLink to="/Society/user-page">Profile</NavLink> */}
                    </DropdownItem>
                    <DropdownItem
                    //  tag="a"
                    //  onClick={this.changePassword}
                    >
                      <Link to="/Society/changePassword">Change Password</Link>
                    </DropdownItem>
                    <DropdownItem tag="a" className="logoutProfile" onClick={this.handleLogout}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <UserPage showModal={this.state.isModalOpen} handleCancel={this.handleCancel} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
})

export default withRouter(connect(mapStateToProps, null)(Header))
