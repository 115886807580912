import {
  UPDATE_UNIT_FLATLIST_FAILED,
  UPDATE_UNIT_FLATLIST_NETWORK_ERROR,
  UPDATE_UNIT_FLATLIST_SUCCESS,
  LOADING_UPDATE_UNIT_FLATLIST,
  RESET_UPDATE_UNIT_FLATLIST,
} from 'redux/types'
import axios from 'config/axios'

const resetUpdateUnitFlatList = () => {
  return {
    type: RESET_UPDATE_UNIT_FLATLIST,
  }
}

const updateUnitFLatList = (userData) => (dispatch) => {
  dispatch({
    type: LOADING_UPDATE_UNIT_FLATLIST,
  })
  axios
    .post('society/updateUnit', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_UNIT_FLATLIST_NETWORK_ERROR, payload: err })
    })
}

function success(data) {
  return {
    type: UPDATE_UNIT_FLATLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_UNIT_FLATLIST_FAILED,
    payload: data,
  }
}

export { resetUpdateUnitFlatList, updateUnitFLatList }
