import axios from '../../config/axios'
import {
  ADDFLATLIST_SUCCESS,
  ADDFLATLIST_FAILED,
  LOADING_ADDFLATLIST,
  RESET_ADDFLATLIST,
  ADDFLATLIST_NETWORKERROR,
} from '../types'

const resetAddFlatList = () => {
  return {
    type: RESET_ADDFLATLIST,
  }
}

const addFlatList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADDFLATLIST })
  axios
    .post('flat/addFlatList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADDFLATLIST_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: ADDFLATLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADDFLATLIST_FAILED,
    payload: data,
  }
}

export { resetAddFlatList, addFlatList }
