import axios from "../../config/axios"
import {
	ADD_DOCTYPE_SUCCESS,
	ADD_DOCTYPE_FAILED,
	LOADING_ADD_DOCTYPE,
	RESET_ADD_DOCTYPE,
	ADD_DOCTYPE_NETWORK_ERROR
} from "../types";

const resetAddDocType = () => {
    return {
        type: RESET_ADD_DOCTYPE
    };
};

const addDocType = userData => dispatch => {
    dispatch({ type: LOADING_ADD_DOCTYPE });
    axios
        .post("society/addSocietyDocumentType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADD_DOCTYPE_NETWORK_ERROR, payload: err }));

};

function success(data) {
    return {
        type: ADD_DOCTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADD_DOCTYPE_FAILED,
        payload: data
    };
}

export { resetAddDocType, addDocType } ;

