import React from 'react'
import Society from 'assets/img/Brand/hiiSociety.png'

function Loader() {
  return (
    <div className="loader-container">
      <img className="loading-logo" src={Society} />
      <h4>loading ...... please wait</h4>
    </div>
  )
}

export default Loader
