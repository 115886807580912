import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Tooltip } from 'antd'

import AddEmailProvider from './AddEmailProvider'
import AddSMSProvider from './AddSMSProvider'
import AddDocumentType from './AddDocumentType'
import DocumentType from './DocumentType'
import AddUnitDocumentType from './Unit Document/AddUnitDocumentType'
import UnitDocumentType from './Unit Document/UnitDocumentType'
import AddDocument from './Document/AddDocument.jsx'
import Document from './Document/Document'
import AddHelperDoc from './Helper Document/AddHelperDoc'
import HelperDocList from './Helper Document/HelperDocList'
import SearchUnitDocumentType from './Unit Document/SearchUnitDocumentType'

import SearchHelperDoc from './Helper Document/SearchFilter'

import ConfigurationsIcon from 'assets/img/Icons/Sidebar/configurations.png'

import UserIcon from 'assets/img/Icons/Categories/user.png'
import HelperIcon from 'assets/img/Icons/Categories/helper.png'
import GuardIcon from 'assets/img/Icons/Categories/guard.png'

import SmsProviderIcon from 'assets/img/Icons/Configuration/smsprovider.png'
import SocietyDocumentIcon from 'assets/img/Icons/Configuration/societydocument.png'
import DocumentIcon from 'assets/img/Icons/Configuration/document.png'
import AddSocietyDoc from './Society Document/AddSocietyDoc'
import SocietyDocList from './Society Document/SocietyDocList'
import SearchSocietyDoc from './Society Document/SearchSocietyDoc'
import SearchDocument from './Document/SearchDocument'

function Configuration() {
  const [activeTab, setActiveTab] = useState('')
  const [isSearch , setIsSearch] = useState(false)
  const login = useSelector(({ login }) => login)

  useEffect(() => {
    login.data.user.role === 'Society'
      ? setActiveTab('Helper')
      : setActiveTab('Society Document')
  }, [login.success])

  const configArr = [
    {
      id: 1,
      name: 'SMS Provider',
      display: 'SuperAdmin',
      // icon: 'fas fa-sms'
      icon: `${SmsProviderIcon}`,
    },
    // {id: 2, name: "Email Provider", display: "SuperAdmin", icon: "fas fa-envelope-open-text"},
    {
      id: 3,
      name: 'Society Document ',
      display: 'SuperAdmin',
      // icon: 'fas fa-file-alt',
      icon: `${SocietyDocumentIcon}`,
    },
    {
      id: 4,
      name: 'User',
      display: 'Society',
      // icon: 'fas fa-file-alt',
      icon: `${UserIcon}`,
    },
    {
      id: 5,
      name: 'Helper',
      display: 'Society',
      // icon: 'fas fa-file-alt',
      icon: `${HelperIcon}`,
    },
    {
      id: 5,
      name: 'Document ',
      display: 'SuperAdmin',
      // icon: 'fas fa-file-alt',
      icon: `${DocumentIcon}`,
    },
  ]

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const typeComponentArr = (t) => {
    switch (t) {
      case 'SMS Provider':
        return <AddSMSProvider />
      case 'Society Document ':
        return <SearchSocietyDoc setIsSearch={() => setIsSearch(!isSearch)}/>
      case 'User':
        return <SearchUnitDocumentType setIsSearch={() => setIsSearch(!isSearch)}/>
      case 'Document ':
        return <SearchDocument setIsSearch={() => setIsSearch(!isSearch)} />
      case 'Helper':
        return <SearchHelperDoc />

      default:
        return null
    }
  }

  const displayComponentArr = (t) => {
    switch (t) {
      case 'SMS Provider':
        return null
      // case "Email Provider" : return null;
      case 'Society Document ':
        return <SocietyDocList onTap={isSearch}/>
      case 'User':
        return <UnitDocumentType onTap={isSearch} />
      case 'Document ':
        return <Document onTap={isSearch} />
      case 'Helper':
        return <HelperDocList />
      default:
        return null
    }
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons gestures_tap-01'} /> */}
                  <img
                    src={ConfigurationsIcon}
                    alt="ConfigurationsIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                {login.data.user.role === 'SuperAdmin' ? (
                  <span className="head-title">Configurations</span>
                ) : (
                  <span className="head-title">Document Configurations</span>
                )}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Nav tabs>
                      {configArr.map((d, id) => (
                        <>
                          {login.data.user.role === d.display ? (
                            <NavItem>
                              <NavLink
                                data-id={id}
                                className={classnames({
                                  active: activeTab === id,
                                })}
                                onClick={() => {
                                  toggle(id)
                                }}
                                key={id}
                              >
                                <div className={`sd-col`}>
                                  <div className="sd-ic">
                                    {/* <i className={d.icon}></i> */}
                                    <img src={d.icon} alt={d.icon} />
                                  </div>
                                  <p className="sd-label">{d.name}</p>
                                </div>
                              </NavLink>
                            </NavItem>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {configArr.map((d, id) => (
                        <TabPane tabId={id} data-id={id} key={id}>
                          {typeComponentArr(d.name)}
                        </TabPane>
                      ))}
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          {configArr.map((d, id) => (
            <TabPane tabId={id} data-id={id} key={id}>
              {displayComponentArr(d.name)}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  )
}

export default Configuration
