import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NoticeBoard from 'assets/img/Brand/notice.png'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import { Alert, Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
// import NoticeDetails from './NoticeDetails'
import PaginationComponent from 'views/Pagination'
import AddMaintenance from './AddMaintenanceMapping'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import {
  resetGetSocietyMnt,
  getSocietyMnt,
} from 'redux/actions/getSocietyMntTypeAction'
import AddFeeConfig from './AddFeeConfig'
import { resetaddSocietyMnt } from 'redux/actions/addSocietyMntTypeAction'
import EditFeeConfig from './EditFeeConfig'
// import ViewFeeConfig from 'views/FeeConfig/ViewFeeConfig'

function ViewFeeConfig(data) {
  const [addModal, setAddModal] = useState(false)
  const [billDetails, setBillDetails] = useState(false)
  const [page, setPage] = useState(1)
  const [editModal, setEditModal] = useState(false)
  const [dataEdit, setDataEdit] = useState('')
  const [editId, setEditId] = useState()
  const [mntTypeId, setMntTypeId] = useState()

  const login = useSelector(({ login }) => login)

  const mntType = useSelector(({ getMntType }) => getMntType)
  const socMntType = useSelector(({ getSocietyMnt }) => getSocietyMnt)

  const addMntType = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const dispatch = useDispatch()
  const limit = 10
  const totalItems = socMntType.data.count

  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  const handleMntEdit = (d) => {
    console.log(d, 'This is the data')
    setMntTypeId(d.mntTypeId)
    setEditModal(!editModal)
    setDataEdit(d)
    setEditId(d.id)
  }

  const getSocietyMntTypeRequest = () => {
    const getMaintenanceTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      columnType: 'fee',
    }
    return dispatch(getSocietyMnt(getMaintenanceTypeReq))
  }

  useEffect(() => {
    getSocietyMntTypeRequest()

    return () => resetGetSocietyMnt()
  }, [])
  useEffect(() => {
    if (addMntType.success) {
      getSocietyMntTypeRequest()
      setAddModal(false)
      NotificationPopups('success', 'Success', addMntType.data.message)
    }
    if (addMntType.failed) {
      NotificationPopups('error', 'Failed', (addMntType.data.errorVOList.map((error , id) =>(<>{id === 0 ? 1 : id+1}-{error.errorMessage}<br/></>))))
    }
    if (addMntType.network) {
      NotificationPopups('error', 'Network Error', addMntType.data.message)
    }
    return () => {
      dispatch(resetaddSocietyMnt())
    }
  }, [addMntType.success,addMntType.failed,addMntType.network])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Fee Mapping List
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {socMntType.success && (
                    <>
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              {/* <th>Column Number</th> */}
                              <th>Calculation Logic</th>
                              <th>Monthly</th>
                              <th>Total Amount</th>
                              <th></th>
                            </tr>
                          </thead>
                          {socMntType.success && (
                            <tbody>
                              {socMntType.success &&
                                socMntType.data?.societyMntList.map((d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {d.name}
                                      </td>
                                      <td
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {d.mntType}
                                      </td>
                                      {/* <td>{d.columnNumber}</td> */}
                                      <td>{d.calculationLogic}</td>
                                      <td>{d.isMonthly ? 'Yes' : 'No'}</td>
                                      <td>₹ {d.totalAmount}</td>
                                      <td>
                                        <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => {
                                            handleMntEdit(d)
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {socMntType.failed && (
                    <div style={{ textAlign: 'center' }}>
                      <Alert message={socMntType.data.message} type="info" style={{ margin: "20px 0" }} />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {socMntType.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '250px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Fee Mapping</ModalHeader>
          <ModalBody>
            <AddFeeConfig />
          </ModalBody>
        </Modal>
      )}

      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="lg"
          style={{ maxWidth: '250px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <EditFeeConfig
              dataEdit={dataEdit}
              editId={editId}
              closeModal={toggleEdit}
              mntTypeId={mntTypeId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewFeeConfig
