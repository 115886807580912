import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FileViewer from 'react-file-viewer'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Select } from 'antd'
import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'

const ViewGatewayDetails = ({ view, modal, toggle, errMessage }) => {
    const [ownerName, setOwnerName] = useState(view.displayName);
    const [intType, setIntType] = useState(view.integrationType);
    const [currency, setCurrency] = useState(view.accTerminalCurrency);
    const [intName, setIntName] = useState(view.integrationName);
    const [memUserName, setUserName] = useState(view.accName);
    const [memId, setMemId] = useState(view.acctToId);
    const [memSecretKey, setMemSecretKey] = useState(view.acctMerchantSecKey);
    const [partId, setPartId] = useState(view.acctPartnerId);
    const [partKey, setPartKey] = useState(view.acctPartnerKey);
    const [partUserName, setPartUserName] = useState(view.acctPartnerUsername);
    const [termId, setTermId] = useState(view.acctTerminalId);
    const [toType, setToType] = useState(view.acctToType);
    const [payType, setPayType] = useState(view.acctPaymentType);
    const [payBrand, setPayBrand] = useState(view.acctPaymentBrand);
    const [payMode, setPayMode] = useState(view.acctPaymentMode);
    const [transUrl, setTransUrl] = useState(view.acctTransactionUrl);
    const [redirectUrl, setRedirectUrl] = useState(view.acctRedirectUrl);
    const [checkoutTransUrl, setCheckoutTransUrl] = useState(view.acctSdkTransactionUrl);
    const [withdrawUrl, setWithdrawUrl] = useState(view.acctWithdrawUrl);
    const [authTokenUrl, setAuthTokenUrl] = useState(view.acctAuthTokenUrl);

    const { Option } = Select
    const login = useSelector(({login})=> login);
    const dispatch = useDispatch();

  return (
    <>
    <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: '1000px', maxHeight: '500px' }}
      >
        <ModalHeader toggle={toggle}>View Gateway</ModalHeader>
        <ModalBody>
        <Form>
          <Row>
          <Col md="12" sm="12" xs="12" lg="12">
            <Row>
              {/* Row - 1 */}
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="areaType" required>Owner Name</Label>
                      <Select
                        type="select"
                        showSearch
                        value={ownerName}
                        placeholder="Select Owner Name"
                        optionFilterProp="children"
                        // onChange={onChangeSearchAreaType}
                        // onSearch={onSearchAreaType}
                        style={{ width: '100%' }}
                        disabled
                      >
                        <Option className="loginSelect" value="">
                          Select Owner Name
                        </Option>
                        {/* {areaTypes.success &&
                          areaTypes.data.areaTypeList.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.type}
                            >
                              {d.type}
                            </Option>
                          ))} */}
                      </Select>
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="areaId" required>Integration Type</Label>
                      <Select
                        type="select"
                        showSearch
                        value={intType}
                        placeholder="Select Integration Type"
                        optionFilterProp="children"
                        // onChange={onChangeSearchAreaId}
                        // onSearch={onSearchAreaId}
                        style={{ width: '100%' }}
                        disabled
                      >
                        <Option className="loginSelect" value="">
                          Select Integration Type
                        </Option>
                      </Select>
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="currency" required>Currency</Label>
                      <Select
                        name = "currency"
                        type="select"
                        showSearch
                        value={currency || ""}
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        disabled
                        // onChange={(e)=> onInputChange(e)}
                        // onSearch={onSearchAreaId}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Currency
                        </Option>
                      </Select>
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="intName">Integration Name</Label>
                      <Input
                        type="text"
                        name="intName"
                        id="intName"
                        placeholder="Integration Name"
                        maxLength="10"
                        value={intName}
                        disabled
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                      />
                    </FormGroup>
              </Col>

              {/* Row - 2 */}
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memUserName">Member User Name/Number</Label>
                      <Input
                        type="text"
                        name="memUserName"
                        id="memUserName"
                        placeholder="Member User Name/Number"
                        maxLength="10"
                        value={memUserName}
                        // onChange={(e)=> onInputChange(e)}
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memId">Member ID/SID</Label>
                      <Input
                        type="text"
                        name="memId"
                        id="memId"
                        placeholder="Member ID/SID"
                        maxLength="10"
                        value={memId}
                        // onChange={(e)=> onInputChange(e, "number")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="memSecretKey">Member Secret Key</Label>
                      <Input
                        type="text"
                        name="memSecretKey"
                        id="memSecretKey"
                        placeholder="Member Secret Key"
                        maxLength="10"
                        value={memSecretKey}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partId">Partner ID</Label>
                      <Input
                        type="text"
                        name="partId"
                        id="partId"
                        placeholder="Partner ID"
                        maxLength="10"
                        value={partId || ''}
                        // onChange={(e)=> onInputChange(e, "number")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              {/* Row - 3 */}
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="termId">Terminal ID</Label>
                      <Input
                        type="text"
                        name="termId"
                        id="termId"
                        placeholder="Terminal ID"
                        maxLength="10"
                        value={termId}
                        // onChange={(e)=> onInputChange(e, "number")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="toType">To Type</Label>
                      <Input
                        type="text"
                        name="toType"
                        id="toType"
                        placeholder="To Type"
                        maxLength="10"
                        value={toType}
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="redirectUrl">Redirect URL</Label>
                      <Input
                        type="text"
                        name="redirectUrl"
                        id="redirectUrl"
                        placeholder="Redirect URL"
                        maxLength="10"
                        value={redirectUrl}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payType">Payment Type</Label>
                      <Input
                        type="text"
                        name="payType"
                        id="payType"
                        placeholder="Payment Type"
                        maxLength="10"
                        value={payType}
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>

              {/* Row - 4 */}
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payBrand">Payment Brand</Label>
                      <Input
                        type="text"
                        name="payBrand"
                        id="payBrand"
                        placeholder="Payment Brand"
                        maxLength="10"
                        value={payBrand}
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="payMode">Payment Mode</Label>
                      <Input
                        type="text"
                        name="payMode"
                        id="payMode"
                        placeholder="Payment Mode"
                        maxLength="10"
                        value={payMode}
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="transUrl">Transaction URL</Label>
                      <Input
                        type="text"
                        name="transUrl"
                        id="transUrl"
                        placeholder="Transaction URL"
                        maxLength="10"
                        value={transUrl}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="checkoutTransUrl">CheckOut Transaction URL</Label>
                      <Input
                        type="text"
                        name="checkoutTransUrl"
                        id="checkoutTransUrl"
                        placeholder="CheckOut Transaction URL"
                        maxLength="10"
                        value={checkoutTransUrl}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>

              {/* Row - 5 */}
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="withdrawUrl">WithDraw URL</Label>
                      <Input
                        type="text"
                        name="withdrawUrl"
                        id="withdrawUrl"
                        placeholder="WithDraw URL"
                        maxLength="10"
                        value={withdrawUrl}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="authTokenUrl">AuthToken URL</Label>
                      <Input
                        type="text"
                        name="authTokenUrl"
                        id="authTokenUrl"
                        placeholder="AuthToken URL"
                        maxLength="10"
                        value={authTokenUrl}
                        // onChange={(e)=> onInputChange(e, "both")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partKey">Partner Key</Label>
                      <Input
                        type="text"
                        name="partKey"
                        id="partKey"
                        placeholder="Partner Key"
                        maxLength="10"
                        value={partKey}
                        // onChange={(e)=> onInputChange(e, "number")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>
              <Col md="3" sm="12" xs="12" lg="3">
                    <FormGroup>
                      <Label for="partUserName">Partner User Name</Label>
                      <Input
                        type="text"
                        name="partUserName"
                        id="partUserName"
                        placeholder="Partner User Name"
                        maxLength="10"
                        value={partUserName}
                        // onChange={(e)=> onInputChange(e, "alphabet")}
                        required
                        disabled
                      />
                    </FormGroup>
              </Col>

            </Row>
          </Col>
        </Row>
        </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ViewGatewayDetails