import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NoticeBoard from 'assets/img/Brand/notice.png'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import { Alert, Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
// import NoticeDetails from './NoticeDetails'
import PaginationComponent from 'views/Pagination'
import AddMaintenance from './AddMaintenanceMapping'
import {
  resetDeleteSocMntType,
  deleteSocMntType,
} from 'redux/actions/deleteSocMntTypeAction'
import {
  resetGetSocietyMnt,
  getSocietyMnt,
} from 'redux/actions/getSocietyMntTypeAction'
import { resetaddSocietyMnt } from 'redux/actions/addSocietyMntTypeAction'
import EditMaintenanceList from './EditMaintenanceList'
import EditBtn from 'assets/img/Brand/buttons/edit-icon.png'
import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'
function ViewMaintenanceList() {
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [billDetails, setBillDetails] = useState(false)
  const [page, setPage] = useState(1)
  const [dataEdit, setDataEdit] = useState('')
  const [editId, setEditId] = useState()
  const [mntTypeId, setMntTypeId] = useState()

  const login = useSelector(({ login }) => login)
  const addedSocMnt = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const socMntType = useSelector(({ getSocietyMnt }) => getSocietyMnt)
  const mntType = useSelector(({ getMntType }) => getMntType)
  const delSocMntType = useSelector(({ deleteSocMntType }) => deleteSocMntType)

  const addMntType = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const dispatch = useDispatch()
  const limit = 6
  const totalItems = socMntType.data.count

  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  const toggleBill = () => {
    setBillDetails(!billDetails)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  
  const getSocietyMntTypeRequest = () => {
    const getMaintenanceTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      columnType: 'master',
      page,
      limit:5
    }
    return dispatch(getSocietyMnt(getMaintenanceTypeReq))
  }
  
  useEffect(() =>{
    getSocietyMntTypeRequest()
  },[page])

  const handleMntEdit = (d) => {
    console.log(d, 'This is the data')
    setMntTypeId(d.mntTypeId)
    setEditModal(!editModal)
    setDataEdit(d)
    setEditId(d.id)
  }

  const toggleEdit = () => {
    setEditModal(!editModal)
  }
  const handelDelte = (id) => {
    const deleteSocMntTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      id,
    }
    return dispatch(deleteSocMntType(deleteSocMntTypeReq))
  }
  useEffect(() => {
    getSocietyMntTypeRequest()

    return () => resetGetSocietyMnt()
  }, [])

  useEffect(() => {
    if (addMntType.success) {
      getSocietyMntTypeRequest()
      setAddModal(false)
    }

    return () => {
      dispatch(resetaddSocietyMnt())
    }
  }, [addMntType.success])
  
  useEffect(() => {
    getSocietyMntTypeRequest()

    if (delSocMntType.success) {
      NotificationPopups('success', 'Success', delSocMntType.data.message)
    }
    if (delSocMntType.failed) {
      NotificationPopups('error', 'Failed', delSocMntType.data.message)
    }
    if (delSocMntType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
  }, [delSocMntType.success, delSocMntType.failed, delSocMntType.network])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add Field
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Field Mapping List
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {socMntType.success && (
                    <>
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Type</th>
                              {/* <th>Column Number</th> */}
                              <th></th>
                            </tr>
                          </thead>
                          {socMntType.success && (
                            <tbody>
                              {socMntType.success &&
                                socMntType.data?.societyMntList.map((d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {d.name}
                                      </td>
                                      <td
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {d.mntType}
                                      </td>
                                      {/* <td>{d.columnNumber}</td> */}
                                      <td>
                                        {/* <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => {
                                            handleMntEdit(d)
                                          }}
                                        >
                                          Edit
                                        </Button> */}
                                        <span
                                          style={{
                                            marginLeft: '15px',
                                            cursor: 'pointer',
                                          }}
                                          title="Edit"
                                        >
                                          <img
                                            src={EditBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() => handleMntEdit(d)}
                                          />
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: '15px',
                                            cursor: 'pointer',
                                          }}
                                          title="Delete"
                                        >
                                          <img
                                            src={TrashBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() => handelDelte(d.id)}
                                          />
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {socMntType.failed && (
                    <div style={{ textAlign: 'center'}}>
                      <Alert message={socMntType.data.message} type="info" style={{margin:"20px 0"}}/>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {socMntType.success && (
                    <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '250px', maxHeight: '500px' ,marginLeft:"auto" , marginRight:"auto"}}
        >
          <ModalHeader toggle={toogleAdd}>Add Field</ModalHeader>
          <ModalBody>
            <AddMaintenance />
          </ModalBody>
        </Modal>
      )}

      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="lg"
          style={{ maxWidth: '250px', maxHeight: '500px',marginLeft:"auto" , marginRight:"auto" }}
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <EditMaintenanceList
              dataEdit={dataEdit}
              editId={editId}
              closeModal={toggleEdit}
              mntTypeId={mntTypeId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewMaintenanceList
