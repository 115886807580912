import axios from '../../config/axios'
import {
  UPDATEBUILDING_SUCCESS,
  UPDATEBUILDING_FAILED,
  LOADING_UPDATEBUILDING,
  RESET_UPDATEBUILDING,
  UPDATEBUILDING_NETWORKERROR,
} from '../types'

const resetupdateBuilding = () => {
  return {
    type: RESET_UPDATEBUILDING,
  }
}

const updateBuilding = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATEBUILDING })
  console.log(userData)
  axios
    .post('society/updateArea', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATEBUILDING_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATEBUILDING_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATEBUILDING_FAILED,
    payload: data,
  }
}

export { resetupdateBuilding, updateBuilding }
