import axios from "../../config/axios";
import {
	DELETECOMPANYTYPE_SUCCESS,
	DELETECOMPANYTYPE_FAILED,
	LOADING_DELETECOMPANYTYPE,
	RESET_DELETECOMPANYTYPE,
	DELETECOMPANYTYPE_NETWORKERROR
	} from "../types";

const resetDeleteCompanyType = () => {
    return {
        type: RESET_DELETECOMPANYTYPE,		
    };
};

const deleteCompanyType = userData => dispatch => {
    dispatch({ type: LOADING_DELETECOMPANYTYPE });
		console.log(userData);
    axios
        .post("superAdmin/deleteCompanyType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: DELETECOMPANYTYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: DELETECOMPANYTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: DELETECOMPANYTYPE_FAILED,
        payload: data
    };
}

export { resetDeleteCompanyType, deleteCompanyType } ;