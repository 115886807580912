import axios from "../../config/axios"
import {
	GETNOTICELIST_SUCCESS,
	GETNOTICELIST_FAILED,
	LOADING_GETNOTICELIST,
	RESET_GETNOTICELIST,
    GETNOTICELIST_NETWORKERROR
} from "../types";

const resetgetNoticeList = () => {
    return {
        type: RESET_GETNOTICELIST
    };
};

const getNoticeList = userData => dispatch => {
    dispatch({ type: LOADING_GETNOTICELIST });
		console.log(userData);
    axios
        .post("notice/getAllNotices", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETNOTICELIST_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETNOTICELIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETNOTICELIST_FAILED,
        payload: data
    };
}

export { resetgetNoticeList, getNoticeList } ;