import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import {
  Tooltip,
  Popconfirm,
  Spin,
  Alert,
  Switch,
  List,
  Avatar,
  Skeleton,
} from 'antd'
import {
  resetGetVisitorDetails,
  getVisitorDetails,
} from 'redux/actions/getVisitorDetailsAction'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import moment from 'moment'
import './visitorstyle.css'
import { getGuestList } from '../../redux/actions/getGuestListAction'

const GuestList = ({ data, onTap }) => {

  let { name, mobileNumber, startDate, endDate } = data
  const [date, setDate] = useState('')

  const login = useSelector(({ login }) => login)
  
  const getGuestListRes = useSelector(
    ({ getGuestList }) => getGuestList)

  const [page, setPage] = useState(1)
  const [limit] = useState(6)

  const totalItems = getGuestListRes.data.count

  const dispatch = useDispatch()

  const getGuestListRequest = () => {
    const getGuestListReq = {
      initiatedBy: "User",
      societyId: login.data.society.id,
      name: name,
      // mobileNumber: mobileNumber,
      startDate,
      endDate,
      limit,
      page,
    }
    return dispatch(getGuestList(getGuestListReq))
  }

  useEffect(() => {
    getGuestListRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Col>
              <div className="typeHeading" id="onTop">Guest</div>
            </Col>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                <Col md="12" style={{ padding: '20px' }} id="onTop">
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        {/* <th>Building</th> */}
                        <th>Flat Number</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Parking Slot</th>
                        <th>Guard Name</th>
                        <th>Date</th>
                        <th>In-Time</th>
                        <th>Out-Time</th>
                        {/* <th>Timestamp</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {getGuestListRes.success && getGuestListRes.data.guestVOList.map((item) => {
                        return (
                          <tr>
                            {/* <td>-</td> */}
                            <td>{item.flatNumber}</td>
                            <td>{item.name}</td>
                            <td>{item.mobileNumber}</td>
                            <td>{item.parkingName === null ? '-' : item.parkingName}</td>
                            <td>{item.guardName}</td>
                            <td>
                              {`${item.date}`.split(" ")[0] !== "null" ?
                                <>
                                  {`${item.date}`.split(" ")[0]}
                                </>
                                :
                                <>
                                  -
                                </>
                              }
                            </td>
                            <td>{item.startTime}</td>
                            <td>{item.endTime}</td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>
                </Col>
              </Col>
              <Col md="12">
                {getGuestListRes.failed && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message="No Guest Found" type="info" style={{ margin: "20px 0" }} />
                  </div>
                )}
              </Col>
              <Col>
                {getGuestListRes.success && (
                  <div className="col-xs-1 center-block">
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default GuestList


