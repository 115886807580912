import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetGetProductServiceSubCategory,
  getProductServiceSubCategory,
} from 'redux/actions/getProductServiceSubCategoryAction'

function SearchProductServiceSubCategory(props) {
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [featureIcon, setFeatureIcon] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [productCategoryId, setProductCategoryId] = useState('')
  const [productCategoryName, setProductCategoryName] = useState('')

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const productServiceCat = useSelector(
    ({ getProductServiceCategory }) => getProductServiceCategory
  )
  const addedProductServCat = useSelector(
    ({ addProductServiceCategory }) => addProductServiceCategory
  )

  const productServiceSubCat = useSelector(
    ({ getProductServiceSubCategory }) => getProductServiceSubCategory
  )

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name') setName(value)
  }
  const labelOptionsDropDown = [
    {
      id: 1,
      name: 'Product',
    },
    {
      id: 2,
      name: 'Service',
    },
  ]

  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    setFeatureIcon(icon[1])
    setUpdatedPhoto(filesList[0].base64)
  }

  const { Option } = Select
  function onChangeFeature(value) {
    setType(value)
  }

  function onSearchFeature(val) {
    setType(val)
  }
  function onChangeProductCat(value) {
    const val = value.split(',')
    setProductCategoryId(val[0])
    setProductCategoryName(val[1])
  }

  function onSearchProductCat(val) {
    setProductCategoryName(val[1])
  }
 
  const getProductServiceCategoryRequest = () => {
    const getProductServiceCategoryRequest = {
      initiatedBy: login.data.user.role,
      productCategoryId,
      name,
      type,
      limit,
      page,
    }
    return dispatch(
      getProductServiceSubCategory(getProductServiceCategoryRequest)
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getProductServiceCategoryRequest()
    props.setIsSearch()
    const obj = {
      productCategoryId,
      name,
      type,
    }
    send(obj)
  }

  const send = (value) =>{
    props.searchFilter(value)
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' , width:"100%" , margin:"auto"}}>
          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="name"
                id="serviceType"
                value={name || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Product Category</label>
              <Select
                type="select"
                value={productCategoryName}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeProductCat}
                onSearch={onSearchProductCat}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Product Category
                </Option>
                {productServiceCat.success &&
                  productServiceCat.data.productCategoryList.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.name}`}
                    >
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Type</label>
              <Select
                type="select"
                value={type}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeFeature}
                onSearch={onSearchFeature}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Type
                </Option>
                {labelOptionsDropDown.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d.name}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {productServiceSubCat.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchProductServiceSubCategory
