import PanelHeader from 'components/PanelHeader/PanelHeader'
import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import AddFeeConfig from 'views/FeeConfig/AddFeeConfig'
import AddMaintenaceMapping from './AddMaintenanceMapping'
import ViewFeeConfig from './ViewFeeConfig'
import ViewMaintenanceList from './ViewMaintenanceList'

import SeviceTypeIcon from 'assets/img/Icons/Sidebar/servictype.png'
import SearchMaintenanceMapping from './SearchMaintenanceMapping'
import SearchMntType from './SearchMaintenanceMapping'
import MaintainceIcon from 'assets/img/Icons/Maintaince/maintainceConfig.png'
import AddTNC from './AddTNC'

const Maintenance = () => {
  const [sendData, setSendData] = useState('')
  const [showScreen, setshowScreen] = useState('ViewMaintenanceList')
  const [isSearch , setIsSearch] = useState(false)

  const showDrawer = (a) => {
    setshowScreen(a)
  }
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons business_bank'} /> */}
                  <img
                    src={MaintainceIcon}
                    alt="SeviceTypeIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span>
                  {showScreen === 'ViewFeeConfig' && (
                    <>
                      <span className="head-title">Fee Mapping </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewMaintenanceList')}
                      >
                        Field Mapping
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('term')}
                      >
                        Terms and Conditions |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('header')}
                      >
                        Header |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('footer')}
                      >
                        Footer |
                      </span>
                    </>
                  )}
                  {showScreen === 'ViewMaintenanceList' && (
                    <>
                      <span className="head-title">Field Mapping</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewFeeConfig')}
                      >
                        Fee Mapping
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('term')}
                      >
                        Terms and Conditions |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('header')}
                      >
                        Header |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('footer')}
                      >
                        Footer |
                      </span>
                    </>
                  )}
                  {showScreen === 'term' && (
                    <>
                      <span className="head-title">Terms And Conditions</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewFeeConfig')}
                      >
                        Fee Mapping
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewMaintenanceList')}
                      >
                        Field Mapping |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('header')}
                      >
                        Header |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('footer')}
                      >
                        Footer |
                      </span>
                    </>
                  )}
                  {showScreen === 'header' && (
                    <>
                      <span className="head-title">Header</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewFeeConfig')}
                      >
                        Fee Mapping
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewMaintenanceList')}
                      >
                        Field Mapping |
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('term')}
                      >
                        Terms and Conditions |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('footer')}
                      >
                        Footer |
                      </span>
                    </>
                  )}
                  {showScreen === 'footer' && (
                    <>
                      <span className="head-title">Footer</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewFeeConfig')}
                      >
                        Fee Mapping
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('ViewMaintenanceList')}
                      >
                        Field Mapping |
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('term')}
                      >
                        Terms and Conditions |
                      </span>

                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('header')}
                      >
                        Header |
                      </span>
                    </>
                  )}
                </span>
              </CardHeader>
              <CardBody>
                {showScreen === 'ViewMaintenanceList' && (
                  <SearchMntType screen="master" setIsSearch={() => setIsSearch(!isSearch)}/>
                )}
                {showScreen === 'ViewFeeConfig' && (
                  <SearchMntType screen="fee" setIsSearch={() => setIsSearch(!isSearch)}/>
                )}
                {(showScreen === 'term') |
                (showScreen === 'header') |
                (showScreen === 'footer') ? (
                  <AddTNC showScreen={showScreen} />
                ) : (
                  ''
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showScreen === 'ViewMaintenanceList' && <ViewMaintenanceList onTap={isSearch}/>}
        {showScreen === 'ViewFeeConfig' && <ViewFeeConfig  onTap={isSearch}/>}
      </div>
    </>
  )
}

export default Maintenance
