import axios from "../../config/axios"
import {
	RESENDSIGNUPOTP_SUCCESS,
	RESENDSIGNUPOTP_FAILED,
	LOADING_RESENDSIGNUPOTP,
	RESET_RESENDSIGNUPOTP,
	RESENDSIGNUPOTP_NETWORKERROR
} from "../types";

const resetresendSignupOTP = () => {
    return {
        type: RESET_RESENDSIGNUPOTP
    };
};

const resendSignupOTP = userData => dispatch => {
    dispatch({ type: LOADING_RESENDSIGNUPOTP });
		console.log(userData);
    axios
        .post("user/resendSocietySignupOtp", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: RESENDSIGNUPOTP_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: RESENDSIGNUPOTP_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: RESENDSIGNUPOTP_FAILED,
        payload: data
    };
}

export { resetresendSignupOTP, resendSignupOTP } ;