import axios from './axios'

export const refreshAccessToken = async () => {
    const token = sessionStorage.getItem('authToken');
    const response = await axios.post('user/regenerateAuthToken', { authToken: token })
    const { authToken } = response.data
    console.log(authToken,"authToken")
    if (authToken) {
        sessionStorage.setItem('authToken',authToken)
        return authToken;
    }
}
