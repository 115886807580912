import {
  GET_SOCIETY_ADMIN_SUCCESS,
  GET_SOCIETY_ADMIN_FAILED,
  GET_SOCIETY_ADMIN_NETWORK_ERROR,
  LOADING_GET_SOCIETY_ADMIN,
  RESET_GET_SOCIETY_ADMIN,
} from 'redux/types'

const initialState = {
  isLoading: false,
  success: false,
  failed: false,
  network: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_GET_SOCIETY_ADMIN:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case GET_SOCIETY_ADMIN_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: false,
        network: true,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case GET_SOCIETY_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case GET_SOCIETY_ADMIN_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case RESET_GET_SOCIETY_ADMIN:
      return (state = initialState)

    default:
      return state
  }
}
