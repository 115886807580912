import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import { Spin, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { addBuilding, resetAddBuilding } from 'redux/actions/addBuildingAction'
import {
  resetAddUnitFlatList,
  addUnitFlatList,
} from 'redux/actions/addUnitFlatListAction'
import axios from 'config/axios'
import Util from 'validations/index'

function AddMiscellaneousType({ areaType, areaId }) {

  const [buildingId, setBuildingId] = useState()
  console.log(buildingId, "bld")
  const [areaName, setAreaName] = useState('')
  const [selectCommonArea, setSelectCommonArea] = useState(false)
  const [isParking, setisParking] = useState()
  const [floor, setFloor] = useState('')
  const [bldFloor, setBldFloor] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [serachAreaName, setSearchAreaName] = useState('');
  const [spanText, setspanText] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [disableFloor, setdisableFloor] = useState(true);
  const [parkingPrefixName, setParkingPrefixName] = useState()
  const [parkingPrefixId, setParkingPrefixId] = useState()
  const [serachParkingName, setSerachParkingName] = useState('');
  const [showPrefixInput, setShowPrefixInput] = useState(true)
  const [showParkingInput, setshowParkingInput] = useState(true)
  const login = useSelector(({ login }) => login)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const addedBuilding = useSelector(({ addBuilding }) => addBuilding)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'areaName')
      Util.alphaNumeric(value) ? setAreaName(value) : setAreaName(areaName)
    // setAreaName(value)

    if (name === 'floor')
      Util.verifyMobile(value) ? setFloor(Math.max(0, value)) : setFloor(floor)
    if (name === 'buildingId') setBuildingId(value)
    if (name === 'prefix') setParkingPrefixId(value.toUpperCase())
  }

  const { Option } = Select

  function onChangeSearch(value) {
    if (value === 'true') {
      setBldgName("Common Area")
      setshowParkingInput(false)
      setAreaName('')
      setParkingPrefixId('')
    }
    else {
      let val = value.split(',')
      let val1 = val[0]
      let val2 = val[1]
      setBuildingId(val1)
      setBldgName(val2)
      setBldFloor(val[2])
      setSearchAreaName('')
      setAreaName('')
      setParkingPrefixId('')
      setshowParkingInput(true)
    }

  }

  function onSearch(val) {
    setSearchAreaName(val)
  }

  function onChangeSearchParkingName(value) {
    console.log(value, "val")
    if (value === 'textBoxTrue') {
      setAreaName('')
      setParkingPrefixId('')
      setshowParkingInput(true)
    } else {
      let prefix = value.split(',')
      setAreaName(prefix[0])
      setParkingPrefixId(prefix[1])
      setSerachParkingName('')
      setshowParkingInput(false)
    }
  }

  function onSearchParkingName(val) {
    setSerachParkingName(val)
  }

  function onChangeSearchPrefix(value) {
    let prefix = value.split(',')
    setParkingPrefixId(prefix[1])
    setParkingPrefixName(prefix[0])
    setSerachParkingName('')
  }

  function onSearchPrefix(val) {
    setSerachParkingName(val)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        // console.log(res.data.activeType, 'res.data.activeType')
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])

  // useEffect(() => {
  //   if (serachAreaName != '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions(res.data.activeType)
  //     })
  //   }
  // }, [serachAreaName])

  // useEffect(() => {
  //   if (serachAreaName === '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       // areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       // console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions2(res.data.activeType)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    // if (addedBuilding.success) {
    //   setBuildingId('')
    //   setAreaName('')
    //   setisParking()
    //   setBldgName('')
    //   setFloor('')
    //   NotificationPopups('success', 'Success', addedBuilding.data.message)
    // }
    // if (addedBuilding.failed) {
    //   setBuildingId('')
    //   setAreaName('')
    //   setisParking()
    //   setBldgName('')
    //   setFloor('')
    //   NotificationPopups('error', 'Failed',addedBuilding.data.message)
    // }
    return () => {
      dispatch(resetAddBuilding())
    }
  }, [addedBuilding.success])

  useEffect(() => {
    if (addedFlatList.success) {
      setBuildingId('')
      setAreaName('')
      setisParking()
      setBldgName('')
      setParkingPrefixId('')
      setFloor('')
      NotificationPopups('success', 'Success', addedFlatList.data.message)
    }

    if (addedFlatList.failed) {
      setBuildingId('')
      setAreaName('')
      setisParking()
      setBldgName('')
      setParkingPrefixId('')
      setFloor('')
      NotificationPopups('error', 'Failed', addedFlatList.data.message)
    }

    return () => {
      dispatch(resetAddUnitFlatList())
    }

  }, [addedFlatList.success, addedFlatList.failed])

  const addBuildingRequest = () => {
    if (!buildingId) {
      let parkingName = `${areaName}-${parkingPrefixId}`
      const addBuildingReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: areaType === 'Parking' ? parkingName : areaName.trim(),
        areaTypeId: areaId === "" ? 1 : areaId,
        floor: floor === "" ? 1 : floor
      }
      return dispatch(addBuilding(addBuildingReq))
      // console.log(addBuildingReq,"addBuildingReq")
    }
    if (buildingId) {
      const addUnitFlatListReq =
      {
        areaName: areaType === 'Parking' ? `${areaName.trim()}-${parkingPrefixId}` : areaName.trim(),
        isParking,
        floor: floor === "" ? 1 : floor,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaTypeId: areaId
      }
      return dispatch(addBuilding(addUnitFlatListReq))
      // console.log(addUnitFlatListReq,"addUnitFlatListReq")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addBuildingRequest()
  }
  const disableButton = () => {
    return !areaName
  }

  useEffect(() => {
    if (bldgName === undefined) {
      setFloor('');
      setdisableFloor(true);
    }
    else if (bldgName === '') {
      setdisableFloor(true)
    }
    else if (bldgName === 'Common Area') {
      setdisableFloor(true)
    }
    else {
      setdisableFloor(false);
    }
  }, [bldgName]);

  useEffect(() => {
    if (bldFloor === undefined) {
      setspanText('');
    }
    else {
      setspanText("Please Enter Valid Floor")
    }
  }, [floor, bldFloor]);

  const PrefixData = [
    { parkingName: 'Ground Parking', prefix: 'G' },
    { parkingName: 'Podium Parking', prefix: 'PO' },
    { parkingName: 'Parallel Parking', prefix: 'PP' },
    { parkingName: 'Slilt Parking', prefix: 'SP' },
  ]

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          {areaType === "Parking" ?
            areaType !== 'Building' && buildingList.data.message === "METHOD_NOT_ALLOWED" || "OK" &&
            <Col md="3" sm="12">
              {
                <FormGroup>
                  <Label for="buildingId">Building Name</Label>
                  <span className='required'>*</span>
                  <Select
                    type="select"
                    showSearch
                    value={bldgName}
                    placeholder="Select Building name"
                    optionFilterProp="children"
                    onChange={onChangeSearch}
                    onSearch={onSearch}

                    style={{ width: '100%' }}
                  >
                    <Option className="loginSelect" value="">
                      Select Building name
                    </Option>
                    {serachAreaName != '' &&
                      options != undefined &&
                      options.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.areaId},${d.areaName},${d.floor}`}
                        >
                          {d.areaName}
                        </Option>
                      ))}
                    {serachAreaName === '' &&
                      options2.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={`${d.areaId},${d.areaName},${d.floor}`}
                        >
                          {d.areaName}
                        </Option>
                      ))}
                  </Select>
                </FormGroup>
              }
            </Col>
            :
            areaType !== 'Building' && (
              <Col md="3" sm="12">
                {
                  <FormGroup>
                    <Label for="buildingId">Building Name</Label>
                    <span className='required'>*</span>
                    <Select
                      type="select"
                      showSearch
                      value={bldgName}
                      placeholder="Select Building name"
                      optionFilterProp="children"
                      onChange={onChangeSearch}
                      onSearch={onSearch}
                      style={{ width: '100%' }}
                    >
                      <Option className="loginSelect" value="">
                        Select Building name
                      </Option>
                      {/* {areaType === 'Parking' || 'Main gate' && <Option className="loginSelect" value={'true'}>
                        Common Area
                      </Option>
                      } */}
                      {serachAreaName != '' &&
                        options != undefined &&
                        options.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={`${d.areaId},${d.areaName},${d.floor}`}
                          >
                            {d.areaName}
                          </Option>
                        ))}
                      {serachAreaName === '' &&
                        options2.map((d, id) => (
                          <Option
                            key={id}
                            className="loginSelect"
                            value={`${d.areaId},${d.areaName},${d.floor}`}
                          >
                            {d.areaName}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                }
              </Col>
            )
          }

          <Col md="3" sm="12">
            <FormGroup>
              <Label for="areaName">{areaType === "Building" ? areaType : 'Area'} Name</Label>
              <span className='required'>*</span>
              <Input
                style={{ padding: "8px", borderRadius: "6px" }}
                type="text"
                name="areaName"
                id="areaName"
                placeholder={areaType}
                value={areaName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          {areaType === 'Parking' &&
            <Col md="3" sm="12">
              <FormGroup>
                <Label for="Prefix">Default Prefix</Label>
                <Input
                  style={{ padding: "8px", borderRadius: "6px" }}
                  type="text"
                  name="prefix"
                  id="prefix"
                  placeholder="Prefix"
                  value={parkingPrefixId || ''}
                  onChange={handleChange}
                  required
                  maxLength="5"
                />
              </FormGroup>
            </Col>
          }

          <Col md="3" sm="12">
            <FormGroup>
              <Label for="floor">No. of Floors</Label>
              <Input
                type="text"
                name="floor"
                id="floor"
                placeholder={floor}
                value={floor || ''}
                onChange={handleChange}
                required
                maxLength="10"
              />
            </FormGroup>
          </Col>

          {areaType === "Building" ?
            <Col md="4" sm="12" style={{ textAlign: "center" }}>
              <Button
                className="cust-button"
                style={{ marginTop: '28px', width: '25%' }}
                onClick={handleSubmit}
                disabled={addedBuilding.isLoading || !areaName  }
              >
                {addedBuilding.isLoading ? <Spin /> : 'Add'}
              </Button>
            </Col>
            :
            <Col md="4" sm="12" style={{ textAlign: "center" }}>
              <Button
                className="cust-button"
                style={{ marginTop: '28px', width: '25%' }}
                onClick={handleSubmit}
                disabled={addedBuilding.isLoading || !areaName || !bldgName}
              >
                {addedBuilding.isLoading ? <Spin /> : 'Add'}
              </Button>
            </Col>
          }
        </Row>
      </Form>
    </div>
  )
}

export default AddMiscellaneousType
