import axios from "../../config/axios"
import {
	GETCOMPLAINTLIST_SUCCESS,
	GETCOMPLAINTLIST_FAILED,
	LOADING_GETCOMPLAINTLIST,
	RESET_GETCOMPLAINTLIST,
    GETCOMPLAINTLIST_NETWORKERROR
} from "../types";

const resetgetComplaintList = () => {
    return {
        type: RESET_GETCOMPLAINTLIST
    };
};

const getComplaintList = userData => dispatch => {
    dispatch({ type: LOADING_GETCOMPLAINTLIST });
		console.log(userData);
    axios
        .post("complain/getComplainList", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETCOMPLAINTLIST_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETCOMPLAINTLIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETCOMPLAINTLIST_FAILED,
        payload: data
    };
}

export { resetgetComplaintList, getComplaintList } ;