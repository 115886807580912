import axios from "../../config/axios"
import {
	GETPENDINGMEMBERLIST_SUCCESS,
	GETPENDINGMEMBERLIST_FAILED,
	LOADING_GETPENDINGMEMBERLIST,
	RESET_GETPENDINGMEMBERLIST,
    GETPENDINGMEMBERLIST_NETWORKERROR
} from "../types";

const resetgetPendingMemberList = () => {
    return {
        type: RESET_GETPENDINGMEMBERLIST
    };
};

const getPendingMemberList = userData => dispatch => {
    dispatch({ type: LOADING_GETPENDINGMEMBERLIST });
		console.log(userData);
    axios
        .post("society/getOwnerList", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETPENDINGMEMBERLIST_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETPENDINGMEMBERLIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETPENDINGMEMBERLIST_FAILED,
        payload: data
    };
}

export { resetgetPendingMemberList, getPendingMemberList };