import axios from '../../config/axios'
import {
    GET_EXPORT_EXCEL_INVOICE_SUCCESS,
    GET_EXPORT_EXCEL_INVOICE_FAILED,
    GET_EXPORT_EXCEL_INVOICE_NETWORKERROR,
    LOADING_GET_EXPORT_EXCEL_INVOICE,
    RESET_GET_EXPORT_EXCEL_INVOICE,
} from '../types'

const resetExportExcelInvoice = () => {
  return {
    type: RESET_GET_EXPORT_EXCEL_INVOICE,
  }
}

const ExportExcelInvoice = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_EXPORT_EXCEL_INVOICE })
  axios
    .post('mnt/exportAllInvoice', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_EXPORT_EXCEL_INVOICE_NETWORKERROR, payload: err })
    )
}
function success(data) {
  return {
    type: GET_EXPORT_EXCEL_INVOICE_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_EXPORT_EXCEL_INVOICE_FAILED,
    payload: data,
  }
}
export { resetExportExcelInvoice, ExportExcelInvoice }
