import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NoticeBoard from 'assets/img/Brand/notice.png'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import { Alert, Spin } from 'antd'
import {
  resetupdateNotice,
  updateNotice,
} from 'redux/actions/updateNoticeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetdeleteNotice,
  deleteNotice,
} from 'redux/actions/deleteNoticeAction'
import {
  getNoticeList,
  resetgetNoticeList,
} from 'redux/actions/getNoticeListAction'
import NoticeDetails from './NoticeDetails'
import PaginationComponent from 'views/Pagination'
import AddNotice from './AddNotice'
import moment from 'moment'

function NoticeList({ data, onTap }) {
  let { EndDate, noticeType, startDate } = data
  const [openModal, setOpenModal] = useState(false)
  const [details, setDetails] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [addModal, setAddModal] = useState(false)
  const addNewNotice = useSelector(({ addNotice }) => addNotice)
  const noticeList = useSelector(({ getNoticeList }) => getNoticeList)
  const deletedNotice = useSelector(({ deleteNotice }) => deleteNotice)
  const updatedNotice = useSelector(({ updateNotice }) => updateNotice)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  const [limit] = useState(9)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  // const pageSize = noticeList.data.pages
  const totalItems = noticeList.data.count
  const toogleAdd = () => {
    setAddModal(!addModal)
  }

  useEffect(() => {
    getNoticeListRequest()
  }, [addNewNotice.success])

  const getNoticeListRequest = () => {
    const getNocListReq = {
      societyId: login.data.society.id,
      initiatedBy: login.data.user.role,
      noticeType,
      startDate,
      EndDate,
      page,
      limit,
    }
    return dispatch(getNoticeList(getNocListReq))
  }

  useEffect(() => {
    getNoticeListRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  // useEffect(() => {
  //   const getNocListReq = {
  //     societyId: login.data.society.id,
  //     initiatedBy: login.data.user.role,
  //     noticeType: data.data.noticeType,
  //     startDate: data.data.startDate,
  //     page,
  //     limit,
  //   }
  //   //console.log(getNocListReq)
  //   return dispatch(getNoticeList(getNocListReq))
  // }, [page])

  const toggleModal = () => {
    seterrMessage('')
    setOpenModal(!openModal)
  }

  const noticeModal = (d) => {
    setDetails(d)
    setOpenModal(true)
  }

  useEffect(() => {
    if (addNewNotice.success) {
      setAddModal(false)
      setOpenModal(false)
      // NotificationPopups('success', 'Success', addNewNotice.data.message)
    }
    if (deletedNotice.success) {
      seterrMessage('')
      setOpenModal(false)
      getNoticeListRequest()
      NotificationPopups('success', 'Success', deletedNotice.data.message)
    }

    if (deletedNotice.failed) {
      seterrMessage(deletedNotice.data.message)
      //NotificationPopups('error','Failed',deletedNotice.data.message)
    }
    if (deletedNotice.network || updatedNotice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    if (updatedNotice.success) {
      seterrMessage('')
      setOpenModal(false)
      getNoticeListRequest()
      // NotificationPopups('success', 'Success', updatedNotice.data.message)
    }

    if (updatedNotice.failed) {
      seterrMessage(updatedNotice.data.message)
      //NotificationPopups('error','Failed',updatedNotice.data.message)
    }

    return () => {
      dispatch(resetupdateNotice())
      dispatch(resetdeleteNotice())
    }
  }, [
    addNewNotice.success,
    deletedNotice.success,
    deletedNotice.failed,
    deletedNotice.network,
    updatedNotice.success,
    updatedNotice.failed,
    updatedNotice.network,
  ])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  return (
    <div className="listcontent">
      <Card>
        <CardBody>
          <Row style={{ justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'baseline',
                width: '100%',
              }}
            >
              <div style={{ marginRight: '25px' }}>
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={() => {
                    setAddModal(!addModal)
                  }}
                >
                  Add Notice
                </Button>
              </div>
              <Col>
                <div className="typeHeading" id="onTop">
                  Notice List
                </div>
              </Col>
            </div>
          </Row>
        </CardBody>
        <Row>
          {noticeList.success && (
            <Col md="12" style={{ padding: '20px' }}>
              <Table responsive style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Notice Event </th>
                    <th>Notice For</th>
                    <th>Start Date - End Date</th>
                    <th>Content</th>
                    <th></th>
                  </tr>
                </thead>
                {noticeList.success && (
                  <tbody>
                    {noticeList.data.societyNoticeVO.map((d, id) => (
                      <React.Fragment key={id}>
                        <tr key={id} id={`toggler${id}`}>
                          <td style={{width:"10%"}}>{d.noticeTitle}</td>
                          <td>{d.noticeType}</td>
                          <td>{d.noticeFor}</td>
                          <td>
                            {d.startDate ? (
                              <span>
                                {moment(d.startDate).format('Do MMM,YY')}
                              </span>
                            ) : (
                              '-'
                            )}
                            {d.endDate ? (
                              <span>
                                {` -   
                                ${moment(d.endDate).format('Do MMM,YY')}
                                `}
                              </span>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {d.noticeContent.lenght > 30
                              ? `${d.noticeContent.substr(0, 30)}...`
                              : `${d.noticeContent.substr(0, 30)}`}
                          </td>
                          <td>
                            <Button
                              color="secondary"
                              className="modalCustomButton"
                              onClick={() => {
                                noticeModal(d)
                              }}
                            >
                              View / Edit
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                )}
              </Table>
            </Col>
          )}
        </Row>
        <Col>
          {noticeList.failed && (
            <div
              style={{
                textAlign: 'center',
                // marginTop: '40px',
                padding: '25px 0px',
              }}
            >
              <Alert message={noticeList.data.message} type="info" />
            </div>
          )}
        </Col>
        <Col>
          <PaginationComponent
            limit={limit}
            totalItems={totalItems}
            onSelect={handlePagination}
            defaultPage={page}
          />
        </Col>
      </Card>

      {openModal && (
        <NoticeDetails
          details={details}
          modal={openModal}
          toggle={toggleModal}
          errMessage={errMessage}
        />
      )}
      <Modal
        isOpen={addModal}
        toggle={toogleAdd}
        size="lg"
        style={{ maxWidth: '1000px', maxHeight: '500px' }}
      >
        <ModalHeader toggle={toogleAdd}> Add Notice</ModalHeader>
        <ModalBody>
          <AddNotice />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default NoticeList
