import axios from '../../config/axios'
import {
  GET_RECENT_ACTIVITY_SUCCESS,
  GET_RECENT_ACTIVITY_FAILED,
  GET_RECENT_ACTIVITY_NETWORK_ERROR,
  LOADING_GET_RECENT_ACTIVITY,
  RESET_GET_RECENT_ACTIVITY,
} from '../types'

const resetGetRecentActivity = () => {
  return {
    type: RESET_GET_RECENT_ACTIVITY,
  }
}

const getRecentActivity = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_RECENT_ACTIVITY })
  console.log(userData)
  axios
    .post('helper/recentVisitor', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_RECENT_ACTIVITY_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_RECENT_ACTIVITY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_RECENT_ACTIVITY_FAILED,
    payload: data,
  }
}

export { resetGetRecentActivity, getRecentActivity }
