import axios from '../../config/axios'
import {
  ADD_MAINTENANCE_INVOICE_SUCCESS,
  ADD_MAINTENANCE_INVOICE_FAILED,
  ADD_MAINTENANCE_INVOICE_NETWORK_ERROR,
  LOADING_ADD_MAINTENANCE_INVOICE,
  RESET_ADD_MAINTENANCE_INVOICE,
} from '../types'

const resetAddMntInvoice = () => {
  return {
    type: RESET_ADD_MAINTENANCE_INVOICE,
  }
}

const addMntInvoice = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_MAINTENANCE_INVOICE })
  axios
    .post('mnt/addMntInvoice', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_MAINTENANCE_INVOICE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: ADD_MAINTENANCE_INVOICE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_MAINTENANCE_INVOICE_FAILED,
    payload: data,
  }
}

export { resetAddMntInvoice, addMntInvoice }
