import React, { useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetaddSocietyMnt,
  addSocietyMnt,
} from 'redux/actions/addSocietyMntTypeAction'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import { Upload, message, Select, Switch } from 'antd'
import { useState } from 'react'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations/index'

const AddFeeConfig = () => {
  const [names, setName] = useState('')
  const [columnNumber, setColumnNumber] = useState('')
  const [feeData, setFeeData] = useState('')
  const [monthlyFee, setMonthlyFee] = useState('')
  const [mntTypeId, setMntTypeId] = useState('')
  const [calculationLogic, setCalculationLogic] = useState('')
  const [totalAmount, setTotalAmount] = useState('')
  const [isMonthly, setIsMonthly] = useState(false)

  const login = useSelector(({ login }) => login)
  const mntType = useSelector(({ getMntType }) => getMntType)

  const addMntType = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
    if (name === 'columnNumber')
      Util.alphabetsOnly(value)
        ? setColumnNumber(value)
        : setColumnNumber(columnNumber)
    if (name === 'totalAmount')
      Util.verifyMobile(value)
        ? setTotalAmount(value)
        : setTotalAmount(totalAmount)
  }
  const onChangeSwitch = (checked) => {
    setIsMonthly(checked)
  }

  const onChangeMntType = (value) => {
    setMntTypeId(value)
  }
  const onSearchMntType = (val) => {
    setMntTypeId(val)
  }
  const onChangeCal = (value) => {
    setCalculationLogic(value)
  }
  const onSearchCal = (val) => {
    setCalculationLogic(val)
  }

  const { Option } = Select
  const CalculationsDropDown = [
    {
      id: 1,
      type: 'Amount to be added',
    },
    {
      id: 2,
      type: 'Amount to be deducted',
    },
    {
      id: 3,
      type: 'Based on amount',
    },
  ]
  const getMntTypeRequest = () => {
    const getMntTypeReq = {
      initiatedBy: login.data.user.role,
      action: 'fee',
      isFee: true,
    }
    return dispatch(getMaintenanceType(getMntTypeReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    AddFeeConfigRequest()
  }
  const AddFeeConfigRequest = () => {
    const addFeeConfigReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      action: 'add',
      mntTypeId,
      name: names.trim(),
      columnType: 'fee',
      columnNumber: columnNumber.trim(),
      calculationLogic,
      totalAmount,
      isMonthly,
    }
    return dispatch(addSocietyMnt(addFeeConfigReq))
  }
  useEffect(() => {
    getMntTypeRequest()
  }, [])
  
  // useEffect(() => {
  //   if (addMntType.success) {
  //     NotificationPopups('success', 'Success', addMntType.data.message)
  //   }
  //   if (addMntType.failed) {
  //     NotificationPopups('error', 'Failed', addMntType.data.message)
  //   }
  //   if (addMntType.network) {
  //     NotificationPopups(
  //       'error',
  //       'Network Error',
  //       'Check your internet connection'
  //     )
  //   }
  //   return () => {
  //     dispatch(resetaddSocietyMnt())
  //   }
  // }, [addMntType.success, addMntType.failed, addMntType.network])

  return (
    <>
      <FormGroup>
        <Label for="name">Name</Label>
        <span className='required'>*</span>
        <Input
          type="text"
          name="name"
          value={names}
          onChange={handleChange}
          id="name"
          placeholder="Name"
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Fee Data</Label>
        <span className='required'>*</span>
        <Select
          type="select"
          showSearch
          value={mntTypeId}
          placeholder="Select Notice Type"
          optionFilterProp="children"
          onChange={onChangeMntType}
          onSearch={onSearchMntType}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Fee Data
          </Option>
          {mntType.success &&
            mntType.data?.maintenanceTypeList.map((data, id) => (
              <Option key={id} value={data.id}>
                {data.name}
              </Option>
            ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>Monthly Fee</Label>
        <br />
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={onChangeSwitch}
          defaultChecked={isMonthly}
        />
      </FormGroup>
      <FormGroup>
        <Label>Calculation Logic</Label>
        <span className='required'>*</span>
        <Select
          type="select"
          showSearch
          value={calculationLogic}
          placeholder="Select Calculation Logic"
          optionFilterProp="children"
          onChange={onChangeCal}
          onSearch={onSearchCal}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Calculation Logic
          </Option>
          {CalculationsDropDown.map((data, id) => (
            <Option key={id} value={data.type}>
              {data.type}
            </Option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>Total Amount:</Label>
        <span className='required'>*</span>
        <Input
          type="text"
          name="totalAmount"
          placeholder="Total Amount"
          value={totalAmount}
          onChange={handleChange}
          id=""
          required
        />
      </FormGroup>

      <Button
        style={{ display: 'flex', margin: "auto" }}
        color="secondary"
        className="modalCustomButton"
        onClick={handleSubmit}
      >
        Add
      </Button>
    </>
  )
}

export default AddFeeConfig
