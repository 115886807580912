import {
	ADD_UNIT_DOCTYPE_SUCCESS,
	ADD_UNIT_DOCTYPE_FAILED,
	LOADING_ADD_UNIT_DOCTYPE,
	RESET_ADD_UNIT_DOCTYPE,
	ADD_UNIT_DOCTYPE_NETWORK_ERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADD_UNIT_DOCTYPE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    					    	network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case ADD_UNIT_DOCTYPE_NETWORK_ERROR:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: false,
    						    network: true,
								data: action.payload,
								message: "",
								networkError: "Network Error"          
            };
            case ADD_UNIT_DOCTYPE_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    						    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case ADD_UNIT_DOCTYPE_FAILED:
            return {
                ...state,
                                isLoading: false,
								success: false,
								failed: true,
    					    	network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
				case RESET_ADD_UNIT_DOCTYPE:
            return (state = initialState);

        default:
            return state;
    }
};