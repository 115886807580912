import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import randomColor from 'randomcolor'
import { Spin, Switch } from 'antd'
//import FileBase64 from 'react-file-base64';
import FileBase64 from '../../components/Custom/UploadFile'
import { Alert, Tooltip, Popconfirm, Select } from 'antd'
import {
  resetupdateCompany,
  updateCompany,
} from 'redux/actions/updateCompanyAction'
import {
  resetdeleteCompany,
  deleteCompany,
} from 'redux/actions/deleteCompanyAction'
import {
  resetgetCompanyList,
  getCompanyList,
} from 'redux/actions/getCompanyListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetgetHelperType,
  getHelperType,
} from '../../redux/actions/getHelperTypeAction'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import Util from 'validations/index'

function Company() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [companyTypeId, setCompanyTypeId] = useState('')
  const [id, setId] = useState('')
  const [iconImage, setIconImage] = useState('')
  const [editCompanyName, seteditCompanyName] = useState('')
  const [editCompanyType, seteditCompanyType] = useState('')
  const [editCompanyLogo, seteditCompanyLogo] = useState('')
  const [serviceType, setServiceType] = useState([''])
  // const [editCompanyLogo, seteditCompanyLogo] = useState('')
  const [servTypeId, setServTypeId] = useState('')
  const [helperTyperId, setHelperTypeId] = useState('')
  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [isSearch , setIsSearch] = useState(false)

  const [isActive, setIsActive] = useState(false)
  const [modal, setModal] = useState(false)
  const [errMessage, seterrMessage] = useState('')
  const [visitorId, setVisitorId] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  const [type, setType] = useState([''])
  const getCompany = useSelector(({ getCompany }) => getCompany)
  const updatedCompany = useSelector(({ updateCompany }) => updateCompany)
  console.log(updatedCompany,"updatedCompany")
  const companyTypes = useSelector(({ companyType }) => companyType)
  const addedCompany = useSelector(({ addCompany }) => addCompany)
  const deletedCompany = useSelector(({ deleteCompany }) => deleteCompany)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const login = useSelector(({ login }) => login)
  const initiatedBy = useState(login.data.user.role)
  const dispatch = useDispatch()

  const pageSize = getCompany.data.pages
  const totalItems = getCompany.data.count

  const getCompanyListRequest = () => {
    if (initiatedBy != 'Society') {
      const getCompanyListReq = {
        initiatedBy: login.data.user.role,
        companyTypeId: helperTyperId,
        limit,
        page,
      }
      return dispatch(getCompanyList(getCompanyListReq))
    }
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const getServiceTypeRequest = () => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }

  const onChange = (checked) => {
    setIsActive(checked)
    console.log(`switch to ${checked}`)
  }

  // console.log('ctid', companyTypeId)

  const { Option } = Select

  function onChangeSearchServType(value) {
    // console.log(`selected ${value}`)
    setServTypeId(value)
    setHelperTypeId('')
  }

  function onSearchServType(val) {
    setServTypeId(val)
  }

  function onChangeSearchHelperType(value) {
    // console.log(`selected ${value}`)
    setHelperTypeId(value)
  }

  function onSearchHelperType(val) {
    setHelperTypeId(val)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getCompanyListRequest()
    setIsSearch(!isSearch)
  }

  useEffect(() =>{
    setPage(1)
  },[isSearch])

  useEffect(() => {
    if (servType.success && servType.data.activeServiceType) {
      const serviceTypeId = servType.data.activeServiceType
      const helperType = serviceTypeId.filter((d) => d.isVisitor === true)
      setType(helperType)
    }
  }, [servType.success])

  useEffect(() => {
    if (servTypeId != '' && login.data.user.role != 'Society') {
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
      }
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [servTypeId])

  useEffect(() => {
    if (initiatedBy != 'Society') {
      const getCompanyListReq = {
        initiatedBy: login.data.user.role,
        companyTypeId: helperTyperId,
        limit,
        page,
      }
      return dispatch(getCompanyList(getCompanyListReq))
    }
  }, [page])

  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId: servTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType, servTypeId])

  useEffect(() => {
    const getHelperTypeReq = {
      initiatedBy: login.data.user.role,
      serviceTypeId: servTypeId,
    }
    return dispatch(getHelperType(getHelperTypeReq))
  }, [servTypeId])

  // useEffect(() => {
  //   getServiceTypeRequest()
  // }, [])

  useEffect(() => {
    if (addedCompany.success && companyTypeId) {
      getCompanyListRequest()
    }
  }, [addedCompany.success])

  useEffect(() => {
    getServiceTypeRequest()
  }, [])

  // useEffect(() => {
  //   getHelperTypeRequest()
  // }, [visitorId])

  useEffect(() => {
    return () => {
      dispatch(resetgetServiceType())
      dispatch(resetgetHelperType())
      dispatch(resetgetCompanyList())
    }
  }, [])

  useEffect(() => {
    if (getCompany.failed) {
      // NotificationPopups('error', 'Failed', getCompany.data.message)
    }
    if (deletedCompany.success) {
      NotificationPopups('success', 'Success', deletedCompany.data.message)
      getCompanyListRequest()
    }
    if (deletedCompany.failed) {
      NotificationPopups('error', 'Failed', deletedCompany.data.message)
    }
    if (updatedCompany.success) {
      seterrMessage('')
      setModal(false)
      NotificationPopups('success', 'Success', updatedCompany.data.message)
      getCompanyListRequest()
    }
    if (updatedCompany.failed) {
      getCompanyListRequest()
      seterrMessage(updatedCompany.data.message)
      //NotificationPopups('error', 'Failed', updatedCompany.data.message)
    }
    if (updatedCompany.network || deletedCompany.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection.'
      )
    }
    return () => {
      dispatch(resetdeleteCompany())
      dispatch(resetupdateCompany())
      dispatch(resetgetCompanyList())
    }
  }, [
    getCompany.failed,
    deletedCompany.success,
    deletedCompany.failed,
    deletedCompany.network,
    updatedCompany.success,
    updatedCompany.failed,
    updatedCompany.network,
  ])

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }

  const openEditModal = (type) => {
    // console.log(type)
    setprofilePictureStatus(false)
    setId(type.id)
    seteditCompanyName(type.name)
    setCompanyTypeId(type.companyTypeId)
    seteditCompanyType(type.companyType)
    seteditCompanyLogo(type.companyLogo)
    setIsActive(type.isActive)
    setModal(!modal)
  }

  const handleEditChange = ({ target: { name, value } }) => {
    console.log(value)
    if (name === 'editCompanyName')
      Util.alphabetsOnly(value)
        ? seteditCompanyName(value)
        : seteditCompanyName(editCompanyName)
    if (name === 'editCompanyType')
      Util.alphaNumeric(value)
        ? setCompanyTypeId(value)
        : setCompanyTypeId(editCompanyType)
    if (name === 'editCompanyLogo') seteditCompanyLogo(value)
    console.log(value)
  }

  const handleEditCompany = () => {
    const editCompanyReq = {
      // companyId: id,
      // company: {
      //   companyName: editCompanyName.trim(),
      //   companyType: editCompanyType,
      //   companyLogo: editCompanyLogo,
      // },
      initiatedBy: login.data.user.role,
      id,
      name: editCompanyName.trim(),
      companyTypeId,
      companyLogo: profilePictureStatus ? editCompanyLogo : '',
      isActive,
    }
    console.log(editCompanyReq)
    return dispatch(updateCompany(editCompanyReq))
  }

  const handleAttachments = (filesList) => {
    // console.log(filesList)
    // seteditCompanyLogo(filesList.base64)
    setprofilePictureStatus(true)
    console.log('filesList', filesList)
    const icon = filesList.base64.split(`,`)
    seteditCompanyLogo(icon[1])
    setIconImage(filesList.base64)
  }

  const disableBtn = () => {
    return !(editCompanyName && editCompanyType && editCompanyLogo)
  }
  useEffect(() => {
    getServiceTypeRequest()
    // console.log(
    //   servType.success &&
    //     servType.data.activeServiceType.map((d) => (d.isVisitor ? d.type : ''))
    // )
    if (servType.success) {
      const type = servType.data.activeServiceType.filter((d) => d.isVisitor)
      setType(type)
      // setServiceType(type)
      console.log(type, 'type')
    }
  }, [])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col md="12" style={{ padding: '20px' }} id='onTop'>
                  <div className="typeHeading">Company List</div>
                  <Form onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col md="4" sm="12">
                        <FormGroup>
                          <Select
                            type="select"
                            showSearch
                            value={servTypeId}
                            placeholder="Select Service Type"
                            optionFilterProp="children"
                            onChange={onChangeSearchServType}
                            onSearch={onSearchServType}
                            style={{ width: '100%' }}
                            loading={servType.isLoading}
                          >
                            <Option className="loginSelect" value="">
                              Select Service Type
                            </Option>
                            {servType.success &&
                              type.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={d.id}
                                >
                                  {d.type}
                                </Option>
                              ))}

                            {servType.failed && (
                              <Option className="loginSelect" value="">
                                No Data Found
                              </Option>
                            )}
                          </Select>
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                        <FormGroup>
                          <Select
                            type="select"
                            showSearch
                            value={helperTyperId}
                            placeholder="Select Company Type"
                            optionFilterProp="children"
                            onChange={onChangeSearchHelperType}
                            onSearch={onSearchHelperType}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            loading={helperTypeList.isLoading}
                          >
                            <Option className="loginSelect" value="">
                              Select Company Type
                            </Option>
                            {searchHelperType != '' &&
                            optionsHelperType != undefined
                              ? optionsHelperType.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d.id}
                                  >
                                    {d.type}
                                  </Option>
                                ))
                              : helperTypeList.success &&
                                helperTypeList.data.helperTypeList.map(
                                  (d, id) => (
                                    <Option
                                      key={id}
                                      className="loginSelect"
                                      value={d.id}
                                    >
                                      {d.type}
                                    </Option>
                                  )
                                )}
                          </Select>
                        </FormGroup>
                      </Col>

                      <Col md="4" sm="12">
                        <Button
                          className="cust-button"
                          style={{ marginTop: '0' }}
                          onClick={handleSubmit}
                          disabled={!helperTyperId || getCompany.isLoading}
                        >
                          {getCompany.isLoading ? <Spin /> : 'Search'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {getCompany.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {getCompany.success && (
                          <tbody>
                            {getCompany.data.activeCompany.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>
                                    <img
                                      src={`${Util.imageUrl}${d.companyLogo}`}
                                      alt="icon"
                                      style={{
                                        height: '40px',
                                        width: '40px',
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        marginLeft: '20px',
                                        textTransform: 'capitalize',
                                        fontWeight: 'semi-bold',
                                        color: ' #00326c',
                                      }}
                                    >
                                      {d.name}
                                    </span>
                                  </td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}

                  {/* {getCompany.success && (
                    <div>
                      <div className="typeHeading">Active Company </div>
                      <Row>
                        {getCompany.success &&
                          getCompany.data.activeCompany.map((d, id) => (
                            <Col sm="12" md="4" key={d.id}>
                              <Card style={{ textAlign: 'center' }}>
                                <CardBody>
                                  <CardTitle tag="h6">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={`${Util.imageUrl}${d.companyLogo}`}
                                        className="AvatarProfile"
                                        alt="Photo"
                                      />
                                      <span
                                        style={{
                                          marginLeft: '20px',
                                          textTransform: 'capitalize',
                                          fontWeight: 'semi-bold',
                                          color: ' #00326c',
                                        }}
                                      >
                                        {d.name}
                                      </span>
                                    </div>
                                  </CardTitle>
                                  <CardBody></CardBody>
                                  <Button
                                    className="modalCustomButton"
                                    onClick={() => openEditModal(d)}
                                  >
                                    Edit
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  )} */}

                  {/* {getCompany.success && !getCompany.data.activeCompany.length && (
                    <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                      <Alert message="No Active Company Found" type="info" />
                    </div>
                  )} */}

                  {/* {getCompany.success && (
                    <div>
                      <div className="typeHeading">Inactive Company </div>
                      <Row>
                        {getCompany.success &&
                          getCompany.data.inActiveCompany.map((d, id) => (
                            <Col sm="12" md="4" key={d.id}>
                              <Card style={{ textAlign: 'center' }}>
                                <CardBody>
                                  <CardTitle tag="h6">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={d.companyLogo}
                                        className="AvatarProfile"
                                        alt="Photo"
                                      />
                                      <span
                                        style={{
                                          marginLeft: '20px',
                                          textTransform: 'capitalize',
                                          fontWeight: 'semi-bold',
                                          color: ' #00326c',
                                        }}
                                      >
                                        {d.name}
                                      </span>
                                    </div>
                                  </CardTitle>
                                  <CardSubtitle>{d.companyType}</CardSubtitle>
                                  <CardBody></CardBody>
                                  <Button
                                    className="modalCustomButton"
                                    onClick={() => openEditModal(d)}
                                  >
                                    Edit
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  )} */}

                  {/* {getCompany.success &&
                    !getCompany.data.inActiveCompany.length && (
                      <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                        <Alert
                          message="No Inactive Company Found"
                          type="info"
                        />
                      </div>
                    )} */}

                  {getCompany.success && (
                    <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="sm"
          style={{ maxWidth: '500px' }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12" md="6" lg="6">
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <FormGroup>
                      <Label for="editCompanyName">Company Name</Label>
                      <Input
                        type="text"
                        name="editCompanyName"
                        id="editCompanyName"
                        value={editCompanyName}
                        onChange={handleEditChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="12">
                    <FormGroup>
                      <Label for="editCompanyType">Company Type</Label>
                      <Input
                        name="editCompanyType"
                        type="select"
                        value={companyTypeId}
                        onChange={handleEditChange}
                      >
                        {helperTypeList.success &&
                          helperTypeList.data.helperTypeList.map((d, id) => (
                            <option
                              key={id}
                              className="loginSelect"
                              value={d.id}
                            >
                              {d.type}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" md="6" lg="6">
                <div style={{ marginTop: '10px' , textAlign:"center"}}>
                  {
                    <img
                      src={
                        profilePictureStatus
                          ? `${iconImage}`
                          : `${Util.imageUrl}${editCompanyLogo}`
                      }
                      style={{
                        width: '80%',
                        height: '150px',
                        border: 'rounded',
                      }}
                    />
                  }
                  <FileBase64
                    multiple={false}
                    accept=".jpeg,.png,.jpg"
                    id="editCompanyFile"
                    onDone={handleAttachments}
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="12">
                <FormGroup>
                  <Label>Status</Label>
                  <br />
                  <Switch
                    defaultChecked={isActive}
                    name="isActive"
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={onChange}
                    // style={{ width: '100%' }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditCompany}
              disabled={updatedCompany.isLoading || disableBtn()}
            >
              {updatedCompany.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default Company
