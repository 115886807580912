import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import randomColor from 'randomcolor'
// import 'react-calendar/dist/Calendar.css'
import {
  Tooltip,
  Popconfirm,
  Alert,
  Rate,
  Spin,
  Switch,
  Select,
  List,
  Avatar,
  Skeleton,
  Collapse,
} from 'antd'
import {
  resetgetHelperDetails,
  getHelperDetails,
} from 'redux/actions/getHelperDetailsAction'
import {
  resetGetHelperDetail,
  getHelperDetail,
} from 'redux/actions/getHelperDetailAction'
import {
  resetGetHelperAttendance,
  getHelperAttendance,
} from 'redux/actions/getHelperAttendanceAction'
import {
  resetgetHelperDocument,
  getHelperDocument,
} from 'redux/actions/getHelperDocumentAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
// import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
// import 'react-datepicker/dist/react-datepicker.css'
import PaginationComponent from 'react-reactstrap-pagination'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetUpdateHelper,
  updateHelper,
} from 'redux/actions/updateHelperAction'
import { resetaddHelper, addHelper } from 'redux/actions/addHelperAction'

// import CoverPhoto from 'assets/img/Icons/ViewHelperDetails/coverphoto.jpg'
// import ProfilePhoto from 'assets/img/Icons/ViewHelperDetails/aashish.jpg'
import './Calendar.css'
import DisplayAttachment from 'components/Custom/DisplayAttachment'

// import ProfilePhoto from 'assets/img/Icons/ViewHelperDetails/aashish.jpg'

function ViewDetails({ helperId, details,data }) {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [helperName, sethelperName] = useState(details.helperName)
  const [helperMobile, sethelperMobile] = useState(details.helperMobile)
  const [helperPhoto, sethelperPhoto] = useState(details.photo)
  const [passcode, setPasscode] = useState(details.passCode)
  const [category, setCategory] = useState(details.helperType)
  const [viewNav, setViewNav] = useState('Review')
  const [isActive, setisActive] = useState(details.isActive)
  const [mark, setMark] = useState([])
  const [isActiveDate, setIsActiveDate] = useState('')
  const date = new Date()
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())
  //filemodal
  const [attachment, setAttachment] = useState('')
  const [fileModal, setFileModal] = useState('')
  const login = useSelector(({ login }) => login)
  const helperAdded = useSelector(({ addHelper }) => addHelper)
  const helperDetail = useSelector(({ getHelperDetail }) => getHelperDetail)
  const activeHelperDetail = useSelector(
    ({ getHelperDetail }) => getHelperDetail
  )
  const attendance = useSelector(
    ({ getHelperAttendance }) => getHelperAttendance
  )
  const documentDetail = useSelector(
    ({ getHelperDocument }) => getHelperDocument
  )
  const updateHelp = useSelector(({ updateHelper }) => updateHelper)
  // const addedHelper = useSelector(({ addHelper }) => addHelper)
  const [initiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()

  //   const totalItems = helperDetails.data.count

  const handleSubmit = (e) => {
    e.preventDefault()
    editDetailsTypeRequest()
    // getDocumentRequest()
  }

  const editDetailsTypeRequest = () => {
    const editDetailsTypeReq = {
      action: 'update',
      initiatedBy: login.data.user.role,
      helperName: helperName.trim(),
      helperMobile: helperMobile,
      photo: profilePictureStatus ? helperPhoto : '',
      isActive,
      id: helperId,
    }
    return dispatch(addHelper(editDetailsTypeReq))
  }

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    sethelperPhoto(icon[1])
    setUpdatedPhoto(filesList.base64)
  }
  const onChange = (checked) => {
    setisActive(checked)
  }

  useEffect(() => {
    setMonth(moment(isActiveDate.activeStartDate).format('MM'))
    setYear(moment(isActiveDate.activeStartDate).format('YYYY'))
  }, [isActiveDate])

  useEffect(() => {
    if (attendance.success) {
      const attend = attendance.data.dateList.map((d) =>
        moment(d).format('YYYY-MM-DD')
      )
      setMark(attend)
    }
    if (attendance.failed) {
      setMark([])
    }
    return () => {
      resetGetHelperAttendance()
    }
  }, [attendance.success, attendance.failed])

  const tileClassName = ({ date, view }) => {
    if (mark.find((x) => x === moment(date).format('YYYY-MM-DD'))) {
      return 'highlight'
    }
  }
  const toggleFileModal = (data) => {
    setFileModal(!fileModal)
    setAttachment(data)
  }

  useEffect(() => {
    if (initiatedBy === 'Society' && helperId != '') {
      const getDetailsReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperId,
      }
      return dispatch(getHelperDetail(getDetailsReq))
    }
  }, [helperId])

  useEffect(() => {
    if (initiatedBy === 'Society' && helperId != '') {
      const getHelperAttendanceReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperId,
        month,
        year,
      }
      return dispatch(getHelperAttendance(getHelperAttendanceReq))
    }
  }, [helperId, year, month])

  useEffect(() => {
    if (initiatedBy === 'Society' && helperId != '') {
      const getDocumentReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperId,
      }
      return dispatch(getHelperDocument(getDocumentReq))
    }
  }, [helperId])

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'helperName')
      Util.alphabetsOnly(value)
        ? sethelperName(value)
        : sethelperName(helperName)
    if (name === 'helperMobile')
      Util.verifyMobile(value)
        ? sethelperMobile(value)
        : sethelperMobile(helperMobile)
  }
  useEffect(() => {
    if (helperAdded.success) {
      NotificationPopups('success', 'Success', helperAdded.data.message)
    }

    if (helperAdded.failed) {
      NotificationPopups('error', 'Failed', helperAdded.data.message)
    }
    if (helperAdded.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      dispatch(resetaddHelper())
    }
  }, [helperAdded.success, helperAdded.failed, helperAdded.network])

  return (
    <>
      <Row>
        <Col md="4" sm="12">
          <div>
            {updatedPhoto ? (
              <img className="profile_photo" src={updatedPhoto} />
            ) : (
              <img
                className="profile_photo"
                src={`${Util.imageUrl}${details.photo}`}
              />
            )}
            <FileBase64
              multiple={false}
              id="iconImage"
              // accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
              accept=".jpeg, .png, .jpg"
              onDone={handleAttachments}
            />
            <div className="profile_status"></div>
          </div>
        </Col>
        <Col md="8">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="helptype">Name</Label>
                <Input
                  type="text"
                  placeholder="Name"
                  name="helperName"
                  value={helperName || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="helptype">Mobile</Label>
                <Input
                  type="text"
                  placeholder="Mobile No."
                  name="helperMobile"
                  value={helperMobile || ''}
                  onChange={handleChange}
                  maxlength="10"
                  minlength='10'
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <Label>Passcode:</Label>
              <Label>{details.passCode}</Label>
            </Col>
            <Col md="6">
              <Label>Category:</Label>
              <Label>{details.helperType}</Label>
            </Col>
            <Col md="6">
              <Label> Rating:</Label>
              <Label>
                <Rate disabled value={helperDetail.data.averageRating} />
              </Label>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Status:</Label>
                {/* <br /> */}
                <Switch
                  defaultChecked={details.isActive}
                  name="isActive"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={onChange}
                  // style={{ width: '100%' }}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12} style={{ textAlign: 'center' }}>
              <Button
                className="cust-button"
                onClick={handleSubmit}
                type="submit"
              >
                Edit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <div className="calender__data">
            <label htmlFor="Attendance">Attendance</label>
            <Calendar
              tileClassName={tileClassName}
              onActiveStartDateChange={(action) => setIsActiveDate(action)}
            />
          </div>
        </Col>
        <Col md="8">
          <Row>
            <Col md="3" style={{ cursor: 'pointer' }}>
              {viewNav === 'Review' ? (
                <p
                  onClick={() => {
                    setViewNav('Review')
                  }}
                  style={{
                    borderBottom: '2px solid #1890ff',
                    transition: '.2s',
                    textAlign: 'center',
                  }}
                >
                  Review
                </p>
              ) : (
                <p
                  onClick={() => {
                    setViewNav('Review')
                  }}
                >
                  Review
                </p>
              )}
            </Col>
            <Col md="3" style={{ cursor: 'pointer' }}>
              {viewNav === 'Working in Houses' ? (
                <p
                  onClick={() => {
                    setViewNav('Working in Houses')
                  }}
                  style={{
                    borderBottom: '2px solid #1890ff',
                    transition: '.2s',
                    textAlign: 'center',
                  }}
                >
                  Working in
                </p>
              ) : (
                <p
                  onClick={() => {
                    setViewNav('Working in Houses')
                  }}
                >
                  Working in
                </p>
              )}
            </Col>
            <Col md="3" style={{ cursor: 'pointer' }}>
              {viewNav === 'History' ? (
                <p
                  onClick={() => {
                    setViewNav('History')
                  }}
                  style={{
                    borderBottom: '2px solid #1890ff',
                    transition: '.2s',
                    textAlign: 'center',
                  }}
                >
                  History
                </p>
              ) : (
                <p
                  onClick={() => {
                    setViewNav('History')
                  }}
                >
                  History
                </p>
              )}
            </Col>
            <Col md="3" style={{ cursor: 'pointer' }}>
              {viewNav === 'Documents' ? (
                <p
                  onClick={() => {
                    setViewNav('Documents')
                  }}
                  style={{
                    borderBottom: '2px solid #1890ff',
                    transition: '.2s',
                    textAlign: 'center',
                  }}
                >
                  Documents
                </p>
              ) : (
                <p
                  onClick={() => {
                    setViewNav('Documents')
                  }}
                >
                  Documents
                </p>
              )}
            </Col>
          </Row>
          <Row>
            {viewNav === 'Review' && (
              <Col
                md="12"
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  // border: '1px solid blue',
                }}
              >
                {/* Review Start */}
                {helperDetail.success && (
                  <div>
                    {helperDetail.data.helperDetail.map((d, id) => (
                      <React.Fragment key={id}>
                        <div style={{ display: 'flex', columnGap: '8px' }}>
                          <div style={{ fontWeight: 'bold' }}>
                            {d.areaName} / {d.unit}
                          </div>
                          <Rate
                            style={{ fontSize: '12px' }}
                            disabled
                            value={d.rating}
                          />
                        </div>
                        <div style={{ color: 'gray', fontSize: '13px' }}>
                          {' '}
                          {d.review}{' '}
                        </div>
                        <hr />
                      </React.Fragment>
                    ))}
                  </div>
                )}

                {/* Review End */}
              </Col>
            )}
          </Row>

          <Row>
            {viewNav === 'Working in Houses' && (
              <Col
                md="12"
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  // border: '1px solid blue',
                }}
              >
                {activeHelperDetail.success && (
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Building Name</th>
                        <th>Flat No.</th>

                        {/* activeHelperDetail */}
                      </tr>
                    </thead>
                    {activeHelperDetail.success && (
                      <tbody>
                        {activeHelperDetail.data.activeHelperDetail.map(
                          (d, id) => (
                            <React.Fragment key={id}>
                              <tr key={id} id={`toggler${id}`}>
                                <td>{d.areaName}</td>
                                <td>{d.unit}</td>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    )}
                  </Table>
                )}
              </Col>
            )}
          </Row>

          <Row>
            {viewNav === 'History' && (
              <Col
                md="12"
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  // border: '1px solid blue',
                }}
              >
                {attendance.success && (
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Unit</th>
                        <th>Date</th>
                        <th>In-Time</th>
                        <th>Out-Time</th>

                        {/* activeHelperDetail */}
                      </tr>
                    </thead>
                    {attendance.success && (
                      <tbody>
                        {attendance.data.detailList.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>{d.unit}</td>
                              <td>
                                {/* {d.moment().format('DD-MM-YYYY')} */}
                                {moment(d.inTime).format('Do MMM,YYYY')}
                              </td>
                              <td>
                                {/* {d.inTime} */}
                                {moment(d.inTime).format('h:mm:ss a')}
                              </td>
                              <td>
                                {/* {d.outTime} */}
                                {d.outTime
                                  ? moment(d.outTime).format('h:mm:ss a')
                                  : '-'}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                )}
              </Col>
            )}
          </Row>

          <Row>
            {viewNav === 'Documents' && (
              <Col
                md="12"
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  // border: '1px solid blue',
                }}
              >
                {documentDetail.success && (
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Number</th>

                        {/* activeHelperDetail */}
                      </tr>
                    </thead>
                    {documentDetail.success && (
                      <tbody>
                        {documentDetail.data.helperDocVOs.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>
                                {/* {d.moment().format('DD-MM-YYYY')} */}
                                <img
                                  src={`${Util.imageUrl}${d.document}`}
                                  alt="doc"
                                  style={{ width: '5rem', height: '5rem' }}
                                />
                              </td>
                              <td>
                                {/* {d.inTime} */}
                                {d.documentType}
                              </td>
                              <td>
                                {/* {d.outTime} */}
                                {d.documentNumber}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                )}
              </Col>
            )}
          </Row>
          {/* <Row>
            {viewNav === 'Documents' && (
              <Col
                md="12"
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  // border: '1px solid blue',
                }}
              >
                {documentDetail.success && (
                  <Table responsive>
                    {documentDetail.success && (
                      <div>
                        {documentDetail.data.helperDocVOs.map((d, id) => (
                          <React.Fragment key={id}>
                            <div key={id} id={`toggler${id}`}>
                              <div>
                                <img
                                  src={`${Util.imageUrl}${d.document}`}
                                  alt="doc"
                                  style={{ width: '5rem', height: '5rem' }}
                                />

                                {d.documentType}
                                <br />
                                {d.documentNumber}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </Table>
                )}
              </Col>
            )}
          </Row> */}
        </Col>
      </Row>

      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${attachment}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
    </>
  )
}

export default ViewDetails
