import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from '../../components/Custom/UploadFile'
import { Spin } from 'antd'
//ant design components
import { Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// core components
import { getTransactionList } from 'redux/actions/getGatewayTransactionList'
import axios from 'config/axios'
import moment from 'moment'
import Util from 'validations'

import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'

const { Dragger } = Upload

// const initialState = {
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	title: "",
// 	content: "",
// 	type: ""
// }

function SearchTransactionDetails(props) {
  const [trackingId, setTrackingId] = useState('')
  // const [paymentId, setPaymentId] = useState()
  const [customerId, setCustomerId] = useState('')
  const [Status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [city, setCity] = useState('')
  const [searchCityName, setSearchCityName] = useState('')
  const [searchSocietyName, setSearchSocietyName] = useState('')
  const [socName, setSocName] = useState('')

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [societyId, setSocietyId] = useState('')

  const login = useSelector(({ login }) => login)
  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
  const cityList = useSelector(({ getCityList }) => getCityList)
  const dispatch = useDispatch()

  const transactionList = useSelector(
    ({ getTransactionList }) => getTransactionList
  )

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'trackingId')
      Util.verifyMobile(value)
        ? setTrackingId(value)
        : setTrackingId(trackingId)
    // if (name === 'paymentId')
    //   Util.verifyMobile(value) ? setPaymentId(value) : setPaymentId(paymentId)
    if (name === 'customerId')
      Util.verifyMobile(value)
        ? setCustomerId(value)
        : setCustomerId(customerId)
    // if (name === 'Status')
    //   Util.verifyAlpahbet(value) ? setStatus(value) : setStatus(Status)

    if (name === 'startDate') setStartDate(value)
    if (name === 'endDate') setEndDate(value)
  }

  const { Option } = Select

  const getTransactionListRequest = () => {
    // const date = new Date()
    // const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    // const startDate = moment(firstDay).format('YYYY-MM-DD')
    const getTransactionListReq = {
      initiatedBy: login.data.user.role,
      societyId,
      endDate,
      startDate,
      // paymentId,
      trackingId,
      userId: customerId,
      status: Status,
      // page,
      limit:6
    }

    return dispatch(getTransactionList(getTransactionListReq))
  }

  const getSocietyListRequest = () => {
    const getSocietyListReq = {
      initiatedBy: login.data.user.role,
      city,
      societyName: searchSocietyName,
    }
    return dispatch(getSocietyList(getSocietyListReq))
  }

  useEffect(() => {
    getSocietyListRequest()
  }, [city,searchSocietyName])

  // useEffect(() => {
  //   getTransactionListRequest()
  // }, [])

  const transactionStatus = ['Success', 'Pending', 'Failed']

  function onChangeSearch(value) {
    setStatus(value)
  }

  function onSearch(val) {
    setStatus(val)
  }

  function onChangeCity(value) {
    setCity(value)
    setSearchCityName('')
    setSocName('')
    setSearchSocietyName('')
  }

  function onChangeSocName(value) {
    setSocName(value)
    setSocietyId(value)
    setSearchSocietyName('')
  }

  function onSearchSocName(value) {
    setSearchSocietyName(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getTransactionListRequest()
    props.setIsSearch()
    const obj = {
      status: Status,
      userId: customerId,
      trackingId,
      startDate,
      endDate,
      societyId,
      socName,
    }
    send(obj)
  }

  const disabledBtn = () => {
    return
  }

  function send(value) {
    props.searchFilter(value)
  }

  // useEffect(() => {
  //   const date = new Date()
  //   const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  //   const startDate = moment(firstDay).format('YYYY-MM-DD')
  //   setStartDate(startDate)
  //   const endDate = moment().format('YYYY-MM-DD')
  //   setEndDate(endDate)
  // }, [])

  useEffect(() => {
    if (searchSocietyName != '') {
      const userData = {
        city,
        initiatedBy: 'Society',
        societyName: searchSocietyName,
      }
      axios.post('society/getSocietyList', userData).then((res) => {
        setOptions(res.data.societyList)
      })
    }
  }, [searchSocietyName, city])

  useEffect(() => {
    if (searchCityName != '') {
      const userData = {
        initiatedBy: 'Society',
        type: searchCityName,
      }
      axios.post('society/allCityList', userData).then((res) => {
        setOptions2(res.data.city)
      })
    }
  }, [searchCityName])

  return (
    <>
      <Row>
        <Col xs="12" sm="12" md="3">
          <FormGroup>
            <Label>From Date</Label>
            <span className="sub-label">(Optional)</span>
            <Input
              name="startDate"
              value={startDate || ''}
              type="date"
              onChange={handleChange}
              // min={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="12" md="3">
          <FormGroup>
            <Label>To Date</Label>
            <span className="sub-label">(Optional)</span>
            <Input
              name="endDate"
              value={endDate || ''}
              type="date"
              onChange={handleChange}
              // min={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
        </Col>
        <Col md="3" sm="12" lg="3" xs="12">
          <Label for="">User Id</Label>
          <Input
            type="text"
            name="customerId"
            id="name"
            placeholder="User Id"
            value={customerId}
            onChange={handleChange}
            required
          />
        </Col>
        <Col md="3" sm="12" lg="3" xs="12">
          <Label for="trackingId">Tracking Id</Label>
          <Input
            type="text"
            name="trackingId"
            id="name"
            placeholder="Tracking Id"
            value={trackingId}
            onChange={handleChange}
            required
          />
        </Col>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Status</Label>
            <Select
              type="select"
              name="Status"
              value={Status}
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              onChange={onChangeSearch}
              onSearch={onSearch}
              style={{ width: '100%', borderRadius: '25px' }}
              // loading={buildingNames.isLoading}
            >
              <Option className="loginSelect" value="">
                Select Status
              </Option>
              {transactionStatus.map((d, idx) => (
                <Option key={idx} value={d}>
                  {d}
                </Option>
              ))}
            </Select>
          </FormGroup>
        </Col>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Select City</Label>
            <Select
              type="select"
              value={city}
              showSearch
              placeholder="Select your society name"
              optionFilterProp="children"
              onChange={onChangeCity}
              // onSearch={onSearchCity}
              style={{ width: '100%' }}
            >
              <Option value="">Select your city name</Option>
              {searchCityName != '' &&
                options2 != undefined &&
                options2.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d}>
                    {d}
                  </Option>
                ))}
              {cityList.success &&
                searchCityName === '' &&
                cityList.data.city.map((d, id) => (
                  <Option key={id} className="loginSelect" value={d}>
                    {d}
                  </Option>
                ))}
            </Select>
          </FormGroup>
        </Col>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Select Society</Label>
            <Select
              type="select"
              value={socName}
              showSearch
              placeholder="Select your society name"
              optionFilterProp="children"
              onChange={onChangeSocName}
              onSearch={onSearchSocName}
              style={{ width: '100%' }}
            >
              <Option value="">Select your society name</Option>
              {searchSocietyName != '' && options != undefined
                ? options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={d.id}
                    >
                      {d.societyName}
                    </Option>
                  ))
                : societyList.success &&
                  societyList.data.societyList.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.societyName}
                    </Option>
                  ))}
            </Select>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          style={{ textAlign: 'center', margin: '20px' }}
        >
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={transactionList.isLoading || disabledBtn()}
          >
            {transactionList.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SearchTransactionDetails
