import axios from '../../config/axios'
import {
  UPDATENOTICETYPE_SUCCESS,
  UPDATENOTICETYPE_FAILED,
  LOADING_UPDATENOTICETYPE,
  RESET_UPDATENOTICETYPE,
  UPDATENOTICETYPE_NETWORKERROR,
} from '../types'

const resetupdateNoticeType = () => {
  return {
    type: RESET_UPDATENOTICETYPE,
  }
}

const updateNoticeType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATENOTICETYPE })
  // console.log(userData);
  axios
    .post('notice/updateNoticeType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATENOTICETYPE_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATENOTICETYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATENOTICETYPE_FAILED,
    payload: data,
  }
}

export { resetupdateNoticeType, updateNoticeType }
