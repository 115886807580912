import axios from "../../config/axios";
import {
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    LOADING_FORGET_PASSWORD,
    FORGET_PASSWORD_NETWORK_ERROR,
    RESET_FORGET_PASSWORD,
} from "../types";

const resetForgetPassword=()=>{
    return{
        type:RESET_FORGET_PASSWORD
    };
};

const forgetPassword=(userData)=>(dispatch)=>{
    dispatch({type:LOADING_FORGET_PASSWORD});
    axios
        .post("user/adminForgetPassword",userData)
        .then((res)=>{
            switch (res.data.statusCode){
                case 200:
                    dispatch(success(res.data));
                    break;
                default :
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type:FORGET_PASSWORD_NETWORK_ERROR , payload: err }));

};
function success(data){
    return{
        type:FORGET_PASSWORD_SUCCESS,
        payload:data
    }
}
function failed(data){
    return{
        type:FORGET_PASSWORD_FAILED,
        payload:data
    }
}
export {resetForgetPassword,forgetPassword};