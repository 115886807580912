import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
//actions
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Tooltip } from 'antd'
import VisitorList from './VisitorList'

import VisitorIcons from 'assets/img/Icons/Sidebar/visitor.png'

import VisitorIcon from 'assets/img/Icons/Visitors/visitor.png'
import Guest from 'assets/img/Icons/Visitors/guest.png'
import SearchFilter from './SearchFilter'
import GuestList from './GuestList'
import SearchGuest from './SearchGuest'

function Visitor() {
  const [category, setCategory] = useState('Visitor')
  const [activeTab, setActiveTab] = useState('Visitor')
  const [sendData, setSendData] = useState('')
  const [isSearch , setIsSearch] = useState(false)
  const login = useSelector(({ login }) => login)

  const toggle = (tab, cat) => {
    setCategory(cat)
    if (activeTab !== tab) setActiveTab(tab)
  }
  const searchFilter = (value) => {
    setSendData(value)
  }

  const categoryArr = [
    {
      id: 1,
      name: 'Visitor',
      display: 'Society',
      // icon: 'fas fa-child',
      icon: `${VisitorIcon}`,
    },
    {
      id: 2,
      name: 'Guest',
      display: 'Society',
      // icon: 'fas fa-child',
      icon: `${Guest}`,
    },
  ]

  const typeComponentArr = (t) => {
    switch (t) {
      case 'Visitor':
        return <SearchFilter searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)}/>
      case 'Guest':
        return <SearchGuest searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)}/>
      default:
        return null
    }
  }

  const displayComponentArr = (t) => {
    switch (t) {
      case 'Visitor':
        return <VisitorList sendData={sendData} onTap={isSearch}/>
      case 'Guest':
        return <GuestList data={sendData} onTap={isSearch}/>
      default:
        return null
    }
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons loader_gear'} /> */}
                  <img
                    src={VisitorIcons}
                    alt="VisitorIcons"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Visitors</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Nav tabs>
                      {categoryArr.map((d, id) => (
                        <>
                          {login.data.user.role === d.display ? (
                            <NavItem>
                              <NavLink
                                data-id={id}
                                className={classnames({
                                  active: activeTab === d.id,
                                })}
                                onClick={() => {
                                  toggle(d.id)
                                }}
                                key={id}
                              >
                                <div className={`sd-col`}>
                                  <div className="sd-ic">
                                    {/* <i className={d.icon}></i> */}
                                    <img src={d.icon} alt={d.icon} />
                                  </div>
                                  <p className="sd-label">{d.name}</p>
                                </div>
                              </NavLink>
                            </NavItem>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {categoryArr.map((d, id) => (
                        <TabPane tabId={d.id} data-id={id} key={id}>
                          {typeComponentArr(d.name)}
                        </TabPane>
                      ))}
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          {categoryArr.map((d, id) => (
            <TabPane tabId={d.id} data-id={id} key={id}>
              {displayComponentArr(d.name)}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  )
}

export default Visitor
