import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Nav } from 'reactstrap'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'

// import logo from "logo-white.svg";
import logo from 'assets/img/Brand/hii_soc.png'
//Side bar logo
import Dashboard from 'assets/img/Icons/Sidebar/dashboard.png'
import SocietyDeatil from 'assets/img/Icons/Sidebar/societydetail1.png'
import NoticeBoard from 'assets/img/Icons/Sidebar/noticeboard.png'
import Members from 'assets/img/Icons/Sidebar/member.png'
import Complaints from 'assets/img/Icons/Sidebar/complain.png'
import EmergencyContact from 'assets/img/Icons/Sidebar/emergencycontact.png'
import Categories from 'assets/img/Icons/Sidebar/category.png'
import Configurations from 'assets/img/Icons/Sidebar/documenttype.png'
import DashboardConfigurations from 'assets/img/Icons/Sidebar/configurations.png'
import Visitor from 'assets/img/Icons/Sidebar/visitor.png'
import FeeConfigIcon from 'assets/img/Icons/Maintaince/feeConfig.png'
import MaintainceIcon from 'assets/img/Icons/Maintaince/maintainceConfig.png'
import MntBilling from 'assets/img/Icons/Maintaince/MntBilling.png'
import Transactions from 'assets/img/Icons/Transactionslist/transaction.png'
import Amenity from 'assets/img/Icons/Sidebar/amenity.png'
import SecurityAlert from 'assets/img/Icons/Sidebar/Security_Alert.png'
function Sidebar() {
  const [active, setActive] = useState(false)
  console.log(active,"active")
  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/Society/dashboard">
          <img src={logo} alt="react-logo" />
        </Link>
      </div>
      <div className="sidebar-wrapper" id="scrollbar-sidebar">
        <Nav>
          <li>
            <NavLink
              to="/Society/dashboard"
              // className="nav-link"
              // activeClassName="active"
            >
              <img src={Dashboard} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/society-detail"
              // className="nav-link"
              // activeClassName="active"
            >
              <img
                src={SocietyDeatil}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}> Society Details</span>
            </NavLink>
          </li>
          <li className={active ? 'active active-pro' : ''}>
            <NavLink
              to="/Society/notice-board"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={NoticeBoard}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}> Notice Board</span>
            </NavLink>
          </li>
          <li className={active ? 'active active-pro' : ''}>
            <NavLink
              to="/Society/members"
              className="nav-link"
              activeClassName="active"
            >
              <img src={Members} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Members</span>{' '}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/complaints"
              className="nav-link"
              activeClassName="active"
            >
              <img src={Complaints} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Complaints</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/amenity-booking"
              className="nav-link"
              activeClassName="active"
            >
              <img src={Amenity} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Amenity Bookings</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/Society/amenity-booking"
              className="nav-link"
              activeClassName="active"
            >
              <i className="now-ui-icons shopping_shop" />
              Amenity Bookings
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/Society/emergency"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={EmergencyContact}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Emergency Contacts</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/Visitor"
              className="nav-link"
              activeClassName="active"
            >
              <img src={Visitor} style={{ height: '25px', width: '25px' }} />
              <span style={{ padding: '15px' }}>Visitors</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/categories"
              className="nav-link"
              activeClassName="active"
            >
              <img src={Categories} style={{ height: '25px', width: '25px' }} />{' '}
              <span style={{ padding: '15px' }}>Categories</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/configuration"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={Configurations}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Doc Config</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/MaintenanceMapping"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={MaintainceIcon}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '12px' }}>Mnt File Config</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/MaintenanceBilling"
              className="nav-link"
              activeClassName="active"
            >
              <img src={MntBilling} style={{ height: '25px', width: '25px' }} />
              <span style={{ padding: '12px' }}>Mnt Billing</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/TransactionMaster"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={Transactions}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Transaction Master</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Society/SecurityAlert"
              className="nav-link"
              activeClassName="active"
            >
              <img
                src={SecurityAlert}
                style={{ height: '25px', width: '25px' }}
              />
              <span style={{ padding: '15px' }}>Security Alert</span>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  )
}

export default Sidebar
