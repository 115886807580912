import {
    UPDATE_SOCIETY_DETAILS_SUCCESS,
    UPDATE_SOCIETY_DETAILS_FAILED,
    UPDATE_SOCIETY_DETAILS_NETWORK_ERROR,
    LOADING_UPDATE_SOCIETY_DETAILS,
    RESET_UPDATE_SOCIETY_DETAILS
  } from 'redux/types'
  
  const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: '',
    networkError: '',
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case LOADING_UPDATE_SOCIETY_DETAILS:
        return {
          isLoading: true,
          success: false,
          failed: false,
          network: false,
          data: {},
          message: '',
          networkError: '',
        }
      case UPDATE_SOCIETY_DETAILS_SUCCESS:
        return {
          isLoading: false,
          success: true,
          failed: false,
          network: false,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case UPDATE_SOCIETY_DETAILS_FAILED:
        return {
          isLoading: false,
          success: false,
          failed: true,
          network: false,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case UPDATE_SOCIETY_DETAILS_NETWORK_ERROR:
        return {
          isLoading: false,
          success: false,
          failed: false,
          network: true,
          data: action.payload,
          message: '',
          networkError: '',
        }
      case RESET_UPDATE_SOCIETY_DETAILS:
        return (state = initialState)
      default:
        return state
    }
  }
  