import axios from '../../config/axios'
import {
  GET_SOCIETY_MNT_TYPE_SUCCESS,
  GET_SOCIETY_MNT_TYPE_FAILED,
  LOADING_GET_SOCIETY_MNT_TYPE,
  RESET_GET_SOCIETY_MNT_TYPE,
  GET_SOCIETY_MNT_TYPE_NETWORKERROR,
} from '../types'

const resetGetSocietyMnt = () => {
  return {
    type: RESET_GET_SOCIETY_MNT_TYPE,
  }
}

const getSocietyMnt = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_SOCIETY_MNT_TYPE })
  console.log(userData)
  axios
    .post('mnt/getSocietyMntType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_SOCIETY_MNT_TYPE_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_SOCIETY_MNT_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_SOCIETY_MNT_TYPE_FAILED,
    payload: data,
  }
}

export { resetGetSocietyMnt, getSocietyMnt }
