import axios from '../../config/axios'
import {
  GET_UNIT_DOCTYPE_SUCCESS,
  GET_UNIT_DOCTYPE_FAILED,
  LOADING_GET_UNIT_DOCTYPE,
  RESET_GET_UNIT_DOCTYPE,
  GET_UNIT_DOCTYPE_NETWORK_ERROR,
} from '../types'

const resetgetUnitDocType = () => {
  return {
    type: RESET_GET_UNIT_DOCTYPE,
  }
}

const getDocUnitType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_UNIT_DOCTYPE })
  //console.log(userData);
  axios
    .post('society/getUnitDocumentType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_UNIT_DOCTYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_UNIT_DOCTYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_UNIT_DOCTYPE_FAILED,
    payload: data,
  }
}

export { resetgetUnitDocType, getDocUnitType }
