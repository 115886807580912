import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetaddAmenitytype,
  addAmenityType,
} from 'redux/actions/addAmenityTypeAction'
import AmenityListDisplay from './AmenityListDisplay'
import { Spin } from 'antd'
import Util from '../../validations'

function AddAmenityType() {
  const [amenityTypeInp, setamenityType] = useState('')

  const login = useSelector(({ login }) => login)
  const addedAmenityType = useSelector(({ addAmenityType }) => addAmenityType)
  const dispatch = useDispatch()

  const addAmenityTypeRequest = () => {
    const addAmenityTypeReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      type: amenityTypeInp.trim(),
    }
    return dispatch(addAmenityType(addAmenityTypeReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'amenityType')
      Util.checkWhiteSpace(value) ? setamenityType(value) : setamenityType('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addAmenityTypeRequest()
  }

  useEffect(() => {
    if (addedAmenityType.success) {
      setamenityType('')
      NotificationPopups('success', 'Success', addedAmenityType.data.message)
    }
    if (addedAmenityType.failed) {
      NotificationPopups('error', 'Failed', addedAmenityType.data.message)
    }
    if (addedAmenityType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetaddAmenitytype())
    }
  }, [
    addedAmenityType.success,
    addedAmenityType.failed,
    addedAmenityType.network,
  ])

  return (
    <>
      <div style={{ margin: '50px' }}>
        <Form onSubmit={handleSubmit}>
          <Row style={{ justifyContent: 'center', marginRight: '0px' }}>
            <Col md="4" sm="12">
              <FormGroup>
                <Label for="amenityType">Add Amenity Type</Label>
                <Input
                  type="text"
                  name="amenityType"
                  id="amenityType"
                  placeholder="Amenity Type"
                  value={amenityTypeInp || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="4" sm="12">
              <Button
                className="cust-button"
                style={{ marginTop: '22px', width: '70%' }}
                disabled={!amenityTypeInp || addedAmenityType.isLoading}
                onClick={handleSubmit}
              >
                {addedAmenityType.isLoading ? <Spin /> : 'Add'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <AmenityListDisplay />
    </>
  )
}

export default AddAmenityType
