import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Modal, ModalHeader, ModalBody, ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col, CardImg, CardText,
  CardTitle, CardSubtitle
} from "reactstrap";
import randomColor from "randomcolor";
import { Tooltip, Popconfirm, Spin } from 'antd';
import { resetgetVehicleType, getVehicleType } from "redux/actions/getVehicleTypeAction";
import { resetupdateVehicleType, updateVehicleType } from "redux/actions/updateVehicleTypeAction";
import { resetdeleteVehicleType, deleteVehicleType } from "redux/actions/deleteVehicleTypeAction";
import NotificationPopups from 'components/NotificationPopups/NotificationPopups';

function VehicleType() {

  const [modal, setModal] = useState(false);
  const [editVehicleType, seteditVehicleType] = useState("")
  const [id, setId] = useState("")
  const vehicleType = useSelector(({ vehicleType }) => vehicleType);
  const updatedVehicleType = useSelector(({ updateVehicleType }) => updateVehicleType);
	const dispatch = useDispatch();

	const getVehicleTypeRequest = () => {
		return dispatch(getVehicleType());
	}

  useEffect(() => {
		getVehicleTypeRequest()
	}, []	)

	useEffect(() => {
    if(updatedVehicleType.success){
      console.log("hello")
      setModal(!modal)
      NotificationPopups('success', 'Success', updatedVehicleType.data.message) 
      getVehicleTypeRequest()
    }

    return () => {
        dispatch(resetupdateVehicleType());
    };
    
	}, [updatedVehicleType.success, updatedVehicleType.failed]	)

  const handleDelete = (id) => {
    const deleteVehicletypeReq = {
      id: id
    }
    return dispatch(deleteVehicleType(deleteVehicletypeReq));
  }

  const openEditModal = (type) => {
    setId(type.id)
    seteditVehicleType(type.type);
    setModal(!modal)
  }

  const toggle = () => {
    setModal(!modal);
  }

  const handleChange = ({ target: { name, value } }) => {
    console.log(value)
		if( name === "editVehicleType" ) seteditVehicleType(value);
    console.log(value)
	}

  const handleVehicleType = () => {
    const editVehicleTypeReq = {
      id: id,
      updateType: editVehicleType
    }
    return dispatch(updateVehicleType(editVehicleTypeReq));
  }

	return (
		<div>
			<div className="typeHeading">Vehicle Type</div>
			<Row>
			 {vehicleType.success && vehicleType.data.vehicleType.map((d) => ( 
              <Col sm="12" md="3" lg="3" key={d.id}>
                <Card className="typeContent" style={{background: randomColor({luminosity: 'light', alpha: 0.5})}}>        
                  <CardBody>
                    <CardText>
                    <span style={{textTransform: "capitalize", fontWeight: "600"}}>{" "}{d.type}</span></CardText>
                    <CardText>
                      <span onClick={() => openEditModal(d)} >
                        <Tooltip placement="leftBottom" title="Edit" color="#108ee9">
                          <i className="far fa-edit"></i>
                        </Tooltip>
                      </span>
                      <span>
                        <Popconfirm
                          title="Are you sure to delete?"
                          onConfirm={() => handleDelete(d.id)}
                          okText="Delete"
                          cancelText="No"
                        >
                          <Tooltip placement="rightBottom" title="Delete" color="#108ee9">
                           <i className="far fa-trash-alt"></i>
                          </Tooltip>
                        </Popconfirm>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>))}
			</Row>


      {modal && <Modal isOpen={modal} toggle={toggle} size="sm" style={{maxWidth:"250px"}}>
        <ModalHeader toggle={toggle}>Edit</ModalHeader>
        <ModalBody>
            <FormGroup>
              <Label for="editVehicleType">Vehicle Type</Label>
              <Input type="text" 
                name="editVehicleType" 
                id="editVehicleType" 
                value={editVehicleType}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Button color="secondary" className="modalCustomButton" onClick={handleVehicleType}>Edit</Button>
            <Button color="secondary" style={{float:"right"}} className="modalCustomButton" onClick={toggle}>Close</Button>
        </ModalBody>
      </Modal>}


		</div>
	)
}

export default VehicleType;
