import axios from "../../config/axios";
import {
	UPDATEAREATYPE_SUCCESS,
	UPDATEAREATYPE_FAILED,
	LOADING_UPDATEAREATYPE,
	RESET_UPDATEAREATYPE,
	UPDATEAREATYPE_NETWORKERROR
	} from "../types";

const resetupdateAreaType = () => {
    return {
        type: RESET_UPDATEAREATYPE,		
    };
};

const updateAreaType = userData => dispatch => {
    dispatch({ type: LOADING_UPDATEAREATYPE });
		console.log(userData);
    axios
        .post("society/updateAreaType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATEAREATYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATEAREATYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATEAREATYPE_FAILED,
        payload: data
    };
}

export { resetupdateAreaType, updateAreaType } ;