import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin, Switch } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { resetAddDocument, addDocument } from 'redux/actions/addDocumentAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'
import Pagination from 'reactstrap/lib/Pagination'
import Progress from 'reactstrap/lib/Progress'

function SearchDocument(props) {
  const [document, setDocument] = useState('')
  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)
  const addedDoc = useSelector(({ addDocument }) => addDocument)
  const dispatch = useDispatch()

  const getDocTypeRequest = () => {
    if (login.data.user.role === 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy: 'SuperAdmin',
        type: document,
        limit,
        page,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'documentType') setDocument(value)
  }

  //console.log(isMandatory)

  const handleSubmit = (e) => {
    e.preventDefault()
    getDocTypeRequest()
    props.setIsSearch()
  }

  const disabledButton = () => {
    return !document
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup>
              <Label>Document</Label>
              <Input
                type="text"
                name="documentType"
                placeholder="Document Type"
                value={document || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="4" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '70%' }}
              // disabled={disabledButton() || addDocument.isLoading}
              onClick={handleSubmit}
            >
              {addDocument.isLoading ? <Spin /> : 'Search'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SearchDocument
