import {
	GETDAILYHELPTYPE_SUCCESS,
	GETDAILYHELPTYPE_FAILED,
	LOADING_GETDAILYHELPTYPE,
	RESET_GETDAILYHELPTYPE,
	GETDAILYHELPTYPE_NETWORKERROR
} from "../types";

const initialState = {
	isLoading: false,
	success: false,
	network: false,
	failed: false,
	data: {},
	message: "",
	networkError: ""
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOADING_GETDAILYHELPTYPE:
			return {
				...state,
				isLoading: true,
				success: false,
				failed: false,
				network: false,
				data: {},
				message: "",
				networkError: ""
			};
		case GETDAILYHELPTYPE_FAILED:
			return {
				...state,
				isLoading: false,
				success: false,
				failed: true,
				network: false,
				data: action.payload,
				message: "",
				networkError: ""

			};
		case GETDAILYHELPTYPE_SUCCESS:
			return {
				...state,
				isLoading: false,
				success: true,
				failed: false,
				network: false,
				data: action.payload,
				message: "",
				networkError: ""
			};
		case GETDAILYHELPTYPE_NETWORKERROR:
			return {
				...state,
				isLoading: false,
				success: false,
				network: true,
				failed: false,
				data: action.payload,
				message: "",
				networkError: "Network Error"
			};
		case RESET_GETDAILYHELPTYPE:
			return (state = initialState);

		default:
			return state;
	}
};