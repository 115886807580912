import React, { useEffect, useState } from 'react'
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import moment from 'moment'

function AmenityTimeLine({ AmenityList }) {
  const [task, setTask] = useState([''])
  let timelineArr = ['']
  let timeLineObj = {}

  const amenityTimeLineObj = () => {
    AmenityList.success &&
      AmenityList.data.amenityDetailsList.map((data) => {
        timeLineObj = {
          start: new Date(2020, 1, 1),
          end: new Date(2020, 1, 2),
          name: 'Idea',
          id: 'Task 0',
          type: 'task',
          progress: 45,
          isDisabled: true,
          styles: {
            progressColor: '#ffbb54',
            progressSelectedColor: '#ff9e0d',
          },
        }
        timelineArr.push(timeLineObj)
      })
  }

  let tasks = [
    {
      start: new Date(2020, 1, 1),
      //   start: '2020,1,1',
      end: new Date(2020, 1, 2),
      name: 'Idea',
      id: 'Task 0',
      type: 'task',
      progress: 45,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
  ]

  useEffect(() => {
    amenityTimeLineObj()
  }, [])

  return (
    <div>
      <Gantt tasks={timelineArr} />{' '}
    </div>
  )
}

export default AmenityTimeLine
