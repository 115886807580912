import axios from '../../config/axios'
import {
  GET_DOCTYPE_SUCCESS,
  GET_DOCTYPE_FAILED,
  LOADING_GET_DOCTYPE,
  RESET_GET_DOCTYPE,
  GET_DOCTYPE_NETWORK_ERROR,
} from '../types'

const resetgetDocType = () => {
  return {
    type: RESET_GET_DOCTYPE,
  }
}

const getDocType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_DOCTYPE })
  //console.log(userData);
  axios
    .post('society/getSocietyDocument', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GET_DOCTYPE_NETWORK_ERROR, payload: err }))
}

function success(data) {
  return {
    type: GET_DOCTYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_DOCTYPE_FAILED,
    payload: data,
  }
}

export { resetgetDocType, getDocType }
