import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import HelperType from './HelperType'
import AddServiceType from './AddServiceType'
import ServiceType from './ServiceType'
import HelperTypeList from './HelperTypeList'

import SeviceTypeIcon from 'assets/img/Icons/Sidebar/servictype.png'

function ServiceProvider() {
  const [showScreen, setshowScreen] = React.useState('Helper Service Type')

  const showDrawer = (a) => {
    setshowScreen(a)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons business_bank'} /> */}
                  <img
                    src={SeviceTypeIcon}
                    alt="SeviceTypeIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span>
                  {showScreen === 'HelperType' && (
                    <>
                      <span className="head-title">Add Helper Type</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('Helper Service Type')}
                      >
                        Helper Service Type
                      </span>
                    </>
                  )}
                  {showScreen === 'Helper Service Type' && (
                    <>
                      {' '}
                      <span className="head-title">Helper Service Type</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('HelperType')}
                      >
                        Add Helper Type
                      </span>
                    </>
                  )}
                </span>
              </CardHeader>
              <CardBody>
                {showScreen === 'Helper Service Type' && <AddServiceType />}
                {showScreen === 'HelperType' && <HelperType />}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showScreen === 'Helper Service Type' && <ServiceType />}
        {showScreen === 'HelperType' && <HelperTypeList />}
      </div>
    </>
  )
}

export default ServiceProvider
