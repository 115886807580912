import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import { Tooltip, Popconfirm } from 'antd'
import { Spin, Alert } from 'antd'
import {
  deleteDailyHelpType,
  resetdeletedailyHelpType,
} from 'redux/actions/deleteDailyHelpTypeAction'
import {
  resetupdateDailyHelpType,
  updateDailyHelpType,
} from 'redux/actions/updateDailyHelpTypeAction'
import {
  resetgetDailyHelp,
  getDailyHelp,
} from 'redux/actions/getDailyHelpAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

function DailyHelpType() {
  const [modal, setModal] = useState(false)
  const [editDailyHelpType, seteditDailyHelpType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const dailyHelpType = useSelector(({ dailyHelpType }) => dailyHelpType)
  const addeddailyHelpType = useSelector(
    ({ adddailyHelpType }) => adddailyHelpType
  )
  const deletedDailyHelpType = useSelector(
    ({ deleteDailyHelpType }) => deleteDailyHelpType
  )
  const updatedDailyHelpType = useSelector(
    ({ updateDailyHelpType }) => updateDailyHelpType
  )
  const dispatch = useDispatch()

  const getDailyHelpTypeRequest = () => {
    return dispatch(getDailyHelp())
  }

  useEffect(() => {
    getDailyHelpTypeRequest()
  }, [addeddailyHelpType.success])

  useEffect(() => {
    if (updatedDailyHelpType.success) {
      seterrMessage('')
      setModal(!modal)
      NotificationPopups(
        'success',
        'Success',
        updatedDailyHelpType.data.message
      )
      getDailyHelpTypeRequest()
    }
    if (updatedDailyHelpType.failed) {
      seterrMessage(updatedDailyHelpType.data.message)
      //NotificationPopups('error', 'Failed', updatedDailyHelpType.data.message)
    }
    if (updatedDailyHelpType.network || deletedDailyHelpType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedDailyHelpType.success) {
      NotificationPopups(
        'success',
        'Success',
        deletedDailyHelpType.data.message
      )
      getDailyHelpTypeRequest()
    }
    if (deletedDailyHelpType.failed) {
      NotificationPopups('error', 'Failed', deletedDailyHelpType.data.message)
    }

    return () => {
      dispatch(resetupdateDailyHelpType())
      dispatch(resetdeletedailyHelpType())
    }
  }, [
    updatedDailyHelpType.success,
    updatedDailyHelpType.failed,
    updatedDailyHelpType.network,
    deletedDailyHelpType.success,
    deletedDailyHelpType.failed,
    deletedDailyHelpType.network,
  ])

  const handleDelete = (id) => {
    const deleteDailyHelptypeReq = {
      id: id,
    }
    //return dispatch(deleteDailyHelpType(deleteDailyHelptypeReq));
  }

  const openEditModal = (type) => {
    setId(type.id)
    seteditDailyHelpType(type.type)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }

  const handleChange = ({ target: { name, value } }) => {
    console.log(value)
    if (name === 'editDailyHelpType') seteditDailyHelpType(value)
    console.log(value)
  }

  const handleEditDailyHelpType = () => {
    const editDailyHelpTypeReq = {
      id: id,
      type: editDailyHelpType.trim(),
    }
    return dispatch(updateDailyHelpType(editDailyHelpTypeReq))
  }

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                <div>
                  <div className="typeHeading">Daily Help Type</div>
                  <Row>
                    {dailyHelpType.success &&
                      dailyHelpType.data.dailyType.map((d, id) => (
                        <Col sm="12" md="3" lg="3" key={id}>
                          <Card
                            className="typeContent"
                            style={{
                              background: randomColor({
                                luminosity: 'light',
                                alpha: 0.5,
                              }),
                            }}
                          >
                            <CardBody>
                              <CardText>
                                <span
                                  style={{
                                    textTransform: 'capitalize',
                                    fontWeight: '600',
                                  }}
                                >
                                  {' '}
                                  {d.type}
                                </span>
                              </CardText>
                              <CardText>
                                <span onClick={() => openEditModal(d)}>
                                  <Tooltip
                                    placement="leftBottom"
                                    title="Edit"
                                    color="#108ee9"
                                  >
                                    <i className="far fa-edit"></i>
                                  </Tooltip>
                                </span>
                                <span>
                                  <Popconfirm
                                    title="Are you sure to delete?"
                                    onConfirm={() => handleDelete(d.id)}
                                    okText="Delete"
                                    cancelText="No"
                                  >
                                    <Tooltip
                                      placement="rightBottom"
                                      title="Delete"
                                      color="#108ee9"
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </Tooltip>
                                  </Popconfirm>
                                </span>
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>

                  {dailyHelpType.failed && (
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                      <Alert message={dailyHelpType.data.message} type="info" />
                    </div>
                  )}

                  {modal && (
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      size="sm"
                      style={{ maxWidth: '250px' }}
                    >
                      <ModalHeader toggle={toggle}>Edit</ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label for="editDailyHelpType">Daily Help Type</Label>
                          <Input
                            type="text"
                            name="editDailyHelpType"
                            id="editDailyHelpType"
                            value={editDailyHelpType}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <div
                          style={{ textAlign: 'center' }}
                          className="errorText"
                        >
                          {errMessage}
                        </div>
                        <Button
                          color="secondary"
                          className="modalCustomButton"
                          onClick={handleEditDailyHelpType}
                          disabled={
                            updatedDailyHelpType.isLoading || !editDailyHelpType
                          }
                        >
                          {updatedDailyHelpType.isLoading ? <Spin /> : 'Edit'}
                        </Button>
                        <Button
                          color="secondary"
                          style={{ float: 'right' }}
                          className="modalCustomButton"
                          onClick={toggle}
                        >
                          Close
                        </Button>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default DailyHelpType
