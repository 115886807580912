import axios from "../../config/axios";
import {
	UPDATENOTICE_SUCCESS,
	UPDATENOTICE_FAILED,
	LOADING_UPDATENOTICE,
	RESET_UPDATENOTICE,
	UPDATENOTICE_NETWORKERROR
} from "../types";

const resetupdateNotice = () => {
    return {
        type: RESET_UPDATENOTICE,		
    };
};

const updateNotice = userData => dispatch => {
    dispatch({ type: LOADING_UPDATENOTICE });
		console.log(userData);
    axios
        .post("notice/updateNotice", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATENOTICE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATENOTICE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATENOTICE_FAILED,
        payload: data
    };
}

export { resetupdateNotice, updateNotice } ;