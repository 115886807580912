import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import { getFeature } from 'redux/actions/getFeatureAction'
import {
  resetUpdateFeature,
  updateFeature,
} from 'redux/actions/updateFeatureAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'
import { updateSubCategory } from 'redux/actions/updateSubCategoryAction'
import AddSubCategory from './AddSubCategory'
function SubCategory(onTap) {
  const [featureId, setFeatureId] = useState('')
  const [name, setName] = useState('')
  const [redirect, setRedirect] = useState('')
  const [modal, setmodal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState()
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const addedSubCategory = useSelector(({ addSubCategory }) => addSubCategory)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const updatedFeature = useSelector(({ updateFeature }) => updateFeature)
  const updatedSubCat = useSelector(
    ({ updateSubCategory }) => updateSubCategory
  )

  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = getSubCat.data.count

  console.log(getSubCat, 'getSubCat')
  const getSubCategoryRequest = () => {
    const getSubCategoryReq = {
      initiatedBy,
      limit,
      page
    }
    return dispatch(getSubCategory(getSubCategoryReq))
  }
  useEffect(() => {
    getSubCategoryRequest()
  }, [page])

  const { Option } = Select
  function onChangeSearch(value) {
    setFeatureId(value)
  }

  function onSearch(val) {}

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() =>{
    setPage(1)
  },[onTap])

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'name') setName(value)
    if (name === 'redirect') setRedirect(value)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
    updateSubCategoryRequest()
    setmodal(!modal)
    if (updatedSubCat.success) {
      setmodal(!modal)
      NotificationPopups('success', 'Success', updatedSubCat.data.message)
    }
  }
  const updateSubCategoryRequest = () => {
    const updateSubCategoryReq = {
      initiatedBy: login.data.user.role,
      id,
      featureId,
      name,
      redirect,
      isActive,
    }
    return dispatch(updateSubCategory(updateSubCategoryReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const openEditModal = (type) => {
    // console.log(type)
    setFeatureId(type.featureId)
    setId(type.id)
    setName(type.name)
    setRedirect(type.redirect)
    setmodal(!modal)
    setisActive(type.isActive)
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    if (addedSubCategory.success) {
      getSubCategoryRequest()
      setAddModal(false)
    }
    if (updatedSubCat.success) {
      setmodal(false)
      setId('')
      setFeatureId('')
      setName('')
      setRedirect('')
      // setmodal(!modal)
      getSubCategoryRequest()
      // NotificationPopups('success', 'Success', updatedSubCat.data.message)
    }
    if (updatedSubCat.failed) {
      NotificationPopups('error', 'Failed', updatedSubCat.data.message)
    }
    if (updatedSubCat.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateFeature())
    }
  }, [
    addedSubCategory.success,
    updatedSubCat.success,
    updatedSubCat.failed,
    updatedSubCat.network,
  ])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add SubCategory
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Sub Category List
                    </div>
                  </Col>
                </div>
                <Col md="12" style={{ padding: '20px' }}>
                  {getSubCat.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Feature Category </th>
                            <th>App Screen</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {getSubCat.success && (
                          <tbody>
                            {getSubCat.data?.subCategory.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>{d.name}</td>
                                  <td>{d.featureName}</td>
                                  <td>{d.redirect}</td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
                <Col>
                  {getSubCat.success && (
                    <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {getSubCat.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={getSubCat.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          style={{ maxWidth: '250px' , marginRight:"auto" , marginLeft:"auto"}}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Feature Category</label>
              <Select
                type="select"
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                value={featureId}
                // filterOption={(input, option) =>
                //   option.children
                //     .toLowerCase()
                //     .indexOf(input.toLowerCase()) >= 0
                // }
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label for="helptype">Name</Label>
              <Input
                type="text"
                name="name"
                id="helptype"
                value={name}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="helptype">App Screen</Label>
              <Input
                type="text"
                name="redirect"
                id="helptype"
                value={redirect}
                onChange={handleEditChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
                // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditFeature}
              disabled={updatedFeature.isLoading || !name || !redirect}
            >
              {updatedFeature.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Sub Category</ModalHeader>
          <ModalBody>
            <AddSubCategory />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default SubCategory
