import DemoNavbar from 'components/Navbars/DemoNavbar'
import Sidebar from 'components/Sidebar/Sidebar'
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import routes from '../routes.js'

function Society() {
  return (
    <div className="wrapper">
      <Sidebar />
      <div className="main-panel">
        <DemoNavbar />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            )
          })}
          {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  )
}

export default Society
