import {
		PINCODE_SUCCESS,
		PINCODE_FAILED,
		RESET_PINCODE,
		LOADING_PINCODE,
		PINCODE_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: "",
		block: "",
		city: "",
		state: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_PINCODE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    						network: false,
								block: "",
								city: "",
								state: "",
								data: {},
								message: "",
                networkError: ""
            };
				case 	PINCODE_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
    						network: false,
								failed: true,
								block: "",
								city: "",
								state: "",
								data: action.payload,
								message: "",
                networkError: ""
                
            };
				case 	PINCODE_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
    						network: false,
								failed: false,
								data: action.payload,
								block: action.payload.PostOffice[(action.payload.PostOffice.length - 1)].Block,
								city: action.payload.PostOffice[0].Region,
								state: action.payload.PostOffice[0].State,
								message: "",
                networkError: ""
            };
            case PINCODE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
    						network: true,
								failed: false,
								data: action.payload,
								block: "",
								city: "",
								state: "",
								message: "",
                networkError: "Network Error"
            };
				case RESET_PINCODE:
            return (state = initialState);

        default:
            return state;
    }
};