import axios from '../../config/axios'
import {
  GETAMENITYLIST_SUCCESS,
  GETAMENITYLIST_FAILED,
  LOADING_GETAMENITYLIST,
  RESET_GETAMENITYLIST,
  GETAMENITYLIST_NETWORKERROR,
} from '../types'

const resetgetAmenityList = () => {
  return {
    type: RESET_GETAMENITYLIST,
  }
}

const getAmenityList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETAMENITYLIST })
  console.log(userData)
  axios
    .post('amenity/getAmenityList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETAMENITYLIST_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GETAMENITYLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETAMENITYLIST_FAILED,
    payload: data,
  }
}

export { resetgetAmenityList, getAmenityList }
