import React from 'react'
import FileViewer from 'react-file-viewer';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function DisplayAttachment({ attachment, fileModal, toggleModal }) {

	const attachmentType = (a) => {

		const myArr = a.split(",");

		switch (myArr[0]) {

			case "data:image/png;base64": return "png";

			case "data:image/jpeg;base64": return "jpeg";

			case "data:application/msword;base64": return "doc";

			case "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64": return "docx";

			case "data:application/pdf;base64": return "pdf";

			default: return "jpeg";

		}

	}

	return (
		<Modal isOpen={fileModal} toggle={toggleModal} className="attachmentModal">
			<FileViewer
				fileType={attachmentType(attachment)}
				filePath={attachment}
			//errorComponent={CustomErrorComponent}
			//onError={this.onError}
			/>
		</Modal>
	)
}

export default DisplayAttachment
