import axios from "../../config/axios"
import {
	GETADMINLIST_SUCCESS,
	GETADMINLIST_FAILED,
	LOADING_GETADMINLIST,
	RESET_GETADMINLIST,
    GETADMINLIST_NETWORKERROR
} from "../types";

const resetgetAdminList = () => {
    return {
        type: RESET_GETADMINLIST
    };
};

const getAdminList = userData => dispatch => {
    dispatch({ type: LOADING_GETADMINLIST });
		console.log(userData);
    axios
        .post("society/getSocietyUserList", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETADMINLIST_NETWORKERROR, payload: err }));
};

function success(data) {
    return {
        type: GETADMINLIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETADMINLIST_FAILED,
        payload: data
    };
}

export { resetgetAdminList, getAdminList } ;