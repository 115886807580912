import {
	GETADMINLIST_SUCCESS,
	GETADMINLIST_FAILED,
	LOADING_GETADMINLIST,
	RESET_GETADMINLIST,
    GETADMINLIST_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETADMINLIST:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case 	GETADMINLIST_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
    network: false,
								failed: true,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case 	GETADMINLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case 	GETADMINLIST_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case RESET_GETADMINLIST:
            return (state = initialState);

        default:
            return state;
    }
};