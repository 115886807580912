import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
} from 'reactstrap'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import FileBase64 from '../../components/Custom/UploadFile'
import { resetgetDocType, getDocType } from 'redux/actions/getDocTypeAction'
import {
  resetaddSocietyDoc,
  addSocietyDoc,
} from 'redux/actions/addSocietyDocAction'
import {
  resetgetSocietyDoc,
  getSocietyDoc,
} from 'redux/actions/getSocietyDocAction'
import {
  resetupdateSocietyDoc,
  updateSocietyDoc,
} from 'redux/actions/updateSocietyDocAction'
//ant design components
import { Spin, Select } from 'antd'
import Util from 'validations/index'
import {
  resetresendSignupOTP,
  resendSignupOTP,
} from 'redux/actions/resendSignupOTPAction'
import {
  resetValidateSignupOtp,
  validateSignupOtp,
} from 'redux/actions/validateSignupOtpAction'
import {
  resetgetSocietyList,
  getSocietyList,
} from 'redux/actions/getSocietyListAction'
import {
  resetLinkNewSociety,
  linkNewSociety,
} from 'redux/actions/linkSocietyAction'
import {
  resetgetPincodeDetails,
  getPincodeDetails,
} from 'redux/actions/pincodeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

function LinkSociety() {
  const [city, setCity] = useState('')
  const [societyId, setSocietyId] = useState('')
  const [societyName, setSocietyName] = useState('')
  const [societyAddress, setsocietyAddress] = useState('')
  const [landmark, setlandmark] = useState('')
  const [areaBlock, setareaBlock] = useState('')
  const [region, setRegion] = useState('')
  const [newCity, setNewCity] = useState('')
  const [pincode, setPincode] = useState('')
  const [toggleSocietyDetails, setToggleSocietyDetails] = useState(false)
  const [toggle, setToggle] = useState(false)

  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  const societyList = useSelector(({ getSocietyList }) => getSocietyList)
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)

  const { Option } = Select

  //   console.log(adminRegister)
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'societyId') setSocietyId(value)
    if (name === 'societyName') setSocietyName(value)
    if (name === 'societyAddress') setsocietyAddress(value)
    if (name === 'landmark') setlandmark(value)
    if (name === 'newCity') setNewCity(value)
    if (name === 'pincode') {
      setPincode(value.trim())
      if (value.length === 6) {
        getPincodeRequest(value)
      }
    }
    if (name === 'region') setRegion(value)
    if (name === 'areaBlock') setareaBlock(value)
    // if (name === 'role') setRole(value)
  }

  const getPincodeRequest = (pin) => {
    console.log(pin)
    return dispatch(getPincodeDetails(pin))
  }

  function onChangeSearch(value) {
    // console.log(`selected ${value}`)
    setSocietyId(value)
  }

  function onSearch(val) {
    setSocietyId(val)
  }

  const handleLogout = () => {
    sessionStorage.removeItem('persist:root')
    window.location.href = '/'
  }

  const getSocietyListRequest = (city) => {
    const getSocListReq = {
      city,
      initiatedBy: 'Society',
    }
    return dispatch(getSocietyList(getSocListReq))
  }

  const handleCity = ({ target: { name, value } }) => {
    if (name === 'city') setCity(value)
    getSocietyListRequest(value)
  }
  const linkSocietyRequest = () => {
    let linkSocietyRequest = ''

    if (toggleSocietyDetails) {
      linkSocietyRequest = {
        initiatedBy: 'Society',
        user: {
          id: login.data.user.id,
        },
        society: {
          societyName: societyName.trim(),
          address: societyAddress.trim(),
          landmark: landmark.trim(),
          state: region.trim(),
          city: newCity.trim(),
          societyBlock: areaBlock.trim(),
          pincode,
        },
      }
    } else {
    }
    linkSocietyRequest = {
      initiatedBy: 'Society',
      society: {
        id: societyId,
      },
      user: {
        id: login.data.user.id,
      },
    }
    return dispatch(linkNewSociety(linkSocietyRequest))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    linkSocietyRequest()
  }

  const handleSocietyToggleChange = () => {
    setToggleSocietyDetails(true)
  }

  const disabledButton = () => {
    return !(city && societyId)
  }

  const disabledSocButton = () => {
    return !(
      societyName &&
      societyAddress &&
      landmark &&
      areaBlock &&
      region &&
      newCity &&
      pincode
    )
  }

  const handleToggle = () => {
    setToggle(!toggle)
  }

  // useEffect(() => {
  //   if (linkSociety.success) {
  //     NotificationPopups('success', 'Success', linkSociety.data.message)
  //     setCity('')
  //     setSocietyId('')
  //     setSocietyName('')
  //     setsocietyAddress('')
  //     setlandmark('')
  //     setareaBlock('')
  //     setRegion('')
  //     setNewCity('')
  //     setPincode('')
  //   }
  //   if (linkSociety.network) {
  //     NotificationPopups(
  //       'error',
  //       'Network Error',
  //       'Check your internet connection'
  //     )
  //   }
  //   if (linkSociety.failed) {
  //     NotificationPopups('error', 'Failed', linkSociety.data.message)
  //   }
  //   return () => {
  //     dispatch(resetLinkNewSociety())
  //     dispatch(resetgetSocietyList())
  //     dispatch(resetValidateSignupOtp())
  //     dispatch(resetgetPincodeDetails())
  //   }
  // }, [linkSociety.success, linkSociety.network, linkSociety.failed])

  return (
    <>
      <Modal
        isOpen={true}
        size="lg"
        backdrop="static"
        centered
        className="docs"
        style={{ maxWidth: '1300px' }}
      >
        <div className="content upload-document-list">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <div className="head-icon">
                    <i className={'now-ui-icons files_single-copy-04'} />
                  </div>
                  <span className="head-title">Verify User</span>
                  <span className="logoutButton" onClick={handleLogout}>
                    Logout
                  </span>
                </CardHeader>
                <CardBody>
                  {/* link Society  */}
                  {login.data.user.userStatus === 'verified' && (
                    <>
                      <Row>
                        <Col>
                          <FormGroup style={{ margin: '20px 0' }}>
                            {/* <label>CITY *</label> */}
                            <Input
                              placeholder="City"
                              type="text"
                              name="city"
                              value={city || ''}
                              onChange={handleCity}
                              required
                              //   style={{ width: '50%' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup style={{ margin: '20px 0' }}>
                            <label>SOCIETY *</label>
                            {/* <Input
                  type="select"
                  name="societyId"
                  onChange={handleChange}
                  required
                >
                  <option className="loginSelect" value="">
                    Select your society name
                  </option>
                  {societyList.success &&
                    societyList.data.societyList.map((d) => (
                      <option key={d.id} className="loginSelect" value={d.id}>
                        {d.societyName}
                      </option>
                    ))}
                </Input> */}
                            <Select
                              type="select"
                              value={societyId}
                              showSearch
                              placeholder="Select your society name"
                              optionFilterProp="children"
                              onChange={onChangeSearch}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              //   style={{ width: '100%' }}
                            >
                              {/* <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="tom">Tom</Option> */}
                              <Option className="loginSelect" value="">
                                Select your society name
                              </Option>
                              {societyList.success &&
                                societyList.data.societyList.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={d.id}
                                  >
                                    {d.societyName}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>

                        {/* <FormGroup style={{ margin: '20px 0' }}>
                <label>Role *</label>
                <Input type="select" name="role" onChange={handleChange} required>
                  <option className="loginSelect" value="">
                    Select your Role
                  </option>
                  {selectOptions.map((d) => (
                    <option key={d.id} className="loginSelect" value={d.role}>
                      {d.role}
                    </option>
                  ))}
                </Input>
              </FormGroup> */}

                        <FormGroup
                          style={{
                            margin: '54px 0px 0px',
                            textAlign: 'center',
                          }}
                        >
                          <Button
                            onClick={handleSubmit}
                            disabled={linkSociety.isLoading || disabledButton()}
                          >
                            {linkSociety.isLoading ? <Spin /> : 'Link Society'}
                          </Button>
                        </FormGroup>
                        {/* <div className="register" onClick={handleToggle}>
                        Did not find your society? Add Here!
                      </div> */}
                      </Row>
                    </>
                  )}
                  {/* Add society */}
                  {login.data.user.userStatus === 'verified' && (
                    // <>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>SOCIETY NAME *</label>
                    //     <Input
                    //       type="text"
                    //       name="societyName"
                    //       id="societyname"
                    //       value={societyName || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="Society Name"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>ADDRESS *</label>
                    //     <Input
                    //       type="textarea"
                    //       rows="4"
                    //       name="societyAddress"
                    //       id="address1"
                    //       value={societyAddress || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="Address"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>LANDMARK *</label>
                    //     <Input
                    //       type="text"
                    //       name="landmark"
                    //       id="landmark"
                    //       value={landmark || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="Landmark"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>PINCODE *</label>
                    //     <Input
                    //       type="text"
                    //       name="pincode"
                    //       id="pincode"
                    //       minLength={6}
                    //       maxLength={6}
                    //       value={pincode || ''}
                    //       onChange={handleChange}
                    //       required
                    //       invalid={!(pincode && pincode.length === 6)}
                    //       placeholder="Pincode"
                    //     />
                    //     <FormFeedback style={{ textAlign: 'center' }}>
                    //       {pincode && !(pincode.length === 6)
                    //         ? 'Invalid Pincode. Enter 6 Digits'
                    //         : ''}
                    //       {/* {pincode.length === 6 && getpincode.failed ? "Incorrect Pincode" : ""} */}
                    //     </FormFeedback>
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>BLOCK *</label>
                    //     <Input
                    //       type="text"
                    //       name="areaBlock"
                    //       id="areaBlock"
                    //       value={areaBlock || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="Block"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>CITY *</label>
                    //     <Input
                    //       type="text"
                    //       name="newCity"
                    //       id="newCity"
                    //       value={newCity || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="City"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup style={{ margin: '20px 0' }}>
                    //     <label>STATE *</label>
                    //     <Input
                    //       type="text"
                    //       name="region"
                    //       id="region"
                    //       value={region || ''}
                    //       onChange={handleChange}
                    //       required
                    //       placeholder="State"
                    //     />
                    //   </FormGroup>
                    //   <FormGroup
                    //     style={{
                    //       margin: '54px 0px 0px',
                    //       textAlign: 'center',
                    //     }}
                    //   >
                    //     <Button
                    //       onClick={handleSubmit}
                    //       disabled={
                    //         linkSociety.isLoading || disabledSocButton()
                    //       }
                    //     >
                    //       {linkSociety.isLoading ? <Spin /> : 'Add Society'}
                    //     </Button>
                    //   </FormGroup>
                    // </>
                    <></>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default LinkSociety
