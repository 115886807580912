import axios from '../../config/axios'
import {
  ADD_COMPLAINT_MESSAGE_SUCCESS,
  ADD_COMPLAINT_MESSAGE_FAILED,
  LOADING_ADD_COMPLAINT_MESSAGE,
  RESET_ADD_COMPLAINT_MESSAGE,
  ADD_COMPLAINT_MESSAGE_NETWORK_ERROR,
  GET_COMPLAINT_MESSAGE_SUCCESS,
  GET_COMPLAINT_MESSAGE_FAILED,
  LOADING_GET_COMPLAINT_MESSAGE,
  RESET_GET_COMPLAINT_MESSAGE,
  GET_COMPLAINT_MESSAGE_NETWORK_ERROR,
} from '../types'

const addComplaintMessage = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_COMPLAINT_MESSAGE })
  //   console.log('addComplainMessage request = ', userData)
  axios
    .post('complain/addComplainRecord', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_COMPLAINT_MESSAGE_NETWORK_ERROR, payload: err })
    )
}

function resetAddComplaintMessage() {
  return {
    type: RESET_ADD_COMPLAINT_MESSAGE,
  }
}

function success(data) {
  return {
    type: ADD_COMPLAINT_MESSAGE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_COMPLAINT_MESSAGE_FAILED,
    payload: data,
  }
}

const getComplaintMessage = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_COMPLAINT_MESSAGE })
  //   console.log('getComplainMessage request = ', userData)
  axios
    .post('complain/getComplainRecord', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(successGetMessage(res.data))
          break
        default:
          dispatch(failedGetMessage(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_COMPLAINT_MESSAGE_NETWORK_ERROR, payload: err })
    )
}

function resetGetComplaintMessage() {
  return {
    type: RESET_GET_COMPLAINT_MESSAGE,
  }
}

function successGetMessage(data) {
  return {
    type: GET_COMPLAINT_MESSAGE_SUCCESS,
    payload: data,
  }
}

function failedGetMessage(data) {
  return {
    type: GET_COMPLAINT_MESSAGE_FAILED,
    payload: data,
  }
}

export {
  addComplaintMessage,
  resetAddComplaintMessage,
  getComplaintMessage,
  resetGetComplaintMessage,
}
