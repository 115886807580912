import {
  GET_HELEPER_DETAILS_SUCCESS,
  GET_HELEPR_DETAILS_FAILED,
  LOADING_GET_HELPER_DETAILS,
  RESET_GET_HELPER_DETAILS,
  GET_HELER_DETAILS_NETWORK_ERROR,
} from 'redux/types'
import axios from 'config/axios'

const resetGetHelperDetail = () => {
  return {
    type: RESET_GET_HELPER_DETAILS,
  }
}

const getHelperDetail = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_HELPER_DETAILS })
  axios
    .post('household/getHelperDetail', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
      }
    })
    .catch((err) =>
      dispatch({ type: GET_HELER_DETAILS_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_HELEPER_DETAILS_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_HELEPR_DETAILS_FAILED,
    payload: data,
  }
}
export { resetGetHelperDetail, getHelperDetail }
