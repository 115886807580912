import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin, Switch } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetSmsProvider,
  getSmsProvider,
} from 'redux/actions/getSmsProviderAction'
import {
  resetchangeSMSProvider,
  changeSMSProvider,
} from 'redux/actions/changeSmsProviderAction'

function AddSMSProvider() {
  const [smsprovider, setsmsprovider] = useState('')
  const [isActive, setIsActive] = useState('')
  const [id, setId] = useState('')
  const getSmsProviders = useSelector(({ getSmsProvider }) => getSmsProvider)
  const login = useSelector(({ login }) => login)

  const getSmsProviderData = useSelector(({ getSmsProvider }) => getSmsProvider)
  const changedSmsProvider = useSelector(
    ({ changeSmsProvider }) => changeSmsProvider
  )
  const dispatch = useDispatch()

  const getSMSProviderRequest = () => {
    if (login.data.user.role === 'SuperAdmin') {
      const getSMSProviderReq = {
        initiatedBy: login.data.user.role,
      }
      return dispatch(getSmsProvider(getSMSProviderReq))
    }
  }

  useEffect(() => {
    getSMSProviderRequest()
  }, [])

  useEffect(() => {}, [getSmsProviderData.success])

  const onChangeSwitch = (checked) => {
    // setisMandatory(checked)
    setIsActive(checked)
    // console.log(`switch to ${checked}`)
  }
  console.log(getSmsProviderData, 'getSmsProviderData')
  const handleChange = ({ target: { name, value } }) => {
    console.log(smsprovider)

    if (name === 'smsprovider') setsmsprovider(value)
    if (name === '') setsmsprovider()
  }

  const changeSMSProviderRequest = () => {
    if (login.data.user.role === 'SuperAdmin' && id) {
      const changeSMSProviderReq = {
        initiatedBy: login.data.user.role,
        id,
        isActive,
      }
      return dispatch(changeSMSProvider(changeSMSProviderReq))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    changeSMSProviderRequest()
  }
  useEffect(() => {
    changeSMSProviderRequest()
  }, [id, isActive])

  useEffect(() => {
    if (changedSmsProvider.success) {
      setsmsprovider('')
      getSMSProviderRequest()
      NotificationPopups('success', 'Success', changedSmsProvider.data.message)
    }
    if (changedSmsProvider.failed) {
      NotificationPopups('error', 'Failed', changedSmsProvider.data.message)
    }
    if (changedSmsProvider.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetchangeSMSProvider())
    }
  }, [
    changedSmsProvider.success,
    changedSmsProvider.failed,
    changedSmsProvider.network,
  ])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12">
            <Label>SMS Provider</Label>

            {getSmsProviderData.success &&
              getSmsProviderData.data.provider.map((d, id) => (
                <FormGroup>
                  <Label> {d.providerName}</Label>
                  <br />
                  <Switch
                    defaultChecked={d.isActive}
                    name="isMandatory"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeSwitch}
                    onClick={() => {
                      setId(d.id)
                    }}
                    // style={{ width: '100%' }}
                    isLoading={changedSmsProvider.isLoading}
                  />
                </FormGroup>
              ))}
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '70%' }}
              disabled={changedSmsProvider.isLoading || !smsprovider}
              onClick={handleSubmit}
            >
              {changedSmsProvider.isLoading ? <Spin /> : 'Change'}
            </Button>
          </Col>
        </Row> */}
      </Form>
    </div>
  )
}

export default AddSMSProvider
