import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin } from 'antd'
import {
  resetAddNoticeType,
  addNoticeType,
} from 'redux/actions/addNoticeTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations/index'

function AddNoticeType() {
  const [noticeType, setNoticeType] = useState('')
  const login = useSelector(({ login }) => login)
  const addednoticetype = useSelector(({ addnoticetype }) => addnoticetype)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'noticeType')
      Util.alphabetsOnly(value)
        ? setNoticeType(value)
        : setNoticeType(noticeType)
  }

  const addNoticeTypeRequest = () => {
    const addNoticeTypeReq = {
      type: noticeType.trim(),
      initiatedBy: login.data.user.role,
    }

    console.log(addNoticeTypeReq)
    return dispatch(addNoticeType(addNoticeTypeReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addNoticeTypeRequest()
  }

  useEffect(() => {
    if (addednoticetype.success) {
      setNoticeType('')
      NotificationPopups('success', 'Success', addednoticetype.data.message)
    }
    if (addednoticetype.failed) {
      NotificationPopups('error', 'Failed', addednoticetype.data.message)
    }
    if (addednoticetype.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      dispatch(resetAddNoticeType())
    }
  }, [addednoticetype.success, addednoticetype.failed, addednoticetype.network])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12">
            <FormGroup>
              <Label for="noticeType">Notice Type</Label>
              <Input
                type="text"
                name="noticeType"
                placeholder="Notice Type"
                id="noticeType"
                value={noticeType || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '70%' }}
              disabled={!noticeType.trim() || addednoticetype.isLoading}
              onClick={handleSubmit}
            >
              {addednoticetype.isLoading ? <Spin /> : 'Add'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddNoticeType
