import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Spin, Switch } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  deleteBuilding,
  resetdeleteBuilding,
} from 'redux/actions/deleteBuildingAction'
import PaginationComponent from 'views/Pagination'
import {
  resetupdateBuilding,
  updateBuilding,
} from 'redux/actions/updateBuildingAction'
import {
  resetUpdateUnitFlatList,
  updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'
import moment from 'moment'
import AddBungalow from './AddBungalow'
import AddMiscellaneous from './AddMiscellaneousType'
import { getAreaSearchList } from 'redux/actions/getAreaSearchListAction'
import Util from 'validations/index'
import AddParking from './AddParking'
import EditBtn from 'assets/img/Brand/buttons/edit-icon.png'
import axios from 'config/axios'

function DisplayList({ type, areaid, isResident }) {
  const [buildingId, setbuildingId] = useState()
  const [buildingName, setBuildingName] = useState('')
  const [floor, setfloor] = useState()
  const [areaTypeId, setAreaTypeId] = useState('')
  const [areaType, setAreaType] = useState('')
  const [unitId, setUnitId] = useState('')
  const [page, setPage] = useState(1)
  const [unit, setUnit] = useState('')
  const [limit] = useState(10)
  const [isParkingEdit, setIsParkingEdit] = useState(false)
  const [modal, setmodal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [areaNamevalue, setareaNameValue] = useState('')
  const [floorData, setFloorData] = useState('')
  const [prefix, setPrefix] = useState('')
  const [spanText, setspanText] = useState('')
  const [parentName, setParentName] = useState('')

  const [errMessage, seterrMessage] = useState('')
  const [isActiveEdit, setisActiveEdit] = useState(false)
  const [areaName, setareaName] = useState('')
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const addedBuilding = useSelector(({ addBuilding }) => addBuilding)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const updatedBuilding = useSelector(({ updateBuilding }) => updateBuilding)
  const updatedUnitFlat = useSelector(
    ({ updateUnitFlatList }) => updateUnitFlatList
  )
  const getAreaSearchListRes = useSelector(({ getAreaSearchList }) => getAreaSearchList)
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()

  const totalItems = buildingList.data.count

  const inActivePageSize = buildingList.data.inActivePages
  const inActiveTotalItems = buildingList.data.inActiveCount

  const openEditModal = (d) => {
    setPrefix(d.unit ? d.unit.split('-')[1] : d.areaName.split('-')[1])
    setfloor(d.floor)
    setAreaType(d.areaType)
    setbuildingId(d.areaId)
    setareaNameValue(d.areaName)
    setareaName(d.areaName ? d.areaName.split('-')[0] : d.areaName)
    setisActiveEdit(d.isActive)
    setIsParkingEdit(d.isParking)
    setAreaTypeId(d.areaTypeId)
    setUnit(d.unit ? d.unit.split('-')[0] : d.unit)
    setUnitId(d.unitId)
    setmodal(!modal)
    setParentName(d.parentName)
  }


  const getAreaSearchListReq = () => {
    const userData = {
      areaType: type,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
    }
    return dispatch(getAreaSearchList(userData))
  }

  useEffect(() => {
      getAreaSearchListReq()

  }, [updatedBuilding.success])

  useEffect(() => {
    getAreaSearchListRes.success && getAreaSearchListRes.data.activeAreaList.map((d) => (
      d.areaName === areaNamevalue ? setFloorData(d.floor) : null
    ))
  }, [areaNamevalue, getAreaSearchListRes.success])

  useEffect(() => {
    if (floorData === undefined) {
      setspanText('');
    }
    else {
      setspanText("Please Enter Valid Floor")
    }
  }, [floorData]);

  const onChangeSwitch = (checked) => {
    setIsParkingEdit(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setisActiveEdit(checked)
  }
  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  const getAreatypeListRequest = (type) => {
    const getBuildingListReq = {
      areaType: type,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      page,
      limit,
    }
    return dispatch(getBuildingList(getBuildingListReq))
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    const getBuildingListReq = {
      areaType: type,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      page,
      limit,
    }
    return dispatch(getBuildingList(getBuildingListReq))
  }, [page, type])

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'areaName')
      Util.alphaNumeric(value) ? setareaName(value) : setareaName(areaName)
    // setareaName(value)
    if (name === 'unit') {
      Util.alphaNumeric(value) ? setUnit(value) : setUnit(unit)
      // setUnit(value)
    }
    if (name === 'parentName') {
      Util.alphaNumeric(value) ? setParentName(value) : setParentName(parentName)
      // setUnit(value)
    }
    if (name === 'floor') {
      Util.verifyMobile(value) ? setfloor(value) : setfloor(floor)
    }
    if (name === "prefix") {
      setPrefix(value.toUpperCase())
    }
  }

  const handleEdit = () => {
    if (areaType === 'Building') {
      const updateUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        areaTypeId,
        floor,
        id: unitId,
        unit: type === "Parking" ? `${unit}` + `-${prefix}` : unit.trim(),
        isParking: isParkingEdit,
        isActive: isActiveEdit,
      }
      return dispatch(updateUnitFLatList(updateUnitFlatListReq))
    } else {
      const updateBuildingReq = {
        initiatedBy: login.data.user.role,
        id: buildingId,
        areaName: type === "Parking" ? `${areaName}` + `-${prefix}` : areaName.trim(),
        // areaName: areaName,
        isActive: isActiveEdit,
        isParking: isParkingEdit,
        unit,
        areaTypeId,
        floor,
      }
      return dispatch(updateBuilding(updateBuildingReq))
    }
  }

  useEffect(() => {
    setPage(1)
  }, [type])

  useEffect(() => {
    if (addedBuilding.success) {
      getAreatypeListRequest(type)
      setAddModal(false)
    }
  }, [addedBuilding.success])

  useEffect(() => {
    if (addedFlatList.success) {
      getAreatypeListRequest(type)
      setAddModal(false)
    }
  }, [addedFlatList.success])

  useEffect(() => {
    if (updatedBuilding.success) {
      setmodal(false)
      getAreatypeListRequest(type)
    }
    if (updatedBuilding.failed) {
      seterrMessage(updatedBuilding.data.message)
    }

    return () => {
      dispatch(resetupdateBuilding())
    }
  }, [updatedBuilding.success, updatedBuilding.failed, updatedBuilding.network])

  useEffect(() => {
    if (updatedUnitFlat.success) {
      setmodal(false)
      getAreatypeListRequest(type)
      NotificationPopups('success', 'Success', updatedUnitFlat.data.message)
    }
    if (updatedUnitFlat.failed) {
      seterrMessage(updatedUnitFlat.data.message)
      NotificationPopups('error', 'Failed', updatedUnitFlat.data.message)
    }
    return () => {
      dispatch(resetUpdateUnitFlatList())
    }
  }, [updatedUnitFlat.success, updatedUnitFlat.failed, updatedUnitFlat.network])

  return (
    <>
      <div className="listcontent">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row></Row>
                <Row>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'baseline',
                      width: '100%',
                    }}
                  >
                    <div style={{ marginRight: '15px' }}>
                      <Button
                        color="secondary"
                        className="modalCustomButton"
                        onClick={() => {
                          setAddModal(!addModal)
                        }}
                      >
                        Add
                      </Button>
                    </div>
                    <Col>
                      <div
                        className="typeHeading"
                        id="onTop"
                      >{`${type} Area List`}</div>
                    </Col>
                  </div>

                  <Col md="12" style={{ padding: '20px' }}>
                    {buildingList.success && (
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            {type != 'Building' && (

                              type === "Parking" ?
                                <tr>
                                  <th>Name</th>
                                  <th>Default Prefix</th>
                                  <th>No. of Floors</th>
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                                :
                                <tr>
                                  <th>Name</th>
                                  <th>No. of Floors</th>
                                  {isResident ? <th>Parking</th> : type === 'Gym' || type === 'Shop' ? <th>Parking</th> : ''}
                                  <th>Status</th>
                                  <th></th>
                                </tr>
                            )}

                            {/* {type === 'Gym' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Parking</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )}
                            {type === 'Shop' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Parking</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )} */}
                            {type === 'Building' && (
                              <tr>
                                <th>Name</th>
                                <th>No. of Floors</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )}
                            {/* {type === 'Main Gate' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )}
                            {type === 'Garden' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )}
                            {type === 'Clubhouse' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )}
                            {type === 'Parking' && (
                              <tr>
                                <th>Name</th>
                                <th>Building Name</th>
                                <th>Floor</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            )} */}
                          </thead>
                          {buildingList.success && (
                            <tbody>
                              {buildingList.data.areaListActive.map(
                                (d, id) => (
                                  // console.log(d,"day")
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td>
                                        {d.parentName
                                          ? `${d.parentName} / ${d.areaName}`
                                          : d.areaName}
                                      </td>
                                      {type === 'Parking' && (
                                        <td style={{ fontSize: '15px' }}>
                                          {d.unit ? d.unit.split('-')[1] === "undefined" ? '-' : d.unit.split('-')[1] : d.areaName.split('-')[1]}
                                        </td>
                                      )}

                                      {/* {type === "Parking" ?
                                        <td>{d.floor ? d.floor : d.areaName.split('-')[1]}</td>
                                        :
                                        <td>{d.floor ? d.floor : 'G'}</td>
                                      } */}
                                      <td>{d.floor ? d.floor : '-'}</td>


                                      {type === 'Bungalow' && (
                                        <td style={{ fontSize: '15px' }}>
                                          <i className="fas fa-parking"></i>{' '}
                                          {d.isParking
                                            ? 'Parking Available'
                                            : 'Parking Unavailable'}
                                        </td>
                                      )}

                                      {type === 'Gym' && (
                                        <td style={{ fontSize: '15px' }}>
                                          <i className="fas fa-parking"></i>{' '}
                                          {d.isParking
                                            ? 'Parking Available'
                                            : 'Parking Unavailable'}
                                        </td>
                                      )}
                                      {type === 'Shop' && (
                                        <td style={{ fontSize: '15px' }}>
                                          <i className="fas fa-parking"></i>{' '}
                                          {d.isParking
                                            ? 'Parking Available'
                                            : 'Parking Unavailable'}
                                        </td>
                                      )}

                                      <td>
                                        {d.isActive ? 'Active' : 'Inactive'}
                                      </td>
                                      <td>
                                        <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => {
                                            openEditModal(d)
                                          }}
                                        >
                                          Edit
                                          {/* <img
                                          src={EditBtn}
                                          alt="edit"
                                          width="20px"
                                          height="20px"
                                        /> */}
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    )}
                  </Col>
                  <Col>
                    {buildingList.success &&
                      !buildingList.data.areaListActive.length && (
                        <div
                          style={{ textAlign: 'center', padding: '20px 0px' }}
                        >
                          <Alert message="No Active Area List " type="info" />
                        </div>
                      )}

                    {buildingList.failed && (
                      <div style={{ textAlign: 'center', margin: '10px' }}>
                        <Alert
                          message={buildingList.data.message}
                          type="info"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6} lg={6}>
                    {buildingList.success && (
                      <PaginationComponent
                        limit={limit}
                        totalItems={totalItems}
                        onSelect={handlePagination}
                        defaultPage={page}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {modal && (
          <Modal
            isOpen={modal}
            toggle={toggle}
            size="sm"
            style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
          >
            <ModalHeader toggle={toggle}>Edit</ModalHeader>
            <ModalBody>
              {type != 'Building' && (
                <FormGroup>
                  <Label for="areaName">Building Name</Label>
                  <Input type="text" value={parentName} required disabled />
                </FormGroup>
              )}

              <FormGroup>
                <Label for="areaName">{type} Name</Label>
                <Input
                  type="text"
                  name={"areaName"}
                  id={"areaName"}
                  value={areaName}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              
              {type === "Parking" ?
                <FormGroup>
                  <Label for="prefix">Prefix</Label>
                  <Input
                    type="text"
                    name="prefix"
                    id="prefix"
                    placeholder="Prefix"
                    value={prefix || ''}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                :
                null
              }

              <FormGroup>
                <Label for="floor">No. of Floors</Label>
                <Input
                  type="text"
                  name="floor"
                  id="floor"
                  placeholder="floor"
                  value={floor || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>

              {type === 'Gym' && (
                <FormGroup>
                  <Label>Parking</Label>
                  <br />
                  <Switch
                    defaultChecked={isParkingEdit}
                    name="isParking"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeSwitch}
                  // style={{ width: '100%' }}
                  />
                </FormGroup>
              )}

              {type === 'Bungalow' && (
                <FormGroup>
                  <Label>Parking</Label>
                  <br />
                  <Switch
                    defaultChecked={isParkingEdit}
                    name="isParking"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeSwitch}
                  // style={{ width: '100%' }}
                  />
                </FormGroup>
              )}

              {type === 'Shop' && (
                <FormGroup>
                  <Label>Parking</Label>
                  <br />
                  <Switch
                    defaultChecked={isParkingEdit}
                    name="isParking"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeSwitch}
                  // style={{ width: '100%' }}
                  />
                </FormGroup>
              )}

              <FormGroup tag="fieldset">
                <Label>Status</Label>
                <br />
                <Switch
                  defaultChecked={isActiveEdit}
                  name="isActive"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={onChangeSwitch2}
                />
              </FormGroup>
              <div style={{ textAlign: 'center' }} className="errorText">
                {errMessage}
              </div>
              {type === "Parking" ?
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={handleEdit}
                  // disabled={unit !== undefined ? floorData === undefined ? 0 : floorData >= floor ? updatedBuilding.isLoading || !areaName : true : ""}
                  disabled={updatedBuilding.isLoading || !areaName}
                >
                  {updatedBuilding.isLoading ? <Spin /> : 'Edit'}
                </Button>
                :
                <Button
                  color="secondary"
                  className="modalCustomButton"
                  onClick={handleEdit}
                  disabled={updatedBuilding.isLoading || !areaName}
                >
                  {updatedBuilding.isLoading ? <Spin /> : 'Edit'}
                </Button>
              }

              <Button
                color="secondary"
                style={{ float: 'right' }}
                className="modalCustomButton"
                onClick={toggle}
              >
                Close
              </Button>
            </ModalBody>
          </Modal>
        )}
        {addModal && (
          <Modal
            isOpen={addModal}
            toggle={toogleAdd}
            size="lg"
            style={{ maxWidth: '1000px', maxHeight: '500px' }}
          >
            <ModalHeader toggle={toogleAdd}> Add {type}</ModalHeader>
            <ModalBody>
              <AddMiscellaneous areaType={type} areaId={areaid} />
            </ModalBody>
          </Modal>
        )}
      </div>
    </>
  )
}

export default DisplayList
