import axios from 'axios'
import { refreshAccessToken } from './helperFunctions'

//axios.defaults.headers.common["Accept"] = "application/json";
//axios.defaults.headers.common["Content-Type"] = "application/json";
//axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
let originalRequest = []
let isRefresh = false

let ignoreApiList = ["user/adminSignin",
  "user/login", "user/resendUserLoginOtp", "user/verifyLoginOtp",
  "user/regenerateAuthToken", "society/allCityList", "society/getSocietyList", "society/getSocietyList",
  "guard/login", "guard/verifyOtp", "guard/resendOtp",
  "user/adminSignUp", "user/signup", "user/verifySignupOtp"]

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV == 'development'
      ? `http://localhost:9300/`
      : 'https://app.hisociety.in/mySocietyServicesApp/',
});


[instance].forEach((fn) => {
  fn.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('authToken');
      if (!ignoreApiList.includes(config.url)) {
        if (token) {
          config.headers.AuthToken = token
        }
      }
      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );

  fn.interceptors.response.use(
    (response) => response,
    async (error) => {
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      // console.log(error, "nfanfapin")
      const originalRequest = { error };
      console.log(originalRequest, "originalRequest")
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log(originalRequest, "originalRequest")
        await refreshAccessToken().then((data) => {
          if (data) sessionStorage.setItem('authToken', data)
          originalRequest._retry = true;
          return instance(originalRequest);

        })
      }
      // let originalRequest = error.config
      // let refreshToken;
      // refreshToken = sessionStorage.getItem('refreshToken')
      // const token = sessionStorage.getItem('authToken');
      // console.log(token, "tkoen", refreshToken)
      // if (refreshToken === null) {
      //   const response = await instance.post('user/regenerateAuthToken', { authToken: token });
      //   const { authToken } = response.data
      //   if (authToken) {
      //     sessionStorage.setItem('refreshToken', authToken)
      //     sessionStorage.setItem('authToken', authToken)
      //     error.headers.AuthToken = authToken
      //   }
      //   return instance(originalRequest)
      return Promise.reject(error);

    }
  )
});



// async function refreshToken() {
//   try {
//     const oldToken = sessionStorage.getItem('authToken');

//     console.log(response.data, "tokensss")
//     sessionStorage.setItem('authToken', authToken);
//     // Retry the original request with the new token
//     if (authToken) {
//       originalRequest.headers.AuthToken = authToken;
//     }
//     originalRequest.forEach((fn) => {
//       console.log("OriginalRequest", fn)
//       return instance(fn)
//     })
//     originalRequest = []

//     // return axios(originalRequest);
//   }
//   catch (error) {
//     console.log("RefreshTOkenError", error)
//     return Promise.reject(error)
//   }
// }




export default instance