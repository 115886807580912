import React, { useState, useEffect, Component } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'
import { getVisitorDashBoard } from 'redux/actions/getVisitorDashboardAction'
import moment from 'moment'
function GetVisitorLineChartDashboard({
  counterValue,
  chartBtn,
  dataCounterBtn,
}) {
  var updatesetterData = {}
  const [data, setData] = useState('')
  const [listData, setListData] = useState([])
  const [modal, setmodal] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [chartData, setChartData] = useState({})
  const [employeeSalary, setEmployeeSalary] = useState([])
  const [employeeAge, setEmployeeAge] = useState([])
  const login = useSelector(({ login }) => login)

  const getVisitorDashBoards = useSelector(
    ({ getVisitorDashBoards }) => getVisitorDashBoards
  )

  const dispatch = useDispatch()
  const [initiatedBy] = useState('Society')

  const Chart = (counterValue) => {
    var dateArray = []
    var counterDataArray = []
    if (dataCounterBtn == 'weekly' || dataCounterBtn == 'monthly') {
      counterValue.forEach(function (element, index) {
        !element.toDate
          ? dateArray.push(moment(element.fromDate).format('DD MMM'))
          : dateArray.push(
            moment(element.toDate).format('DD MMM') +
            ' - ' +
            moment(element.fromDate).format('DD MMM')
          )
        counterDataArray.push(element.count)
      })
    } else {
      counterValue.forEach(function (element, index) {
        // dateArray.push(element.key);
        // dateArray.push((element.key).slice(0, 3));
        dateArray.push(
          element.month.slice(0, 3) +
          ' ' +
          Number(String(element.year).slice(2, 4))
        )
        counterDataArray.push(element.count)
      })
    }

    //   counterValue.forEach(function(element, index) {
    //     console.log(element.fromDate+" "+element.toDate);
    //     console.log( moment(new Date()).format('YYYY-MM-DD'))
    //     !(element.toDate) ? dateArray.push(moment(element.fromDate).format('DD MMM')):dateArray.push(moment(element.fromDate).format(('DD MMM'))+' - '+moment(element.toDate).format(('DD MMM')));

    //     // !(element.toDate) ? dateArray.push(element.fromDate):dateArray.push(element.fromDate+'- '+element.toDate);
    //     // dateArray.push(element.fromDate+'- '+element.toDate);
    //     counterDataArray.push(element.count);
    // });

    setChartData({
      labels: Object.values(dateArray),

      datasets: [
        {
          label: 'Visitor',
          data: counterDataArray,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }

  useEffect(() => {
    if (chartBtn == 'linechart') {
      Chart(counterValue, dataCounterBtn)
    }
  }, [counterValue])

  return (
    <>
      <div className="App">
        <div>
          <Line
            data={chartData}
            options={{
              responsive: true,
              title: { text: 'THICCNESS SCALE', display: true },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              title: {
                display: true,
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GetVisitorLineChartDashboard
