import axios from '../../config/axios'
import {
  GET_TYPE_LANGUAGE_SUCCESS,
  GET_TYPE_LANGUAGE_FAILED,
  GET_TYPE_LANGUAGE_NETWORK_ERROR,
  LOADING_GET_TYPE_LANGUAGE_ERROR,
  RESET_GET_TYPE_LANGUAGE,
} from '../types'

const resetGetTypeLanguage = () => {
  return {
    type: RESET_GET_TYPE_LANGUAGE,
  }
}

const getTypeLanguage = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_TYPE_LANGUAGE_ERROR })
  console.log(userData)
  axios
    .post('util/getTypeLanguage', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_TYPE_LANGUAGE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_TYPE_LANGUAGE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_TYPE_LANGUAGE_FAILED,
    payload: data,
  }
}

export { resetGetTypeLanguage, getTypeLanguage }
