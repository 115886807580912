import axios from '../../config/axios'
import {
  ADDCOMPANY_SUCCESS,
  ADDCOMPANY_FAILED,
  LOADING_ADDCOMPANY,
  RESET_ADDCOMPANY,
  ADDCOMPANY_NETWORKERROR,
} from '../types'

const resetAddCompany = () => {
  return {
    type: RESET_ADDCOMPANY,
  }
}

const addCompany = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADDCOMPANY })
  axios
    .post('household/addCompany', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADDCOMPANY_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: ADDCOMPANY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADDCOMPANY_FAILED,
    payload: data,
  }
}

export { resetAddCompany, addCompany }
