import {
    GETAMENITYTYPE_SUCCESS,
    GETAMENITYTYPE_FAILED,
    LOADING_GETAMENITYTYPE,
    RESET_GETAMENITYTYPE,
    GETAMENITYTYPE_NETWORKERROR,
} from '../types'

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: '',
    networkError: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETAMENITYTYPE:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                data: {},
                message: '',
                networkError: '',
            }
        case GETAMENITYTYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: true,
                network: false,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case GETAMENITYTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case GETAMENITYTYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: '',
                networkError: 'Network Error',
            }
        case RESET_GETAMENITYTYPE:
            return (state = initialState)

        default:
            return state
    }
}
