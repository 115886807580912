import {
  ADD_UNIT_FLATLIST_SUCCESS,
  ADD_UNIT_FLATLIST_FAILED,
  LOADING_ADD_UNIT_FLATLIST,
  RESET_ADD_UNIT_FLATLIST,
  ADD_UNIT_FLATLIST_NETWORK_ERROR,
} from 'redux/types'
import axios from 'config/axios'

const resetAddUnitFlatList = () => {
  return {
    type: RESET_ADD_UNIT_FLATLIST,
  }
}

const addUnitFlatList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_UNIT_FLATLIST })
  axios
    .post('society/addUnitList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_UNIT_FLATLIST_NETWORK_ERROR, payload: err })
    )
}
function success(data) {
  return {
    type: ADD_UNIT_FLATLIST_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: ADD_UNIT_FLATLIST_FAILED,
    payload: data,
  }
}

export { resetAddUnitFlatList, addUnitFlatList }
