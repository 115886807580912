import {
	ADDUSERSTATUS_SUCCESS,
	ADDUSERSTATUS_FAILED,
	LOADING_ADDUSERSTATUS,
	RESET_ADDUSERSTATUS,
    ADDUSERSTATUS_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADDUSERSTATUS:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case ADDUSERSTATUS_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
				case ADDUSERSTATUS_FAILED:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case ADDUSERSTATUS_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case RESET_ADDUSERSTATUS:
            return (state = initialState);

        default:
            return state;
    }
};