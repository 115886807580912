import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import { Spin, Alert } from 'antd'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import {
  resetgetBuildingNames,
  getBuildingNames,
} from 'redux/actions/getBuildingNamesAction'
import { addBuilding, resetAddBuilding } from 'redux/actions/addBuildingAction'
import { addFlatList, resetAddFlatList } from 'redux/actions/addFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  deleteBuilding,
  resetdeleteBuilding,
} from 'redux/actions/deleteBuildingAction'
import {
  resetupdateBuilding,
  updateBuilding,
} from 'redux/actions/updateBuildingAction'
import AddFlatList from './AddFlatList'
import AddBungalow from './AddBungalow'
import AddMiscellaneousType from './AddMiscellaneousType'
import DisplayList from './DisplayList'
import DisplayFlats from './DisplayFlats'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
// TempAddFlatList
//ICONS
import SocietyDeatilIcon from 'assets/img/Icons/Sidebar/societydetail1.png'

import BuildingIcon from 'assets/img/Icons/SocietyDetails/building.png'
import MainGateIcon from 'assets/img/Icons/SocietyDetails/maingate.png'
import GymIcon from 'assets/img/Icons/SocietyDetails/gym.png'
import ShopIcon from 'assets/img/Icons/SocietyDetails/shop.png'
import GardenIcon from 'assets/img/Icons/SocietyDetails/garden.png'
import BungalowIcon from 'assets/img/Icons/SocietyDetails/bungalow.png'
import ClubHouseIcon from 'assets/img/Icons/SocietyDetails/clubhouse.png'
import FlatIcon from 'assets/img/Icons/SocietyDetails/flat.png'
import OthersIcon from 'assets/img/Icons/SocietyDetails/others.png'
import ParkingIcon from 'assets/img/Icons/SocietyDetails/parking.png'
import Amenities from 'assets/img/Icons/SocietyDetails/amenities.png'
import SearchList from './SearchList'
import AddParking from './AddParking'
import DisplayParkingSlots from './DisplayParkingSlot'
import AddCommonAreaFlatList from './AddCommonAreaFlat'

function SocietyDetail() {
  const [activeTab, setActiveTab] = useState(0)
  const [atype, setAType] = useState('Building')
  const [isResident, setISResident] = useState('')
  const [activeAreaType, setActiveAreaType] = useState([])
  const [atypeId, setatypeId] = useState('')
  const [areaid, setareaid] = useState('')
  const addedFlatList = useSelector(({ addFlatList }) => addFlatList)
  const addedBuilding = useSelector(({ addBuilding }) => addBuilding)
  const areaType = useSelector(({ areaType }) => areaType)
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const deleteBuilding = useSelector(({ deleteBuilding }) => deleteBuilding)
  const updatedBuilding = useSelector(({ updateBuilding }) => updateBuilding)
  const dispatch = useDispatch()

  const toggle = (tab, id, type, isResident) => {
    if (activeTab !== tab) setActiveTab(tab)
    setAType(type)
    setareaid(id)
    setISResident(isResident)
    // getAreatypeListRequest(type)
  }

  const getAreaTypeRequest = () => {
    const areaTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getAreaType(areaTypeReq))
  }

  const areaTypeFilter = () => {
    //  areaType.success && areaType.data.areaTypeList.filter((d) d.isActive )
  }

  useEffect(() => {
    if (areaType.success && areaType.data.areaTypeList) {
      const areaTypeList = areaType.data.areaTypeList
      const activeAreaType = areaTypeList.filter((d) => d.isActive === true)
      setActiveAreaType(activeAreaType)
    }
  }, [areaType.success])

  useEffect(() => {
    getAreaTypeRequest()
  }, [])

  useEffect(() => {
    if (addedBuilding.success) {
      NotificationPopups('success', 'Success', addedBuilding.data.message)
    }
    if (addedBuilding.failed) {
      NotificationPopups('error', 'Failed', addedBuilding.data.message)
    }
    if (
      addedBuilding.network ||
      addedFlatList.network ||
      deleteBuilding.network ||
      updatedBuilding.network
    ) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (addedFlatList.success) {
      // getAreatypeListRequest(atype)
      NotificationPopups('success', 'Success', addedFlatList.data.message)
    }
    if (addedFlatList.failed) {
      NotificationPopups('error', 'Failed', addedFlatList.data.message)
    }

    if (updatedBuilding.success) {
      // getAreatypeListRequest(atype)
      NotificationPopups('success', 'Success', updatedBuilding.data.message)
    }
    return () => {
      dispatch(resetAddBuilding())
      dispatch(resetAddFlatList())
      dispatch(resetdeleteBuilding())
      dispatch(resetupdateBuilding())
    }
  }, [
    addedBuilding.success,
    addedBuilding.failed,
    addedBuilding.network,
    addedFlatList.success,
    addedFlatList.failed,
    addedFlatList.network,
    updatedBuilding.success,
    updatedBuilding.failed,
    updatedBuilding.network,
  ])

  const iconArr = (t) => {
    switch (t.toLowerCase()) {
      case 'flat':
        // return 'fas fa-building'
        return `${FlatIcon}`
      case 'building':
        // return 'fas fa-city'
        return `${BuildingIcon}`
      case 'bungalow':
        // return 'fas fa-home'
        return `${BungalowIcon}`
      case 'shop':
        // return 'fas fa-store-alt'
        return `${ShopIcon}`
      case 'clubhouse':
        // return 'fas fa-gamepad'
        return `${ClubHouseIcon}`
      case 'main gate':
        // return 'fas fa-dungeon'
        return `${MainGateIcon}`
      case 'gym':
        // return 'fas fa-dumbbell'
        return `${GymIcon}`
      case 'garden':
        // return 'fab fa-pagelines'
        return `${GardenIcon}`
      case 'others':
        // return 'fas fa-tools'
        return `${OthersIcon}`
      case 'parking':
        // return 'fas fa-parking'
        return `${ParkingIcon}`
      case 'amenities':
        return `${Amenities}`
      case 'parking slot':
        return ''
      default:
        return 'fas fa-cog'
    }
  }
  const typeComponentArr = (t, id) => {
    switch (t.toLowerCase()) {
      case 'flat':
        return <AddFlatList areaType={atype} areaTypeId={areaid} id={id} />
      case 'building':
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
      case 'bungalow':
        return <AddBungalow areaType={atype} areaId={areaid} id={id} />
      case 'shop':
        return <AddBungalow areaType={atype} areaId={areaid} id={id} />
      case 'gym':
        return <AddBungalow areaType={atype} areaId={areaid} id={id} />
      case 'clubhouse':
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
      case 'gate':
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
      case 'garden':
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
      case 'others':
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
      case 'parking':
        return <AddParking areaType={atype} areaId={areaid} id={id} />
      case 'commonAreaFlat':
        return <AddCommonAreaFlatList areaType={atype} areaId={areaid} id={id} />
      default:
        return <AddMiscellaneousType areaType={atype} areaId={areaid} id={id} />
    }
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="societydetail society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  <img
                    src={SocietyDeatilIcon}
                    alt="SocietyDeatilIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Society Details</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Nav tabs>
                      {areaType.success &&
                        activeAreaType.map((d, id) => (
                          <NavItem key={id}>
                            <NavLink
                              data-id={id}
                              className={classnames({
                                active: activeTab === id,
                              })}
                              onClick={() => {
                                toggle(id, d.id, d.type, d.isResidential)
                              }}
                              key={id}
                            >
                              <div className={`sd-col`}>
                                <div className="sd-ic">
                                  {/* <i className={iconArr(d.type)}></i> */}
                                  <img
                                    src={iconArr(d.type)}
                                    alt={iconArr(d.type)}
                                  />
                                </div>
                                <p className="sd-label">{d.type}</p>
                              </div>
                            </NavLink>
                          </NavItem>
                        ))}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      {areaType.success &&
                        activeAreaType.map((d, id) => (
                          <TabPane tabId={id} data-id={id} key={id}>
                            {/* {typeComponentArr(d.type, d.id)} */}

                            {/* {atype === 'Flat' && (
                              <AddFlatList
                                areaType={atype}
                                areaId={areaid}
                                areaTypeId={areaid}
                              />
                            )} */}
                            {/* {atype === 'Parking' && <AddParking />} */}
                          </TabPane>
                        ))}
                      {/* {atype === 'Flat' && (
                        <AddFlatList
                          areaType={atype}
                          areaId={areaid}
                          areaTypeId={areaid}
                        />
                      )} */}
                      {atype === 'Parking' && <AddParking />}

                      {(atype === 'Shop' || atype === 'Bungalow' || atype === "Flat") && <AddCommonAreaFlatList
                        areaType={atype}
                        areaId={areaid}
                        areaTypeId={areaid} />}
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {areaType.failed && (
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <Alert message={areaType.data.message} type="info" />
          </div>
        )}
      </div>

      {atype.toLowerCase() === '' ? (
        ''
      ) : atype.toLowerCase() === 'flat' ? (
        < DisplayFlats type={atype} id={areaid} />
      ) : (atype.toLowerCase() === 'shop' || atype.toLowerCase() === 'bungalow') ? (
        <>
          <DisplayList type={atype} areaid={areaid} isResident={isResident} />
          < DisplayFlats type={atype} id={areaid} />
        </>
      ) : atype.toLowerCase() === 'parking' ? (
        <>
          <DisplayList type={atype} areaid={areaid} isResident={isResident} />
          <DisplayParkingSlots
            type={atype}
            areaid={areaid}
            isResident={isResident}
          />
        </>
      ) : (
        <DisplayList type={atype} areaid={areaid} isResident={isResident} />
      )}
    </>
  )
}

export default SocietyDetail
