import {
  UPDATE_GUARD_DETAILS_SUCCESS,
  UPDATE_GUARD_DETAILS_FAILED,
  UPDATE_GUARD_DETAILS_NETWORK_ERROR,
  LOADING_UPDATE_GUARD_DETAILS,
  RESET_UPDATE_GUARD_DETAILS,
} from 'redux/types'
import axios from 'config/axios'

const resetUpdateGuard = () => {
  return {
    type: RESET_UPDATE_GUARD_DETAILS,
  }
}
const updateGuard = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_GUARD_DETAILS })
  axios
    .post('guard/updateGuard', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_GUARD_DETAILS_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: UPDATE_GUARD_DETAILS_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: UPDATE_GUARD_DETAILS_FAILED,
    payload: data,
  }
}

export { resetUpdateGuard, updateGuard }
