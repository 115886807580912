import {
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_FAILED,
  GET_CITY_LIST_NETWORK_ERROR,
  LOADING_GET_CITY_LIST,
  RESET_GET_CITY_LIST,
} from 'redux/types'
import axios from 'config/axios'

const resetGetCityList = () => {
  return { type: RESET_GET_CITY_LIST }
}

const getCityList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_CITY_LIST })
  axios
    .post('society/allCityList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_CITY_LIST_NETWORK_ERROR, payload: err })
    )
}
function success(data) {
  return {
    type: GET_CITY_LIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_CITY_LIST_FAILED,
    payload: data,
  }
}

export { resetGetCityList, getCityList }
