import axios from "../../config/axios"
import {
	VALIDATESIGNUPOTP_SUCCESS,
	VALIDATESIGNUPOTP_FAILED,
	LOADING_VALIDATESIGNUPOTP,
	RESET_VALIDATESIGNUPOTP,
    VALIDATESIGNUPOTP_NETWORKERROR
} from "../types";

const resetValidateSignupOtp = () => {
    return {
        type: RESET_VALIDATESIGNUPOTP
    };
};

const validateSignupOtp = userData => dispatch => {
    dispatch({ type: LOADING_VALIDATESIGNUPOTP });
		console.log(userData);
    axios
        .post("user/verifySignupOtp", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: VALIDATESIGNUPOTP_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: VALIDATESIGNUPOTP_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: VALIDATESIGNUPOTP_FAILED,
        payload: data
    };
}

export { resetValidateSignupOtp, validateSignupOtp } ;