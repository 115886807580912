import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin, Switch } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { resetAddDocument, addDocument } from 'redux/actions/addDocumentAction'
import Pagination from 'reactstrap/lib/Pagination'
import Progress from 'reactstrap/lib/Progress'
import Util from 'validations/index'

function AddDocument() {
  const [document, setDocument] = useState('')

  const login = useSelector(({ login }) => login)
  const addedDoc = useSelector(({ addDocument }) => addDocument)
  const dispatch = useDispatch()

  const addUnitDocTypeRequest = () => {
    const addUnitDocTypeReq = {
      type: document.trim(),
      initiatedBy: login.data.user.role,
    }
    //console.log(addUnitDocTypeReq)
    return dispatch(addDocument(addUnitDocTypeReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'documentType')
      Util.alphabetsOnly(value) ? setDocument(value) : setDocument(document)
  }

  //console.log(isMandatory)

  const handleSubmit = (e) => {
    e.preventDefault()
    addUnitDocTypeRequest()
    console.log('clicked')
  }

  useEffect(() => {
    if (addedDoc.success) {
      setDocument('')
      NotificationPopups('success', 'Success', addedDoc.data.message)
    }
    if (addedDoc.failed) {
      NotificationPopups('error', 'Failed', addedDoc.data.message)
    }
    if (addedDoc.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddDocument())
    }
  }, [addedDoc.success, addedDoc.failed, addedDoc.network])

  const disabledButton = () => {
    return !document
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Document</Label>
          <Input
            type="text"
            name="documentType"
            placeholder="Document Type"
            value={document || ''}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            disabled={disabledButton() || addDocument.isLoading}
            onClick={handleSubmit}
          >
            {addDocument.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddDocument
