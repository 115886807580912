import {
	GETNOTICETYPE_SUCCESS,
	GETNOTICETYPE_FAILED,
	LOADING_GETNOTICETYPE,
	RESET_GETNOTICETYPE,
	GETNOTICETYPE_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GETNOTICETYPE:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                networkError: ""
            };
				case 	GETNOTICETYPE_FAILED:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: ""
                                ,
                networkError: ""
                
            };
				case 	GETNOTICETYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: ""
                                ,
                networkError: ""
            };
            case 	GETNOTICETYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                networkError: "Network Error"
            };
				case RESET_GETNOTICETYPE:
            return (state = initialState);

        default:
            return state;
    }
};