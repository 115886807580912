import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
  ModalFooter
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Switch, Select } from 'antd'
//Components
import UploadMntBilling from './UplaodMntBilling'
import AddMntBilling from './AddMntBilling'
import UpdateMntBilling from './UpdateMntBilling'
//Action files
import {
  getMntInvoice,
  resetGetMntInvoice,
} from 'redux/actions/getMntInvoiceAction'
import {
  resetGenerateMntInvoice,
  generateMntInvoice,
} from 'redux/actions/generateMntInvoiceAction'
import {
  resetDeleteMntInvoice,
  deleteMntInvoice,
} from 'redux/actions/deleteMntInvoiceAction'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Checkbox } from 'antd'
import moment from 'moment'
import PaginationComponent from 'views/Pagination'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { Redirect } from 'react-router'
import Util from 'validations/index'
import EditBtn from 'assets/img/Brand/buttons/edit-icon.png'
import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'
import DownloadBtn from 'assets/img/Brand/buttons/download-icon.png'
import GenrateBtn from 'assets/img/Brand/buttons/pdf-icon.png'
import { Link, Navigate } from 'react-router-dom'
import { getAreaType } from 'redux/actions/getAreaTypeAction'
import {
  getSocietyTemplate,
  resetGetSocietyTemplate,
} from 'redux/actions/getSocietyTemplateAction'
import { resetSingleInvoice } from 'redux/actions/singleMntInvoice'
import ReactExport from 'react-data-export';
import { CSVLink } from 'react-csv';
import { ExportExcelInvoice, resetExportExcelInvoice } from 'redux/actions/exportExcelInvoiceAction'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const ViewMntBilling = ({ sendData, onTap }) => {

  const [mntInvoiceBill, setMntInvoiceBill] = useState([''])
  const [addModal, setAddModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [addMntBillingModal, setAddMntBillingModal] = useState(false)
  const [updateMntBillingModal, setUpdateMntBillingModal] = useState(false)
  const [updateData, setUpdateData] = useState('')
  const [invoiceId, setInvoiceId] = useState([])
  const [id, setId] = useState([])
  const [page, setPage] = useState(1)
  const [exportData, setExportData] = useState([])
  const [idx, setIdx] = useState('')
  const [collapase, setCollapase] = useState(false)
  const [deletes, setDeletes] = useState(false)
  const login = useSelector(({ login }) => login)
  const mntInvoicBill = useSelector(({ getMntInvoice }) => getMntInvoice)
  const addedMntInvoice = useSelector(({ addMntInvoice }) => addMntInvoice)
  const generatedMntInvoice = useSelector(
    ({ generateMntInvoice }) => generateMntInvoice
  )
  const singleInvoiceMnt = useSelector(
    ({ singleMntInvoice }) => singleMntInvoice
  )
  const getSocTemp = useSelector(({ getSocietyTemplate }) => getSocietyTemplate)
  const deletedMntInvoice = useSelector(
    ({ deleteMntInvoice }) => deleteMntInvoice
  )

  const ExportExcelInvoiceRes = useSelector(({ exportExcelInvoice }) => exportExcelInvoice)

  const limit = 10
  const totalItems = mntInvoicBill.data.count
  const select = []
  const dispatch = useDispatch()

  const handleAllCheckbox = ({ target: { name, checked } }) => {
    if (name === 'selectAll') setSelectAll(!selectAll)
    if (!selectAll) {
      const id = mntInvoicBill.data.invoiceList.map((d) => d.id)
      setId(id)
    } else if (selectAll) {
      setId([])
    }
  }

  // let checkedId = []
  // const handleCheckbox = (data) => {
  //   checkedId.push(...id, data)
  //   setId(checkedId)
  // }

  const handleCheckbox = (data) => {
    if (id.includes(data)) {
      let index = id.indexOf(data)
      id.splice(index, 1)
    } else {
      id.push(data)
    }
  }

  useEffect(() => {
    if (mntInvoicBill.success) {
      setIdx('')
    }
  }, [mntInvoicBill.success])
  // let checkedData = []
  // const handleCheckboxData = (data) => {
  //   checkedData.push(...id, data)
  //   setId(checkedData)
  //   console.log(checkedData, "checkedData")
  //   console.log(data, "datassss")
  //   debugger
  //   console.log(data, "datassss")
  // }

  const getAreaTypeRequest = () => {
    const areaTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getAreaType(areaTypeReq))
  }
  const getSocietyTemplateRequest = () => {
    const getSocietyTempReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      templateType: 'term',
    }
    return dispatch(getSocietyTemplate(getSocietyTempReq))
  }

  const MntInvoice = () => {
    mntInvoicBill.success &&
      mntInvoicBill.data.invoiceList.map((d) => {
        setMntInvoiceBill([
          {
            area: d.area,
            unit: d.unit,
            month: d.month,
            year: d.year,
            invoiceStatus: d.invoiceStatus,
          },
        ])
      })
  }

  const getMntInvoiceRequest = () => {
    const getMntInvoiceReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      area: sendData.area,
      unit: sendData.unit,
      year: sendData.year,
      month: sendData.month,
      invoiceStatus: sendData.invoiceStatus,
      limit,
      page,
    }
    dispatch(getMntInvoice(getMntInvoiceReq))
  }

  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  const toogleUpdate = () => {
    setUpdateModal(!updateModal)
  }

  const toggleAddMntModal = () => {
    setAddMntBillingModal(!addMntBillingModal)
  }
  const toggleUpdateMntModal = (data) => {
    setUpdateData(data)
    setUpdateMntBillingModal(!updateMntBillingModal)
  }

  const toggleDel = () => {
    setDeletes(!deletes)
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const generateInvoice = () => {
    generateMntInvoiceRequest(id)
    // if (generatedMntInvoice.success) {
    //   setSelectAll(false)
    //   setId('')
    //   NotificationPopups('success', 'Success', generatedMntInvoice.data.message)
    // }
    // if (generatedMntInvoice.failed) {
    //   NotificationPopups('error', 'Failed', generatedMntInvoice.data.message)
    // }
    // if (generatedMntInvoice.network) {
    //   NotificationPopups(
    //     'error',
    //     'Network Error',
    //     'Check your internet connection'
    //   )
    // }
  }
  const deleteInvoice = () => {
    console.log(id, "id")
    deleteMntInvoiceRequest(id)
    // if (deletedMntInvoice.success) {
    //   setId('')
    //   setPage(page - 1)
    //   NotificationPopups('success', 'Success', deletedMntInvoice.data.message)
    // }
    // if (deletedMntInvoice.failed) {
    //   NotificationPopups('error', 'Failed', deletedMntInvoice.data.message)
    // }
    // if (deletedMntInvoice.network) {
    //   NotificationPopups(
    //     'error',
    //     'Network Error',
    //     'Check your internet connection'
    //   )
    // }
  }
  const handleGenerate = (data) => {
    const id = []
    id.push(data)
    generateMntInvoiceRequest(id)
    // if (generatedMntInvoice.success) {
    //   setSelectAll(false)
    //   setId('')
    //   NotificationPopups('success', 'Success', generatedMntInvoice.data.message)
    // }
    // if (generatedMntInvoice.failed) {
    //   NotificationPopups('error', 'Failed', generatedMntInvoice.data.message)
    // }
    // if (generatedMntInvoice.network) {
    //   NotificationPopups(
    //     'error',
    //     'Network Error',
    //     'Check your internet connection'
    //   )
    // }
  }
  const handleDelte = (data) => {
    const id = []
    id.push(data)
    deleteMntInvoiceRequest(id)
    // if (deletedMntInvoice.success) {
    //   NotificationPopups('success', 'Success', deletedMntInvoice.data.message)
    // }
    // if (deletedMntInvoice.failed) {
    //   NotificationPopups('error', 'Failed', deletedMntInvoice.data.message)
    // }
    // if (deletedMntInvoice.network) {
    //   NotificationPopups(
    //     'error',
    //     'Network Error',
    //     'Check your internet connection'
    //   )
    // }
  }
  const generateMntInvoiceRequest = (invoiceId) => {
    const generateMntInvoiceReq = {
      initiatedBy: login.data.user.role,
      invoiceId,
    }
    return dispatch(generateMntInvoice(generateMntInvoiceReq))
  }
  const deleteMntInvoiceRequest = (invoiceId) => {
    const deleteMntInvoiceReq = {
      initiatedBy: login.data.user.role,
      invoiceId,
    }
    return dispatch(deleteMntInvoice(deleteMntInvoiceReq))
  }
  const handleDownload = (data) => {
    var link = document.createElement('a')
    link.href = 'https://mysocietyapp.paymentz.com/Documents/' + data
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const handleExcel = () => {
    var link = document.createElement('a')
    link.href = 'https://mysocietyapp.paymentz.com/Documents/' + getSocTemp.data.invoiceTemplate
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Excel Data

  const ExcelData = () => {
    const req = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      area: sendData.area,
      unit: sendData.unit,
      year: sendData.year,
      month: sendData.month,
      invoiceStatus: sendData.invoiceStatus,
    }
    return dispatch(ExportExcelInvoice(req))
  }

  useEffect(() => {
    if (ExportExcelInvoiceRes.success) {
      var link = document.createElement('a')
      link.download = `${login.data.society.societyName}.xlsx`
      link.href = "data:application/octet-stream;base64," + ExportExcelInvoiceRes.data.base64
      link.click()
      dispatch(resetExportExcelInvoice())

    }
  }, [ExportExcelInvoiceRes.success])

  useEffect(() => {
    setSelectAll(false)
  }, [page])

  useEffect(() => {
    getMntInvoiceRequest()
    // $('html, body').animate({ scrollTop: 0 })
    // window.top()
    // window.document.body.style.animate({ scrollTop: 0 }, 'fast')
    // setId('')
  }, [page, selectAll])

  useEffect(() => {
    getMntInvoiceRequest()
    // setSelectAll(false)
    if (generatedMntInvoice.success) {
      setSelectAll(false)
      setId('')
      NotificationPopups('success', 'Success', generatedMntInvoice.data.message)
    }
    if (generatedMntInvoice.failed) {
      NotificationPopups('error', 'Failed', generatedMntInvoice.data.message)
    }
    if (generatedMntInvoice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
  }, [
    generatedMntInvoice.success,
    generatedMntInvoice.failed,
    generatedMntInvoice.network,
  ])
  useEffect(() => {
    if (singleInvoiceMnt.success) {
      getMntInvoiceRequest()
      setAddMntBillingModal(false)
      setUpdateMntBillingModal(false)

      NotificationPopups('success', 'Success', singleInvoiceMnt.data.message)
    }
    return () => resetSingleInvoice()
  }, [singleInvoiceMnt.success])

  useEffect(() => {
    getMntInvoiceRequest()
    setSelectAll(false)
    if (deletedMntInvoice.success) {
      setId('')
      setPage(page - 1)
      NotificationPopups('success', 'Success', deletedMntInvoice.data.message)
    }
    if (deletedMntInvoice.failed) {
      NotificationPopups('error', 'Failed', deletedMntInvoice.data.message)
    }
    if (deletedMntInvoice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
  }, [
    deletedMntInvoice.success,
    deletedMntInvoice.failed,
    deletedMntInvoice.network,
  ])

  useEffect(() => {
    MntInvoice()
  }, [mntInvoicBill.success])

  useEffect(() => {
    if (mntInvoicBill.failed) {
      setSelectAll(false)
      // setPage(page - 1)
    }
  }, [mntInvoicBill.failed, selectAll])

  useEffect(() => {
    if (addedMntInvoice.success) {
      setAddModal(false)
      getMntInvoiceRequest()
    }
    // if (addedInvoice.success) {
    //   NotificationPopups('success', 'Success', addedInvoice.data.message)
    // }
  }, [addedMntInvoice.success, singleInvoiceMnt.success])

  useEffect(() => {
    getAreaTypeRequest()
    getSocietyTemplateRequest()

    return () => {
      resetGetSocietyTemplate()
      resetSingleInvoice()
    }
  }, [singleInvoiceMnt.success, singleInvoiceMnt.failed])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div className="mntBilling">
                  {/* <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Download Payment Details
                    </Button>
                  </div> */}

                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Upload Bill Details
                    </Button>
                  </div>

                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddMntBillingModal(true)
                      }}
                    >
                      Add Single Invoice
                    </Button>
                  </div>

                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        // setAddMntBillingModal(true)
                        handleExcel()
                      }}
                    >
                      Download Format
                    </Button>
                  </div>

                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        ExcelData()
                      }}
                    >
                      Export Data
                    </Button>
                  </div>

                  {/* <div style={{ marginRight: '15px' }}>
                    <Button color="secondary"
                      className="modalCustomButton">
                      <CSVLink data={exportData} onClick={() => { }} style={{ color: 'white' }}>Export Data</CSVLink>
                    </Button>
                  </div> */}

                  <Col>
                    <div className="typeHeading" id="onTop">
                      Maintenance Billing List
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  <>
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>
                              {mntInvoicBill.success ? (
                                <Checkbox
                                  onChange={handleAllCheckbox}
                                  name="selectAll"
                                  value={selectAll}
                                  defaultChecked={selectAll}
                                />
                              ) : (
                                ''
                              )}
                            </th>
                            <th>Invoice Id</th>
                            <th>Building</th>
                            <th>Flat No.</th>
                            <th>Month-Year</th>
                            <th>Invoice Amount</th>
                            <th>Invoice Status</th>
                            {/* <th>Bill No</th>
                            <th>Invoice Status</th>
                            <th>Payment Status</th>
                            <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {deletedMntInvoice.isLoading ||
                            generatedMntInvoice.isLoading ? null : (
                            mntInvoicBill.success &&
                            mntInvoicBill.data.invoiceList.map((d, id) => (
                              <>
                                <tr key={id}
                                  id={`toggler${id}`}
                                  style={{
                                    backgroundColor: '#e8f5fd',
                                    color: 'black',
                                    fontWeight: 'semi-bold',
                                    boxShadow: 'none',
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setIdx(id)
                                    id = `toggler${id}`
                                  }}
                                >
                                  <td>
                                    <Checkbox
                                      onChange={() => {
                                        handleCheckbox(d.id);
                                        // handleCheckboxData(d)
                                      }}
                                      name={id}
                                      value={select[id]}
                                      defaultChecked={selectAll}
                                    />
                                  </td>
                                  <td>{d.id}</td>
                                  <td>{d.area}</td>
                                  <td>{d.unit}</td>
                                  <td>
                                    {d.month} - {moment(d.year).format('YYYY')}
                                  </td>

                                  <td>₹ {d.totalAmount}</td>
                                  <td style={{ textTransform: 'capitalize' }}>
                                    {d.invoiceStatus}
                                  </td>
                                  {/* <td>{d.billNumber}</td>
                                  <td style={{ textTransform: 'capitalize' }}>
                                    {d.invoiceStatus}
                                  </td>
                                  <td style={{ textTransform: 'capitalize' }}>
                                    {d.paymentStatus}
                                  </td>
                                  <td>
                                    {d.invoiceStatus === 'uploaded' ? (
                                      <div style={{ maxWidth: '150px' }}>
                                        <span style={{ margin: '5px' }}>
                                          <img
                                            src={EditBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() =>
                                              toggleUpdateMntModal(d)
                                            }
                                          />
                                        </span>

                                        <span style={{ margin: '5px' }}>
                                          <img
                                            src={GenrateBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() => handleGenerate(d.id)}
                                          />
                                        </span>

                                        <span style={{ margin: '5px' }}>
                                          <img
                                            src={TrashBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() => handleDelte(d.id)}
                                          />
                                        </span>
                                      </div>
                                    ) : d.invoiceStatus === 'sent' ? (
                                      <div style={{ maxWidth: '100px' }}>
                                        <span style={{ margin: '5px' }}>
                                          <img
                                            src={DownloadBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() =>
                                              handleDownload(d.invoiceUrl)
                                            }
                                          />
                                        </span>

                                        <span style={{ margin: '5px' }}>
                                          <img
                                            src={TrashBtn}
                                            alt="edit"
                                            width="20px"
                                            height="20px"
                                            onClick={() => handleDelte(d.id)}
                                          />
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </td> */}
                                </tr>

                                <tr className="ml-detail-tr">
                                  <td colSpan="6" className="ml-detail-td">
                                    <div
                                      toggler={`toggler${id}`}
                                    >
                                      {idx === id ? (
                                        <>
                                          <Row>
                                            <Col md="3">
                                              <b>Bill No</b>
                                              {
                                                mntInvoicBill.success &&
                                                mntInvoicBill.data.invoiceList.map((d, id) => (
                                                  id === idx ?
                                                    <p key={id}>{d.billNumber}</p> : null
                                                ))
                                              }

                                            </Col>
                                            {/* <Col md="3">
                                              <b>Invoice Status</b>
                                              {
                                                mntInvoicBill.success &&
                                                mntInvoicBill.data.invoiceList.map((d, id) => (
                                                  id === idx ?
                                                    <p key={id} style={{ textTransform: 'capitalize' }}>{d.invoiceStatus}</p> : null
                                                ))
                                              }
                                            </Col> */}
                                            <Col md="3">
                                              <b>Payment Status</b>
                                              {
                                                mntInvoicBill.success &&
                                                mntInvoicBill.data.invoiceList.map((d, id) => (
                                                  id === idx ?
                                                    <p key={id} style={{ textTransform: 'capitalize' }}>{d.paymentStatus}</p> : null
                                                ))
                                              }
                                            </Col>
                                            <Col md="3">
                                              <b>Action</b>
                                              {
                                                mntInvoicBill.success &&
                                                mntInvoicBill.data.invoiceList.map((d, id) => (
                                                  id === idx ?
                                                    <p key={id}>
                                                      {d.invoiceStatus === 'uploaded' ? (
                                                        <div>
                                                          <Tooltip
                                                            placement="leftBottom"
                                                            title="Edit"
                                                            color="#108ee9"
                                                          >
                                                            <span style={{ margin: '5px', cursor: "pointer" }}>
                                                              <img
                                                                src={EditBtn}
                                                                alt="edit"
                                                                width="20px"
                                                                height="20px"
                                                                onClick={() =>
                                                                  toggleUpdateMntModal(d)
                                                                }
                                                              />
                                                            </span>
                                                          </Tooltip>

                                                          <Tooltip
                                                            placement="bottom"
                                                            title="Generate"
                                                            color="#108ee9"
                                                          >
                                                            <span style={{ margin: '5px', cursor: "pointer" }}>
                                                              <img
                                                                src={GenrateBtn}
                                                                alt="edit"
                                                                width="20px"
                                                                height="20px"
                                                                onClick={() => handleGenerate(d.id)}
                                                              />
                                                            </span>
                                                          </Tooltip>

                                                          <Tooltip
                                                            placement="rightBottom"
                                                            title="Delete"
                                                            color="#108ee9"
                                                          >
                                                            <span style={{ margin: '5px', cursor: "pointer" }}>
                                                              <img
                                                                src={TrashBtn}
                                                                alt="edit"
                                                                width="20px"
                                                                height="20px"
                                                                onClick={() => handleDelte(d.id)}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        </div>
                                                      ) : d.invoiceStatus === 'sent' ? (
                                                        <div>
                                                          <Tooltip
                                                            placement="leftBottom"
                                                            title="Download"
                                                            color="#108ee9"
                                                          >
                                                            <span style={{ margin: '5px', cursor: "pointer" }}>
                                                              <img
                                                                src={DownloadBtn}
                                                                alt="edit"
                                                                width="20px"
                                                                height="20px"
                                                                onClick={() =>
                                                                  handleDownload(d.invoiceUrl)
                                                                }
                                                              />
                                                            </span>
                                                          </Tooltip>

                                                          <Tooltip
                                                            placement="rightBottom"
                                                            title="Delete"
                                                            color="#108ee9"
                                                          >
                                                            <span style={{ margin: '5px', cursor: "pointer" }}>
                                                              <img
                                                                src={TrashBtn}
                                                                alt="edit"
                                                                width="20px"
                                                                height="20px"
                                                                onClick={() => handleDelte(d.id)}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        </div>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </p> : null
                                                ))
                                              }
                                            </Col>
                                          </Row>
                                        </>
                                      )
                                        :
                                        (' ')
                                      }

                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </Table>
                      {deletedMntInvoice.isLoading ||
                        generatedMntInvoice.isLoading ? <div
                          className="loaderParent"
                          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        >
                        <div className="loader"></div>
                      </div> : null}
                    </Col>

                    {mntInvoicBill.success && (
                      <div className="mntBtn">
                        <Button
                          color="secondary"
                          className="modalCustomButton"
                          onClick={() => {
                            //   setAddModal(!addModal)
                            generateInvoice()
                          }}
                          disable={
                            deletedMntInvoice.isLoading ||
                            generatedMntInvoice.isLoading
                          }
                          style={{ marginRight: "15px" }}
                        >
                          Generate All Invoice
                        </Button>

                        <Button
                          color="secondary"
                          className="modalCustomButton mntDelete"
                          onClick={() => {

                            setDeletes(!deletes)
                          }}
                          disable={
                            deletedMntInvoice.isLoading ||
                            generatedMntInvoice.isLoading
                          }
                        >
                          Delete All
                        </Button>

                      </div>
                    )}

                    <Row>
                      <Col>
                        {mntInvoicBill.success && (
                          <PaginationComponent
                            limit={limit}
                            totalItems={totalItems}
                            onSelect={handlePagination}
                            defaultPage={page}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                </Col>
                <Col>
                  {mntInvoicBill.failed && (
                    <div style={{ textAlign: 'center' }}>
                      <Alert message={mntInvoicBill.data.message} type="info" style={{ margin: "20px 0" }} />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{
            maxWidth: '250px',
            maxHeight: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <ModalHeader toggle={toogleAdd}>Upload File</ModalHeader>
          <ModalBody>
            <UploadMntBilling />
          </ModalBody>
        </Modal>
      )}
      {updateMntBillingModal && (
        <Modal isOpen={updateMntBillingModal} toggle={toggleUpdateMntModal}>
          <ModalHeader toggle={toggleUpdateMntModal}>Update</ModalHeader>
          <ModalBody>
            <UpdateMntBilling data={updateData} />
          </ModalBody>
        </Modal>
      )}
      {addMntBillingModal && (
        <Modal isOpen={addMntBillingModal} toggle={toggleAddMntModal}>
          <ModalHeader toggle={toggleAddMntModal}>
            Add Single Invoice
          </ModalHeader>
          <ModalBody>
            <AddMntBilling />
          </ModalBody>
        </Modal>
      )}

      {
        <Modal
          isOpen={deletes}
          toggle={toggleDel}
          size="lg"
          style={{
            maxWidth: '250px',
            maxHeight: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%'
          }}
        >
          <ModalHeader toggle={toggleDel}>Delete</ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            Are You Sure ?
          </ModalBody>
          <ModalFooter style={{ display: 'flex', justifyContent: "space-around" }}>
            <Button
              style={{ backgroundColor: "#4e97e6" }}
              onClick={() => {
                deleteInvoice()
                toggleDel()
              }}>
              Delete
            </Button>{' '}
            <Button color="secondary" onClick={toggleDel}>
              Cancel
            </Button>
          </ModalFooter>

        </Modal>
      }
    </>
  )
}

export default ViewMntBilling
