import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'

import { Upload, message, Select, Switch, Spin } from 'antd'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import NoticeBoardIcon from 'assets/img/Icons/Sidebar/noticeboard.png'

import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import Util from 'validations/index'

const { Dragger } = Upload

// const initialState = {
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	title: "",
// 	content: "",
// 	type: ""
// }

function SearchFeeConfig(props) {
  const [names, setName] = useState('')
  const [isFee, setIsFee] = useState(true)

  const login = useSelector(({ login }) => login)
  const addedMntType = useSelector(({ addMntType }) => addMntType)
  const mntType = useSelector(({ getMntType }) => getMntType)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name')
    Util.alphabetsOnly(value) ? setName(value) : setName(names)
  }

  const onChange = (checked) => {
    setIsFee(checked)
  }
  const { Option } = Select

  const getMntTypeRequest = () => {
    const getMntTypeReq = {
      initiatedBy: login.data.user.role,
      name: names.trim(),
      isFee,
      action: "fee",
    }
    return dispatch(getMaintenanceType(getMntTypeReq))
  }

  const handleSubmit = (e) => {
    props.setIsSearch()
    e.preventDefault()
    getMntTypeRequest()
    const obj = {
      name: names,
      isFee,
      action: "fee",
    }
    send(obj)
  }

  function send(value) {
    props.searchFilter(value)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={names}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Fee</Label>
              <br />
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={isFee}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button className="cust-button" onClick={handleSubmit} type="submit">
            {mntType.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchFeeConfig
