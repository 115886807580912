import React, { useState, useEffect } from 'react'
import AddNotice from './AddNotice'
import NoticeList from './NoticeList'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Tooltip } from 'antd'
import SearchNotice from './SearchNotice'

const initialState = {
  areaType: '',
  areaName: '',
  floors: '',
  noOfFlatsPF: '',
  startRange: '',
  endRange: '',
  rangeMulitplier: '',
}

const initialInputList = [
  {
    flatNumber: '',
  },
]

function NoticeBoard() {
  const [sendData, setSendData] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const searchFilter = (value) => {
    setSendData(value)
  }
  return (
    <>
      <SearchNotice searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)}/>
      <NoticeList data={sendData} onTap={isSearch}/>
    </>
  )
}

export default NoticeBoard
