import {
  GET_GUARD_ATTENDANCE_SUCCESS,
  GET_GUARD_ATTENDANCE_FAILED,
  GET_GUARD_ATTENDANCE_NETWORK_ERROR,
  LOADING_GET_GUARD_ATTENDANCE,
  RESET_GET_GUARD_ATTENDANCE,
} from '../types'
import axios from 'config/axios'

const resetGuardAttendance = () => {
  return {
    type: RESET_GET_GUARD_ATTENDANCE,
  }
}

const getGuardAttendance = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_GUARD_ATTENDANCE })
  axios
    .post('guard/getGuardAttendance', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: GET_GUARD_ATTENDANCE_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: GET_GUARD_ATTENDANCE_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_GUARD_ATTENDANCE_FAILED,
    payload: data,
  }
}
export { resetGuardAttendance, getGuardAttendance }
