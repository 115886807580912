import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Table, UncontrolledCollapse, FormGroup, Label, Input, Button, Modal, ModalBody, ModalHeader, FormFeedback } from 'reactstrap'
import { Alert, Tooltip, Select,Collapse } from 'antd'
import {
  getAdminList,
  resetgetAdminList,
} from 'redux/actions/getAdminListAction'
import Util from 'validations/index'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import { UpdateAdminUser, resetUpdateAdminUser } from 'redux/actions/updateAdminUserAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import EditBtn from 'assets/img/Brand/buttons/edit-icon.png'
import {updateMemberRole,resetUpdateMemberRole} from  'redux/actions/updateMemberRoleAction'

function AdminsRejectedList({ adminList, onTap }) {
  const [file, setFile] = useState('')
  const [limit] = useState(9)
  const [page, setPage] = useState(1)
  const [updateSocietyUserModal, setUpdateSocietyUserModal] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [id, setId] = useState('')
  const [societyId, setSocietyId] = useState('')
  const [role, setRole] = useState('')
  const [societyRole, setSocietyRole] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [showTextArea, setShowTextArea] = useState(false)

  const [fileModal, setFileModal] = useState(false)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  const updateAdminUserRes = useSelector(({ updateAdminUser }) => updateAdminUser)
  const updateMemberRoleRes = useSelector(({ updateMemberRole }) => updateMemberRole)
  // const adminList = useSelector(({ adminList }) => adminList)

  const totalItems = adminList.data.count
  // const pageSize = Math.floor(totalItems / limit)

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const handleOpenFile = (a) => {
    setFile(a)
    setFileModal(true)
  }

  //Toggle For Attachment Modal
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  const adminListRequest = () => {
    const adminListReq = {
      adminStatus: 'rejected',
      initiatedBy: login.data.user.role,
      limit,
      page,
    }

    return dispatch(getAdminList(adminListReq))
  }
  useEffect(() => {
    adminListRequest()
  }, [page])

  // useEffect(() => {
  //   const adminListReq = {
  //     adminStatus: 'rejected',
  //     initiatedBy: login.data.user.role,
  //     limit,
  //     page,
  //   }
  //   return dispatch(getAdminList(adminListReq))
  // }, [page])

  // Update Admin User

  const updateAdminUserReq = () => {
    const obj = {
      initiatedBy: login.data.user.role,
      societyId: societyId,
      id: id,
      name: name,
      mobileNumber: phone,
      email: email
    }
    return dispatch(UpdateAdminUser(obj))
  }

  const openEditModel = (value) => {
    setUpdateSocietyUserModal(!updateSocietyUserModal)
    setName(value.name)
    setEmail(value.email)
    setPhone(value.mobileNumber)
    setId(value.id)
    setSocietyId(value.societyId)
    setSocietyRole(value.societyRole)
  }

  const toogleEdit = () => {
    setUpdateSocietyUserModal(!updateSocietyUserModal)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'name') {
      Util.alphabetsOnly(value) ? setName(value) : setName(name)
    }

    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value)
    }

    if (name === 'phone') {
      Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
    }
    if (name === 'role') setRole(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    updateAdminUserReq()
  }

  const updateSocietyRole = () => {
    const obj = {
      initiatedBy: login.data.user.role,
      id: id,
      societyRole: role
    }
    return dispatch(updateMemberRole(obj))
  }
  const hamdleSubmitRole = (e) => {
    e.preventDefault()
    updateSocietyRole()
  }

  // useEffect(() => {
  //   if (updateAdminUserRes.success) {
  //     // NotificationPopups('success', 'Success', updateAdminUserRes.data.message)
  //   }
  //   if (updateAdminUserRes.failed) {
  //     // NotificationPopups('error', 'Failed', updateAdminUserRes.data.message)
  //   }
  //   if (updateAdminUserRes.network) {
  //     NotificationPopups('error', 'Network Error', updateAdminUserRes.data.message)
  //   }
  //   if(updateMemberRoleRes.success){
  //     NotificationPopups('success','Success',updateMemberRoleRes.data.message)
  //   }
  //   if (updateMemberRoleRes.failed) {
  //     NotificationPopups('error', 'Failed', updateMemberRoleRes.data.message)
  //   }
  //   if (updateMemberRoleRes.network) {
  //     NotificationPopups('error', 'Network Error', updateMemberRoleRes.data.message)
  //   }

  //   return () => {
  //     dispatch(resetUpdateAdminUser())
  //     dispatch(resetUpdateMemberRole())
  //   }
  // }, [updateAdminUserRes.success, updateAdminUserRes.failed, updateAdminUserRes.network,
  //   updateMemberRoleRes.success,updateMemberRoleRes.failed,updateMemberRoleRes.network])

  const RoleData = [
    { role: 'Chairman', id: 1 },
    { role: 'Secretary', id: 2 },
    { role: 'Treasurer', id: 3 },
    { role: 'Committee Member', id: 4 },
    { role: 'Others', id: 5 }
  ]

  const { Option } = Select

  const onChangeRole = (value) => {
    if (value === "textArea") {
      setRole('')
      setShowTextArea(true)
    }
    else {
      setRole(value)
      setShowTextArea(false)
    }
  }

  const onSearchRole = () => {

  }

  const items = [
    {
      key: '1',
      label: 'Edit Admin Society Role',
      children: <div>
        <FormGroup style={{ margin: '20px 0' }}>
          <label>Role in Society</label>
          {!showTextArea &&
            <Select
              type="select"
              showSearch
              value={societyRole}
              placeholder='Select Role'
              optionFilterProp="children"
              onChange={onChangeRole}
              onSearch={onSearchRole}
              style={{ width: '100%' }}
            >
              <Option className="loginSelect" value=''>
                Select Role
              </Option>
              {RoleData.map((d, id) => (
                <Option
                  key={id}
                  className="loginSelect"
                  value={d.role === "Others" ? 'textArea' : d.role}
                >
                  {d.role}
                </Option>
              ))}
            </Select>
          }

          {showTextArea && <Input
            style={{ padding: "8px", borderRadius: "6px" }}
            type="text"
            name="role"
            id="role"
            placeholder="Others"
            value={role}
            onChange={handleChange}
            required
          />}
        </FormGroup>
        <FormGroup style={{ textAlign: 'center' }}>
          <Button
            onClick={hamdleSubmitRole}
            className="cust-button"
          >
            Edit
          </Button>
        </FormGroup>
      </div>,
    },
  ];

  return (
    <>
      <div>
        <div className="table-full-width table-responsive" id="onTop">
          <Table responsive style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>Society Name</th>
                <th>Name</th>
                {/* <th>Email</th> */}
                <th>Phone</th>
                <th>City</th>
                <th>Pincode</th>
                {/* <th style={{ width: "100px" }}></th> */}
              </tr>
            </thead>
            <tbody>
              {adminList.success &&
                adminList.data.societyUserList.map((d) => (
                  <React.Fragment key={d.id}>
                    {/* <Tooltip
                      placement="bottom"
                      title="Edit"
                      color="#108ee9"
                    >
                      <img
                        src={EditBtn}
                        alt="edit"
                        width="20px"
                        height="20px"
                        style={{ position: "absolute", zIndex: 999, left: '93%', marginTop: '12px' }}
                        onClick={() =>
                          openEditModel(d)
                        }
                      />
                    </Tooltip> */}
                    <tr
                      key={d.id}
                      id={`toggler${d.id}`}
                      style={{
                        backgroundColor: '#e8f5fd',
                        color: 'black',
                        fontWeight: 'semi-bold',
                        boxShadow: 'none',
                      }}
                    >
                      <td>{d.societyName}</td>
                      <td>{d.name}</td>
                      {/* <td>{d.email}</td> */}
                      <td>{d.mobileNumber}</td>
                      <td>{d.city}</td>
                      <td>{d.pincode}</td>
                      {/* <td></td> */}
                    </tr>

                    <tr className="ml-detail-tr">
                      <td colSpan="6" className="ml-detail-td">
                        <UncontrolledCollapse toggler={`toggler${d.id}`}>
                        <h6>{d.message}</h6>
                          <Row className="ml-detail-row">
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{ padding: '20px' }}
                            >
                              <label>Address</label>
                              <div className="detail-val">{d.address}</div>
                            </Col>
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{ padding: '20px' }}
                            >
                              <label>Landmark</label>
                              <div className="detail-val">{d.landmark}</div>
                            </Col>
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{ padding: '20px' }}
                            >
                              <label>Block</label>
                              <div className="detail-val">{d.societyBlock}</div>
                            </Col>
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{ padding: '20px' }}
                            >
                              <label>State</label>
                              <div className="detail-val">{d.state}</div>
                            </Col>
                          </Row>
                          <Row>
                            {d.societyDocument &&
                              d.societyDocument.map((doc, id) => (
                                <Col
                                  xs="12"
                                  sm="6"
                                  md="4"
                                  lg="3"
                                  style={{ padding: '20px' }}
                                  key={id}
                                >
                                  <label>{doc.documentType}</label>
                                  {doc.document && (
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                        padding: '5px',
                                        height: '130px',
                                      }}
                                      onClick={() => handleOpenFile(doc.document)}
                                    >
                                      {doc.document &&
                                        Util.attachmentType(doc.document) ? (
                                        <img
                                          // src={doc.document}
                                          src={`${Util.imageUrl}${doc.document}`}
                                          // alt={`${Util.imageUrl}${doc.document}`}
                                          style={{
                                            width: '100px',
                                            height: '100px',
                                          }}
                                        />
                                      ) : (
                                        <i
                                          className={Util.iconType(doc.document)}
                                          style={{ fontSize: '100px' }}
                                        ></i>
                                      )}
                                    </div>
                                  )}
                                </Col>
                              ))}
                          </Row>
                        </UncontrolledCollapse>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
          {adminList.failed && (
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <Alert message={adminList.data.message} type="info" />
            </div>
          )}
        </div>

        {fileModal && (
          <DisplayAttachment
            attachment={`${Util.imageUrl}${file}`}
            fileModal={fileModal}
            toggleModal={toggleFileModal}
          />
        )}
        {adminList.success && (
          <PaginationComponent
            limit={limit}
            totalItems={totalItems}
            onSelect={handlePagination}
            defaultPage={page}
          />
        )}
      </div>

      {/* <Modal isOpen={updateSocietyUserModal} toggle={toogleEdit}>
        <ModalHeader toggle={toogleEdit}>Edit</ModalHeader>
        <ModalBody>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                placeholder=" Name"
                id="email"
                value={name || ''}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                invalid={!(email && isEmailValid)}
                placeholder="Email"
                type="email"
                name="email"
                value={email || ''}
                onChange={handleChange}
              />
              <FormFeedback style={{ textAlign: 'center' }}>
                {email && isEmailValid === false ? 'Invalid Email' : ''}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                type="text"
                name="phone"
                placeholder="Phone"
                id="phone"
                value={phone || ''}
                onChange={handleChange}
                maxlength='10'
              />
            </FormGroup>
            <FormGroup style={{ textAlign: 'center' }}>
              <Button
                onClick={handleSubmit}
                className="cust-button"
              >
                Edit
              </Button>
            </FormGroup>

            <Collapse accordion expandIconPosition={'end'} items={items} />
        </ModalBody>
      </Modal> */}
    </>
  )
}

export default AdminsRejectedList
