import isEmpty from '../../validations/is-empty'
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOADING_LOGIN,
  RESET_LOGIN,
  LOGIN_NETWORKERROR,
} from '../types'

const initialState = {
  isLoading: false,
  success: false,
  failed: false,
  network: false,
  isAvailable: false,
  isVerified: false,
  isSociety: false,
  network: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_LOGIN:
      return {
        ...state,
        isLoading: true,
        success: false,
        network: false,
        failed: false,
        isAvailable: false,
        isVerified: false,
        isSociety: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        network: false,
        failed: false,
        network: false,
        isAvailable:
          action.payload.user.role === 'SuperAdmin'
            ? true
            : action.payload.isVerified &&
              Object.keys(action.payload.society).length != 0
            ? true
            : false,
        isVerified:
          action.payload.user.role === 'SuperAdmin'
            ? true
            : action.payload.isVerified
            ? true
            : false,
        isSociety:
          action.payload.user.role === 'SuperAdmin'
            ? true
            : action.payload.society != null &&
              Object.keys(action.payload.society).length != 0
            ? true
            : false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        isAvailable: false,
        isVerified: false,
        isSociety: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case LOGIN_NETWORKERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: false,
        network: true,
        isAvailable: false,
        isVerified: false,
        isSociety: false,
        network: true,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case RESET_LOGIN:
      return (state = initialState)

    default:
      return state
  }
}
