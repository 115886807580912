import axios from 'config/axios'
import {
  UPDATE_AMENITY_STATUS_SUCCESS,
  UPDATE_AMENITY_STATUS_FAILED,
  LOADING_UPDATE_AMENITY_STATUS,
  RESET_UPDATE_AMENITY_STATUS,
  UPDATE_AMENITY_STATUS_NETWORK_ERROR,
} from 'redux/types'

const resetUpdateAmenityStatus = () => {
  return {
    type: RESET_UPDATE_AMENITY_STATUS,
  }
}

const updateAmenityStatus = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_AMENITY_STATUS })
  axios
    .post('amenity/updateAmenityStatus', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_AMENITY_STATUS_NETWORK_ERROR, payload: err })
    })
}

function success(data) {
  return {
    type: UPDATE_AMENITY_STATUS_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: UPDATE_AMENITY_STATUS_FAILED,
    payload: data,
  }
}

export { resetUpdateAmenityStatus, updateAmenityStatus }
