import {
  DELETE_SOC_MNT_TYPE_SUCCESS,
  DELETE_SOC_MNT_TYPE_FAILED,
  DELETE_SOC_MNT_TYPE_LOADING,
  DELETE_SOC_MNT_TYPE_NETWORK_ERROR,
  DELETE_SOC_MNT_TYPE_RESET,
} from 'redux/types'

const initialState = {
  isLoading: false,
  success: false,
  failed: false,
  network: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SOC_MNT_TYPE_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case DELETE_SOC_MNT_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case DELETE_SOC_MNT_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case DELETE_SOC_MNT_TYPE_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: false,
        network: true,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case DELETE_SOC_MNT_TYPE_RESET:
      return (state = initialState)

    default:
      return initialState
  }
}
