import axios from '../../config/axios'
import {
  GENERATE_MNT_INVOICE_SUCCESS,
  GENERATE_MNT_INVOICE_FAILED,
  GENERATE_MNT_INVOICE_NETWORK_ERROR,
  LOADING_GENERATE_MNT_INVOICE,
  RESET_GENERATE_MNT_INOVICE,
} from '../types'

const resetGenerateMntInvoice = () => {
  return {
    type: RESET_GENERATE_MNT_INOVICE,
  }
}

const generateMntInvoice = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GENERATE_MNT_INVOICE })
  axios
    .post('mnt/generateInvoice', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GENERATE_MNT_INVOICE_NETWORK_ERROR, payload: err })
    )
}
function success(data) {
  return {
    type: GENERATE_MNT_INVOICE_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GENERATE_MNT_INVOICE_FAILED,
    payload: data,
  }
}
export { resetGenerateMntInvoice, generateMntInvoice }
