import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'

import { useSelector, useDispatch } from 'react-redux'
import { Upload, message, Select, Switch } from 'antd'
import {
  resetAddMaintenanceType,
  addMaintenanceType,
} from 'redux/actions/addMntTypeAction'
import Util from 'validations/index'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

const AddFeeConfig = () => {
  const [names, setName] = useState('')
  const [isFee, setIsFee] = useState(false)

  const login = useSelector(({ login }) => login)

  const addedMntType = useSelector(({ addMntType }) => addMntType)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    AddMaintenanceTypeRequest()
    if (addedMntType.success) {
      setName('')
      NotificationPopups('success', 'Success', addedMntType.data.message)
    }
  }
  const AddMaintenanceTypeRequest = () => {
    const AddMaintenanceTypeReq = {
      initiatedBy: login.data.user.role,
      action: 'add',
      name: names.trim(),
      isFee,
    }
    return dispatch(addMaintenanceType(AddMaintenanceTypeReq))
  }
  const onChangeSwitch = (checked) => {
    setIsFee(checked)
  }

  useEffect(() => {
    if (addedMntType.success) {
      setName('')
      // NotificationPopups('success', 'Success', addedMntType.data.message)
    }
    // if (addedMntType.failed) {
    //   NotificationPopups('error', 'Failed', addedMntType.data.message)
    // }
    if (addedMntType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => resetAddMaintenanceType()
  }, [addedMntType.success, addedMntType.failed, addedMntType.network])
  return (
    <>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          value={names}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Fee</Label>
        <br />
        <Switch
          defaultChecked={isFee}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={onChangeSwitch}
        />
      </FormGroup>

      <Row>
        <Col md="12">
          <Button
            style={{ textAlign: 'center' }}
            color="secondary"
            className="modalCustomButton"
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default AddFeeConfig
