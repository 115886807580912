import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

// ant design components
import { Tooltip } from 'antd'
import SubCategory from './SocietyDashBoard/SubCategory/SubCategory'
import Feature from './SocietyDashBoard/Feature/Feature'
import AddFeature from './SocietyDashBoard/Feature/AddFeature'
import AddSubCategory from './SocietyDashBoard/SubCategory/AddSubCategory'
import AddFeatureType from './SocietyDashBoard/FeatureType/AddFeatureType'
import FeatureType from './SocietyDashBoard/FeatureType/FeatureType'
import AddDashboard from './SocietyDashBoard/Dashboard/AddDashboard'
import Dashboard from './SocietyDashBoard/Dashboard/Dashboard'
import SearchDashboard from './SocietyDashBoard/Dashboard/SearchDashboard'
import SearchFeature from './SocietyDashBoard/Feature/SearchFeature'
import SearchFeatureType from './SocietyDashBoard/FeatureType/SearchFeatureType'
import SearchSubCategory from './SocietyDashBoard/SubCategory/SearchSubCategory'

import DashboardConfigurationsIcon from 'assets/img/Icons/Sidebar/configurations.png'

import AppHomeConfigIcon from 'assets/img/Icons/Sidebar/configurations.png'

import GuardIcon from 'assets/img/Icons/Categories/guard.png'

function DashboardConfiguration() {
  const [activeTab, setActiveTab] = useState('')
  const login = useSelector(({ login }) => login)
  const [showScreen, setshowScreen] = useState('Dashboard')
  const [sendData, setSendData] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const showDrawer = (a) => {
    setshowScreen(a)
  }
  const configArr = [
    { id: 1, name: 'Guard', display: 'Society', icon: `${GuardIcon}` },
  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const typeComponentArr = (t) => {
    switch (t) {
      case 'Guard':
        return
      default:
        return null
    }
  }

  const displayComponentArr = (t) => {
    switch (t) {
      case 'Guard':
        return
      default:
        return null
    }
  }

  const searchFilter = (value) => {
    setSendData(value)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {login.data.user.role === 'SuperAdmin' ? (
                    // <img
                    //   src={AppHomeConfigIcon}
                    //   alt="AppHomeConfigIcon"
                    //   style={{ height: '50%', width: '50%' }}
                    // />
                    <i className="now-ui-icons gestures_tap-01" />
                  ) : (
                    <img
                      src={DashboardConfigurationsIcon}
                      alt="AppHomeConfigIcon"
                      style={{ height: '50%', width: '50%' }}
                    />
                  )}
                  {/* <img src={DashboardConfigurationsIcon} alt="DashboardConfigurationsIcon" style={{ height: '50%', width: '50%' }} /> */}
                </div>
                <span>
                  {showScreen === 'Dashboard' &&
                    login.data.user.role === 'SuperAdmin' && (
                      <>
                        {' '}
                        <span className="head-title">User App Home Config</span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Feature Type')}
                        >
                          | Feature Type
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Sub Category')}
                        >
                          | Sub Category
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Feature')}
                        >
                          Feature Category
                        </span>
                      </>
                    )}
                  {showScreen === 'Sub Category' &&
                    login.data.user.role === 'SuperAdmin' && (
                      <>
                        <span className="head-title">Sub Category</span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Feature Type')}
                        >
                          | Feature Type
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Feature')}
                        >
                          | Feature Category
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Dashboard')}
                        >
                          User App Home Config
                        </span>
                      </>
                    )}
                  {showScreen === 'Feature' &&
                    login.data.user.role === 'SuperAdmin' && (
                      <>
                        {' '}
                        <span className="head-title">Feature Category</span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Feature Type')}
                        >
                          | Feature Type
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Sub Category')}
                        >
                          | Sub Category
                        </span>
                        <span
                          style={{ float: 'right', paddingTop: '10px' }}
                          className="right-menu"
                          onClick={() => showDrawer('Dashboard')}
                        >
                          User App Home Config
                        </span>
                      </>
                    )}
                  {showScreen === 'Feature Type' && (
                    <>
                      {' '}
                      <span className="head-title">Feature Type</span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('Feature')}
                      >
                        | Feature Category
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('Sub Category')}
                      >
                        | Sub Category
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('Dashboard')}
                      >
                        User App Home Config
                      </span>
                    </>
                  )}
                </span>
              </CardHeader>
              <CardBody>
                {showScreen === 'Dashboard' &&
                  login.data.user.role === 'SuperAdmin' && (
                    <SearchDashboard searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
                  )}
                {showScreen === 'Feature' &&
                  login.data.user.role === 'SuperAdmin' && (
                    <SearchFeature searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
                  )}
                {showScreen === 'Sub Category' &&
                  login.data.user.role === 'SuperAdmin' && (
                    <SearchSubCategory searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
                  )}
                {showScreen === 'Feature Type' &&
                  login.data.user.role === 'SuperAdmin' && (
                    <SearchFeatureType searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)} />
                  )}
                <Col md="12" style={{ padding: '20px' }}>
                  <Nav tabs>
                    {configArr.map((d, id) => (
                      <>
                        {login.data.user.role === d.display ? (
                          <NavItem>
                            <NavLink
                              data-id={id}
                              className={classnames({
                                active: activeTab === id,
                              })}
                              onClick={() => {
                                toggle(id)
                              }}
                              key={id}
                            >
                              <div className={`sd-col`}>
                                <div className="sd-ic">
                                  {/* <i className={d.icon}></i> */}
                                  <img src={d.icon} alt={d.icon} />
                                </div>
                                <p className="sd-label">{d.name}</p>
                              </div>
                            </NavLink>
                          </NavItem>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    {configArr.map((d, id) => (
                      <TabPane tabId={id} data-id={id} key={id}>
                        {typeComponentArr(d.name)}
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showScreen === 'Dashboard' &&
          login.data.user.role === 'SuperAdmin' && (
            <Dashboard data={sendData} onTap={isSearch} />
          )}
        {showScreen === 'Feature' &&
          login.data.user.role === 'SuperAdmin' && (
            <Feature data={sendData} onTap={isSearch} />
          )}
        {showScreen === 'Sub Category' &&
          login.data.user.role === 'SuperAdmin' && (
            <SubCategory data={sendData} onTap={isSearch} />
          )}
        {showScreen === 'Feature Type' &&
          login.data.user.role === 'SuperAdmin' && (
            <FeatureType data={sendData} onTap={isSearch} />
          )}
      </div>
    </>
  )
}

export default DashboardConfiguration
