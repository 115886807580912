import axios from '../../config/axios'
// import axios from 'axios'
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOADING_LOGIN,
  RESET_LOGIN,
  LOGIN_NETWORKERROR,
} from '../types'

const resetLogin = () => {
  return {
    type: RESET_LOGIN,
  }
}

const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_LOGIN })
  console.log(userData)
  axios
    .post('user/adminSignin', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          //history.push({ pathname: "/admin/dashboard" });
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: LOGIN_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: LOGIN_FAILED,
    payload: data,
  }
}

export { resetLogin, loginUser }
