import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetUpdateServiceType,
  updateServiceType,
} from '../../redux/actions/updateServiceTypeAction'
import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'

function ServiceType() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [serviceType, setServiceType] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [isSocietyUser, setIsSocietyUser] = useState()
  const [isUser, setIsUser] = useState()
  const [isGuard, setIsGuard] = useState()
  const [id, setId] = useState('')
  const [visitorId, setVisitorId] = useState('')
  const [iconImage, setIconImage] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isAttendance, setIsAttendance] = useState(false)
  const [isVisitor, setIsVisitor] = useState(false)
  const [isActive, setisActive] = useState()
  const [modal, setmodal] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const updatedServiceType = useSelector(
    ({ updateServiceType }) => updateServiceType
  )
  const addedServiceType = useSelector(({ addservicetype }) => addservicetype)
  const [limit] = useState(20)
  const [page, setPage] = useState(1)

  const pageSize = servType.data.pages
  const totalItems = servType.data.count

  useEffect(() => {
    console.log(`${Util.imageUrl}`)
    getServiceTypeRequest()
  }, [addedServiceType.success])

  const getServiceTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const getServiceTypeReq = {
        initiatedBy: login.data.user.role,
        limit,
        page,
      }
      return dispatch(getServiceType(getServiceTypeReq))
    }
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const getServiceTypeReq = {
        initiatedBy: login.data.user.role,
        limit,
        page,
      }
      return dispatch(getServiceType(getServiceTypeReq))
    }
  }, [page])

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'serviceType')
      Util.alphabetsOnly(value)
        ? setServiceType(value)
        : setServiceType(serviceType)
    if (name === 'isSocietyUser') setIsSocietyUser(checked)
    if (name === 'isUser') setIsUser(checked)
    if (name === 'isGuard') setIsGuard(checked)
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }
  const onChangeSwitch = (checked) => {
    setIsAttendance(checked)
    console.log(`switch to ${checked}`)
  }
  const onChangeSwitch2 = (checked) => {
    setIsVisitor(checked)
    console.log(`switch to ${checked}`)
  }

  const openEditModal = (type) => {
    console.log(
      `${Util.imageUrl}${servType.data.activeServiceType[0].iconImage}`
    )
    setprofilePictureStatus(false)
    setServiceType(type.type)
    setIsAttendance(type.isAttendance)
    setId(type.id)
    setisActive(type.isActive)
    setIsSocietyUser(type.isSocietyUser)
    setIsUser(type.isUser)
    setIsGuard(type.isGuard)
    setIconImage(type.iconImage)
    setIsVisitor(type.isVisitor)
    setmodal(!modal)
    console.log(type.iconImage, 'icon')
    console.log(iconImage, 'iconImage')
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const handleAttachments = (filesList) => {
    // setprofilePictureStatus(true)
    // console.log('filesList', filesList)
    // // const icon = filesList.base64.split(`,`)
    // console.log(icon)
    // setIconImage(icon[1])
    // setIconImage(filesList.base64.slice(22))
    // iconImage.slice(22)
    //setIconImage(filesList.base64);
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    setIconImage(icon[1])
    setUpdatedPhoto(filesList.base64)
  }

  const handleOpenFile = () => {
    setFileModal(true)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const handleEditServiceType = () => {
    const updateServiceTypeReq = {
      initiatedBy: login.data.user.role,
      id,
      type: serviceType.trim(),
      isSocietyUser,
      isUser,
      isGuard,
      iconImage: profilePictureStatus ? iconImage : '',
      // iconImage,
      isAttendance,
      isActive,
      isVisitor,
    }
    //console.log(updateServiceType)
    return dispatch(updateServiceType(updateServiceTypeReq))
  }

  useEffect(() => {
    if (updatedServiceType.success) {
      setServiceType('')
      setisActive()
      setmodal(false)
      setIsSocietyUser()
      setIsUser()
      setIsVisitor(false)
      setisActive(false)
      setIsAttendance(false)
      setIsGuard()
      seterrMessage('')
      NotificationPopups('success', 'Success', updatedServiceType.data.message)
    }
    if (updatedServiceType.failed) {
      seterrMessage(updatedServiceType.data.message)
      setmodal(false)
      NotificationPopups('error', 'Failed', updatedServiceType.data.message)
    }
    if (updatedServiceType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    getServiceTypeRequest()

    return () => {
      dispatch(resetUpdateServiceType())
      dispatch(resetgetServiceType())
    }
  }, [
    updatedServiceType.success,
    updatedServiceType.failed,
    updatedServiceType.network,
  ])
  // console.log(!servType.data.activeServiceType.iconImage)
  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col md="12" style={{ padding: '20px' }}>
                  <div>
                    {servType.success && (
                      <div className="typeHeading" id="onTop">
                        Active Service Type
                      </div>
                    )}
                    <Row>
                      {servType.success &&
                        servType.data.activeServiceType.map((d, id) => (
                          <Col sm="12" md="4" lg="4" key={id}>
                            <Card className="typeContent">
                              <CardBody>
                                <CardText>
                                  <CardImg
                                    // src={d.iconImage}
                                    src={`${Util.imageUrl}${d.iconImage}`}
                                    //src={`${Util.imageUrl}${servType.data.activeServiceType[0].iconImage}`}
                                    alt="Icon"
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                  <span
                                    style={{
                                      textTransform: 'capitalize',
                                      fontWeight: 'semi-bold',
                                      color: ' #00326c',
                                    }}
                                  >
                                    {' '}
                                    {d.type}
                                  </span>
                                </CardText>
                                <div style={{ fontSize: '15px' }}>
                                  <i class="fas fa-user-tag"></i> Accessed By:
                                  {d.isSocietyUser && 'Society User'}
                                  {d.isSocietyUser && d.isGuard && ','}
                                  {d.isGuard && 'Guard'}
                                  {d.isGuard && d.isUser && ','}
                                  {d.isSocietyUser &&
                                    d.isUser &&
                                    !d.isGuard &&
                                    ','}
                                  {d.isUser && 'User'}
                                </div>
                                <CardText>
                                  <Button
                                    color="secondary"
                                    className="modalCustomButton"
                                    onClick={() => openEditModal(d)}
                                  >
                                    Edit
                                  </Button>
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                    {servType.success &&
                      !servType.data.activeServiceType.length && (
                        <div
                          style={{ textAlign: 'center', padding: '20px 0px' }}
                        >
                          <Alert
                            message="No Active Service Type Found"
                            type="info"
                          />
                        </div>
                      )}
                  </div>

                  <div>
                    {servType.success && (
                      <div className="typeHeading">Inactive Service Type</div>
                    )}
                    <Row>
                      {servType.success &&
                        servType.data.inActiveServiceType.map((d, id) => (
                          <Col sm="12" md="4" lg="4" key={id}>
                            <Card className="typeContent">
                              <CardBody>
                                <CardText>
                                  <CardImg
                                    //src={d.iconImage}
                                    src={`${Util.imageUrl}${d.iconImage}`}
                                    alt="Icon"
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                  <span
                                    style={{
                                      textTransform: 'capitalize',
                                      fontWeight: 'semi-bold',
                                      color: ' #00326c',
                                    }}
                                  >
                                    {d.type}
                                  </span>
                                </CardText>
                                <div style={{ fontSize: '15px' }}>
                                  <i class="fas fa-user-tag"></i> Accessed By:{' '}
                                  {d.isSocietyUser && 'Society User,'}{' '}
                                  {d.isGuard && 'Guard,'} {d.isUser && 'User'}
                                </div>

                                <CardText>
                                  <Button
                                    color="secondary"
                                    className="modalCustomButton"
                                    onClick={() => openEditModal(d)}
                                  >
                                    Edit
                                  </Button>
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                    {servType.success &&
                      !servType.data.inActiveServiceType.length && (
                        <div
                          style={{ textAlign: 'center', padding: '20px 0px' }}
                        >
                          <Alert
                            message="No Inactive Service Type Found"
                            type="info"
                          />
                        </div>
                      )}
                  </div>
                  {/* 
                  {servType.failed && (
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                      <Alert message={servType.data.message} type="info" />
                    </div>
                  )} */}

                  {servType.success && (
                    <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          // style={{ maxWidth: '250px' }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="serviceType">Service Type</Label>
              <Input
                type="text"
                name="serviceType"
                id="serviceType"
                value={serviceType}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>Accessed By</Label>
              <Row style={{ padding: '8px 25px' }}>
                <Col md="12" sm="12" lg="12">
                  <Input
                    defaultChecked={isSocietyUser}
                    type="checkbox"
                    name="isSocietyUser"
                    id="isSocietyUser"
                    value="isSocietyUser"
                    onChange={handleEditChange}
                  />
                  {'  '}
                  Society User
                </Col>
                <Col md="12" sm="12" lg="12">
                  <Input
                    defaultChecked={isGuard}
                    type="checkbox"
                    name="isGuard"
                    id="isGuard"
                    value="isGuard"
                    onChange={handleEditChange}
                  />
                  {'  '}
                  Guard
                </Col>
                <Col md="12" sm="12" lg="12">
                  <Input
                    defaultChecked={isUser}
                    type="checkbox"
                    name="isUser"
                    id="isUser"
                    value="isUser"
                    onChange={handleEditChange}
                  />
                  {'  '}
                  User
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Col xs="12" sm="12" md="12">
                <label>Icons</label>
                <div style={{ cursor: 'pointer' }} onClick={handleOpenFile}>
                  {
                    <img
                      src={
                        profilePictureStatus
                          ? `${updatedPhoto}`
                          : `${Util.imageUrl}${iconImage}`
                      }
                      style={{
                        width: '50%',
                        height: '150px',
                        border: 'rounded',
                      }}
                    />
                  }
                </div>
                <div style={{ marginTop: '10px' }}>
                  <FileBase64
                    multiple={false}
                    id="editNoticeFiles"
                    accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                    onDone={handleAttachments}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup>
              <Label>Attendance</Label>
              <br />
              <Switch
                defaultChecked={isAttendance}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
                // style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Is Visitor</Label>
              <br />
              <Switch
                defaultChecked={isVisitor}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch2}
                // style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
                // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditServiceType}
              disabled={updatedServiceType.isLoading || !serviceType}
            >
              {updatedServiceType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {fileModal && (
        <DisplayAttachment
          attachment={iconImage}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
    </>
  )
}

export default ServiceType
