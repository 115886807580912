import axios from "../../config/axios"
import {
	ADDCOMPANYTYPE_SUCCESS,
	ADDCOMPANYTYPE_FAILED,
	LOADING_ADDCOMPANYTYPE,
	RESET_ADDCOMPANYTYPE,
	ADDCOMPANYTYPE_NETWORKERROR
} from "../types";

const resetaddCompanyType = () => {
    return {
        type: RESET_ADDCOMPANYTYPE
    };
};

const addCompanyType = userData => dispatch => {
    dispatch({ type: LOADING_ADDCOMPANYTYPE });
    axios
        .post("household/addCompanyType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADDCOMPANYTYPE_NETWORKERROR, payload: err }));
};

function success(data) {
    return {
        type: ADDCOMPANYTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADDCOMPANYTYPE_FAILED,
        payload: data
    };
}

export { resetaddCompanyType, addCompanyType } ;