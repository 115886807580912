import axios from "../../config/axios";
import {
	UPDATECOMPANYTYPE_SUCCESS,
	UPDATECOMPANYTYPE_FAILED,
	LOADING_UPDATECOMPANYTYPE,
	RESET_UPDATECOMPANYTYPE,
	UPDATECOMPANYTYPE_NETWORKERROR
	} from "../types";

const resetupdateCompanyType = () => {
    return {
        type: RESET_UPDATECOMPANYTYPE,		
    };
};

const updateCompanyType = userData => dispatch => {
    dispatch({ type: LOADING_UPDATECOMPANYTYPE });
		console.log(userData);
    axios
        .post("superAdmin/updateCompanyType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATECOMPANYTYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATECOMPANYTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATECOMPANYTYPE_FAILED,
        payload: data
    };
}

export { resetupdateCompanyType, updateCompanyType } ;