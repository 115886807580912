import {
  GET_UNIT_FLATLIST_SUCCESS,
  GET_UNIT_FLATLIST_FAILED,
  LOADING_GET_UNIT_FLATLIST,
  RESET_GET_UNIT_FLATLIST,
  GET_UNIT_FLATLIST_NETWORK_ERROR,
} from 'redux/types'
import axios from 'config/axios'

const resetGetUnitFlatList = () => {
  return {
    type: RESET_GET_UNIT_FLATLIST,
  }
}

const getUnitFlatList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_UNIT_FLATLIST })
  axios
    .post('society/getUnitList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
      }
    })
    .catch((err) => {
      dispatch({ type: GET_UNIT_FLATLIST_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: GET_UNIT_FLATLIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_UNIT_FLATLIST_FAILED,
    payload: data,
  }
}

export { resetGetUnitFlatList, getUnitFlatList }
