import React, { useEffect, useState } from 'react'
import PaginationComponent from 'react-reactstrap-pagination'

const Pagination = (props) => {

    const [windowDimension, setWindowDimensions] = useState({
        width: window.innerWidth
    })

    const detectSize = () => {
        setWindowDimensions({
            width: window.innerWidth
        })
    }
    // const [coll, setColl] = useState([])
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    return (
        <div className="col-xs-1 center-block">
            {windowDimension.width < 700 ?
                <PaginationComponent
                    totalItems={props.totalItems}
                    pageSize={props.limit}
                    maxPaginationNumbers={2}
                    defaultActivePage={props.defaultPage}
                    nextPageText={'>>'}
                    previousPageText={'<<'}
                    onSelect={props.onSelect}
                    size={'sm'}
                /> :
                <PaginationComponent
                    totalItems={props.totalItems}
                    pageSize={props.limit}
                    maxPaginationNumbers={5}
                    defaultActivePage={props.defaultPage}
                    nextPageText={'Next'}
                    previousPageText={'Previous'}
                    onSelect={props.onSelect}
                />
            }
        </div>
    )
}

export default Pagination;