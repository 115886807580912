import axios from '../../config/axios'
import {
  ADDSERVICETYPE_SUCCESS,
  ADDSERVICETYPE_FAILED,
  LOADING_ADDSERVICETYPE,
  RESET_ADDSERVICETYPE,
  ADDSERVICETYPE_NETWORK_ERROR,
} from '../types'

const resetaddServiceType = () => {
  return {
    type: RESET_ADDSERVICETYPE,
  }
}

const addServiceType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADDSERVICETYPE })
  console.log(userData)
  axios
    .post('helper/addServiceType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADDSERVICETYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: ADDSERVICETYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADDSERVICETYPE_FAILED,
    payload: data,
  }
}

export { resetaddServiceType, addServiceType }
