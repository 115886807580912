import React, { useState, useEffect, Component } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'
import { getVisitorDashBoard } from 'redux/actions/getVisitorDashboardAction'
import moment from 'moment'
function GetVisitorDashboard({ counterValue, chartBtn, dataCounterBtn }) {
  var updatesetterData = {}
  const [data, setData] = useState('')
  const [listData, setListData] = useState([])
  const [modal, setmodal] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [chartData, setChartData] = useState({})
  const [employeeSalary, setEmployeeSalary] = useState([])
  const [employeeAge, setEmployeeAge] = useState([])
  const login = useSelector(({ login }) => login)

  const getVisitorDashBoards = useSelector(
    ({ getVisitorDashBoards }) => getVisitorDashBoards
  )

  const dispatch = useDispatch()
  const [initiatedBy] = useState('Society')

  console.log(counterValue)
  var counterDataArrayIs = []
  console.log(counterValue)

  const Chart = (counterValue) => {
    var dateArray = []
    var counterDataArray = []

    if (dataCounterBtn == 'weekly' || dataCounterBtn == 'monthly') {
      counterValue.forEach(function (element, index) {
        !element.toDate
          ? dateArray.push(moment(element.fromDate).format('DD MMM'))
          : dateArray.push(
            moment(element.toDate).format('DD MMM') +
            ' - ' +
            moment(element.fromDate).format('DD MMM')
          )
        counterDataArray.push(element.count)
      })
    } else {
      counterValue.forEach(function (element, index) {
        dateArray.push(
          element.month.slice(0, 3) +
          ' ' +
          Number(String(element.year).slice(2, 4))
        )
        counterDataArray.push(element.count)
      })
    }

    //   counterValue.forEach(function(element, index) {
    //     console.log(element.fromDate+" "+element.toDate);
    //     //dateArray.push(element.fromDate);
    //     !(element.toDate) ? dateArray.push(moment(element.fromDate).format('DD MMM')):dateArray.push(moment(element.fromDate).format(('DD MMM'))+' - '+moment(element.toDate).format(('DD MMM')));
    //     counterDataArray.push(element.count);
    // });

    // if (dataCounterBtn == 'weekly' || dataCounterBtn == 'monthly') {
    //   counterValue.forEach(function (element, index) {
    //     !element.toDate
    //       ? dateArray.push(moment(element.fromDate).format('DD MMM'))
    //       : dateArray.push(
    //           moment(element.fromDate).format('DD MMM') +
    //             ' - ' +
    //             moment(element.toDate).format('DD MMM')
    //         )
    //     counterDataArray.push(element.count)
    //   })
    // } else {
    //   counterValue.forEach(function (element, index) {
    //     // dateArray.push(element.key.slice(0, 3))
    //     dateArray.push((element.month).slice(0, 3)+' '+Number(String(element.year).slice(2, 4)));
    //     counterDataArray.push(element.count)
    //   })
    // }

    //   counterValue.forEach(function(element, index) {
    //     console.log(element.fromDate+" "+element.toDate);
    //     //dateArray.push(element.fromDate);
    //     !(element.toDate) ? dateArray.push(moment(element.fromDate).format('DD MMM')):dateArray.push(moment(element.fromDate).format(('DD MMM'))+' - '+moment(element.toDate).format(('DD MMM')));
    //     counterDataArray.push(element.count);
    // });

    // for (const [key, value] of Object.entries(counterValue[0])) {
    //   dateArray.push(key.fromDate)
    //   counterDataArray.push(value)
    // }

    setChartData({
      labels: Object.values(dateArray),

      datasets: [
        {
          label: 'Visitor',
          data: counterDataArray,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    })
  }

  useEffect(() => {
    if (chartBtn == 'tablechart') {
      Chart(counterValue, dataCounterBtn)
    } else if (chartBtn == 'linechart') {
      Chart(counterValue, dataCounterBtn)
    } else {
      Chart(counterValue, dataCounterBtn)
    }
  }, [counterValue])

  return (
    <>
      <div className="App">
        <div>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              title: { text: 'THICCNESS SCALE', display: true },
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
              title: {
                display: true,
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GetVisitorDashboard
