import axios from '../../config/axios'
import {
  UPDATE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_FAILED,
  LOADING_UPDATE_ADMIN_USER,
  RESET_UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_NETWORK_ERROR,
} from '../types'

const resetUpdateAdminUser = () => {
  return {
    type: RESET_UPDATE_ADMIN_USER,
  }
}

const UpdateAdminUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_ADMIN_USER })
  console.log(userData)
  axios
    .post('user/updateAdminUser', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATE_ADMIN_USER_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATE_ADMIN_USER_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_ADMIN_USER_FAILED,
    payload: data,
  }
}

export { resetUpdateAdminUser, UpdateAdminUser }
