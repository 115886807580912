import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Spin } from 'antd'
import { sha256 } from 'js-sha256'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { loginUser, resetLogin } from 'redux/actions/loginAction'
import { Link } from 'react-router-dom'
import AdminSignup from './AdminSignup'
import classnames from 'classnames'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import SocietyImg from '../../assets/img/Brand/society2.png'
import Util from '../../validations/index'
import Form from 'reactstrap/lib/Form'
import Login from '../Login'
import {
  forgetPassword,
  resetForgetPassword,
} from 'redux/actions/forgetPasswordAction'

function ForgetPassword({ toggle, changeFPwSection }) {
  const [activeTab, setActiveTab] = useState('')
  const [email, setEmail] = useState('')
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [sigin, setSignIn] = useState(false)
  const dispatch = useDispatch()
  const forgetPW = useSelector(({ forgetPassword }) => forgetPassword)

  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('SuperAdmin')) {
    setInitiatedBy('SuperAdmin')
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value)
    }
  }

  const disabledButton = () => {
    return !(email && isEmailValid)
  }

  const forgetPasswordRequest = () => {
    let params = new URLSearchParams(document.location.search.substring(1))
    const forgetPasswordReq = {
      email,
      initiatedBy: params.has('role') ? params.get('role') : 'Society',
    }

    console.log(forgetPasswordReq)
    return dispatch(forgetPassword(forgetPasswordReq))
  }

  useEffect(() => {
    if (forgetPW.network)
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )

    if (forgetPW.failed)
      NotificationPopups('error', 'Failed', forgetPW.data.message)

    if (forgetPW.success) {
      setEmail("")
      NotificationPopups('success', 'Success', forgetPW.data.message)
    }
    return () => {
      dispatch(resetForgetPassword())
    }
  }, [forgetPW.network, forgetPW.failed, forgetPW.success])

  return (
    <Card>
      <Row>
        <Col>
          <CardBody>
            <Form>
              <FormGroup style={{ margin: '20px 0' }}>
                <label>EMAIL *</label>
                <Input
                  required
                  invalid={!(email && isEmailValid)}
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email || ''}
                  onChange={handleChange}
                />
                <FormFeedback
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {email && !isEmailValid ? 'Invalid Email' : ''}
                </FormFeedback>
              </FormGroup>
              <FormGroup
                style={{
                  margin: '20px 0px',
                  textAlign: 'center',
                  cursor: "pointer"
                }}
              >
                <Button

                  onClick={forgetPasswordRequest}
                  disabled={disabledButton() || forgetPW.loading}
                >
                  {forgetPW.loading ? <Spin /> : 'Submit'}
                </Button>
                <div className="forgot-password">
                  <div className="fp-label">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle('1')
                        changeFPwSection()
                      }}
                    >
                      Sign In 
                    </NavLink>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </CardBody>
        </Col>
      </Row>
    </Card>
  )
}

export default ForgetPassword
