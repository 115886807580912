import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import {
  resetaddSocietyMnt,
  addSocietyMnt,
} from 'redux/actions/addSocietyMntTypeAction'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import Util from 'validations/index'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { Select } from 'antd'

const EditMaintenanceList = ({ editId, dataEdit, closeModal }) => {
  const [mntName, setMntName] = useState(dataEdit.name)
  const [mntType, setMntType] = useState(dataEdit.mntType)
  const [mntColNum, setMntColNum] = useState(dataEdit.columnNumber)
  const [mntTypeId, setMntTypeId] = useState(dataEdit.mntTypeId)

  const login = useSelector(({ login }) => login)
  const addSociety = useSelector(({ addSocietyMnt }) => addSocietyMnt)
  const getMntType = useSelector(({ getMntType }) => getMntType)
  console.log(addSociety,"addSociety")

  const dispatch = useDispatch()
  console.log(getMntType, 'getMntType')
  const { Option } = Select

  function onChangeMasterData(value) {
    setMntType(value)
    setMntTypeId(value)
  }

  function onSearchMasterData(val) {
    setMntType(val)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'mntName')
      Util.alphabetsOnly(value) ? setMntName(value) : setMntName(mntName)
    if (name === 'mntColNum')
      Util.alphabetsOnly(value) ? setMntColNum(value) : setMntColNum(mntColNum)
  }

  const editMntRequest = () => {
    const editMntReq = {
      initiatedBy: login.data.user.role,
      name: mntName,
      columnNumber: mntColNum,
      mntTypeId,
      action: 'update',
      id: editId,
    }
    dispatch(addSocietyMnt(editMntReq))
    // closeModal()
  }
  const getMntRequest = () => {
    const editMntReq = {
      initiatedBy: login.data.user.role,
      action: 'fee',
    }
    dispatch(getMaintenanceType(editMntReq))
  }

  const handleMntEdit = () => {
    editMntRequest()
    // console.log(getSociety(), 'use Selector data');
  }

  useEffect(() => {
    getMntRequest()
  }, [])
  useEffect(() => {
    if (addSociety.success) {
      setMntName('')
      setMntType('')
      setMntColNum('')
      closeModal()
      NotificationPopups('success', 'Success', addSociety.data.message)
    }
    if (addSociety.failed) {
      NotificationPopups('error', 'Failed', addSociety.data.message)
    }
    if (addSociety.network) {
      NotificationPopups('error', 'Network Error', addSociety.data.message)
    }
    return () => {
      resetaddSocietyMnt()
    }
  }, [addSociety.success, addSociety.failed, addSociety.network])

  console.log(addSociety.failed,"addSociety.failed")

  return (
    <div>
      <FormGroup>
        <Label for="mntName">Name</Label>
        <Input
          type="text"
          name="mntName"
          placeholder=" Name"
          id="mntName"
          value={mntName || ''}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* <FormGroup>
                <Label for="mntType">Type</Label>
                <Input
                  type="text"
                  name="mntType"
                  placeholder=" Type"
                  id="mntType"
                  value={ mntType || ''}  
                  onChange={handleChange}
                  required
                />
              </FormGroup> */}
      <FormGroup style={{ margin: '10px 1px' }}>
        <label>Type</label>
        <Select
          type="select"
          value={mntType}
          showSearch
          placeholder="Select Feature Category"
          optionFilterProp="children"
          onChange={onChangeMasterData}
          onSearch={onSearchMasterData}
          style={{ width: '100%' }}
        >
          <Option className="loginSelect" value="">
            Select Master Data
          </Option>
          {getMntType.success &&
            getMntType.data?.maintenanceTypeList.map((d, id) => (
              <Option key={id} value={d.id}>
                {d.name}
              </Option>
            ))}
          {/* {getSociety.success &&
            getSociety.data?.maintenanceTypeList.map((data, id) => (
              <Option key={id} value={data.id}>
                {data.name}
              </Option>
            ))} */}
        </Select>
      </FormGroup>
      {/* <FormGroup>
        <Label for="mntColNum">Column Number</Label>
        <Input
          type="text"
          name="mntColNum"
          placeholder=" Column Number"
          id="mntColNum"
          value={mntColNum || ''}
          onChange={handleChange}
          required
        />
      </FormGroup> */}

      <Button
        color="secondary"
        className="modalCustomButton"
        onClick={handleMntEdit}
        style={{ position: 'relative', left: '4rem', width: '40%' }}
      >
        Edit
      </Button>
    </div>
  )
}

export default EditMaintenanceList
