import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch } from 'antd'
import {
  resetGetProfessionType,
  getProfessionType,
} from 'redux/actions/getProfessionTypeAction'
import Util from 'validations/index'

function SearchProfessionType(props) {
  const [profession, setProfession] = useState('')

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)
  const getProfession = useSelector(
    ({ getProfessionType }) => getProfessionType
  )

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'Profession')
    Util.alphabetsOnly(value) ? setProfession(value) : setProfession(profession)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.setIsSearch()
    getProfessionTypeRequest()
    const obj = {
      profession: profession,
    }
    send(obj)
  }

  const getProfessionTypeRequest = () => {
    const getProfessionReq = {
      initiatedBy: login.data.user.role,
      profession:profession.trim(),
      limit,
      page,
    }
    return dispatch(getProfessionType(getProfessionReq))
  }
  const disabledBtn = () => {
    return !profession
  }

  const send = (value) => {
    props.searchFilter(value)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Profession Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="Profession"
                id="serviceType"
                value={profession || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={disabledBtn()}
            type="submit"
          >
            {getProfession.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchProfessionType
