import axios from '../../config/axios'
import {
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAILED,
  ADD_DOCUMENT_NETWORK,
  LOADING_ADD_DOCUMENT,
  RESET_ADD_DOCUMENT,
} from '../types'

const resetAddDocument = () => {
  return {
    type: RESET_ADD_DOCUMENT,
  }
}

const addDocument = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_DOCUMENT })
  axios
    .post('society/addDocument', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADD_DOCUMENT_NETWORK, payload: err }))
}

function success(data) {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_DOCUMENT_FAILED,
    payload: data,
  }
}

export { resetAddDocument, addDocument }
