import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
} from 'reactstrap'
import { Spin } from 'antd'
import Util from '../../validations/index'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { sha256 } from 'js-sha256'
import {
  resetchangePassword,
  changePassword,
} from '../../redux/actions/changePasswordAction'
import ShowPwd from 'assets/img/Icons/Privacy/show_pwd.png'
import HidePwd from 'assets/img/Icons/Privacy/hide_pwd.png'

function ChangePassword() {
  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [isOldPwValid, setisOldPwValid] = React.useState('')
  const [isPwValid, setisPwValid] = React.useState('')
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setshowConfirmPassword] = React.useState(false)
  const login = useSelector(({ login }) => login)
  const changedPassword = useSelector(({ changepassword }) => changepassword)

  const dispatch = useDispatch()

  //request
  const changePasswordRequest = () => {
    const changePasswordReq = {
      id: login.data.user.id,
      initiatedBy: login.data.user.role,
      newPassword: sha256(password),
      password: sha256(oldPassword),
    }
    //console.log(changePasswordReq)
    return dispatch(changePassword(changePasswordReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'oldPassword') {
      setisOldPwValid(Util.verifyPassword(value))
      setOldPassword(value.trim())
      // console.log(isOldPwValid);
    }
    if (name === 'password') {
      setisPwValid(Util.verifyPassword(value))
      setPassword(value.trim())
    }
    if (name === 'confirmPassword') setConfirmPassword(value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    changePasswordRequest()
    // console.log(values)
  }

  const handleOldpassword = () => {
    setShowOldPassword(!showOldPassword)
  }
  const handlepassword = () => {
    setShowPassword(!showPassword)
  }

  const handleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword)
  }

  const disableButton = () => {
    return !(
      //oldPassword &&
      //isOldPwValid &&
      (password && isPwValid && password === confirmPassword)
    )
  }

  useEffect(() => {
    if (changedPassword.success) {
      setOldPassword('')
      setPassword('')
      setConfirmPassword('')
      NotificationPopups('success', 'Success', changedPassword.data.message)
    }
    if (changedPassword.failed) {
      NotificationPopups('error', 'Failed', changedPassword.data.message)
    }
    if (changedPassword.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetchangePassword())
    }
  }, [changedPassword.success, changedPassword.failed, changedPassword.network])

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Card>
          <CardHeader>
            <div className="head-icon">
              <i className={'now-ui-icons ui-1_lock-circle-open'}></i>
            </div>
            <span className="head-title">Change Password</span>
          </CardHeader>

          <CardBody>
            <Row style={{ justifyContent: 'center' }}>
              <Col md="4" lg="4" sm="12" xs="12" style={{ padding: '20px' }}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" sm="12" lg="12" xs="12">
                      <FormGroup style={{ margin: '20px 0' }}>
                        <label>OLD PASSWORD *</label>
                        <InputGroup>
                          <Input
                            required
                            invalid={oldPassword && !isOldPwValid}
                            //   invalid={oldPassword && !(oldPassword && isOldPwValid)}
                            placeholder="Old Password"
                            type={showOldPassword ? 'text' : 'password'}
                            name="oldPassword"
                            value={oldPassword || ''}
                            onChange={handleChange}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                background: 'rgba(150, 146, 146, 0.3)',
                                borderRadius: '30px',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                              onClick={handleOldpassword}
                            >
                              {showOldPassword ? (
                                <img
                                  src={ShowPwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              ) : (
                                <img
                                  src={HidePwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback style={{ textAlign: 'center' }}>
                            {oldPassword && !isOldPwValid
                              ? 'Password should be 8 to 20 characters and should have numbers and special characters'
                              : ''}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="12" sm="12" lg="12" xs="12">
                      <FormGroup style={{ margin: '20px 0' }}>
                        <label>NEW PASSWORD *</label>
                        <InputGroup>
                          <Input
                            required
                            invalid={password && !isPwValid}
                            // invalid={password && !(password && isPwValid)}
                            placeholder="New Password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            value={password || ''}
                            onChange={handleChange}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                background: 'rgba(150, 146, 146, 0.3)',
                                borderRadius: '30px',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                              onClick={handlepassword}
                            >
                              {showPassword ? (
                                <img
                                  src={ShowPwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              ) : (
                                <img
                                  src={HidePwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback style={{ textAlign: 'center' }}>
                            {password && !isPwValid
                              ? 'Password should be 8 to 20 characters and should have numbers and special characters'
                              : ''}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="12" sm="12" lg="12" xs="12">
                      <FormGroup style={{ margin: '20px 0' }}>
                        <label>CONFIRM PASSWORD *</label>
                        <InputGroup>
                          <Input
                            required
                            invalid={
                              confirmPassword && !(password === confirmPassword)
                            }
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            value={confirmPassword || ''}
                            onChange={handleChange}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{
                                background: 'rgba(150, 146, 146, 0.3)',
                                borderRadius: '30px',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                              onClick={handleConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <img
                                  src={ShowPwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              ) : (
                                <img
                                  src={HidePwd}
                                  style={{
                                    marginLeft: '12px',
                                    width: '16px',
                                  }}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback style={{ textAlign: 'center' }}>
                            {confirmPassword && !(password === confirmPassword)
                              ? 'Passwords do not match'
                              : ''}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="12" sm="12">
                      <FormGroup>
                        <Button
                          onClick={handleSubmit}
                          disabled={disableButton()}
                          type="submit"
                          className="cust-button"
                          style={{ width: '100%' }}
                        >
                          {changedPassword.isLoading ? <Spin /> : 'Submit'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default ChangePassword
