import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetUpdateUnitFlatList,
  updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'

import PaginationComponent from 'views/Pagination'
import { resetGetParking, getParking } from 'redux/actions/getParkingAction'
import {
  resetUpdateParking,
  updateParking,
} from 'redux/actions/updateParkingSlotAction'
import { resetAddParking, addParking } from 'redux/actions/addParkingAction'
import axios from 'config/axios'

import AddImg from 'assets/img/Icons/flat/add.png'
import Delete from 'assets/img/Icons/flat/delete.png'

import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'

function DisplayFlats({ type, areaType, areaTypeId }) {
  console.log(type, "areaType")
  const [buildingId, setBuildingId] = useState('')
  const [id, setId] = useState('')
  const [limit, setLimit] = useState(10)
  const [modal, setModal] = useState(false)
  const [unitEdit, setUnitEdit] = useState('')
  const [floor, setFloor] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [parkingName, setParkingName] = useState('')
  const [parkingId, setParkingId] = useState('')
  const [parkingSlot, setParkingSlot] = useState('')
  const [parkingSlotId, setParkingSlotId] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')
  const [serachAreaName2, setSearchAreaName2] = useState('')
  const [searchparking, setSearchParking] = useState('')
  const [showParking, setShowParking] = useState(false)
  const [parkingEditSlotAdded, setEditParkingSlotsAdded] = useState([])
  const [parkingSlotsAdded, setPArkingSlotsAdded] = useState([])
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])
  const [options4, setOptions4] = useState([])
  const [options5, setOptions5] = useState([])
  const [options6, setOptions6] = useState([])
  const [parkingSlotName, setParkingSlotName] = useState('')
  const [submit, setSubmit] = useState(false)
  const [page, setPage] = useState(1)
  const [isParkingEdit, setIsParkingEdit] = useState(false)
  const [isActiveEdit, setisActiveEdit] = useState(false)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const updatedUnitFlat = useSelector(
    ({ updateUnitFlatList }) => updateUnitFlatList
  )
  const ParkingList = useSelector(({ getParking }) => getParking)

  const dispatch = useDispatch()

  const totalItems = getFlat.data.count
  // const pageSize = Math.floor(totalItems / limit)

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'buildingId') setBuildingId(value)
    if (name === 'floor') setFloor(value)
  }
  const handleEditChange = ({ target: { name, value } }) => {
    if (name === 'unitEdit') setUnitEdit(value)
  }
  const onChangeSwitch = (checked) => {
    setIsParkingEdit(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setisActiveEdit(checked)
  }

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setPage(1)
  }

  function onSearch(val) {
    setSearchAreaName(val)
  }
  function onChangeParking(value) {
    console.log(value, "valllll")
    let val = value.split(',')
    let parkingId = val[0]
    let parkingName = val[1].split('-')[0]
    setParkingName(parkingName)
    setParkingId(parkingId)
    setSearchAreaName2('')
    setParkingSlotId('')
    setParkingSlot('')
    setParkingSlotName('')
  }
  function onSearchParking(val) {
    setSearchAreaName2('')
  }
  function onChangeParkingSlot(value) {
    console.log(value, "value")
    let val = value.split(',')
    let parkingSlotId = val[0]
    let parkingSlot = val[1]
    setParkingSlotId(parkingSlotId)
    setParkingSlot(parkingSlot)
    setSearchAreaName2('')
  }
  function onSearchParkingSlot(val) {
    setParkingSlotName(val)
  }
  function toggleParking() {
    setShowParking(true)
  }

  function getParkingRequest() {
    const getParkingReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: parkingId,
      action: 'occupied',
      filter: 'visitor',
    }
    return dispatch(getParking(getParkingReq))
  }

  useEffect(() => {
    if (parkingId !== '') {
      getParkingRequest()
    }
  }, [parkingId])

  function addParkingSlot() {
    if (parkingSlotId && parkingName && parkingSlot) {
      const obj = {
        id: parkingSlotId,
        areaName: parkingName,
        name: parkingSlot,
      }
      setParkingName('')
      setParkingId('')
      setParkingSlot('')
      setParkingSlotId('')
      setPArkingSlotsAdded([...parkingSlotsAdded, obj])
      setEditParkingSlotsAdded([...parkingEditSlotAdded, obj])
    }
  }

  // function updateParkingSlotRequest() {
  //   const updateParkingSlotReq = {
  //     initiatedBy: login.data.user.role,
  //     societyId: login.data.society.id,
  //     unitId: id,
  //     areaId: parkingId,
  //     id: parkingSlotId,
  //   }
  //   return dispatch(addParking(updateParkingSlotReq))
  // }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (serachAreaName2 != '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName2,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        console.log(res.data,"res")
        setOptions3(res.data.areaListActive)
      })
    }
  }, [serachAreaName2])

  useEffect(() => {
    if (serachAreaName2 === '') {
      const userData = {
        areaType: 'parking',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        console.log(res, "ressss")
        setOptions4(res.data.areaListActive)
      })
    }
  }, [])

  useEffect(() => {
    if (parkingSlot !== '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        unitId: parkingId,
        name: parkingSlotName,
      }
      axios.post('society/getParkingSlot', userData).then((res) => {
        setOptions5(res.data.parkingSlotVOList)
      })
    }
  }, [parkingSlot])

  // useEffect(() => {
  //   if (serachAreaName != '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       // console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions(res.data.activeType)
  //     })
  //   }
  // }, [serachAreaName])

  // useEffect(() => {
  //   if (serachAreaName === '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       // areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       // console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions2(res.data.activeType)
  //     })
  //   }
  // }, [])



  const getUnitFlatListRequest = () => {
    const getUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      areaType: type,
      page,
      limit,
    }
    return dispatch(getUnitFlatList(getUnitFlatListReq))
  }

  const handleSubmit = (e) => {
    // setSubmit(!submit)
    e.preventDefault()
    getUnitFlatListRequest()
  }

  const toggle = () => {
    setModal(!modal)
    setParkingName('')
    setParkingSlot('')
    return () => (
      dispatch(resetGetParking())
    )
  }

  const openEditModal = (data) => {
    setFloor(data.floor)
    setModal(!modal)
    setUnitEdit(data.unit)
    setIsParkingEdit(data.isParking)
    setId(data.id)
    setisActiveEdit(data.isActive)
    setPArkingSlotsAdded(data.parking)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleUnitEditFlatList = (e) => {
    e.preventDefault()
    updateUnitFlatListRequest()

    // updateParkingSlotRequest()
  }

  const updateUnitFlatListRequest = () => {
    const updateUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      floor,
      id,
      unit: unitEdit,
      isParking: isParkingEdit,
      isActive: isActiveEdit,
      action: 'add',
      parkingSlot: parkingEditSlotAdded.map((d) => d.id),
    }
    return dispatch(updateUnitFLatList(updateUnitFlatListReq))
  }
  const removeParkingSlot = (parkingId) => {
    const updateUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      floor,
      id,
      unit: unitEdit,
      isParking: isParkingEdit,
      isActive: isActiveEdit,
      action: 'remove',
      parkingSlot: [parkingId],
    }
    return dispatch(updateUnitFLatList(updateUnitFlatListReq))
  }

  useEffect(() => {

  }, [])

  // useEffect(() => {
  //   if (serachAreaName != '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions(res.data.activeType)
  //     })
  //   }
  // }, [serachAreaName])

  // useEffect(() => {
  //   if (serachAreaName === '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       // areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       // console.log(res.data.activeType, 'res.data.activeType')
  //       setOptions2(res.data.activeType)
  //     })
  //   }
  // }, [])
  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  // useEffect(() => {
  //   if (serachAreaName === '') {
  //     const userData = {
  //       areaType: 'building',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       // areaName: serachAreaName,
  //     }
  //     axios.post('society/getAreaSearchList', userData).then((res) => {
  //       setOptions2(res.data.activeAreaList)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: type === "Shop" || type === "Bungalow" ? type : 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [type])

  useEffect(() => {
    const getUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      areaType: 'Flat',
      page,
      limit,
    }
    return dispatch(getUnitFlatList(getUnitFlatListReq))
  }, [page])

  useEffect(() => {
    // if (getFlat.failed) {
    //   NotificationPopups('error', 'Failed', getFlat.data.message)
    // }
    if (updatedUnitFlat.success) {
      setModal(false)
      setParkingId('')
      setParkingSlot('')
      setParkingName('')
      setParkingSlotId([])
      setEditParkingSlotsAdded([])
      getUnitFlatListRequest()
      // NotificationPopups('success', 'Success', updatedUnitFlat.data.message)
    }
    if (updatedUnitFlat.failed) {
      getUnitFlatListRequest()
      setParkingSlotId([])
      // NotificationPopups('error', 'Failed', updatedUnitFlat.data.message)
    }
    return () => {
      dispatch(resetUpdateUnitFlatList())
      dispatch(resetGetUnitFlatList())
      dispatch(resetGetParking())
    }
  }, [updatedUnitFlat.success, updatedUnitFlat.failed, updatedUnitFlat.network])

  console.log(options4,"options4")

  return (
    <>
      <div className="listcontent">
        <Card>
          <div style={{ padding: '25px' }}>
            <div className="typeHeading" id="onTop">{`${type}s`}</div>
            {options2 && (
              <Form onSubmit={handleSubmit}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col md="4" sm="12">
                    <FormGroup>
                      <label>Select Building name</label>
                      <Select
                        type="select"
                        value={bldgName}
                        showSearch
                        placeholder="Select Building name"
                        onChange={onChangeSearch}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="" >
                          Select Building name
                        </Option>
                        {serachAreaName != '' &&
                          options != undefined &&
                          options.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.areaName}
                            </Option>
                          ))}
                        {serachAreaName === '' &&
                          options2.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.areaName}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>

                  <Col md="4" sm="12">
                    <FormGroup>
                      <Button
                        className="cust-button"
                        style={{ marginTop: '22px' }}
                        onClick={handleSubmit}
                        disabled={getFlat.isLoading}
                      >
                        {getFlat.isLoading ? <Spin /> : 'Search'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
            {getFlat.success && (
              <Col md="12" style={{ padding: '20px' }}>
                <Table responsive style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>Flat Number</th>
                      <th>Floor</th>
                      <th>Parking</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {getFlat.success && (
                    <tbody>
                      {getFlat.data.activeUnitList.map((d, id) => (
                        <React.Fragment key={id}>
                          <tr key={id} id={`toggler${id}`}>
                            <td>
                              <span
                                style={{
                                  marginLeft: '20px',
                                  textTransform: 'capitalize',
                                  fontWeight: 'semi-bold',
                                  color: ' #00326c',
                                }}
                              >
                                {d.unit}
                              </span>
                            </td>
                            <td>{d.floor ? d.floor : 'G'}</td>
                            <td>
                              {d.isParking
                                ? d.parking.length === 0
                                  ? 'Available'
                                  : d.parking.map((x, idx) => <span>{x.name}{idx !== (d.parking.length - 1) ? "," : ''}</span>)
                                : 'Unavailable'}
                            </td>
                            <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                            <td>
                              <Button
                                color="secondary"
                                className="modalCustomButton"
                                onClick={() => {
                                  openEditModal(d)
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  )}
                </Table>
              </Col>
            )}
            <Col>
              {getFlat.success && !getFlat.data.activeUnitList.length && (
                <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                  <Alert message={getFlat.data.message} type="info" />
                </div>
              )}
              {getFlat.data.message === 'Mandatory Parameter Missing' ? null : getFlat.failed && (
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <Alert message={getFlat.data.message} type="info" />
                </div>
              )}
            </Col>
            <Col>
              {getFlat.success && (
                <PaginationComponent
                  limit={limit}
                  totalItems={totalItems}
                  onSelect={handlePagination}
                  defaultPage={page}
                />
              )}
            </Col>

            {modal && (
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                size="sm"
                style={{ maxWidth: '300px' }}
              >
                <ModalHeader toggle={toggle}>Edit</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="unit">Flat Number</Label>
                    <Input
                      type="text"
                      name="unitEdit"
                      id="unitEdit"
                      value={unitEdit}
                      onChange={handleEditChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="floor">Floor</Label>
                    <Input
                      type="text"
                      name="floor"
                      id="floor"
                      placeholder="floor"
                      value={floor || ''}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Parking</Label>
                    <Switch
                      defaultChecked={isParkingEdit}
                      name="isParking"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch}
                      style={{ marginLeft: '10px' }}
                    />
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <Label>Active</Label>
                    <Switch
                      defaultChecked={isActiveEdit}
                      name="isActive"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch2}
                      style={{ marginLeft: '10px' }}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Button
                      color="secondary"
                      style={{ float: 'left' }}
                      className="modalCustomButton"
                      onClick={() => toggleParking()}
                    >
                      Add Parking
                    </Button>
                  </FormGroup>
                  */}
                  {isParkingEdit &&
                    (options4 !== undefined ?
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label for="buildingId">Parking Area</Label>
                            <Select
                              type="select"
                              showSearch
                              value={parkingName}
                              placeholder="Select Parking name"
                              optionFilterProp="children"
                              onChange={onChangeParking}
                              onSearch={onSearchParking}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              style={{ width: '100%' }}
                            >
                              <Option className="loginSelect" value="">
                                Select Parking Area
                              </Option>
                              {serachAreaName != '' &&
                                options3 != undefined &&
                                options3.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                              {serachAreaName === '' &&
                                options4 != undefined &&
                                options4.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          {options5 != undefined ?
                            <FormGroup>
                              <Label for="buildingId">Parking Slot</Label>
                              <Select
                                type="select"
                                // mode="multiple"
                                showSearch
                                value={parkingSlot}
                                placeholder="Select Parking name"
                                optionFilterProp="children"
                                onChange={onChangeParkingSlot}
                                onSearch={onSearchParkingSlot}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                              >
                                <Option className="loginSelect" value="">
                                  Select Parking Slot
                                </Option>
                                {ParkingList.success &&
                                  ParkingList.data.parkingSlotVOList.map((d) => (
                                    <Option
                                      className="loginSelect"
                                      value={`${d.id},${d.name}`}
                                    >
                                      {d.name}
                                    </Option>
                                  ))}
                                {parkingSlotName != '' &&
                                  options5 != undefined &&
                                  options5.map((d, id) => (
                                    <Option
                                      key={id}
                                      className="loginSelect"
                                      value={`${d.id},${d.name}`}
                                    >
                                      {d.name}
                                    </Option>
                                  ))}
                              </Select>
                            </FormGroup> :
                            <Row style={{ justifyContent: 'center' }}>
                              <span style={{ color: "red" }}>Please add parking slots to assign parking.</span>
                            </Row>}
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Button
                              color="secondary"
                              className="modalCustomButton"
                              // style={{ float: 'left' }}
                              onClick={addParkingSlot}
                              disabled={
                                !parkingName || !parkingSlot || !parkingSlotId
                              }
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      :
                      <Row style={{ justifyContent: 'center' }}>
                        <span style={{ color: "red" }}>Please add parking slots to assign parking.</span>
                      </Row>)}
                  <Row>
                    <Col>
                      {parkingSlotsAdded.map((data, idx) => (
                        <div key={idx} style={{ padding: '5px' }}>
                          <span> {`${data.areaName} / ${data.name}`}</span>

                          <span style={{ marginLeft: '15px', cursor: "pointer" }}>
                            <img
                              src={TrashBtn}
                              alt="edit"
                              width="20px"
                              height="20px"
                              onClick={() => removeParkingSlot(data.id)}
                            />
                          </span>
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup style={{ width: '100%' }}>
                      <Button
                        color="secondary"
                        className="modalCustomButton"
                        style={{ marginLeft: '20px' }}
                        onClick={handleUnitEditFlatList}
                        disabled={updatedUnitFlat.isLoading}
                      >
                        {updatedUnitFlat.isLoading ? <Spin /> : 'Edit'}
                      </Button>
                      <Button
                        color="secondary"
                        style={{ float: 'right', marginRight: '20px' }}
                        className="modalCustomButton"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                    </FormGroup>
                  </Row>
                </ModalBody>
              </Modal>
            )}
          </div>
        </Card >
      </div >
    </>
  )
}

export default DisplayFlats
