// Redux Imports
import React, { Suspense } from 'react'
import { Provider, useSelector } from 'react-redux'
import { store, persistor } from 'redux/store'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/js/src/collapse.js'
import 'assets/scss/now-ui-dashboard.scss?v1.4.0'
import 'assets/css/demo.css'
// import 'antd/dist/antd.less'
// import 'antd/dist/antd.css'
import Loader from './components/Loader/Loading.js'
import Society from 'layouts/Society'


const AdminLayout = React.lazy(() => import('layouts/Admin.js'))
const Login = React.lazy(() => import('views/Login.js'))
const LoginSA = React.lazy(() => import('views/LoginSA.jsx'))

const hist = createBrowserHistory()

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router history={hist}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) =>
              store.getState().login.isAvailable &&
                store.getState().login.isSociety ? (
                // <AdminLayout {...props} />
                <Society {...props} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            exact
            path="/Superadmin/Signin"
            render={(props) =>
              store.getState().login.isAvailable &&
                store.getState().login.isSociety ? (
                <AdminLayout {...props} />
              ) : (
                <LoginSA />
              )
            }
          />
          <Route
            path="/Society"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route
            path="/Superadmin"
            render={(props) => <AdminLayout {...props} />}
          />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
