import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
// reactstrap components
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'

function GetComplainTableChartDashboard({
  counterValue,
  chartBtn,
  dataCounterBtn,
}) {
  console.log(dataCounterBtn)
  console.log(counterValue)

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col md="12">
            <Col md="12">
              {dataCounterBtn == 'monthly' ? (
                <Table
                  responsive
                  style={{ textAlign: 'center', fontSize: '12px' }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Pending</th>
                      <th>Rejected</th>
                      <th>Resolved</th>
                    </tr>
                  </thead>

                  <tbody>
                    {counterValue.map((d, id) => (
                      <React.Fragment key={id}>
                        <tr key={id} id={`toggler${id}`}>
                          <td>{`${moment(d.toDate).format('Do MMM,YYYY')} - ${moment(d.fromDate).format('Do MMM,YYYY')}`}</td>
                          <td>{d.pendingCount ? d.pendingCount : '0'}</td>
                          <td>{d.rejectedCount ? d.rejectedCount : '0'}</td>
                          <td>{d.resolvedCount ? d.resolvedCount : '0'}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              ) : dataCounterBtn == 'weekly' ? (
                <Table
                  responsive
                  style={{ textAlign: 'center', fontSize: '12px' }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Pending</th>
                      <th>Rejected</th>
                      <th>Resolved</th>
                    </tr>
                  </thead>
                  <tbody>
                    {counterValue.map((d, id) => (
                      <React.Fragment key={id}>
                        <tr key={id} id={`toggler${id}`}>
                          <td>{`${moment(d.fromDate).format('Do MMM,YYYY')}`}</td>
                          <td>{d.pendingCount ? d.pendingCount : '0'}</td>
                          <td>{d.rejectedCount ? d.rejectedCount : '0'}</td>
                          <td>{d.resolvedCount ? d.resolvedCount : '0'}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table
                  responsive
                  style={{ textAlign: 'center', fontSize: '12px' }}
                >
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Pending</th>
                      <th>Rejected</th>
                      <th>Resolved</th>
                    </tr>
                  </thead>
                  <tbody>
                    {counterValue.map((d, id) => (
                      <React.Fragment key={id}>
                        <tr key={id} id={`toggler${id}`}>
                          <td>
                            {d.month} - {d.year}
                          </td>
                          <td>{d.pendingCount ? d.pendingCount : '0'}</td>
                          <td>{d.rejectedCount ? d.rejectedCount : '0'}</td>
                          <td>{d.resolvedCount ? d.resolvedCount : '0'}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default GetComplainTableChartDashboard
