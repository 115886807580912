import React from 'react'

function AddEmailProvider() {
	return (
		<div>
			AddEmailProvider
		</div>
	)
}

export default AddEmailProvider
