import axios from "../../config/axios";
import {
	UPDATEDAILYHELPTYPE_SUCCESS,
	UPDATEDAILYHELPTYPE_FAILED,
	LOADING_UPDATEDAILYHELPTYPE,
	RESET_UPDATEDAILYHELPTYPE,
	UPDATEDAILYHELPTYPE_NETWORKERROR
	} from "../types";

const resetupdateDailyHelpType = () => {
    return {
        type: RESET_UPDATEDAILYHELPTYPE,		
    };
};

const updateDailyHelpType = userData => dispatch => {
    dispatch({ type: LOADING_UPDATEDAILYHELPTYPE });
		console.log(userData);
    axios
        .post("superAdmin/updateDailyHelperType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATEDAILYHELPTYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATEDAILYHELPTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATEDAILYHELPTYPE_FAILED,
        payload: data
    };
}

export { resetupdateDailyHelpType, updateDailyHelpType } ;