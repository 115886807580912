import axios from "../../config/axios"
import {
	ADDNOTICE_SUCCESS,
	ADDNOTICE_FAILED,
	LOADING_ADDNOTICE,
	RESET_ADDNOTICE,
    ADDNOTICE_NETWORKERROR
} from "../types";

const resetAddNotice = () => {
    return {
        type: RESET_ADDNOTICE
    };
};

const addNotice = userData => dispatch => {
    dispatch({ type: LOADING_ADDNOTICE });
		console.log(userData);
    axios
        .post("notice/addNotice", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADDNOTICE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: ADDNOTICE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADDNOTICE_FAILED,
        payload: data
    };
}

export { resetAddNotice, addNotice } ;