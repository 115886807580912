import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Select, Switch } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import { resetaddHelper, addHelper } from 'redux/actions/addHelperAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'
import {
  resetaddHelperDoc,
  addHelperDoc,
} from 'redux/actions/addHelperDocAction'
import {
  resetgetHelperDoc,
  getHelperDoc,
} from 'redux/actions/getHelperDocAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import axios from 'config/axios'

function SearchHelperDoc() {
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [documentType, setDocumentType] = useState('')
  const [isMandatory, setIsMandatory] = useState(false)
  const [helperType, setHelperType] = useState()
  const [serviceType, setServiceType] = useState('')
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [seacrhDocumentType, setSearchDocumentType] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsDocumentType, setOptionsDocumentType] = useState('')
  const [optionsServType, setOptionsServType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  const login = useSelector(({ login }) => login)
  const docType = useSelector(({ getDocument }) => getDocument)
  const helperdocType = useSelector(({ getHelperDoc }) => getHelperDoc)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const addedHelperDoc = useSelector(({ addHelperDoc }) => addHelperDoc)

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'serviceTypeId') setServiceTypeId(value)
    if (name === 'helperType') setHelperType(value)
  }
  function onChange(checked) {
    setIsMandatory(checked)
  }
  const { Option } = Select
  function onChangeDocumentType(value) {
    setDocumentType(value)
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }

  function onChangeSearchServType(value) {
    let serverType = value.split(',')
    let serverType1 = serverType[0]
    let serverType2 = serverType[1]
    setServiceTypeId(serverType1)
    setServiceType(serverType2)
    setHelperType('')
    setSearchServType('')
  }

  function onSearchServType(val) {
    setSearchServType(val)
  }

  function onChangeSearchHelperType(value) {
    if (value === '') {
      setHelperType('')
    } else {
      setHelperType(value)
      setSearchHelperType('')
    }
  }

  function onSearchHelperType(val) {
    setSearchHelperType(val)
  }
  //search of area type

  //search of flat

  //search service type
  useEffect(() => {
    if (searchServType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchServType,
      }
      axios.post('helper/getServiceType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsServType(res.data.activeType)
        }
      })
    }
  }, [searchServType])

  const getDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [])
  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType, serviceTypeId])

  useEffect(() => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }, [])

  useEffect(() => {
    if (login.data.user.role === 'Society' && serviceTypeId != '') {
      setHelperType('')
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
      }

      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [serviceTypeId])

  const handleSubmit = (e) => {
    e.preventDefault()
    getHelperDocTypeRequest()
  }

  const getHelperDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        helperTypeId: helperType,
        serviceType,
        documentTypeId: documentType,
        limit: 10,
      }
      return dispatch(getHelperDoc(docTypeReq))
    }
  }

  const disabledButton = () => {
    return !(serviceTypeId && helperType)
  }

  // console.log(servType)
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4" sm="12" xs="12" lg="6">
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="" >
                  Select Document Type
                </Option>
                {docType.success &&
                  docType.data.activeType.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.type}
                    </Option>
                  ))}
                {docType.failed && (
                  <Option className="loginSelect" value="" >
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" xs="12" lg="6">
            <FormGroup>
              <Label for="helperType">Service Type</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                value={serviceType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeSearchServType}
                onSearch={onSearchServType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="" >
                  Select Service Type
                </Option>

                {searchServType != '' && optionsServType != undefined
                  ? optionsServType.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id}, ${d.type}`}
                      >
                        {d.type}
                      </Option>
                    ))
                  : servType.success &&
                    servType.data.activeType.map((d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id}, ${d.type}`}
                      >
                        {d.type}
                      </Option>
                    ))}
                {servType.failed && (
                  <Option className="loginSelect" value="">
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>
          <Col md="4" sm="12" xs="12" lg="6">
            <FormGroup>
              <Label for="helperType">Helper Type</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                // mode="multiple"
                // defaultValue={serviceTypeId ? helperType : ''}
                value={helperType === '' ? setHelperType() : helperType}
                showSearch
                placeholder="Select Helper Type"
                optionFilterProp="children"
                onChange={onChangeSearchHelperType}
                onSearch={onSearchHelperType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="" >
                  Select Helper Type
                </Option>
                {searchHelperType != '' && optionsHelperType != undefined
                  ? optionsHelperType.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.type}
                      </Option>
                    ))
                  : helperTypeList.success &&
                    helperTypeList.data.helperTypeList.map((d, id) => (
                      <Option key={id} className="loginSelect" value={d.id}>
                        {d.type}
                      </Option>
                    ))}
              </Select>
            </FormGroup>
          </Col>
          {/* <Col md="4" sm="12" xs="12" lg="6">
            <FormGroup>
              <Label>Is Mandatory</Label>
              <br />
              <Switch
                defaultChecked={isMandatory}
                name="isActive"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChange}
                // style={{ width: '100%' }}
              />
            </FormGroup>
          </Col> */}
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={disabledButton()}
          >
            {helperdocType.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchHelperDoc
