import axios from '../../config/axios'
import {
  SINGLE_MNT_INVOICE_SUCCESS,
  SINGLE_MNT_INVOICE_FAILED,
  NETWORK_SINGLE_MNT_INVOICE,
  LOADING_SINGLE_MNT_INVOICE,
  RESET_SINGLE_MNT_INVOICE,
} from '../types'

const resetSingleInvoice = () => {
  return {
    type: RESET_SINGLE_MNT_INVOICE,
  }
}

const singleInvoice = (userData) => (dispatch) => {
  dispatch({ type: LOADING_SINGLE_MNT_INVOICE })
  axios
    .post('mnt/addInvoice', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: NETWORK_SINGLE_MNT_INVOICE, payload: err })
    )
}

function success(data) {
  return {
    type: SINGLE_MNT_INVOICE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: SINGLE_MNT_INVOICE_FAILED,
    payload: data,
  }
}

export { resetSingleInvoice, singleInvoice }
