import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Upload, message } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { addGuard, resetAddGuard } from 'redux/actions/addGuardAction'
import Util from 'validations/index'
import { UploadOutlined } from '@ant-design/icons'
import { getGuardList, resetGuardList } from 'redux/actions/getGuardListAction'

function SearchGuard(props) {
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [helperType, setHelperType] = useState('')
  const [guardName, setGuardName] = useState('')
  const [guardMobile, setGuardMobile] = useState('')
  const [guardPhoto, setGuardPhoto] = useState('')
  const [guardPin, setGuardPin] = useState('')
  //   const [serviceTypeId, setServiceTypeId] = useState('')
  const login = useSelector(({ login }) => login)
  const guardList = useSelector(({ getGuardList }) => getGuardList)

  const dispatch = useDispatch()
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'guardName')
    Util.alphabetsOnly(value) ? setGuardName(value) : setGuardName(guardName)
    if (name === 'guardMobile')
      Util.verifyMobile(value) ? setGuardMobile(value) : setGuardMobile(guardMobile)
    if (name === 'guardPin')
      Util.verifyMobile(value) ? setGuardPin(value) : setGuardPin('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.setIsSearch()
    getGuardListRequest()
    const obj = {
      name: guardName,
      mobileNumber: guardMobile,
      guardPin: guardPin,
    }
    send(obj)
    return(
      setGuardPin('')
    )
  }
  const getGuardListRequest = () => {
    if (initiatedBy === 'Society') {
      const getGuardListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        name: guardName.trim(),
        guardPin,
        limit: 6,
      }
      const getGuardListMobileReq  = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        name: guardName.trim(),
        mobileNumber: guardMobile,
        guardPin,
        limit: 6,
      }
      return dispatch(getGuardList(guardMobile ? getGuardListMobileReq:getGuardListReq))
    }
  }

  const disabledButton = () => {
    return !(guardName && guardMobile && guardPhoto)
  }
  const send = (value) => {
    props.searchFilter(value)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="3" sm="12" xs="12" lg="4">
            <FormGroup>
              <Label for="helperName">Name</Label>
              <Input
                type="text"
                name="guardName"
                id="guardName"
                placeholder="Name"
                value={guardName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" xs="12" lg="4">
            <FormGroup>
              <Label for="helperMobile">Mobile</Label>
              <Input
                type="text"
                name="guardMobile"
                id="guardMobile"
                placeholder="Mobile"
                maxLength="10"
                value={guardMobile || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" xs="12" lg="4">
            <FormGroup>
              <Label for="helperName">Passcode</Label>
              <Input
                type="text"
                name="guardPin"
                id="guardName"
                placeholder="Passcode"
                maxLength="6"
                value={guardPin || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button className="cust-button" onClick={handleSubmit}>
            {guardList.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchGuard
