import {
  GET_SOCIETY_ADMIN_SUCCESS,
  GET_SOCIETY_ADMIN_FAILED,
  GET_SOCIETY_ADMIN_NETWORK_ERROR,
  LOADING_GET_SOCIETY_ADMIN,
  RESET_GET_SOCIETY_ADMIN,
} from 'redux/types'
import axios from '../../config/axios'

const resetGetSocietyAdmin = () => {
  return {
    type: RESET_GET_SOCIETY_ADMIN,
  }
}

const getSocietyAdmin = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_SOCIETY_ADMIN })
  axios
    .post('society/getSocietyAdmin', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_SOCIETY_ADMIN_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_SOCIETY_ADMIN_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_SOCIETY_ADMIN_FAILED,
    payload: data,
  }
}

export { resetGetSocietyAdmin, getSocietyAdmin }
