import axios from '../../config/axios'
import {
  UPDATECOMPANY_SUCCESS,
  UPDATECOMPANY_FAILED,
  LOADING_UPDATECOMPANY,
  RESET_UPDATECOMPANY,
  UPDATECOMPANY_NETWORKERROR,
} from '../types'

const resetupdateCompany = () => {
  return {
    type: RESET_UPDATECOMPANY,
  }
}

const updateCompany = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATECOMPANY })
  console.log(userData)
  axios
    .post('household/updateCompany', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATECOMPANY_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATECOMPANY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATECOMPANY_FAILED,
    payload: data,
  }
}

export { resetupdateCompany, updateCompany }
