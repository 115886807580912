import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import randomColor from 'randomcolor'
// import 'react-calendar/dist/Calendar.css'
import {
  Tooltip,
  Popconfirm,
  Alert,
  Rate,
  Spin,
  Switch,
  Select,
  List,
  Avatar,
  Skeleton,
  Collapse,
} from 'antd'
import {
  resetgetHelperDetails,
  getHelperDetails,
} from 'redux/actions/getHelperDetailsAction'
import {
  resetGetHelperDetail,
  getHelperDetail,
} from 'redux/actions/getHelperDetailAction'
import {
  resetGetHelperAttendance,
  getHelperAttendance,
} from 'redux/actions/getHelperAttendanceAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Calendar from 'react-calendar'
// import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
// import 'react-datepicker/dist/react-datepicker.css'
import PaginationComponent from 'views/Pagination'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetUpdateHelper,
  updateHelper,
} from 'redux/actions/updateHelperAction'

import AddHelperDetail from './AddHelper'
import ViewDetails from './ViewDetails'
import DisplayAttachment from 'components/Custom/DisplayAttachment'

function HelperList({data,onTap}) {
  console.log(data,"d")
  const [editmodal, seteditmodal] = useState(false)
  const [CalDate, setCalDate] = useState(new Date())
  const [modal, setModal] = useState(false)
  const [id, setId] = useState('')
  const [details, setdetails] = useState('')
  const [helperName, sethelperName] = useState('')
  const [helperMobile, sethelperMobile] = useState()
  const [helperPhoto, sethelperPhoto] = useState('')
  const [helperId, setHelperId] = useState('')
  // const [isActive, setIsActive] = useState(false)
  const [isActive, setisActive] = useState(false)
  const [mark, setMark] = useState([])
  const [errMessage, seterrMessage] = useState('')

  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState('')
  const [isActiveDate, setIsActiveDate] = useState('')
  const date = new Date()
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())
  const [viewDetails, setViewDetails] = useState(false)
  const [editDetails, setEditDetails] = useState(false)
  //Filter
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [helperType, setHelperType] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsAreaType, setOptionsAreaType] = useState([''])
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [optionsServType, setOptionsServType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)
  const updateHelp = useSelector(({ updateHelper }) => updateHelper)
  const addedHelper = useSelector(({ addHelper }) => addHelper)
  const helperDetails = useSelector(({ getHelperDetails }) => getHelperDetails)
  const helperDetail = useSelector(({ getHelperDetail }) => getHelperDetail)
  const getPendingMembers = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const attendance = useSelector(
    ({ getHelperAttendance }) => getHelperAttendance
  )

  //filter
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)

  const [initiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()

  const totalItems = helperDetails.data.count

  const getHelperRequest = () => {
    if (initiatedBy === 'Society') {
      const getHelperReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        limit,
        page,
      }
      return dispatch(getHelperDetails(getHelperReq))
    }
  }

  useEffect(() =>{
    setPage(1)
  },[onTap])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  const toggleFileModal = (data) => {
    setFileModal(!fileModal)
    setAttachment(data)
  }

  const openEditModal = (data) => {
    setdetails(data)
    setHelperId(data.id)
    setViewDetails(!viewDetails)
  }
  useEffect(() => {
    if (initiatedBy === 'Society') {
      const getHelperReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        serviceTypeId: data.serviceTypeId,
        helperTypeId: data.helperTypeId,
        helperName: data.helperName,
        helperMobile: data.helperMobile,
        limit: 6,
        page,
      }
      return dispatch(getHelperDetails(getHelperReq))
    }
  }, [page])

  useEffect(() => {
    setMonth(moment(isActiveDate.activeStartDate).format('MM'))
    setYear(moment(isActiveDate.activeStartDate).format('YYYY'))
  }, [isActiveDate])

  useEffect(() => {
    if (attendance.success) {
      const attend = attendance.data.dateList.map((d) =>
        moment(d).format('DD-MM-YYYY')
      )
      setMark(attend)
    }
    if (attendance.failed) {
      setMark([])
    }
    return () => {
      resetGetHelperAttendance()
    }
  }, [attendance.success, attendance.failed])

  useEffect(() => {
    if (addedHelper.success && login.data.user.role === 'Society')
      getHelperRequest()
  }, [addedHelper.success])

  useEffect(() => {
    if (initiatedBy === 'Society' && helperId != '') {
      const getDetailsReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperId,
      }
      return dispatch(getHelperDetail(getDetailsReq))
    }
  }, [helperId])

  useEffect(() => {
    if (initiatedBy === 'Society' && helperId != '') {
      const getHelperAttendanceReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        helperId,
        month,
        year,
      }
      return dispatch(getHelperAttendance(getHelperAttendanceReq))
    }
  }, [helperId, year, month])

  // const handleHelperEdit = (e) => {
  //   e.preventDefault()

  //   if (updateHelp.success) {
  //     NotificationPopups('success', 'Success', updateHelp.data.message)
  //   }
  //   setEditDetails(!editDetails)
  // }

  const toggleDetails = () => {
    setModal(!modal)
  }
  const visitorDetailsToggle = () => {
    setViewDetails(!viewDetails)
  }

  useEffect(() => {
    if (addedHelper.success) {
      setModal(false)
      setViewDetails(false)
    }
    if (updateHelp.success) {
      setdetails('')
      getHelperRequest()
      NotificationPopups('success', 'Success', updateHelp.data.message)
    }

    return () => {
      resetUpdateHelper()
    }
  }, [addedHelper.success, updateHelp.success, updateHelp.failed])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setModal(!modal)
                    }}
                  >
                    Add Helpers
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    Helper
                  </div>
                </Col>
              </div>

              {helperDetails.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Passcode</th>

                        <th>Category</th>
                        <th>Added by</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {helperDetails.success && (
                      <tbody>
                        {helperDetails.data.activeHelperDetail.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td onClick={() => toggleFileModal(d.photo)} style={{cursor:"pointer"}}>
                                <img
                                  src={`${Util.imageUrl}${d.photo}`}
                                  alt="icon"
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                  }}
                                />
                              </td>
                              <td>{d.helperName}</td>
                              <td>{d.helperMobile}</td>
                              <td>{d.passCode}</td>
                              {/* <td>{d.serviceType}</td> */}
                              <td>{d.helperType}</td>
                              <td style={{ textTransform: 'capitalize' }}>
                                {d.addedBy}
                              </td>
                              <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    // setViewDetails(!viewDetails)
                                    openEditModal(d)
                                  }}
                                >
                                  View Details
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}
              {helperDetails.success &&
                !helperDetails.data.activeHelperDetail.length && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message="No Active Helpers " type="info" style={{padding: '20px 0px'}}/>
                  </div>
                )}

              <Col md="12">
                {helperDetails.failed && (
                  <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Alert message={helperDetails.data.message} type="info" />
                  </div>
                )}
              </Col>
              <Col>
                {helperDetails.success && (
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {viewDetails && (
        <Modal
          isOpen={viewDetails}
          toggle={visitorDetailsToggle}
          size="lg"
          style={{ maxWidth: '800px', maxHeight: '600px' }}
        >
          <ModalHeader toggle={visitorDetailsToggle}>
            Helper Details
          </ModalHeader>
          <ModalBody>
            <ViewDetails helperId={helperId} details={details} />
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleDetails}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggleDetails}>Add Helper</ModalHeader>
          <ModalBody>
            <AddHelperDetail />
          </ModalBody>
        </Modal>
      )}

      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${attachment}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )}
    </Row>
  )
}

export default HelperList
