import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select, Divider } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetUpdateUnitFlatList,
  updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'

import PaginationComponent from 'views/Pagination'
import { resetGetParking, getParking } from 'redux/actions/getParkingAction'
import {
  resetUpdateParking,
  updateParking,
} from 'redux/actions/updateParkingSlotAction'
import { resetAddParking, addParking } from 'redux/actions/addParkingAction'
import axios from 'config/axios'
import { getBuildingList } from 'redux/actions/getBuildingListAction'
import Util from 'validations'
import Assign from '../SocietyDetails/AssignButton'

function DisplayParkingSlot({ areaType, areaTypeId }) {
  const [prop, setProp] = useState({})
  const [buildingSlotAdd, setBuildingSlotsAdd] = useState()
  const [parkId, setParkId] = useState('')
  const [buildId, setBuildId] = useState('')
  const [id, setId] = useState('')
  const [limit, setLimit] = useState(30)
  const [modal, setModal] = useState(false)
  const [assignedModal, setAssignedModal] = useState(false)
  const [isVisitor, setIsVisitor] = useState(false)
  const [assigned, setAssigned] = useState(false)
  const [visitor, setVisitor] = useState('')
  const [isOccupied, setIsOccupied] = useState(false)
  const [Occupied, setOccupied] = useState(false)
  const [Name, setName] = useState('')
  const [unitMapping, SetUnitMapping] = useState('')
  const [buildingName, setBuildingName] = useState('')
  const [parkingName, setParkingName] = useState('')
  const [header, setHeader] = useState('')
  const [parkingId, setParkingId] = useState('')
  const [parkingSlot, setParkingSlot] = useState('')
  const [parkingSlotId, setParkingSlotId] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')
  const [serachAreaName2, setSearchAreaName2] = useState('')
  const [serachBuildName, setSearchBuildName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [buildOptions, setBuildOptions] = useState([])
  const [buildOptions2, setBuildOptions2] = useState([])
  const [parkingAreaList1, setParkingAreaList1] = useState([])
  const [parkingAreaList2, setParkingAreaList2] = useState([])
  const [submit, setSubmit] = useState(false)
  const [page, setPage] = useState(1)
  const [isActiveEdit, setisActiveEdit] = useState(false)
  const [areaBldType, setAreaBldType] = useState('Building')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [searchFlat, setSearchFlat] = useState('')
  const [boolean, setBoolean] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [flatNo, setFlatNo] = useState('')
  const [parkingNumber, setParkingNumber] = useState('')
  const [datas, setDatas] = useState([])
  const [parkingArea, setParkArea] = useState([])
  const [areaID, setAreaID] = useState()
  const [unitIDMapping,setUnitIDMapping] = useState()
  const [unitNameMapping , setUnitNameMapping] = useState()

  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  // const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)

  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const login = useSelector(({ login }) => login)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const updatedUnitFlat = useSelector(
    ({ updateUnitFlatList }) => updateUnitFlatList
  )
  const updatedParkingSlot = useSelector(
    ({ updateParkingSlot }) => updateParkingSlot
  )

  const ParkingList = useSelector(({ getParking }) => getParking)

  const dispatch = useDispatch()

  function building(prop) {
    setBuildingSlotsAdd(prop)
  }

  const totalItems = ParkingList.data.count

  const handleEditChange = ({ target: { name, value } }) => {
    if (name === 'name')
      Util.alphaNumeric(value) ? setName(value) : setName(Name)

    if (name === 'unitMapping')
      Util.alphaNumeric(value) ? SetUnitMapping(value) : SetUnitMapping(unitMapping)
  }
  const onChangeSwitch = (checked) => {
    setIsOccupied(checked)
  }
  const onChangeSwitch3 = (checked) => {
    setIsVisitor(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setisActiveEdit(checked)
  }

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setParkId(val1)
    setParkingName(val2)
    setSearchFlat('')
    setPage(1)
  }


  function onSearch(val) {
    setSearchFlat(val)
  }

  function onChangeVistor(val) {
    setVisitor(val)
  }

  function getParkingRequest() {

    const getvisitorReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      filter: "Visitor",
      isVisitor: visitor,
      areaId: parkId,
      name: parkingNumber,
      unitNameMapping: flatNo,
      limit,
      page,
    }
    const getParkingReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      isVisitor: visitor,
      areaId: parkId,
      name: parkingNumber,
      unitNameMapping: flatNo,
      limit,
      page,
    }
    return dispatch(getParking(visitor === "" ? getParkingReq : getvisitorReq))
  }



  // useEffect(()=>{
  //   setFlatName()
  // },[flatNo])

  // useEffect(() => {
  //   if (serachAreaName != '') {
  //     const userData = {
  //       areaType: 'parking',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       areaName: serachAreaName,
  //       isActive: true,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       setOptions(res.data.activeAreaList)
  //     })
  //   }
  // }, [serachAreaName])

  // useEffect(() => {
  //   if (boolean === true) {
  //     const userData = {
  //       areaType: 'parking',
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       isActive: true,
  //     }
  //     axios.post('society/getAreaList', userData).then((res) => {
  //       setOptions2(res.data.activeAreaList)
  //     })
  //   }
  // }, [boolean])

  const handleSubmit = (e) => {
    setSubmit(true)
    e.preventDefault()
    getParkingRequest()
    setIsSearch(!isSearch)
    setHeader(parkingName)
  }


  const [graphicalView, setGraphicalView] = useState(false);

  const handleView = (e) => {
    e.preventDefault()
    setGraphicalView(!graphicalView)
    if (graphicalView === true) {
      setLimit(30)
      setPage(1)
    } else {
      setLimit(10)
      setPage(1)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  const assignedtoggle = () => {
    setAssignedModal(false)
  }


  const openEditModal = (data) => {
    setName(data.name)
    setModal(!modal)
    setAssigned(data.unitIdMapping !== undefined ? true : false)
    setOccupied(data.isVisitor === true ? false : true)
    setId(data.id)
    setisActiveEdit(data.isActive)
    setIsVisitor(data.isVisitor)
    setIsOccupied(data.isOccupied)
    setAreaID(data.areaId)
    setUnitIDMapping(data.unitIdMapping)
    setUnitNameMapping(data.unitNameMapping)
  }

  const openAssignedModal = (data, prop) => {
    setId(data.id)
    setAreaID(data.areaID)
    setName(data.name)
    setProp(data)
    setAssignedModal(true)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop1')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleUnitEditFlatList = (e) => {
    e.preventDefault()
    updateUnitFlatListRequest()
  }

  const updateUnitFlatListRequest = () => {
    const updateUnitFlatListReq = {
      initiatedBy: login.data.user.role,
      id,
      name: Name,
      isActive: isActiveEdit,
      isVisitor: isVisitor === true ? true : false,
      isOccupied: isOccupied !== true ? false : isVisitor,
      areaId: areaID,
      unitIdMapping: unitIDMapping,
      unitNameMapping: unitNameMapping
    }
    return dispatch(updateParking(updateUnitFlatListReq))
  }

  useEffect(() => {
    getParkingRequest()
  }, [id, parkingId, page, limit])

  useEffect(() => {
    if (ParkingList.success) {
      setParkingNumber('')
      setFlatNo('')
      setVisitor('')
    }
  }, [ParkingList.success])


  useEffect(() => {
    if (updatedParkingSlot.success) {
      setModal(false)
      setAssignedModal(false)
      setParkingId('')
      setParkingSlot('')
      setParkingSlotId([])
      getParkingRequest()
      NotificationPopups('success', 'Success', updatedParkingSlot.data.message)
    }
    if (updatedParkingSlot.failed) {
      setParkingSlotId([])
      NotificationPopups('error', 'Failed', updatedParkingSlot.data.message)
    }
    return () => {
      dispatch(resetUpdateParking())
    }
  }, [
    updatedParkingSlot.success,
    updatedParkingSlot.failed,
    updatedParkingSlot.network,
  ])

  useEffect(() => {
    if (updatedUnitFlat.success) {
      getParkingRequest()
      // NotificationPopups('success', 'Success', updatedUnitFlat.data.message)
    }
    if (updatedUnitFlat.failed) {
      getParkingRequest()
      // NotificationPopups('error', 'Failed', updatedUnitFlat.data.message)
    }
    return () => {
      dispatch(resetUpdateUnitFlatList())
    }
  }, [updatedUnitFlat.success, updatedUnitFlat.failed])

  // Building Dropdown

  function onChangeSearchBld(value) {
    setBoolean(false)
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildId(val1)
    setBuildingName(val2)
    setParkingName('')
    setParkId('')
    setSearchBuildName('')
    setPage(1)
    // }
  }

  function onSearchBld(val) {
    setSearchBuildName(val)
  }

  // useEffect(() => {
  //   const getUnitFlatListReq = {
  //     initiatedBy: login.data.user.role,
  //     societyId: login.data.society.id,
  //     areaId: buildId,
  //     areaType: 'Parking',
  //   }
  //   return dispatch(getUnitFlatList(getUnitFlatListReq))

  // }, [buildId])

  // useEffect(() => {
  //   if (areaBldType === 'Building' && buildId && searchFlat !== "") {
  //     const userData = {
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       areaId: buildId,
  //       areaType: 'Parking',
  //       unit: searchFlat,
  //     }
  //     axios.post('society/getUnitList', userData).then((res) => {
  //       setOptionsUnit(res.data.activeUnitList)
  //     })
  //   }
  // }, [searchFlat])

  useEffect(() => {
      if (serachBuildName != '') {
        const userData = {
          areaType: 'building',
          initiatedBy: login.data.user.role,
          societyId: login.data.society.id,
          areaName: serachBuildName,
        }
        axios.post('society/getAreaSearchList', userData).then((res) => {
          setBuildOptions(res.data.activeAreaList)
        })
      } else if (serachBuildName === '') {
        const userData = {
          areaType: 'building',
          initiatedBy: login.data.user.role,
          societyId: login.data.society.id,
        }
        axios.post('society/getAreaSearchList', userData).then((res) => {
          setBuildOptions2(res.data.activeAreaList)
        })
      }
  }, [serachBuildName])

  useEffect(() => {
    if (buildId === "") {
      if (searchFlat !== '') {
        const userData = {
          areaType: "Parking",
          areaName: searchFlat,
          initiatedBy: login.data.user.role,
          societyId: login.data.society.id,
        }
        axios.post('society/getAreaSearchList', userData).then((res) => {
          setParkingAreaList1(res.data.activeAreaList)
        })
      }
    }
    else if (searchFlat === '') {
      const userData = {
        areaType: "Parking",
        parentId: buildId,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setParkingAreaList2(res.data.activeAreaList)
      })
    }


  }, [buildId])

  useEffect(() => {
    setPage(1)
  }, [isSearch])

  useEffect(() => {
    if (buildingName === "" || parkingName === "") {
      setSubmit(false)
    }
    if (buildingName === "" || parkingName === undefined) {
      setSubmit(false)
    }
  }, [buildingName, parkingName])

  function handleChange({ target: { name, value, max } }) {
    if (name === 'flatNo') {
      setFlatNo(value)
    }
    if (name === 'parkingNumber') {
      setParkingNumber(value)
    }
  }

  const [hide, setHide] = useState(true)

  useEffect(() => {
    if (buildingName === "") {
      setHide(true)
    }
    else if (buildingName === undefined) {
      setHide(true)
    }
    else {
      setHide(false)
    }
  })


  useEffect(() => {
    ParkingList.success &&
      ParkingList.data.parkingSlotVOList.map((d, id) => (
        datas.push(d.areaName)
      ))
  }, [datas])

  function removeDuplicates(datas) {
    return [...new Set(datas)];
  }

  useEffect(() => {
    setParkArea(removeDuplicates(datas))
  }, [datas])


  return (
    <>
      <div className="listcontent">
        <Card>
          <div style={{ padding: '25px' }}>
            <Row>
              <Col md='10' id="onTop1">
                <div className="typeHeading">
                  Parking Slots
                </div>
              </Col>
              <Col md='2'>
                <p
                  className='switchTable'
                  onClick={handleView}
                >
                  {graphicalView ? 'Switch Map' : 'Switch Table View'}
                </p>
              </Col>
            </Row>

            {options2 && (
              <Form onSubmit={handleSubmit}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col md="2" sm="12" style={{ paddingLeft: "0px", marginRight: "25px" }}>
                    <FormGroup>
                      <Label for="city">Building Name</Label>
                      <span className='required'>*</span>
                      <Select
                        type="select"
                        value={buildingName}
                        showSearch
                        placeholder="Select Building name"
                        optionFilterProp="children"
                        onChange={onChangeSearchBld}
                        onSearch={onSearchBld}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Building name
                        </Option>
                        {serachBuildName != '' &&
                          buildOptions != undefined &&
                          buildOptions.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.areaName}
                            </Option>
                          ))}
                        {serachBuildName === '' &&
                          buildOptions2.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={`${d.areaId},${d.areaName}`}
                            >
                              {d.areaName}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  {
                    areaBldType === "Building" && (
                      <Col md="2" sm="12" style={{ marginRight: "25px" }}>
                        <FormGroup>
                          <label>Select Parking Area</label>
                          <span className='required'>*</span>
                          <Select
                            type="select"
                            value={parkingName}
                            showSearch
                            placeholder="Select Parking Area"
                            onChange={onChangeSearch}
                            onSearch={onSearch}
                            style={{ width: '100%' }}
                          >
                            <Option className="loginSelect" value="">
                              Select Parking Area
                            </Option>
                            {searchFlat != '' &&
                              parkingAreaList1 != undefined &&
                              parkingAreaList1.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={`${d.areaId},${d.areaName}`}
                                >
                                  {d.areaName}
                                </Option>
                              ))}
                            {serachBuildName === '' &&
                              parkingAreaList2 != undefined &&
                              parkingAreaList2.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={`${d.areaId},${d.areaName}`}
                                >
                                  {d.areaName}
                                </Option>
                              ))}
                          </Select>
                        </FormGroup>
                      </Col>
                    )
                  }

                  <Col lg="2" md="2" sm="12" xs="12" style={{ marginRight: "25px" }}>
                    <FormGroup>
                      <Label for="parkingNumber">Parking No</Label>
                      <Input
                        disabled={hide}
                        type="text"
                        name="parkingNumber"
                        id="parkingNumber"
                        placeholder="Parking Number"
                        value={parkingNumber || ''}
                        onChange={handleChange}
                        max="99"
                        style={{ padding: '8px', borderRadius: "6px" }}
                      />
                    </FormGroup>
                  </Col>

                  {areaBldType === "Building" && (
                    <Col lg="2" md="2" sm="12" xs="12" style={{ marginRight: "25px" }}>
                      <FormGroup>
                        <Label for="flatNo">Flat No</Label>
                        <Input
                          disabled={hide}
                          type="text"
                          name="flatNo"
                          id="flatNo"
                          placeholder="Flat No"
                          value={flatNo || ''}
                          onChange={handleChange}
                          max="99"
                          style={{ padding: '8px', borderRadius: "6px" }}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col lg="2" md="2" sm="12" xs="12">
                    <FormGroup>
                      <Label for="isVisitor">IsVisitor</Label>
                      <Select
                        type="select"
                        value={visitor}
                        showSearch
                        placeholder="Select IsVisitor"
                        onChange={onChangeVistor}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select IsVisitor
                        </Option>
                        <Option value="true">
                          Yes
                        </Option>
                        <Option value="false">
                          No
                        </Option>
                      </Select>
                    </FormGroup>
                  </Col>

                  <Col md="3" sm="12" style={{ textAlign: 'center' }}>
                    <FormGroup>
                      <Button
                        className="cust-button"
                        style={{ marginTop: '22px' }}
                        onClick={handleSubmit}
                      // disabled={getFlat.isLoading || buildingName === "" || parkingName === "" || parkingName === undefined}
                      >
                        {/* {getFlat.isLoading ? <Spin /> : 'Search'} */}
                        Search
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}

            {graphicalView ?
              submit ?
                <>
                  {ParkingList.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Parking Number</th>
                            <th>Floor</th>
                            <th>Status</th>
                            <th>Occupied</th>
                            <th>Assigned To</th>
                            <th>Visitor Parking</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        {ParkingList.success && (
                          <tbody>
                            {ParkingList.success &&
                              ParkingList.data.parkingSlotVOList.map((d, id) => (
                                <React.Fragment key={id}>
                                  <tr key={id} id={`toggler${id}`}>
                                    <td>{d.name}</td>
                                    <td>{d.floor === undefined ? "G" : d.floor}</td>
                                    <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                    <td>{d.isOccupied ? 'Yes' : 'No'}</td>
                                    <td>{d.buildingName ? `${d.buildingName} - ${d.unitName}` : '-'}</td>
                                    <td>{d.isVisitor ? 'Yes' : 'No'}</td>
                                    <td>

                                      <Button
                                        disabled={d.isVisitor === true ? true : false}
                                        color="secondary"
                                        className="modalCustomButton"
                                        onClick={() => {
                                          openAssignedModal(d)
                                        }}
                                      >
                                        Assign
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        color="secondary"
                                        className="modalCustomButton"
                                        onClick={() => {
                                          openEditModal(d)
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                  <Col>
                    {ParkingList.failed && (
                      <div style={{ textAlign: 'center' }}>
                        <Alert message={ParkingList.data.message} type="info" />
                      </div>
                    )}
                  </Col>
                  <Col>
                    {ParkingList.success && (
                      <PaginationComponent
                        limit={limit}
                        totalItems={totalItems}
                        onSelect={handlePagination}
                        defaultPage={page}
                      />
                    )}
                  </Col>
                </>
                :
                null
              :
              submit ?
                <>
                  <div style={{ padding: '20px', textAlign: 'center', border: "1px solid black", margin: "30px", height: 'auto' }}>
                    <span>{header}</span>
                    <Row>
                      <Divider style={{ backgroundColor: "black" }} />
                      {ParkingList.success &&
                        ParkingList.data.parkingSlotVOList.map((d, id) => (
                          <Col sm='1' md='2' style={{ display: "grid", justifyContent: "center" }}>
                            {d.isActive ?
                              d.unitIdMapping ?
                                <>
                                  <b>{d.buildingName}</b>
                                  <div className={d.isOccupied === true ? 'isOccupiedT' : 'isOccupiedF'}>
                                    <p style={{ marginTop: "8px", fontWeight: 'bold' }}>{d.isVisitor ? 'V' : d.unitName}</p>
                                  </div>
                                </>
                                :
                                d.isVisitor ?
                                  <>
                                    <b style={{ marginTop: "21px" }}></b>
                                    <div className={d.isOccupied === true ? 'isOccupiedV' : 'isOccupiedF'}>
                                      <p style={{ marginTop: "8px", fontWeight: 'bold' }}>V</p>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <b style={{ marginTop: "21px" }}></b>
                                    <div className={'isAvailable'}>
                                      <p style={{ marginTop: "8px", fontWeight: 'bold' }}></p>
                                    </div>
                                  </>
                              :
                              <>
                                <b style={{ marginTop: "21px" }}></b>
                                <div className={'isInactive'}>
                                  <p style={{ marginTop: "8px", fontWeight: 'bold' }}></p>
                                </div>
                              </>
                            }
                            {/* d.isOccupied ? 'isOccupiedT' : !d.isOccupied ? 'isOccupiedF' : null */}
                            <span style={{ marginBottom: '10px' }}>{d.name}</span>
                          </Col>
                        ))}
                      <Col md="12">
                        {ParkingList.failed && (
                          <div style={{ textAlign: 'center' }}>
                            <Alert message={ParkingList.data.message} type="info" />
                          </div>
                        )}
                      </Col>
                      <Divider style={{ backgroundColor: "black" }} />
                      <Col>
                        <Row style={{ marginTop: "20px" }}>
                          <Col md="3" className="legend">
                            <div className='gapDetails' ></div>
                            <span style={{ margin: '5px' }}>Occupied (By Flat)</span>
                          </Col>
                          <Col md="3" className="legend">
                            <div className='gapisOccupiedV' ><b style={{ fontSize: "12px" }}>V</b></div>
                            <span style={{ margin: '5px' }}>Occupied (By Visitor)</span>
                          </Col>
                          <Col md="3" className="legend">
                            <div className='gapDetail' ><b style={{ fontSize: "12px" }}>V</b></div>
                            <span style={{ margin: '5px' }}>Not Occupied (By Visitor)</span>
                          </Col>
                          <Col md="3" className="legend">
                            <div className='gapDetailAvailable' ></div>
                            <span style={{ margin: '5px' }}>Available (Parking)</span>
                          </Col>
                          <Col md="3" className="legend">
                            <div className='gapisInactive' ></div>
                            <span style={{ margin: '5px' }}>Inactive (Parking)</span>
                          </Col>

                        </Row>
                      </Col>
                    </Row>

                  </div >
                  <Col>
                    {ParkingList.success && (
                      <PaginationComponent
                        limit={limit}
                        totalItems={totalItems}
                        onSelect={handlePagination}
                        defaultPage={page}
                      />
                    )}
                  </Col>
                </>
                :
                null

            }

            {modal && (
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                size="sm"
                style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
              >
                <ModalHeader toggle={toggle}>Edit</ModalHeader>
                <ModalBody style={{ justifyContent: 'space-around' }}>
                  <FormGroup>
                    <Label for="unit">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="unitEdit"
                      value={Name}
                      onChange={handleEditChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup tag="fieldset" style={{ justifyContent: 'space-around' }}>
                    <Label>Occupied</Label>
                    <Switch
                      defaultChecked={isOccupied}
                      name="isActive"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch}
                      disabled={Occupied === true ? true : false}
                    />
                  </FormGroup>

                  <FormGroup tag="fieldset">
                    <Label>Visitor</Label>
                    <Switch
                      defaultChecked={isVisitor}
                      name="isActive"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch3}
                      disabled={assigned === true ? true : false}
                    />
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <Label>Active</Label>
                    <Switch
                      defaultChecked={isActiveEdit}
                      name="isActive"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch2}
                    />
                  </FormGroup>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={handleUnitEditFlatList}
                    disabled={updatedParkingSlot.isLoading}
                  >
                    {updatedParkingSlot.isLoading ? <Spin /> : 'Edit'}
                  </Button>
                  <Button
                    color="secondary"
                    style={{ float: 'right' }}
                    className="modalCustomButton"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                </ModalBody>
              </Modal>
            )}

            {assignedModal && (
              <Modal
                isOpen={assignedModal}
                toggle={assignedtoggle}
                centered
                size="sm"
                style={{ maxWidth: '250px', marginLeft: "auto", marginRight: "auto" }}
              >
                <ModalHeader toggle={assignedtoggle}>Assign</ModalHeader>
                <ModalBody>
                  <Assign data={prop} toggles={assignedtoggle} page={page} build={building} />
                </ModalBody>
              </Modal>
            )}
          </div>
        </Card >
      </div >
    </>
  )
}

export default DisplayParkingSlot
