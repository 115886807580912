import axios from '../../config/axios'
import {
  ADDBUILDING_SUCCESS,
  ADDBUILDING_FAILED,
  LOADING_ADDBUILDING,
  RESET_ADDBUILDING,
  ADDBUILDING_NETWORKERROR,
} from '../types'

const resetAddBuilding = () => {
  return {
    type: RESET_ADDBUILDING,
  }
}

const addBuilding = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADDBUILDING })
  axios
    .post('society/addArea', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADDBUILDING_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: ADDBUILDING_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADDBUILDING_FAILED,
    payload: data,
  }
}

export { resetAddBuilding, addBuilding }
