import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import { Spin, Select } from 'antd'
import FileBase64 from 'components/Custom/UploadFile'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetgetServiceType,
  getServiceType,
} from 'redux/actions/getServiceTypeAction'
import { resetgetAreaType, getAreaType } from 'redux/actions/getAreaTypeAction'
import { resetaddHelper, addHelper } from 'redux/actions/addHelperAction'
import {
  resetgetHelperType,
  getHelperType,
} from 'redux/actions/getHelperTypeAction'
import {
  resetgetHelperDoc,
  getHelperDoc,
} from 'redux/actions/getHelperDocAction'
import {
  resetgetHelperDocMandatory,
  getHelperDocMandatory,
} from 'redux/actions/getHelperDocMandatoryAction'
import {
  resetaddHelperDocument,
  addHelperDocument,
} from 'redux/actions/addHelperDocumentAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations/index'
import axios from 'config/axios'

function AddHelper() {
  const [formStep, setFormStep] = useState(1)
  const [areaType, setareaType] = useState('')
  const [areaTypeId, setareaTypeId] = useState('')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [initiatedBy, setInitiatedBy] = useState('Society')
  const [helperType, setHelperType] = useState('')
  const [helperName, sethelperName] = useState('')
  const [helperMobile, sethelperMobile] = useState('')
  const [helperPhoto, sethelperPhoto] = useState('')
  const [helperUpdatePhoto, setHelperUpdatePhoto] = useState('')
  const [helperTypeId, setHelperTypeId] = useState('')
  const [serviceTypeId, setServiceTypeId] = useState('')
  const [searchAreaType, setSearchAreaType] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [searchArea, setStearchArea] = useState('')
  const [searchServType, setSearchServType] = useState('')
  const [searchHelperType, setSearchHelperType] = useState('')
  const [optionsAreaType, setOptionsAreaType] = useState([''])
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [optionsServType, setOptionsServType] = useState([''])
  const [optionsHelperType, setOptionsHelperType] = useState([''])
  //Docs
  const [helperDocData, setHelperDocData] = useState('')
  const [addHelperDocScreen, setAddHelperDocScreen] = useState(false)
  const [data, setData] = useState('')
  const [documents, setDocuments] = useState([''])
  const [docCheck, setDocCheck] = useState([''])
  const [helperDoc, setHelperDoc] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [disableBtn, setDisableBtn] = useState(true)
  const [docDisableBtn, setDocDisableBtn] = useState(false)
  const [bldgName, setBldgName] = useState('')
  const [flatName, setFlatName] = useState('')
  const login = useSelector(({ login }) => login)
  const areaTypes = useSelector(({ areaType }) => areaType)
  const [societyId] = useState(login.data.society.id)
  const [documentNumber, setdocumentNumber] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [userId] = useState(login.data.user.id)
  const [aadhar, setAadhar] = useState('')

  // const buildingList = useSelector(({ buildingList }) => buildingList)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  // const getFlat = useSelector(({ getFlatList }) => getFlatList)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const dailyHelpType = useSelector(({ dailyHelpType }) => dailyHelpType)
  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const helperAdded = useSelector(({ addHelper }) => addHelper)
  const docType = useSelector(
    ({ getHelperDocMandatory }) => getHelperDocMandatory
  )
  const addedHelperDocument = useSelector(
    ({ addHelperDocument }) => addHelperDocument
  )
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'areaType') setareaType(value)
    if (name === 'areaId') setareaId(value)
    if (name === 'flatid') setflatid(value)
    if (name === 'serviceTypeId') setServiceTypeId(value)
    if (name === 'helperType') setHelperType(value)
    if (name === 'helperName')
      Util.alphabetsOnly(value)
        ? sethelperName(value)
        : sethelperName(helperName)
    if (name === 'helperMobile')
      Util.verifyMobile(value)
        ? sethelperMobile(value)
        : sethelperMobile(helperMobile)
    if (name === 'helperPhoto') sethelperPhoto(value)
  }

  const getDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        helperTypeId,
      }
      return dispatch(getHelperDocMandatory(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [helperTypeId])
  const { Option } = Select

  function onChangeSearchAreaType(value) {
    setareaType(value)
    setareaId('')
    setSearchAreaType('')
  }

  function onSearchAreaType(val) {
    setareaType(val)
  }

  function onChangeSearchAreaId(value) {
    const area = value.split(',')
    const area1 = area[0]
    const area2 = area[1].trim()
    setareaId(area1)
    setBldgName(area2)
    setSearchAreaType('')
    setflatid('')
    setFlatName('')
  }

  function onSearchAreaId(val) {
    setSearchAreaType(val)
  }

  function onChangeSearchFlatId(value) {
    const flat = value.split(',')
    const flatID1 = flat[0]
    const flatUnit1 = flat[1].trim() 
    setflatid(flatID1)
    setFlatName(flatUnit1)
    setSearchFlat('')
  }

  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  function onChangeSearchServType(value) {
    setServiceTypeId(value)
    setHelperType('')
    setSearchServType('')
  }

  function onSearchServType(val) {
    setSearchServType(val)
  }

  function onChangeSearchHelperType(value) {
    console.log(value,"valuesss")
    const Helper = value.split(',')
    const helpertype = Helper[0]
    const helpertypeid = Helper[1].trim()
    setHelperType(helpertypeid)
    setHelperTypeId(helpertype)
    setSearchHelperType('')
    console.log(helperTypeId)
  }

  function onSearchHelperType(val) {
    setSearchHelperType(val[1])
  }
  //search of area type
  useEffect(() => {
    if (searchAreaType != '') {
      const userData = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: searchAreaType,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsAreaType(res.data.activeAreaList)
        }
      })
    }
  }, [searchAreaType])

  //search of flat
  useEffect(() => {
    if (areaType === 'Building' && areaId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsUnit(res.data.activeType)
        }
      })
    }
  }, [searchFlat])

  //search service type
  useEffect(() => {
    if (searchServType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        type: searchServType,
      }
      axios.post('helper/getServiceType', userData).then((res) => {
        if (res.status === 200 && res.data.activeType != '') {
          setOptionsServType(res.data.activeType)
        }
      })
    }
  }, [searchServType])

  //search helper type
  useEffect(() => {
    if (searchHelperType != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
        type: searchHelperType,
      }
      axios.post('helper/getHelperType', userData).then((res) => {
        if (res.status === 200 && res.data.helperTypeList != '') {
          setOptionsHelperType(res.data.helperTypeList)
        }
      })
    }
  }, [searchHelperType, serviceTypeId])

  useEffect(() => {
    if (helperPhoto != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }, [helperPhoto])

  useEffect(() => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }, [])

  useEffect(() => {
    const areaTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getAreaType(areaTypeReq))
  }, [])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && areaId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: areaId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [areaType, areaId])

  useEffect(() => {
    if (login.data.user.role === 'Society' && serviceTypeId != '') {
      setHelperType('')
      const getHelperTypeReq = {
        initiatedBy: login.data.user.role,
        serviceTypeId,
      }
      console.log(getHelperTypeReq)
      return dispatch(getHelperType(getHelperTypeReq))
    }
  }, [serviceTypeId])

  const addHelperRequest = () => {
    const addHelperReq = {
      action: 'add',
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      unitId: flatid ? flatid : areaId,
      helperTypeId,
      helperName:helperName.trim(),
      helperMobile,
      type: helperType,
      addedBy: login.data.user.role,
      photo: helperPhoto,
      areaId,
      documentList: documents,

      // areaTypeId,
    }

    // console.log(addHelperReq)
    return dispatch(addHelper(addHelperReq))
  }

  useEffect(() => {
    if (helperAdded.success) {
      // console.log(helperAdded.success, 'helperAdded')
      setflatid('')
      sethelperMobile('')
      sethelperName('')
      sethelperPhoto('')
      setareaType('')
      setareaTypeId('')
      setareaId('')
      setHelperType('')
      setServiceTypeId('')
      setBldgName('')
      setDocDisableBtn(false)
      NotificationPopups('success', 'Success', helperAdded.data.message)
    }

    if (helperAdded.failed) {
      NotificationPopups('error', 'Failed', helperAdded.data.message)
    }
    if (helperAdded.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      // console.log(resetaddHelper, 'test')
      dispatch(resetaddHelper())
    }
  }, [helperAdded.success, helperAdded.failed, helperAdded.network])

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormStep(2)
    addHelperRequest()
  }

  const handleDocSubmit = (e, documentName, document) => {
    e.preventDefault()
  }

  const disabledButton = () => {
    return !(
      helperName &&
      helperPhoto &&
      helperMobile &&
      helperType &&
      areaType
    )
  }

  const handleAttachments = (filesList) => {
    console.log(filesList)
    let img = filesList.base64.split(`,`)
    setHelperUpdatePhoto(filesList.base64)
    sethelperPhoto(img[1])
    // helperPhoto ? setDisableBtn(false) : setDisableBtn(true)
  }

  let helperDocArr = []
  let checkDocArr = []

  useEffect(() => {
    setHelperDocDeatils()
  }, [docType.success])

  useEffect(() => {
    setDocDisableBtn(false)
  }, [helperTypeId])

  const setHelperDocDeatils = () => {
    docType.success &&
      docType.data.activeHelperDocList.map((data, idx) => {
        // console.log(data, 'data')
        let helperDocObj = {
          photo: '',
          document: '',
          documentType: data.documentName,
          documentTypeId: data.documentTypeId,
          societyId: login.data.society.id,
          documentNumber: '',
          isActive: true,
          isMandatory: data.ismandatory ? true : false,
          check: data.mandatory ? false : true,
          file: idx,
          allNotMan: false,
        }
        helperDocArr.push(helperDocObj)
      })
    setDocuments(helperDocArr)
    setDocCheck(checkDocArr)
  }

  const handleAttachmentsDoc = (filesList) => {
    let id = filesList.id
    let photo = filesList.base64
    let img = filesList.base64.split(`,`)
    let image = img[1]
    let helperDocs = [...documents]
    helperDocs[id].document = image
    helperDocs[id].photo = photo
    helperDocs[id].check = photo && true

    setDocuments(helperDocs)

    console.log(helperDocs, 'helperDocs')
  }
  useEffect(() => {
    const check = documents.map((d) => d.check)
    const set = [...new Set(check)]
    // setDocDisableBtn(set)
    console.log(set.length, 'length')
    if (set.length === 1) {
      setDocDisableBtn(set[0])
    }
  }, [documents])

  const handleChangeDocNumber = ({ target: { name, id, value } }) => {
    let helperDocs = [...documents]
    helperDocs[name].documentNumber = value
    setDocuments(helperDocs)

    if (id === 'aadhar')
      Util.verifyMobile(value) ? setAadhar(value) : setAadhar(aadhar)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      {formStep === 1 && (
        <>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12" sm="12" xs="12" lg="12">
                <Row>
                  <Col md="4" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="areaType">Area Type</Label>
                      <span className='required'>*</span>
                      <Select
                        type="select"
                        showSearch
                        value={areaType}
                        placeholder="Select  Select Area Type"
                        optionFilterProp="children"
                        onChange={onChangeSearchAreaType}
                        onSearch={onSearchAreaType}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Area Type
                        </Option>
                        {areaTypes.success &&
                          areaTypes.data.areaTypeList.map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.type}
                            >
                              {d.type}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="areaId">Area Name</Label>
                      <span className='required'>*</span>
                      <Select
                        type="select"
                        showSearch
                        value={bldgName}
                        placeholder="Select Area Name"
                        optionFilterProp="children"
                        onChange={onChangeSearchAreaId}
                        onSearch={onSearchAreaId}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Area Name
                        </Option>
                        {searchAreaType != '' && optionsAreaType != undefined
                          ? optionsAreaType.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={`${d.areaId}, ${d.areaName}`}
                              >
                                {d.areaName ? `${d.areaName}${'  -  '}` : ''}
                                {d.unit}
                              </Option>
                            ))
                          : buildingList.success &&
                            buildingList.data.areaListActive.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={`${d.areaId}, ${d.areaName}`}
                              >
                                {areaType != 'Building'
                                  ? `${d.areaName ? d.areaName : '-'} - ${
                                      d.unit ? d.unit : '-'
                                    }`
                                  : d.areaName
                                  ? d.areaName
                                  : '-'}
                              </Option>
                            ))}
                        {/* {buildingList.success &&
                      buildingList.data.activeAreaList.map((d, id) => (
                        <Option
                          key={id}
                          className="loginSelect"
                          value={[d.areaId, d.type]}
                        >
                          {d.areaName ? `${d.areaName}${'  -  '}` : ''}
                          {d.type}
                        </Option>
                      ))} */}
                      </Select>
                    </FormGroup>
                  </Col>
                  {areaType === 'Building' && (
                    <Col md="4" sm="12" xs="12" lg="6">
                      <FormGroup>
                        {/* <Label for="flatid" lat> */}
                        <Label for="flatid">Flat</Label>
                        <span className='required'>*</span>
                        <Select
                          type="select"
                          showSearch
                          value={flatName}
                          placeholder="Select Flat Number"
                          optionFilterProp="children"
                          onChange={onChangeSearchFlatId}
                          onSearch={onSearchFlatId}
                          style={{ width: '100%' }}
                        >
                          <Option className="loginSelect" value="">
                            Select Flat Number
                          </Option>
                          {searchFlat != '' && optionsUnit != undefined
                            ? optionsUnit.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={`${d.id}, ${d.type}`}
                                >
                                  {d.type}
                                </Option>
                              ))
                            : getFlat.success &&
                              getFlat.data.activeUnitList.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={`${d.id}, ${d.unit}`}
                                >
                                  {d.unit}
                                </Option>
                              ))}

                          {/* {getFlat.failed && (
                        <Option className="loginSelect" value="">
                          No Data Found
                        </Option>
                      )} */}
                        </Select>
                      </FormGroup>
                    </Col>
                  )}
                  {areaType === 'Flat' && (
                    <Col md="4" sm="12" xs="12" lg="6">
                      <FormGroup>
                        <Label for="flatid" lat>
                          Flat
                        </Label>
                        <span className='required'>*</span>
                        <Select
                          type="select"
                          value={flatid}
                          showSearch
                          placeholder="Select Flat Number"
                          optionFilterProp="children"
                          onChange={onChangeSearchFlatId}
                          onSearch={onSearchFlatId}
                          // filterOption={(input, option) =>
                          //   option.children
                          //     .toLowerCase()
                          //     .indexOf(input.toLowerCase()) >= 0
                          // }
                          style={{ width: '100%' }}
                        >
                          <Option className="loginSelect" value="" >
                            Select Flat Number
                          </Option>
                          {searchFlat != '' && optionsUnit != undefined
                            ? optionsUnit.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={d.id}
                                >
                                  {d.type}
                                </Option>
                              ))
                            : getFlat.success &&
                              getFlat.data.activeType.map((d, id) => (
                                <Option
                                  key={id}
                                  className="loginSelect"
                                  value={d.id}
                                >
                                  {d.type}
                                </Option>
                              ))}
                        </Select>
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="4" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="helperType">Service Type</Label>
                      <span className='required'>*</span>
                      <Select
                        type="select"
                        value={serviceTypeId}
                        showSearch
                        placeholder="Select Service Type"
                        optionFilterProp="children"
                        onChange={onChangeSearchServType}
                        onSearch={onSearchServType}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Service Type
                        </Option>

                        {searchServType != '' && optionsServType != undefined
                          ? optionsServType.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={d.id}
                              >
                                {d.type}
                              </Option>
                            ))
                          : servType.success &&
                            servType.data.activeType.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={d.id}
                              >
                                {d.type}
                              </Option>
                            ))}
                        {servType.failed && (
                          <Option className="loginSelect" value="">
                            No Data Found
                          </Option>
                        )}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="4" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="helperType">Helper Type</Label>
                      <span className='required'>*</span>
                      <Select
                        type="select"
                        value={helperType}
                        showSearch
                        placeholder="Select Helper Type"
                        optionFilterProp="children"
                        onChange={onChangeSearchHelperType}
                        onSearch={onSearchHelperType}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Helper Type
                        </Option>
                        {searchHelperType != '' &&
                        optionsHelperType != undefined
                          ? optionsHelperType.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={`${d.id}, ${d.type}`}
                              >
                                {d.type}
                              </Option>
                            ))
                          : helperTypeList.success &&
                            helperTypeList.data.helperTypeList.map((d, id) => (
                              <Option
                                key={id}
                                className="loginSelect"
                                value={`${d.id}, ${d.type}`}
                              >
                                {d.type}
                              </Option>
                            ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="helperName">Name</Label>
                      <span className='required'>*</span>
                      <Input
                        type="text"
                        name="helperName"
                        id="helperName"
                        placeholder="Name"
                        value={helperName || ''}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12" xs="12" lg="6">
                    <FormGroup>
                      <Label for="helperMobile">Mobile</Label>
                      <span className='required'>*</span>
                      <Input
                        type="text"
                        name="helperMobile"
                        id="helperMobile"
                        placeholder="Mobile"
                        maxLength="10"
                        minLenght='10'
                        value={helperMobile || ''}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="4" sm="12" xs="12" lg="4">
                <label>Profile Picture</label>
                <span className='required'>*</span>
                <FileBase64
                  multiple={false}
                  id="addhelperPhoto"
                  accept=".jpeg, .png, .jpg"
                  onDone={handleAttachments}
                />
                {helperUpdatePhoto && (
                  <img
                    src={helperUpdatePhoto}
                    width="50%"
                    height="150"
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              {docType.success ? (
                <Button
                  className="cust-button"
                  onClick={() => setFormStep(2)}
                  // disabled={disabledButton()}
                >
                  {helperAdded.isLoading ? <Spin /> : 'Next'}
                </Button>
              ) : (
                <Button
                  className="cust-button"
                  onClick={handleSubmit}
                  disabled={disabledButton()}
                >
                  {helperAdded.isLoading ? <Spin /> : 'Add'}
                </Button>
              )}
            </Row>
          </Form>
        </>
      )}
      {formStep === 2 && (
        <>
          <Row>
            {docType.success &&
              docType.data.activeHelperDocList.map((d, id) => (
                <Col md={4} sm={12} lg={4} key={id}>
                  <Label>
                    Upload {d.documentName}{' '}
                    {d.mandatory && <span className="errorText">*</span>}
                  </Label>
                  {documents[id].photo && (
                    <img
                      src={documents[id].photo}
                      width="50%"
                      height="150"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '10px',
                      }}
                    />
                  )}

                  <FileBase64
                    multiple={false}
                    id={id}
                    accept=".jpeg, .png, .jpg"
                    onDone={handleAttachmentsDoc}
                  />
                  <FormGroup>
                    <Label for="helperMobile">{d.documentName} Number</Label>
                    <Input
                      type="text"
                      // multiname={['aadhar', id]}
                      // name={[id, aadhar]}
                      name={id}
                      id="aadhar"
                      placeholder={`${d.documentName} Number`}
                      maxLength="12"
                      value={aadhar || ''}
                      onChange={handleChangeDocNumber}
                      required
                    />
                  </FormGroup>
                </Col>
              ))}
          </Row>

          <Row style={{ justifyContent: 'center' }}>
            <Button
              className="cust-button"
              onClick={() => setFormStep(1)}
              // disabled={disabledButton()}
            >
              Back
            </Button>
            <Button
              className="cust-button"
              onClick={handleSubmit}
              disabled={!docDisableBtn}
            >
              {helperAdded.isLoading ? <Spin /> : 'Add'}
            </Button>
          </Row>
        </>
      )}
    </div>
  )
}

export default AddHelper
