import axios from '../../config/axios'
import {
  GET_PRODUCT_SERVICE_CATEGORY_FAILED,
  GET_PRODUCT_SERVICE_CATEGORY_SUCCESS,
  GET_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR,
  LOADING_GET_PRODUCT_SERVICE_CATEGORY,
  RESET_GET_PRODUCT_SERVICE_CATEGORY,
} from '../types'

const resetGetProductServiceCategory = () => {
  return {
    type: RESET_GET_PRODUCT_SERVICE_CATEGORY,
  }
}

const getProductServiceCategory = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_PRODUCT_SERVICE_CATEGORY })
  console.log(userData)
  axios
    .post('productService/getProductServiceCategory', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_PRODUCT_SERVICE_CATEGORY_NETWORK_ERROR,
        payload: err,
      })
    )
}

function success(data) {
  return {
    type: GET_PRODUCT_SERVICE_CATEGORY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_PRODUCT_SERVICE_CATEGORY_FAILED,
    payload: data,
  }
}

export { resetGetProductServiceCategory, getProductServiceCategory }
