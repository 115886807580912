import axios from "../../config/axios"
import {
	ADDDAILYHELPTYPE_SUCCESS,
	ADDDAILYHELPTYPE_FAILED,
	LOADING_ADDDAILYHELPTYPE,
	RESET_ADDDAILYHELPTYPE,
    ADDDAILYHELPTYPE_NETWORKERROR
} from "../types";

const resetAddDailyHelpType = () => {
    return {
        type: RESET_ADDDAILYHELPTYPE
    };
};

const addDailyHelpType = userData => dispatch => {
    dispatch({ type: LOADING_ADDDAILYHELPTYPE });
    axios
        .post("superAdmin/addDailyHelperType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADDDAILYHELPTYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: ADDDAILYHELPTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADDDAILYHELPTYPE_FAILED,
        payload: data
    };
}

export { resetAddDailyHelpType, addDailyHelpType } ;