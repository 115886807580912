import {
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAILED,
  ADD_INVOICE_NETWORK,
  ADD_INVOICE_RESET,
  ADD_INVOICE_LOADING,
} from '../types'

const initialState = {
  isLoading: false,
  success: false,
  failed: false,
  network: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVOICE_LOADING:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case ADD_INVOICE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case ADD_INVOICE_NETWORK:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: false,
        network: true,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case ADD_INVOICE_RESET:
      return (state = initialState)

    default:
      return state
  }
}
