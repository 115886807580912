import axios from '../../config/axios'
import {
  GETCOMPANY_SUCCESS,
  GETCOMPANY_FAILED,
  LOADING_GETCOMPANY,
  RESET_GETCOMPANY,
  GETCOMPANY_NETWORKERROR,
} from '../types'

const resetgetCompanyList = () => {
  return {
    type: RESET_GETCOMPANY,
  }
}

const getCompanyList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETCOMPANY })
  console.log(userData)
  axios
    .post('household/getCompany', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GETCOMPANY_NETWORKERROR, payload: err }))
}

function success(data) {
  return {
    type: GETCOMPANY_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETCOMPANY_FAILED,
    payload: data,
  }
}

export { resetgetCompanyList, getCompanyList }
