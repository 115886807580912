import {
    GET_ALL_SECURITY_ALERT_LIST_SUCCESS,
	GET_ALL_SECURITY_ALERT_LIST_FAILED,
	LOADING_GET_ALL_SECURITY_ALERT_LIST,
	RESET_GET_ALL_SECURITY_ALERT_LIST,
	GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GET_ALL_SECURITY_ALERT_LIST:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                data: {},
                message: "",
                networkError: ""
            };

        case GET_ALL_SECURITY_ALERT_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: true,
                network: false,
                data: action.payload,
                message: "",
                networkError: ""
            };

        case GET_ALL_SECURITY_ALERT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                message: "",
                networkError: ""
            };

        case GET_ALL_SECURITY_ALERT_LIST_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: "",
                networkError: "Network Error"
            };

        case RESET_GET_ALL_SECURITY_ALERT_LIST:
            return (state = initialState);

        default:
            return state;
    }
};