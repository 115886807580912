import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from '../../components/Custom/UploadFile'
import { Spin } from 'antd'
//ant design components
import { Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// core components
import { getTransactionList } from 'redux/actions/getGatewayTransactionList'
import axios from 'config/axios'
import moment from 'moment'
import Util from 'validations'

const { Dragger } = Upload

// const initialState = {
// 	startDate: new Date(),
// 	endDate: new Date(),
// 	title: "",
// 	content: "",
// 	type: ""
// }

function SearchTransactionsList(props) {
  const [trackingId, setTrackingId] = useState('')
  // const [paymentId, setPaymentId] = useState()
  const [customerId, setCustomerId] = useState('')
  const [Status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const inputRef = useRef(null)
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()

  const transactionList = useSelector(
    ({ getTransactionList }) => getTransactionList
  )

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'trackingId')
      Util.verifyMobile(value)
        ? setTrackingId(value)
        : setTrackingId(trackingId)
    // if (name === 'paymentId')
    //   Util.verifyMobile(value) ? setPaymentId(value) : setPaymentId(paymentId)
    if (name === 'customerId')
      Util.verifyMobile(value)
        ? setCustomerId(value)
        : setCustomerId(customerId)
    // if (name === 'Status')
    //   Util.verifyAlpahbet(value) ? setStatus(value) : setStatus(Status)

    if (name === 'startDate') setStartDate(value)
    if (name === 'endDate') setEndDate(value)
  }
  const { Option } = Select

  const getTransactionListRequest = () => {
    // const date = new Date()
    // const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    // const startDate = moment(firstDay).format('YYYY-MM-DD')
    const getTransactionListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      endDate:endDate,
      startDate:startDate,
      // paymentId,
      trackingId,
      userId: customerId,
      status: Status,
      // page,
      limit: 6,
    }

    return dispatch(getTransactionList(getTransactionListReq))
  }
  
  // useEffect(() => {
  //   getTransactionListRequest()
  // }, [])

  const transactionStatus = ['Success', 'Pending', 'Failed']

  function onChangeSearch(value) {
    setStatus(value)
  }

  function onSearch(val) {
    setStatus(val)
  }

  const handleSubmit = (e) => {
    props.setIsSearch()
    e.preventDefault()
    getTransactionListRequest()
    // setTrackingId('')
    // setCustomerId('')
    // setStatus('')
    // setStartDate('')
    // setEndDate('')
    const obj = {
      status: Status,
      userId: customerId,
      trackingId,
      startDate,
      endDate,
    }
    send(obj)
  }

  const disabledBtn = () => {
    return
  }

  function send(value) {
    props.searchFilter(value)
  }

  // useEffect(() => {
  //   const date = new Date()
  //   const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  //   const startDate = moment(firstDay).format('YYYY-MM-DD')
  //   setStartDate(startDate)
  //   const endDate = moment().format('YYYY-MM-DD')
  //   setEndDate(endDate)
  // }, [])

  return (
    <>
      <Row>
        <Col xs="12" sm="12" md="4">
          <FormGroup>
            <Label>From Date</Label>
            <span className="sub-label">(Optional)</span>
            <Input
              name="startDate"
              value={startDate || ''}
              type="date"
              onChange={handleChange}
              ref={inputRef}
            // min={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="12" md="4">
          <FormGroup>
            <Label>To Date</Label>
            <span className="sub-label">(Optional)</span>
            <Input
              name="endDate"
              value={endDate || ''}
              type="date"
              onChange={handleChange}
            // min={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
        </Col>
        <Col md="4" sm="12" lg="4" xs="12">
          <Label for="trackingId">Tracking Id</Label>
          <Input
            type="text"
            name="trackingId"
            id="name"
            placeholder="Tracking Id"
            value={trackingId}
            onChange={handleChange}
            required
            style={{padding:'10px 18px 10px 18px'}}
          />
        </Col>
        {/* <Col md="4" sm="12" lg="4" xs="12">
          <Label for="paymentId">Payment Id</Label>
          <Input
            type="text"
            name="paymentId"
            id="name"
            placeholder="Payment Id"
            value={paymentId}
            onChange={handleChange}
            required
          />
        </Col> */}
        <Col md="4" sm="12" lg="4" xs="12">
          <Label for="customerId">Customer Id</Label>
          <Input
            type="text"
            name="customerId"
            id="name"
            placeholder="Customer Id"
            value={customerId}
            onChange={handleChange}
            required
            style={{padding:'10px 18px 10px 18px'}}
          />
        </Col>
        <Col md="4" sm="12">
          <FormGroup>
            <Label for="city">Status</Label>
            <Select
              type="select"
              name="Status"
              value={Status}
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              onChange={onChangeSearch}
              onSearch={onSearch}
              style={{ width: '100%', borderRadius: '25px'}}
            >
              <Option className="loginSelect" value="">
                Select Status
              </Option>
              {transactionStatus.map((d, idx) => (
                <Option key={idx} value={d}>
                  {d}
                </Option>
              ))}
            </Select>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}
        >
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={transactionList.isLoading || disabledBtn()}
          >
            {transactionList.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SearchTransactionsList
