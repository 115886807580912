import axios from "../../config/axios";
import {
	DELETENOTICE_SUCCESS,
	DELETENOTICE_FAILED,
	LOADING_DELETENOTICE,
	RESET_DELETENOTICE,
	DELETENOTICE_NETWORKERROR
} from "../types";

const resetdeleteNotice = () => {
    return {
        type: RESET_DELETENOTICE,		
    };
};

const deleteNotice = userData => dispatch => {
    dispatch({ type: LOADING_DELETENOTICE });
		console.log(userData);
    axios
        .post("notice/deleteNotice", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: DELETENOTICE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: DELETENOTICE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: DELETENOTICE_FAILED,
        payload: data
    };
}

export { resetdeleteNotice, deleteNotice } ;