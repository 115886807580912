import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import { addFeatureType } from 'redux/actions/addFeatureTypeAction'
import { resetAddFeatureType } from 'redux/actions/addFeatureTypeAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'
import Util from 'validations/index'
import axios from 'config/axios'

function AddFeatureType() {
  const [featureId, setFeatureId] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [subTypeId, setSubTypeId] = useState('')
  const [subTypeName, setSubTypeName] = useState('')
  const [names, setName] = useState('')
  const [featureIcon, setFeatureIcon] = useState('')
  const [description, setDescription] = useState('')
  const [redirect, setRedirect] = useState('')
  const [externalUrl, setExternalUrl] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [isFavorite, setIsFav] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [isDashboard, setIsInDashboard] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')

  //options
  const [optionsFeatureCat, setOptionsFeatureCat] = useState([''])
  const [optionsSubCat, setOptionsSubCat] = useState([''])
  const [searchFeatureCat, setSearchFeatureCat] = useState('')
  const [searchSubCat, setSearchSubCat] = useState('')

  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const addedFeatureType = useSelector(({ addFeatureType }) => addFeatureType)

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
    if (name === 'description') setDescription(value)
    if (name === 'redirect')
      Util.alphabetsOnly(value) ? setRedirect(value) : setRedirect(redirect)
    if (name === 'priorityOrder') setPriorityOrder(value)
    if (name === 'externalUrl') setExternalUrl(value)
  }
  //feature dropdown
  useEffect(() => {
    if (searchFeatureCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios
        .post('dashboard/getFeatureTypeList', userData)
        .then((res) => setOptionsFeatureCat(res.data.featureType))
    }
  }, [searchFeatureCat])
  //sub cat
  useEffect(() => {
    if (searchSubCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        featureId,
        name: searchSubCat,
      }
      axios
        .post('dashboard/getSubCategoryList', userData)
        .then((res) => setOptionsSubCat(res.data.subCategory))
    }
  }, [searchSubCat])

  const getSubCategoryRequest = () => {
    const getSubCategoryReq = {
      initiatedBy: login.data.user.role,
      featureId,
    }
    return dispatch(getSubCategory(getSubCategoryReq))
  }
  useEffect(() => {
    getSubCategoryRequest()
  }, [featureId])

  const onChangeSwitch = (checked) => {
    setIsFav(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setIsNew(checked)
  }
  const onChangeSwitch3 = (checked) => {
    setIsInDashboard(checked)
  }
  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    setFeatureIcon(icon[1])
    setUpdatedPhoto(filesList[0].base64)
    console.log(featureIcon,"featureIcon")
  }

  const { Option } = Select
  function onChangeFeature(value) {
    setFeatureId(value)
    setSubTypeId('')
  }

  function onSearchFeature(val) {
    setSearchFeatureCat(val)
  }
  function onChangeSubCat(value) {
    setSubTypeId(value)
  }

  function onSearchSubCat(val) {
    setSearchSubCat(val)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addFeatureTypeRequest()
  }
  const addFeatureTypeRequest = () => {
    const addFeatureTypeReq = {
      initiatedBy: login.data.user.role,
      featureId,
      subCategoryId: subTypeId,
      names:names.trim(),
      description,
      redirect:redirect.trim(),
      priorityOrder,
      externalUrl,
      iconUrl: featureIcon.slice(','),
      isDashboard,
      isFavorite,
      isNew,
    }
    return dispatch(addFeatureType(addFeatureTypeReq))
  }

  useEffect(() => {
    if (addedFeatureType.success) {
      setSubTypeId('')
      setSubTypeName('')
      setName('')
      setFeatureIcon('')
      setDescription('')
      setFeatureId('')
      setFeatureName('')
      setRedirect('')
      setIsFav('')
      setIsInDashboard('')
      setIsNew('')
      NotificationPopups('success', 'Success', addedFeatureType.data.message)
    }
    if (addedFeatureType.failed) {
      NotificationPopups('error', 'Failed', addedFeatureType.data.message)
    }
    if (addedFeatureType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddFeatureType())
    }
  }, [
    addedFeatureType.success,
    addedFeatureType.failed,
    addedFeatureType.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Feature Category</label>
              <Select
                type="select"
                value={featureId}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeFeature}
                onSearch={onSearchFeature}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {searchFeatureCat != '' &&
                  optionsFeatureCat != undefined &&
                  optionsFeatureCat.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                {searchFeatureCat === '' &&
                  getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Sub Category </label>
              <Select
                type="select"
                value={subTypeId}
                showSearch
                placeholder="Select Sub Category Type"
                optionFilterProp="children"
                onChange={onChangeSubCat}
                onSearch={onSearchSubCat}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Sub Category Type
                </Option>
                {searchSubCat === '' &&
                  getSubCat.success &&
                  getSubCat.data.subCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
                {searchSubCat != '' &&
                  optionsSubCat != undefined &&
                  optionsSubCat.map((d, id) => (
                    <Option key={id} className="loginSelect">
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Feature Type Name</Label>
              <Input
                type="text"
                placeholder="Feature Type Name"
                name="name"
                id="serviceType"
                value={names || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Description</Label>
              <Input
                type="textarea"
                placeholder="Enter Description"
                name="description"
                id="serviceType"
                value={description || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">App Screen Name</Label>
              <Input
                type="text"
                placeholder="App Screen Name"
                name="redirect"
                id="serviceType"
                value={redirect || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">External Url</Label>
              <Input
                type="text"
                placeholder="External Url"
                name="externalUrl"
                id="serviceType"
                value={externalUrl || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Select Icon</Label>
              <FileBase64
                multiple={true}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {featureIcon && (
              <img
                src={updatedPhoto}
                width="40px"
                height="40px"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col>
          <Col xs="12" sm="12" md="1">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>Favourite</Label>
              <br />
              <Switch
                defaultChecked={isFavorite}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
                // style={{ width: '100%' }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="12" md="1">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>New</Label>
              <br />
              <Switch
                defaultChecked={isNew}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch2}
                // style={{ width: '100%' }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {/* {addedServiceType.isLoading ? <Spin /> : 'Add'} */}
            Add
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddFeatureType
