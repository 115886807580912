import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Collapse, Select, Spin } from 'antd'
// import ComplaintPendingList from "./ComplaintPendingList";
// import ComplaintRejectedList from "./ComplaintRejectedList";
// import ComplaintResolvedList from "./ComplaintResolvedList";
import {
  getComplaintList,
  resetgetComplaintList,
} from 'redux/actions/getComplaintListAction'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addComplaintStatus,
  resetComplaintStatus,
} from 'redux/actions/addComplaintStatusAction'
import {
  addComplaintMessage,
  resetAddComplaintMessage,
  getComplaintMessage,
  resetGetComplaintMessage,
} from 'redux/actions/complaintMessageAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import moment from 'moment'
import ComplaintsIcon from 'assets/img/Icons/Sidebar/complain.png'
import Tick from '../../assets/img/check1.png'

function Complaints() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [iconImage, setIconImage] = useState('')
  const [status, setStatus] = useState('pending')
  const [complainId, setComplainId] = useState('')
  const complaintList = useSelector(({ complaintList }) => complaintList)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [pageForScroll, setPageForScroll] = useState(1)
  const screenRef = React.createRef()
  const [ids, setIds] = useState('')
  //filter
  const [complainType, setComplainType] = useState('')
  const [areaType, setareaType] = useState('Building')
  const [areaId, setareaId] = useState()
  const [flatid, setflatid] = useState()
  const [serachAreaName, setSearchAreaName] = useState('')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [memberName, setMemberName] = useState('')
  const [phone, setPhone] = useState('')
  const [residentType, setResidentType] = useState('')
  const [startDate, setStartDate] = useState('')
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const ComplainStatus = useSelector(
    ({ addComplainStatus }) => addComplainStatus
  )
  const getComplaintMessageData = useSelector(
    ({ getComplaintMessage }) => getComplaintMessage
  )
  const addComplaintMessageData = useSelector(
    ({ addComplaintMessage }) => addComplaintMessage
  )

  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
  const { Panel } = Collapse
  // const {
  //   data: { user, society },
  // } = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const [message, setMessage] = useState({
    id: '',
    content: '',
    attachment: '',
  })
  const [msgAtt, setMsgAtt] = useState('')

  const login = useSelector(({ login }) => login)
  const totalItems = complaintList.data.count
  // useEffect(() => {
  //   getComplainListRequest('pending')
  //   return () => {
  //     dispatch(resetgetComplaintList())
  //     dispatch(resetAddComplaintMessage())
  //     dispatch(resetGetComplaintMessage())
  //   }
  // }, [])

  const handleStatus = (status) => {
    setStatus(status)
  }

  useEffect(() => {
    getComplainListRequest()
  }, [status])

  const getComplainListRequest = () => {
    if (buildingId && !flatid) {
      const getComplainListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        complainStatus: status,
        complainTitle: complainType,
        unitId: flatid,
        complainDate: startDate,
        areaId: buildingId,
        limit,
        page,
      }
      return dispatch(getComplaintList(getComplainListReq))
    } else if (buildingId && flatid) {
      const getComplainListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        complainStatus: status,
        complainTitle: complainType,
        unitId: flatid,
        complainDate: startDate,
        // areaId: buildingId,
        limit,
        page,
      }
      return dispatch(getComplaintList(getComplainListReq))
    } else if (buildingId === '') {
      const getComplainListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        complainStatus: status,
        complainTitle: complainType,
        unitId: flatid,
        complainDate: startDate,
        // areaId: buildingId,
        limit,
        page,
      }
      return dispatch(getComplaintList(getComplainListReq))
    }
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [isSearch])

  useEffect(() => {
    getComplainListRequest()
  }, [page])

  const handleComplaintStatus = (id, status) => {
    const complaintStatusReq = {
      initiatedBy: login.data.user.role,
      complainId: id,
      complainStatus: status,
    }
    return dispatch(addComplaintStatus(complaintStatusReq))
  }

  const attachmentType = (a) => {
    const myArr = a.split(',')
    switch (myArr[0]) {
      case 'data:image/png;base64':
      case 'data:image/jpeg;base64':
      case 'data:image/jpg;base64':
        return 'image'
      default:
        return 'notImage'
    }
  }

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const scrollHandle = () => {
    const scrollY = window.scrollY
  }

  useEffect(() => {
    setPage(1)
  }, [complaintList.failed])

  useEffect(() => {
    setPage(1)
  }, [status])

  useEffect(() => {
    if (ComplainStatus.success) {
      getComplainListRequest('pending')
      NotificationPopups('success', 'Success', ComplainStatus.data.message)
    }
    if (ComplainStatus.failed) {
      NotificationPopups('error', 'Failed', ComplainStatus.data.message)
    }
    if (ComplainStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetComplaintStatus())
    }
  }, [
    ComplainStatus.success,
    ComplainStatus.failed,
    ComplainStatus.network,
  ])

  useEffect(() => {
    if (addComplaintMessageData.success) {
      setMessage({})
      // NotificationPopups(
      //   'success',
      //   'Success',
      //   addComplaintMessageData.data.message
      // )
    }
    if (addComplaintMessageData.failed) {
      NotificationPopups('error', 'Failed', addComplaintMessageData.data.message)
    }
    return () => {
      dispatch(resetAddComplaintMessage())
    }
  }, [addComplaintMessageData.success,
  addComplaintMessageData.failed])

  const getComplaintMessageList = (id) => {
    console.log(id, 'id')
    const req = {
      initiatedBy: login.data.user.role,
      complainId: id,
    }
    dispatch(getComplaintMessage(req))
  }

  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    setIconImage(icon[1])
    setUpdatedPhoto(filesList.base64)
  }

  const handleComplainMessage = (evt, id) => {
    const {
      target: { value },
    } = evt
    if (Util.checkWhiteSpace(value)) {
      setMessage({
        ...message,
        id: id,
        content: value,
        // attachment,
      })
    }
    // console.log('message in content = ', message)
  }

  const handleAddComplainMessage = (id) => {
    if (message.content != '') {
      const req = {
        initiatedBy: login.data.user.role,
        role: login.data.user.role,
        adminId: login.data.user.id,
        content:
          message.id === id && message.content
            ? message.content.trim()
            : 'PLease Check the Image',
        attachment: profilePictureStatus ? iconImage : '',
        //   attachment: message.attachment ? message.attachment : '',
        complainId: id,
      }
      console.log('request = ', req)
      return dispatch(addComplaintMessage(req))
    }
    // getComplaintMessageList(id)
  }

  useEffect(() => {
    console.log('add complain message data =', addComplaintMessageData)
    if (addComplaintMessageData.success) {
      getComplaintMessageList(complainId)
      setMessage({
        id: '',
        content: '',
        attachment: '',
      })

      setIconImage('')
      setUpdatedPhoto('')
    }
    return () => {
      dispatch(resetComplaintStatus())
    }
  }, [addComplaintMessageData])
  //Filter
  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        console.log(res.data.activeType, 'res.data.activeType')
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        page: page
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        page: pageForScroll
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        console.log(res, "response")
        setOptions2(res.data.activeAreaList)
        // pageForScroll === 1 ? setOptions2(res.data.activeAreaList) : setOptions2(...res.data.activeAreaList)
      })
    }
  }, [])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.status === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'complainType') setComplainType(value)
    if (name === 'startDate') setStartDate(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getComplainListRequest()
    setIsSearch(!isSearch)
  }
  useEffect(() => {
    setBldgName('')
    setBuildingId('')
    setFlatName('')
    setflatid('')
    setComplainType('')

  }, [status])

  useEffect(() => {
    if (buildingId === "") {
      dispatch(resetGetUnitFlatList())
    }
  }, [buildingId])

  const disabledButton = () => {
    return !message.content
  }

  // Infinite Scroll

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPageForScroll(pageForScroll + 1)
    }
  }
  // useEffect(() => {
  //   handleScroll()
  // }, [])

  // const handleScroll = () => {
  //   const scroll = document.getElementById("building")
  //   console.log(scroll.scrollHeight, "height")
  // }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content members-list">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons travel_info'} /> */}
                  <img
                    src={ComplaintsIcon}
                    alt="ComplaintsIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">
                  {status === 'resolved' && 'Resolved Complaints'}
                  {status === 'rejected' && 'Rejected Complaints'}
                  {status === 'pending' && 'Pending Complaints'}
                </span>
                <span style={{ float: 'right', paddingTop: '10px' }}>
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('resolved')}
                  >
                    Resolved List
                  </span>
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('rejected')}
                  >
                    {' '}
                    Rejected List{' '}
                  </span>{' '}
                  |
                  <span
                    className="right-menu"
                    onClick={() => handleStatus('pending')}
                  >
                    {' '}
                    Pending List{' '}
                  </span>
                </span>
              </CardHeader>
              {/* {complaintList.success && ( */}
              <CardBody>
                <div className="table-full-width table-responsive" id="onTop">
                  {buildingList.success && (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="3" sm="12">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="city">Building Name</Label>
                            <Select
                              type="select"
                              value={bldgName}
                              showSearch
                              id="building"
                              placeholder="Select Building name"
                              optionFilterProp="children"
                              onChange={onChangeSearch}
                              onSearch={onSearch}
                              onPopupScroll={handleScroll}
                              style={{ width: '100%', borderRadius: '25px' }}
                            // loading={buildingNames.isLoading}
                            >
                              <Option className="loginSelect" value="">
                                Select Building name
                              </Option>

                              {serachAreaName != '' &&
                                options != undefined &&
                                options.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                              {serachAreaName === '' &&
                                options2.map((d, id) => (
                                  <Option
                                    key={id}
                                    className="loginSelect"
                                    value={`${d.areaId},${d.areaName}`}
                                  >
                                    {d.areaName}
                                  </Option>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                        {areaType === 'Building' && (
                          <Col md="3" sm="12">
                            <FormGroup style={{ margin: '10px 1px' }}>
                              <Label for="flatid" lat>
                                Flat
                              </Label>
                              <Select
                                type="select"
                                showSearch
                                value={flatName}
                                placeholder="Select Flat Number"
                                optionFilterProp="children"
                                onChange={onChangeSearchFlatId}
                                onSearch={onSearchFlatId}
                                style={{ width: '100%' }}
                              >
                                <Option className="loginSelect" value="">
                                {bldgName === "" ? "No Flat Found" : getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                                </Option>
                                {searchFlat != '' && optionsUnit != undefined
                                  ? optionsUnit.map((d, id) => (
                                    <Option
                                      key={id}
                                      className="loginSelect"
                                      value={`${d.id},${d.unit}`}
                                    >
                                      {d.unit}
                                    </Option>
                                  ))
                                  : getFlat.success &&
                                  getFlat.data.activeUnitList.map((d, id) => (
                                    <Option
                                      key={id}
                                      className="loginSelect"
                                      value={`${d.id}, ${d.unit}`}
                                    >
                                      {d.unit}
                                    </Option>
                                  ))}
                              </Select>
                            </FormGroup>
                          </Col>
                        )}

                        {/* <Col md="3" sm="12">
                        <FormGroup style={{ margin: '10px 1px' }}>
                          <Label for="societyName">Name</Label>
                          <Input
                            type="text"
                            placeholder="Member Name"
                            name="MemberName"
                            id="city"
                            value={memberName || ''}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col> */}
                        {/* <Col md="3" sm="12">
                        <FormGroup style={{ margin: '10px 1px' }}>
                          <Label for="pincode">Phone Number</Label>
                          <Input
                            type="tel"
                            placeholder="Phone Number"
                            name="phone"
                            id="phone"
                            value={phone || ''}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col> */}

                        <Col md="3" sm="12">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="societyName">Complain Title</Label>
                            <Input
                              type="text"
                              placeholder="Complain Title"
                              name="complainType"
                              id="city"
                              value={complainType || ''}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12" sm="12" md="3">
                          <FormGroup style={{ margin: '10px 1px' }}>
                            <label>Date</label>{' '}
                            <span className="sub-label">(optional)</span>
                            <Input
                              name="startDate"
                              value={startDate || ''}
                              type="date"
                              onChange={handleChange}
                            // min={moment().format('YYYY-MM-DD')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: 'center' }}>
                        <Col sm="12" style={{ textAlign: 'center' }}>
                          <Button
                            className="cust-button"
                            onClick={handleSubmit}
                            disabled={complaintList.isLoading}
                          >
                            {complaintList.isLoading ? <Spin /> : 'Search'}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Raised By</th>
                        <th>Phone</th>
                        <th>Complain Title</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {complaintList.success &&
                        complaintList.data.complainDetails.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr
                              key={id}
                              id={`toggler${id}`}
                              onClick={() => {
                                getComplaintMessageList(d.id)
                                setComplainId(d.id)
                                setIds(id)
                              }}
                              style={{
                                backgroundColor: '#e8f5fd',
                                color: 'black',
                                fontWeight: 'semi-bold',
                                boxShadow: 'none',
                              }}
                            >
                              <td>
                                {d.userName}
                                <br />
                                {d.areaName} / {d.unit}
                              </td>
                              <td>{d.mobileNumber}</td>
                              <td>
                                {d.complainTitle.substr(0, 30)} {'...'}
                              </td>
                              <td>
                                {moment(d.complainDate).format('Do MMM,YYYY')}
                              </td>
                            </tr>

                            <tr className="ml-detail-tr">
                              <td colSpan="6" className="ml-detail-td">
                                <UncontrolledCollapse toggler={`toggler${id}`}>
                                  {ids === id ?
                                    <>
                                      <Row className="ml-detail-row">
                                        <Col
                                          xs="12"
                                          sm="6"
                                          md="12"
                                          lg="12"
                                          style={{
                                            padding: '20px',
                                          }}
                                        >
                                          <label>Message</label>
                                        </Col>
                                      </Row>
                                      <Row
                                        className="ml-detail-row"
                                        onScroll={scrollHandle}
                                        style={{
                                          maxHeight: '250px',
                                          overflow: 'auto',
                                          margin: 'auto',
                                          width: "80%"
                                        }}
                                      >
                                        {getComplaintMessageData.success &&
                                          getComplaintMessageData.record[d.id] &&
                                          getComplaintMessageData.record[d.id].map(
                                            (data, index) => (
                                              <>
                                                <Col
                                                  xs="12"
                                                  sm="6"
                                                  md="12"
                                                  lg="12"
                                                  key={index}
                                                  style={{
                                                    padding: '10px 5px',
                                                    borderBottom:
                                                      '1px solid #ebecf1',
                                                    // scroll:
                                                  }}
                                                >
                                                  <div
                                                    className="detail-val"
                                                    style={{
                                                      float:
                                                        data.role === 'Society'
                                                          ? 'right'
                                                          : 'left',
                                                    }}
                                                  >
                                                    <div
                                                      className={`custom-text-message-${data.role === 'Society'
                                                        ? 'right'
                                                        : 'left'
                                                        }`}
                                                    >
                                                      {data.content && data.content}
                                                      {data.role === 'Society' && <div style={{ display: 'flex', justifyContent: "end" }}>
                                                        <img
                                                          src={Tick}
                                                          alt="tick"
                                                          width="15px"
                                                          height="15px"
                                                        />
                                                      </div>}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="detail-time"
                                                    style={{
                                                      textAlign:
                                                        data.role === 'Society'
                                                          ? 'right'
                                                          : 'left',
                                                    }}
                                                  >
                                                    {Util.formatDateTimeHour(
                                                      data.timestamp
                                                    )}
                                                  </div>
                                                </Col>
                                                <Col
                                                  xs="12"
                                                  sm="6"
                                                  md="4"
                                                  lg="4"
                                                  style={{
                                                    padding: '10px 5px',
                                                    marginLeft: "auto"
                                                  }}
                                                >
                                                  {data.attachment && (
                                                    <div
                                                      onClick={() =>
                                                        handleOpenFile(
                                                          data.attachment
                                                        )
                                                      }
                                                    >
                                                      {data.attachment ? (
                                                        <img
                                                          // src={data.attachment}
                                                          src={`${Util.imageUrl}${data.attachment}`}
                                                          style={{
                                                            width: '100px',
                                                            cursor: 'pointer'
                                                          }}
                                                          // alt={`${Util.imageUrl}${data.attachment}`}
                                                          alt="image"
                                                        />
                                                      ) : (
                                                        <i
                                                          className="far fa-file-alt"
                                                          style={{
                                                            fontSize: '60px',
                                                          }}
                                                        ></i>
                                                      )}
                                                    </div>
                                                  )}
                                                </Col>
                                              </>
                                            )
                                          )}
                                      </Row>

                                      {d.complainStatus === 'pending' && (
                                        <>
                                          <div className="detail-val">
                                            <Row
                                              style={{ justifyContent: 'center' }}
                                            >
                                              <Col
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6"
                                                style={{ padding: '10px' }}
                                              >
                                                <FormGroup>
                                                  <Input
                                                    type="textarea"
                                                    row="1"
                                                    name={message.content}
                                                    value={
                                                      message.id === d.id
                                                        ? message.content
                                                        : ''
                                                    }
                                                    //id={}
                                                    placeholder="Reply"
                                                    // defaultValue={message[d.complainId] && message[d.complainId].content}
                                                    onChange={(e) =>
                                                      handleComplainMessage(e, d.id)
                                                    }
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col
                                                xs="12"
                                                sm="12"
                                                md="3"
                                                lg="3"
                                              // style={{ padding: '25px 10px' }}
                                              >
                                                <Button
                                                  className="cust-button"
                                                  style={{ minWidth: '100%' }}
                                                  onClick={() =>
                                                    handleAddComplainMessage(d.id)
                                                  }
                                                  disabled={disabledButton() || complaintList.isLoading}
                                                >
                                                  {addComplaintMessageData.isLoading ? <Spin /> : "Send"}
                                                </Button>
                                                <FormGroup
                                                  style={{ margin: '10px 1px' }}
                                                >
                                                  {iconImage && (
                                                    <img
                                                      // src={iconImage}
                                                      src={`${updatedPhoto}`}
                                                      // src={`${Util.imageUrl}${attachment}`}
                                                      width="40px"
                                                      height="40px"
                                                      style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                      }}
                                                    />
                                                  )}
                                                  <FileBase64
                                                    multiple={false}
                                                    id="iconImage"
                                                    accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                                                    onDone={handleAttachments}
                                                  />
                                                </FormGroup>


                                                {/* <FileBase64
                                              multiple={false}
                                              name={message.attachment}
                                              value={
                                                // message.id === d.id
                                                // ?
                                                msgAtt
                                              }
                                              id={`remark_${d.id}`}
                                              accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                              onDone={handleAttachments}
                                            /> */}
                                              </Col>
                                              {/* <Col
                                            xs="12"
                                            sm="12"
                                            md="3"
                                            lg="3"
                                            style={{ padding: '31px 10px' }}
                                          >
                                            <Button
                                              className="cust-button"
                                              style={{ minWidth: '100%' }}
                                              onClick={() =>
                                                handleAddComplainMessage(d.id)
                                              }
                                              disabled={disabledButton() || complaintList.isLoading}
                                            >
                                              {addComplaintMessageData.isLoading ? <Spin /> : "Send"}
                                            </Button>
                                          </Col> */}
                                            </Row>
                                          </div>
                                          <div className="detail-val">
                                            <span
                                              className="right-menu"
                                              onClick={() =>
                                                handleComplaintStatus(
                                                  d.id,
                                                  'resolved'
                                                )
                                              }
                                            >
                                              Resolved
                                            </span>
                                            {' | '}
                                            <span
                                              className="right-menu"
                                              onClick={() =>
                                                handleComplaintStatus(
                                                  d.id,
                                                  'rejected'
                                                )
                                              }
                                            >
                                              Reject
                                            </span>
                                          </div>
                                        </>
                                      )}

                                      {d.complainStatus === 'resolved' && (
                                        <>
                                          <div className="detail-val">
                                            <Row
                                              style={{ justifyContent: 'center' }}
                                            >
                                              <Col
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6"
                                                style={{ padding: '10px' }}
                                              >
                                                <FormGroup>
                                                  <Input
                                                    type="textarea"
                                                    row="1"
                                                    name={message.content}
                                                    value={
                                                      message.id === d.id
                                                        ? message.content
                                                        : ''
                                                    }
                                                    //id={}
                                                    placeholder="Reply"
                                                    // defaultValue={message[d.complainId] && message[d.complainId].content}
                                                    onChange={(e) =>
                                                      handleComplainMessage(e, d.id)
                                                    }
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col
                                                xs="12"
                                                sm="12"
                                                md="3"
                                                lg="3"
                                                style={{ padding: '25px 10px' }}
                                              >
                                                <FileBase64
                                                  multiple={false}
                                                  name={message.attachment}
                                                  value={
                                                    // message.id === d.id
                                                    // ?
                                                    msgAtt
                                                  }
                                                  id={`remark_${d.id}`}
                                                  accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                                  onDone={handleAttachments}
                                                />
                                                {/* <FileBase64
                                                multiple={false}
                                                // id={type.type}
                                                value={msgAtt}
                                                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                                onDone={handleAttachments}
                                              /> */}
                                              </Col>
                                              <Col
                                                xs="12"
                                                sm="12"
                                                md="3"
                                                lg="3"
                                                style={{ padding: '25px 10px' }}
                                              >
                                                <Button
                                                  className="cust-button"
                                                  style={{ minWidth: '100%' }}
                                                  onClick={() =>
                                                    handleAddComplainMessage(d.id)
                                                  }
                                                  disabled={disabledButton()}
                                                >
                                                  Send
                                                </Button>
                                              </Col>
                                            </Row>
                                          </div>
                                        </>
                                      )}
                                    </>
                                    : ''}
                                </UncontrolledCollapse>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                      {fileModal && (
                        <DisplayAttachment
                          attachment={`${Util.imageUrl}${attachment}`}
                          fileModal={fileModal}
                          toggleModal={toggleFileModal}
                        />
                      )}

                      {/* {(status === "resolved" && complaintList.success) && <ComplaintResolvedList complaintList={complaintList} />}
                      {(status === "rejected" && complaintList.success) && <ComplaintRejectedList complaintList={complaintList} />}
                      {(status === "pending" && complaintList.success) && <ComplaintPendingList complaintList={complaintList} getComplaintList={getComplainListRequest} />} */}
                      {/* </Collapse> */}
                    </tbody>
                  </Table>
                  {/* {complaintList.failed && (
                      <div style={{ textAlign: 'center' }}>
                        <Alert
                          message={complaintList.data.message}
                          type="info"
                        />
                      </div>
                    )} */}
                  {complaintList.failed && (
                    <div style={{ textAlign: 'center' }}>
                      <Alert message={complaintList.data.message} type="info" style={{ margin: "20px 0 20px 0" }} />
                    </div>
                  )}
                  {complaintList.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </div>
              </CardBody>
              {/* )} */}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Complaints
