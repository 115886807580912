import axios from '../../config/axios'
import {
  GET_FEATURE_SUCCESS,
  GET_FEATURE_FAILED,
  GET_FEATURE_NETWORK_ERROR,
  LOADING_GET_FEATURE,
  RESET_GET_FEATURE,
} from '../types'

const resetGetFeature = () => {
  return {
    type: RESET_GET_FEATURE,
  }
}

const getFeature = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_FEATURE })
  console.log(userData)
  axios
    .post('dashboard/getFeatureCategoryList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GET_FEATURE_NETWORK_ERROR, payload: err }))
}

function success(data) {
  return {
    type: GET_FEATURE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_FEATURE_FAILED,
    payload: data,
  }
}

export { resetGetFeature, getFeature }
