import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Popconfirm, Spin, Tooltip } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import {
    resetUpdateParking,
    updateParking,
} from 'redux/actions/updateParkingSlotAction'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Label,
    Input,
    Row,
    Col,
    Table,
    UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Select } from 'antd'

import {
    resetGetUnitFlatList,
    getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import {
    resetGetBuildingList,
    getBuildingList,
} from 'redux/actions/getBuildingListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import TrashBtn from 'assets/img/Brand/buttons/trash-icon.png'
import {
    resetUpdateUnitFlatList,
    updateUnitFLatList,
} from 'redux/actions/updateUnitFlatListAction'
import { resetGetParking, getParking } from 'redux/actions/getParkingAction'

function AssignButton({ data, toggles, build }) {

    const [propData, setPropData] = useState(data)
    console.log(propData,"propData")
    const [disable, setDisable] = useState(false)
    //filter
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [areaType, setareaType] = useState('Building')
    const [flatid, setflatid] = useState()
    const [serachAreaName, setSearchAreaName] = useState('')
    const [buildingOptionsUnit, setBuildingOptionsUnit] = useState([''])
    const [flatName, setFlatName] = useState('')
    const [searchFlat, setSearchFlat] = useState('')
    const [buildingId, setBuildingId] = useState()
    const [bldgName, setBldgName] = useState('')
    const [buildingOptions, setBuildingOptions] = useState([])
    const [buildingOptions2, setBuildingOptions2] = useState([])
    const [buildingSlotsAdded, setBuildingSlotsAdded] = useState([])
    const [editBuildingSlotsAdded, setEditBuildingSlotsAdded] = useState([])

    const login = useSelector(({ login }) => login)
    const buildingList = useSelector(({ buildingList }) => buildingList)
    const updateRole = useSelector(({ updateMemberRole }) => updateMemberRole)
    const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)
    const updatedUnitFlat = useSelector(
        ({ updateUnitFlatList }) => updateUnitFlatList
    )

    const ParkingList = useSelector(({ getParking }) => getParking)
    //filter

    const { Option } = Select

    function onChangeSearch(value) {
        let val = value.split(',')
        let val1 = val[0]
        let val2 = val[1]
        setBuildingId(val1)
        setBldgName(val2)
        setSearchAreaName('')
        setflatid('')
        setFlatName('')
    }

    function onSearch(value) {
        setSearchAreaName(value)
    }
    function onChangeSearchFlatId(value) {
        let flatval = value.split(",")
        let flatval1 = flatval[0]
        let flatval2 = flatval[1]
        setflatid(flatval1)
        setFlatName(flatval2)
        setSearchFlat('')
    }
    function onSearchFlatId(val) {
        setSearchFlat(val)
    }

    useEffect(() => {
        if (serachAreaName != '') {
            const userData = {
                areaType: 'building',
                initiatedBy: login.data.user.role,
                societyId: login.data.society.id,
                areaName: serachAreaName,
            }
            axios.post('society/getAreaSearchList', userData).then((res) => {
                setBuildingOptions(res.data.activeAreaList)
            })
        }
    }, [serachAreaName])

    useEffect(() => {
        if (areaType === 'Building' && buildingId) {
            const getUnitFlatListReq = {
                initiatedBy: login.data.user.role,
                societyId: login.data.society.id,
                areaId: buildingId,
                areaType: 'Flat',
            }
            return dispatch(getUnitFlatList(getUnitFlatListReq))
        }
    }, [buildingId])
    useEffect(() => {
        if (areaType === 'Building' && buildingId && searchFlat != '') {
            const userData = {
                initiatedBy: login.data.user.role,
                societyId: login.data.society.id,
                areaId: buildingId,
                areaType: 'Flat',
                unit: searchFlat,
            }
            axios.post('society/getUnitList', userData).then((res) => {
                setBuildingOptionsUnit(res.data.activeUnitList)
            })
        }
    }, [searchFlat])

    useEffect(() => {
        if (serachAreaName === '') {
            const userData = {
                areaType: 'building',
                initiatedBy: login.data.user.role,
                societyId: login.data.society.id,
            }
            axios.post('society/getAreaSearchList', userData).then((res) => {
                setBuildingOptions2(res.data.activeAreaList)
            })
        }
    }, [])

    useEffect(() => {
        buildingSlotsAdded.map((d) =>
            d ? setDisable(true) : setDisable(false)
        )
    }, [buildingSlotsAdded])

    const dispatch = useDispatch()

    useEffect(() => {
        {
            ParkingList.success && ParkingList.data.parkingSlotVOList.map((d, id) =>
                d.name === propData.name && d.unitIdMapping ?
                    setBuildingSlotsAdded([{
                        id: d.id,
                        areaName: d.buildingName,
                        name: d.unitName,
                        areaId: d.areaId,
                        unitIdMapping:d.unitIdMapping,
                        unitNameMapping:d.unitNameMapping
                    }]) : null
            )
        }
    }, [ParkingList.success])

    function assignParking() {
        if (bldgName && flatName && flatid) {
            const obj = {
                id: propData.id,
                areaName: bldgName,
                name: flatName.trim(),
                isActive: propData.isActive,
                areaId: propData.areaId,
                unitIdMapping: flatid,
                unitNameMapping:flatName
            }

            setflatid('')
            setFlatName('')
            setBuildingId('')
            setBldgName('')
            setBuildingSlotsAdded([...buildingSlotsAdded, obj])
            setEditBuildingSlotsAdded([...editBuildingSlotsAdded, obj])
        }
        else {
            console.log("Else is Running")
        }
    }

    build(buildingSlotsAdded)

    const removeAssign = (assignId) => {
        const updateUnitFlatListReq = {
            initiatedBy: login.data.user.role,
            id: assignId.id,
            unit: assignId.name,
            isParking: true,
            isActive: propData.isActive,
            action: 'remove',
            parkingSlot: [propData.id],
        }
        toggles()
        return dispatch(updateParking(updateUnitFlatListReq))
    }

    const updateUnitFlatListRequest = (data) => {
        console.log(data,"data")
        const updateParkingListReq = {
            initiatedBy: login.data.user.role,
            id: data.id,
            areaId: data.areaId,
            unitIdMapping: data.unitIdMapping,
            unitNameMapping: data.unitNameMapping,
            isParking: true,
            isActive: propData.isActive,
            isOccupied:true,
            action: 'add',
            parkingSlot: [propData.id],
        }
        toggles()
        return dispatch(updateParking(updateParkingListReq))
    }

    console.log(buildingSlotsAdded,"buildingSlotsAdded")

    return (
        <div>
            {/* {buildingList.success && ( */}
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label for="unit">Name</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={propData.name}
                            disabled
                        />
                    </FormGroup>
                </Col>

                <Col sm="12">
                    <FormGroup style={{ margin: '10px 1px' }}>
                        <Label for="city">Building Name</Label>
                        <Select
                            disabled={disable}
                            type="select"
                            value={bldgName}
                            showSearch
                            placeholder="Select Building name"
                            optionFilterProp="children"
                            onChange={onChangeSearch}
                            onSearch={onSearch}
                            style={{ width: '100%', borderRadius: '25px' }}
                        >
                            <Option className="loginSelect" value="">
                                Select Building name
                            </Option>

                            {serachAreaName != '' &&
                                buildingOptions != undefined &&
                                buildingOptions.map((d, id) => (
                                    <Option
                                        key={id}
                                        className="loginSelect"
                                        value={`${d.areaId},${d.areaName}`}
                                    >
                                        {d.areaName}
                                    </Option>
                                ))}
                            {serachAreaName === '' &&
                                buildingOptions2.map((d, id) => (
                                    <Option
                                        key={id}
                                        className="loginSelect"
                                        value={`${d.areaId},${d.areaName}`}
                                    >
                                        {d.areaName}
                                    </Option>
                                ))}
                        </Select>
                    </FormGroup>
                </Col>

                {areaType === 'Building' && (
                    <Col sm="12">
                        <FormGroup style={{ margin: '10px 1px' }}>
                            <Label for="flatid">
                                Flat
                            </Label>
                            <Select
                                disabled={disable}
                                type="select"
                                showSearch
                                value={flatName}
                                placeholder="Select Flat Number"
                                optionFilterProp="children"
                                onChange={onChangeSearchFlatId}
                                onSearch={onSearchFlatId}
                                style={{ width: '100%' }}
                            >
                                <Option className="loginSelect" value="">
                                    Select Flat Number
                                </Option>
                                {searchFlat != '' && buildingOptionsUnit != undefined
                                    ? buildingOptionsUnit.map((d, id) => (
                                        <Option
                                            key={id}
                                            className="loginSelect"
                                            value={`${d.id},${d.unit}`}
                                        >
                                            {d.unit}
                                        </Option>
                                    ))
                                    : getFlat.success &&
                                    getFlat.data.activeUnitList.map((d, id) => (
                                        <Option
                                            key={id}
                                            className="loginSelect"
                                            value={`${d.id},${d.unit}`}
                                        >
                                            {d.unit}
                                        </Option>
                                    ))}
                            </Select>
                        </FormGroup>
                    </Col>
                )}

                <Col sm="12">
                    <FormGroup>
                        <Button
                            color="secondary"
                            className="modalCustomButton"
                            onClick={assignParking}
                            disabled={
                                !bldgName || !flatName || !flatid
                            }
                        >
                            Add
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm="12">
                    {buildingSlotsAdded.map((data, idx) => (
                        <div key={idx} style={{ padding: '5px' }}>
                            <span> {`${data.areaName}-${data.name}`}</span>

                            <span style={{ marginLeft: '15px' }}>
                                <img
                                    src={TrashBtn}
                                    alt="edit"
                                    width="20px"
                                    height="20px"
                                    onClick={() => removeAssign(data)}
                                    style={{cursor:"pointer"}}
                                />
                            </span>
                        </div>
                    ))}
                </Col>
                <Col sm="12" md="6">
                    {buildingSlotsAdded.map((data, idx) => (
                        <Button
                            color="secondary"
                            className="modalCustomButton"
                            onClick={() => updateUnitFlatListRequest(data)}
                            disabled={updatedUnitFlat.isLoading}
                        >
                            {updatedUnitFlat.isLoading ? <Spin /> : 'Add'}
                        </Button>
                    ))}

                </Col>
                <Col sm="12" md="6">
                    <Button
                        color="secondary"
                        style={{ float: 'right' }}
                        className="modalCustomButton"
                        onClick={toggles}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
            {/* )} */}
        </div>
    )
}

export default AssignButton
