import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import { getFeature } from 'redux/actions/getFeatureAction'
import {
  resetUpdateFeature,
  updateFeature,
} from 'redux/actions/updateFeatureAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'
import { updateSubCategory } from 'redux/actions/updateSubCategoryAction'
import { getFeatureType } from 'redux/actions/getFeatureTypeAction'
import {
  resetUpdateFeatureType,
  updateFeatureType,
} from 'redux/actions/updateFeatureTypeAction'
import AddFeatureType from './AddFeatureType'
function FeatureType(onTap) {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [featureNAme, setFeatureName] = useState('')
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [featureId, setFeatureId] = useState('')
  const [subCategoryId, setSubCategoryId] = useState('')
  const [subCategoryName, setSubCategoryName] = useState('')
  const [name, setName] = useState('')
  const [redirect, setRedirect] = useState('')

  const [featureIcon, setFeatureIcon] = useState('')
  const [description, setDescription] = useState('')
  const [priorityOrder, setPriorityOrder] = useState('')
  const [isFavorite, setIsFav] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [isDashboard, setIsInDashboard] = useState(false)
  const [modal, setmodal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  //options
  const [optionsFeatureCat, setOptionsFeatureCat] = useState([''])
  const [optionsSubCat, setOptionsSubCat] = useState('')
  const [searchFeatureCat, setSearchFeatureCat] = useState('')
  const [searchSubCat, setSearchSubCat] = useState('')

  const [isActive, setisActive] = useState()
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const addedFeatureType = useSelector(({ addFeatureType }) => addFeatureType)
  const addedSubCategory = useSelector(({ addSubCategory }) => addSubCategory)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const getSubCat = useSelector(({ getSubCategory }) => getSubCategory)
  const FeatureType = useSelector(({ getFeatureType }) => getFeatureType)
  const updatedFeatureType = useSelector(
    ({ updateFeatureType }) => updateFeatureType
  )
  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = FeatureType.data.count

  //feature dropdown
  useEffect(() => {
    if (searchFeatureCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getFeatureTypeList', userData).then((res) => {
        setOptionsFeatureCat(res.data.featureCategory)
      })
    }
  }, [searchFeatureCat])

  useEffect(() => {
    if (searchSubCat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        name: searchFeatureCat,
      }
      axios.post('dashboard/getSubCategoryList', userData).then((res) => {
        setOptionsSubCat(res.data.subCategory)
      })
    }
  }, [searchSubCat])

  const getFeatureTypeRequest = () => {
    const getFeatureTypeReq = {
      initiatedBy,
      limit,
      page,
    }
    return dispatch(getFeatureType(getFeatureTypeReq))
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const getSubCategoryRequest = () => {
    const getSubCategoryReq = {
      initiatedBy,
      featureId,
    }
    return dispatch(getSubCategory(getSubCategoryReq))
  }
  useEffect(() => {
    getSubCategoryRequest()
  }, [])

  useEffect(() => {
    getFeatureTypeRequest()
  }, [])

  useEffect(() => {
    getFeatureTypeRequest()
  }, [page])

  const onChangeSwitch = (checked) => {
    setIsFav(checked)
  }
  const onChangeSwitch2 = (checked) => {
    setIsNew(checked)
  }
  const onChangeSwitch3 = (checked) => {
    setIsInDashboard(checked)
  }
  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    const icon = filesList.base64.split(`,`)
    setFeatureIcon(icon[1])
    setUpdatedPhoto(filesList.base64)
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  const { Option } = Select

  function onChangeFeature(value) {
    setFeatureId(value)
    setSubCategoryId('')
    setSubCategoryName('')
  }

  function onSearchFeature(val) {
    setFeatureName(val)
  }
  function onChangeSubCat(value) {
    const val = value.split(',')
    setSubCategoryId(val[0])
    setSubCategoryName(val[1])
  }

  function onSearchSubCat(val) {
    subCategoryName(val)
  }

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'name') setName(value)
    if (name === 'description') setDescription(value)
    if (name === 'redirect') setRedirect(value)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
    updateFeatureTypeRequest()
    // if (updatedFeatureType.success) {
    //   setmodal(!modal)
    //   NotificationPopups('success', 'Success', updatedFeatureType.data.message)
    // }
  }

  const updateFeatureTypeRequest = () => {
    const updateFeatureTypeReq = {
      initiatedBy: login.data.user.role,
      id,
      featureId,
      subCategoryId,
      name,
      redirect,
      description,
      isFavorite,
      isNew,
      isActive,
      ...(profilePictureStatus && { iconUrl: featureIcon })
    }
    return dispatch(updateFeatureType(updateFeatureTypeReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const openEditModal = (type) => {
    setFeatureId(type.featureId)
    setSubCategoryId(type.subCategoryId)
    setSubCategoryName(type.subCategoryName)
    setprofilePictureStatus(false)
    setId(type.id)
    setName(type.name)
    setDescription(type.description)
    setFeatureIcon(type.iconUrl)
    setisActive(type.isActive)
    setRedirect(type.redirect)
    setPriorityOrder(type.priorityOrder)
    setIsInDashboard(type.isDashboard)
    setIsNew(type.isNew)
    setIsFav(type.isFavorite)
    setmodal(!modal)
    // setData(type)
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }
  const toogleAdd = () => {
    setAddModal(!addModal)
  }

  useEffect(() => {
    if (addedFeatureType.success) {
      setAddModal(false)
      getFeatureTypeRequest()
    }
    if (updatedFeatureType.success) {
      setId('')
      setFeatureId('')
      setName('')
      setRedirect('')
      setmodal(!modal)
      getFeatureTypeRequest()
      NotificationPopups('success', 'Success', updatedFeatureType.data.message)
    }
    if (updatedFeatureType.failed) {
      NotificationPopups('error', 'Failed', updatedFeatureType.data.message)
    }
    if (updatedFeatureType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
   
  }, [
    addedFeatureType.success,
    updatedFeatureType.success,
    updatedFeatureType.failed,
    updatedFeatureType.network,
  ])

  useEffect(() =>{
      dispatch(resetUpdateFeatureType())
  },[updatedFeatureType.success,
    updatedFeatureType.failed])

  const getFeatureRequest = () => {
    const getFeatureReq = {
      initiatedBy
    }
    return dispatch(getFeature(getFeatureReq))
  }
  useEffect(() => {
    getFeatureRequest()
  }, [])


  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add Feature type
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Feature Type List
                    </div>
                  </Col>
                </div>
                <Col md="12" style={{ padding: '20px' }}>
                  {FeatureType.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Feature Category</th>
                            <th>Sub Category</th>
                            <th>App Screen</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {FeatureType.success && (
                          <tbody>
                            {FeatureType.data?.featureType.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>
                                    <img
                                      src={`${Util.imageUrl}${d.iconUrl}`}
                                      alt="icon"
                                      style={{
                                        height: '40px',
                                        width: '40px',
                                      }}
                                    />
                                  </td>
                                  <td>{d.name}</td>
                                  <td>{d.featureName}</td>
                                  <td>
                                    {d.subCategoryName
                                      ? d.subCategoryName
                                      : '-'}
                                  </td>
                                  <td>{d.redirect}</td>
                                  <td>
                                    {d.description.substr(0, 15)}
                                    {'...'}
                                  </td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
                <Col>
                  {FeatureType.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {FeatureType.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={FeatureType.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          style={{ maxWidth: '600px' }}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Feature Category</label>
              <Select
                type="select"
                value={featureId}
                showSearch
                placeholder="Select Feature Category"
                optionFilterProp="children"
                onChange={onChangeFeature}
                onSearch={onSearchFeature}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Feature Category
                </Option>
                {getFeatureCat.success &&
                  getFeatureCat.data.featureCategory.map((d, id) => (
                    <Option key={id} className="loginSelect" value={d.id}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup style={{ margin: '10px 1px' }}>
              <label>Select Sub Category </label>
              <Select
                type="select"
                value={subCategoryName}
                showSearch
                placeholder="Select Sub Category Type"
                optionFilterProp="children"
                onChange={onChangeSubCat}
                onSearch={onSearchSubCat}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Sub Category Type
                </Option>
                {getSubCat.success &&
                  getSubCat.data.subCategory.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.name}`}
                    >
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="helptype">Feature Type</Label>
              <Input
                type="text"
                name="name"
                id="helptype"
                value={name}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Description</Label>
              <Input
                type="text"
                placeholder="Description"
                name="description"
                id="serviceType"
                value={description || ''}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="helptype">App Screen Name</Label>
              <Input
                type="text"
                name="redirect"
                id="helptype"
                value={redirect}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Col xs="12" sm="12" md="12">
                <label>Icon</label>
                <div style={{ cursor: 'pointer' }}>
                  {
                    <img
                      src={
                        profilePictureStatus
                          ? `${updatedPhoto}`
                          : `${Util.imageUrl}${featureIcon}`
                      }
                      style={{
                        width: '50%',
                        height: '150px',
                        border: 'rounded',
                      }}
                    />
                  }
                </div>{' '}
                <div style={{ marginTop: '10px' }}>
                  <FileBase64
                    multiple={false}
                    id="editNoticeFiles"
                    accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                    onDone={handleAttachments}
                  />
                </div>
              </Col>
            </FormGroup>

            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>Favourite</Label>
              <br />
              <Switch
                defaultChecked={isFavorite}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
              // style={{ width: '100%' }}
              />
            </FormGroup>

            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>New</Label>
              <br />
              <Switch
                defaultChecked={isNew}
                name="isAttendance"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch2}
              // style={{ width: '100%' }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditFeature}
              disabled={updatedFeatureType.isLoading || !name || !redirect}
            >
              {updatedFeatureType.isLoading ? <Spin /> : 'Edit'}
              {/* Edit */}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Feature Type</ModalHeader>
          <ModalBody>
            <AddFeatureType />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default FeatureType
