import {
  GET_HELPER_DOC_SUCCESS,
  GET_HELPER_DOC_FAILED,
  GET_HELPER_DOC_NETWORK_ERROR,
  LOADING_GET_HELPER_DOC,
  RESET_GET_HELPER_DOC,
} from '../types'

const initialState = {
  isLoading: false,
  success: false,
  failed: false,
  network: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_GET_HELPER_DOC:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case GET_HELPER_DOC_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        network: false,
        failed: true,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case GET_HELPER_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case GET_HELPER_DOC_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        network: true,
        failed: false,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case RESET_GET_HELPER_DOC:
      return (state = initialState)

    default:
      return state
  }
}
