import {
  UPDATE_PARKING_SUCCESS,
  UPDATE_PARKING_FAILED,
  UPDATE_PARKING_NETWORK_ERROR,
  LOADING_UDPATE_PARKING,
  RESET_UPDATE_PARKING,
} from 'redux/types'
import axios from 'config/axios'

const resetUpdateParking = () => {
  return { type: RESET_UPDATE_PARKING }
}

const updateParking = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UDPATE_PARKING })
  axios
    .post('society/updateParkingSlot', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PARKING_NETWORK_ERROR,
        payload: err,
      })
    })
}

function success(data) {
  return {
    type: UPDATE_PARKING_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: UPDATE_PARKING_FAILED,
    payload: data,
  }
}
export { resetUpdateParking, updateParking }
