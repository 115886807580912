import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
// reactstrap components
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import Util from 'validations/index'
import { Tooltip, Popconfirm, Alert, Switch } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetAddHelperType,
  addHelperType,
} from 'redux/actions/addHelperTypeAction'
import { getHelperType } from 'redux/actions/getHelperTypeAction'
import AddHelperTypeForm from './AddHelperTypeForm'
import classnames from 'classnames'

function HelperType() {
  const [activeTab, setActiveTab] = useState()
  const login = useSelector(({ login }) => login)
  const servType = useSelector(({ getservicetype }) => getservicetype) //
  const helperType = useSelector(({ getHelperType }) => getHelperType)
  const dispatch = useDispatch()
  const [type, setType] = useState('')
  const [servTypeId, setServTypeId] = useState('')

  const toggle = (tab, id, type) => {
    if (activeTab !== tab) setActiveTab(tab)
    setServTypeId(id)
    setType(type)
  }

  useEffect(() => {
    getServiceTypeRequest()
  }, [])

  const getServiceTypeRequest = () => {
    const getServiceTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getServiceType(getServiceTypeReq))
  }

  const iconArr = (t) => {
    switch (t.toLowerCase()) {
      case 'Daily Helper':
        return 'fas fa-people-carry'
      case 'Handy man':
      case 'handyman':
        return 'fas fa-screwdriver'
      case 'Tutors':
        return 'fas fa-chalkboard-teacher'
      case 'Society Security':
        return 'fas fa-user-shield'
      case 'society maintenance staff':
        return 'fas fa-wrench'
      case 'vendors':
      case 'vendor':
        return 'fas fa-balance-scale'
      case 'full time helpers':
        return 'fas fa-hands-helping'
      case 'society administration staff':
        return 'fas fa-user-cog'
      case 'transport':
        return 'fas fa-dolly'
      default:
        return 'fas fa-cog'
    }
  }
  return (
    <>
      <Row style={{width:"100%" , margin:"auto"}}>
        <Col md="12" style={{ padding: '20px' }}>
          <Nav tabs>
            {servType.success &&
              servType.data.activeServiceType.map((d, id) => (
                <NavItem key={id}>
                  <NavLink
                    data-id={id}
                    className={classnames({
                      active: activeTab === id,
                    })}
                    onClick={() => {
                      toggle(id, d.id, d.type)
                    }}
                    key={id}
                  >
                    <div className={`sd-col`}>
                      <div className="sd-ic">
                        <img
                          // src={d.iconImage}
                          src={`${Util.imageUrl}${d.iconImage}`}
                          // alt={d.iconImage}
                          // alt={`${Util.imageUrl}${d.iconImage}`}
                          alt="icon"
                          style={{
                            width: '60px',
                            height: '40px',
                          }}
                        />
                      </div>
                      <p className="sd-label">{d.type}</p>
                    </div>
                  </NavLink>
                </NavItem>
              ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {type && (
              <AddHelperTypeForm serType={type} serTypeId={servTypeId} />
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  )
}

export default HelperType
