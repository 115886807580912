import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NoticeBoard from 'assets/img/Brand/notice.png'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import randomColor from 'randomcolor'
import Util from 'validations/index'
import { Alert, Spin, Switch } from 'antd'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import AddFeeConfig from './AddFeeConfig'
import {
  resetAddMaintenanceType,
  addMaintenanceType,
} from 'redux/actions/addMntTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import PaginationComponent from 'views/Pagination'

function ViewFeeConfig({sendData, onTap}) {
  const [addModal, setAddModal] = useState(false)
  const [billDetails, setBillDetails] = useState(false)
  const [page, setPage] = useState(1)
  const [editModal, setEditModal] = useState(false)
  const [mntName, setMntName] = useState()
  const [mntFee, setMntFee] = useState()
  const [mntData, setMntData] = useState('')
  const [id, setId] = useState()

  const login = useSelector(({ login }) => login)
  const addedMntType = useSelector(({ addMntType }) => addMntType)
  const mntType = useSelector(({ getMntType }) => getMntType)

  const dispatch = useDispatch()
  const limit = 6
  const totalItems = mntType.data.count

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const toogleAdd = () => {
    setAddModal(!addModal)
  }

  const toogleEdit = () => {
    setEditModal(!editModal)
  }

  const onChange = (checked) => {
    console.log(checked, 'This is checked !!!')
    setMntFee(checked)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'mntName')
      Util.alphabetsOnly(value) ? setMntName(value) : setMntName(mntName)
  }
  // const toggleBill = () => {
  //   setBillDetails(!billDetails)
  // }

  const addMntTypeRequest = () => {
    const addMntTypeReq = {
      initiatedBy: login.data.user.role,
      name: mntName.trim(),
      isFee: mntFee,
      id,
      action: 'update',
    }
    return dispatch(addMaintenanceType(addMntTypeReq))
  }

  const getMntTypeRequest = () => {
    const getMntTypeReq = {
      initiatedBy: login.data.user.role,
      name: sendData.name,
      isFee: sendData.isFee,
      action: "fee",
      limit,
      page,
    }
    return dispatch(getMaintenanceType(getMntTypeReq))
  }
  useEffect(() => {
    getMntTypeRequest()
    return () => resetgetMaintenanceType()
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    if (addedMntType.success) {
      setAddModal(false)
      getMntTypeRequest()
    }
  }, [addedMntType.success, addedMntType.failed, addedMntType.network])

  const openEditModal = (d) => {
    // setdetails(data)
    // setHelperId(data.id)
    setEditModal(!editModal)
    setMntFee(d.isFee)
    setMntName(d.name)
    setId(d.id)
  }
  const handleMntEdit = () => {
    addMntTypeRequest()
  }

  useEffect(() => {
    if (addedMntType.success) {
      setMntName('')
      setMntFee('')
      setEditModal(false)
      getMntTypeRequest()
      NotificationPopups('success', 'Success', addedMntType.data.message)
    }
    if (addedMntType.failed) {
      NotificationPopups('error', 'Failed', addedMntType.data.message)
    }
    if (addedMntType.network) {
      NotificationPopups('error', 'Network Error', addedMntType.data.message)
    }
    return () => {
      resetAddMaintenanceType()
    }
  }, [addedMntType.success, addedMntType.failed, addedMntType.network])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Maintenance Master List{' '}
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {mntType.success && (
                    <>
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th> Name</th>
                              <th>Fee</th>
                              <th></th>
                            </tr>
                          </thead>
                          {mntType.success && (
                            <tbody>
                              {mntType.data?.maintenanceTypeList.map(
                                (d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td>{d.name}</td>
                                      <td>{d.isFee ? 'Yes' : 'No'}</td>
                                      <td>
                                        <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => {
                                            openEditModal(d)
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {mntType.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={mntType.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {mntType.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '250px', maxHeight: '500px', marginRight: "auto", marginLeft: "auto" }}
        >
          <ModalHeader toggle={toogleAdd}> Add</ModalHeader>
          <ModalBody>
            <AddFeeConfig />
          </ModalBody>
        </Modal>
      )}

      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toogleEdit}
          size="lg"
          centered
          style={{ maxWidth: '250px', maxHeight: '500px', marginRight: "auto", marginLeft: "auto" }}
        >
          <ModalHeader toggle={toogleEdit}>Fee Details</ModalHeader>
          <ModalBody>
            <div>
              <FormGroup>
                <Label for="mntName">Name</Label>
                <Input
                  type="text"
                  name="mntName"
                  placeholder=" Name"
                  id="mntName"
                  value={mntName || ''}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>is Fee</Label>
                <br />
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={mntFee}
                  onChange={onChange}
                />
              </FormGroup>

              <Button
                color="secondary"
                className="modalCustomButton"
                onClick={handleMntEdit}
                style={{ position: 'relative', left: '4rem', width: '40%' }}
              >
                Edit
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewFeeConfig
