import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Switch, Select, Checkbox } from 'antd'

import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetAddUnitFlatList,
  addUnitFlatList,
} from 'redux/actions/addUnitFlatListAction'
import axios from 'config/axios'
import Util from 'validations/index'

import Add from 'assets/img/Icons/flat/add.png'
import Delete from 'assets/img/Icons/flat/delete.png'

function AddCommonAreaFlatList({ areaTypeId, areaType, areaId }) {
  const [buildingId, setBuildingId] = useState('')
  const [noOfFlatsPF, setNoOfFlatsPF] = useState()
  const [noOfFloors, setNoOfFloors] = useState(0)
  const [startRange, setStartRange] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [floors, setFloors] = useState([])
  const [startFloor, setStartFloor] = useState([])
  const [groundFloorCheck, setGroundFloorCheck] = useState(false)

  const getareaType = useSelector(({ areaType }) => areaType)
  const addedFlatList = useSelector(({ addUnitFlatList }) => addUnitFlatList)
  const buildingNames = useSelector(({ buildingNames }) => buildingNames)
  const buildingList = useSelector(({ buildingList }) => buildingList)
  const login = useSelector(({ login }) => login)
  const updatedBuilding = useSelector(({ updateBuilding }) => updateBuilding)

  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')

  const dispatch = useDispatch()

  function handleChange({ target: { name, value, max } }) {
    if (name === 'noOfFlatsPF') {
      Util.verifyMobile(value) && value >= max
        ? setNoOfFlatsPF(0)
        : setNoOfFlatsPF(Math.max(0, value))
    }
    if (name === 'prefix')
      // Util.alphabetsOnly(value) ? setPrefix(value.toUpperCase().trim()) : setPrefix(prefix.trim())
      setPrefix(value.toUpperCase().trim())

    if (name === 'noOfFloors') {
      Util.verifyMobile(value) && value >= max
        ? setNoOfFloors(0)
        : setNoOfFloors(Math.max(0, value))
    }
    if (name === 'startRange')
      Util.verifyMobile(value)
        ? setStartRange(Math.max(0, value))
        : setStartRange(0)
    if (name === 'startFloor')
      Util.verifyMobile(value)
        ? setStartFloor(value)
        : setStartFloor(startFloor)
    // setStartFloor(value)
  }
  const { Option } = Select

  function onChangeSearch(value) {
    console.log(value,"valuuuu")
    let val = value.split(",")
   

    setBuildingId(val[0])
    setBldgName(val[1])
    setNoOfFloors(val[2])
    setSearchAreaName('')
    if (bldgName !== val[1]) {
      setNoOfFlatsPF('')
      setStartRange('')
    }
  }

  function onSearch(val) {
    setSearchAreaName(val)
  }

  useEffect(() => {
    if (updatedBuilding.success) {
      setBldgName('')
      setNoOfFloors('')
    }
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName, updatedBuilding.success])

  useEffect(() => {
    if (updatedBuilding.success) {
      setBldgName('')
      setNoOfFloors('')
    }
    if (serachAreaName === '') {
      const userData = {
        areaType: areaType === "Flat" ? 'building' : areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
      setBldgName('')
      setNoOfFloors('')
    }
  }, [areaType, updatedBuilding.success])

  function AddFlatBtn(floorIndex) {
    const newFloors = floors.map((rooms, floorIdx) => {
      if (floorIndex === floorIdx) {
        return [
          ...rooms,
          {
            isParking: false,
            unit: startRange
              ? `${prefix}` +
              parseInt(startRange + floorIndex * 100 + rooms.length)
              : null,
            floor: floorIdx + 1,
            initiatedBy: login.data.user.role,
            societyId: login.data.society.id,
            areaId: buildingId,
            areaTypeId,
          },
        ]
      }
      return rooms
    })
    setFloors(newFloors)
  }

  function EditUnit(floorIndex, roomIndex, name, e, checked) {
    console.log(floorIndex, roomIndex, name, e, checked, "visitor")
    const mainObj = [...floors]
    const floorsMap = [...mainObj[floorIndex]]
    floorsMap[roomIndex] = {
      ...floorsMap[roomIndex],
      floor: floorIndex + 1,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaId: buildingId,
      areaTypeId,
      [name]: name === 'unit' ? e.target.value : checked,
    }
    mainObj[floorIndex] = floorsMap
    setFloors(mainObj)
  }

  // console.log(mainObj)
  const addFlatListRequest = () => {
    const obj = floors.flat()
    return dispatch(addUnitFlatList(obj))
  }

  function handleSubmit(e) {
    e.preventDefault()
    addFlatListRequest()
    // setGroundFloorCheck(false)
    setPrefix('')
    // inputRef.current.focus()
  }

  function RemoveFlatBtn(floorIndex) {
    const newFloors = floors.map((rooms, i) => {
      if (floorIndex === i) {
        return rooms.splice(0, rooms.length - 1)
      }
      return rooms
    })

    setFloors(newFloors)
  }

  useEffect(() => {
    const increment = startRange < 99 ? 10 : 100

    if (
      noOfFloors &&
      noOfFlatsPF
    ) {
      let len = parseInt(noOfFloors - startFloor + 1)
      const newFloors = Array(len).fill(null).map((_, floorIdx) =>
        Array(noOfFlatsPF)
          .fill(null)
          .map((_, roomIdx) => {
            return {
              // unit: startRange ? prefix + - + parseInt(startRange + floorIdx * increment + roomIdx) : 0,
              unit: startRange ? (prefix && `${prefix}-`) + (startRange < 99 ? parseInt(startRange + floorIdx * increment * (floorIdx === 0 ? '' : 10) + roomIdx) : parseInt(startRange + floorIdx * increment + roomIdx)) : '',
              isParking: false,
              floor: `${parseInt(startFloor) + floorIdx}`,
              initiatedBy: login.data.user.role,
              societyId: login.data.society.id,
              areaId: buildingId,
              areaTypeId,
            }
          })
      )

      newFloors ? setFloors(newFloors) : setFloors([])
    }
  }, [noOfFloors, noOfFlatsPF, startRange, startFloor, prefix])

  useEffect(() => {
    if (addedFlatList.success) {
      setNoOfFloors('')
      setNoOfFlatsPF('')
      setStartRange('')
      setBuildingId('')
      setBldgName('')
      setStartFloor('')
      setFloors([])
      NotificationPopups('success', 'Success', addedFlatList.data.message)
    }
    if (addedFlatList.failed) {
      setNoOfFloors('')
      setNoOfFlatsPF('')
      setStartRange('')
      setBldgName('')
      setBuildingId('')
      setStartFloor('')
      setFloors([])
      NotificationPopups('error', 'Failed', addedFlatList.data.message)
    }
    if (addedFlatList.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
        // addedFlatList.data.message
      )
    }

    return () => {
      dispatch(resetAddUnitFlatList())
    }
  }, [addedFlatList.success, addedFlatList.failed, addedFlatList.network])

  const disableButton = () => {
    return !(buildingId && noOfFloors && noOfFlatsPF)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <Col lg="3" md="3" sm="12" xs="12">
          {options2 ? (
            <FormGroup>
              <Label for="buildingId">Building Name</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                showSearch
                value={bldgName}
                placeholder="Select Building name"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Building name
                </Option>
                {serachAreaName != '' &&
                  options != undefined &&
                  options.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName},${d.floor}`}
                    >
                      {d.areaName.split('-')[0]}
                    </Option>
                  ))}
                {serachAreaName === '' &&
                  options2.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.areaId},${d.areaName},${d.floor}`}
                    >
                      {d.areaName.split('-')[0]}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          ) : (
            <Label for="buildingId">Please Add Building</Label>
          )}
        </Col>
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="floors">Number of Floors</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="noOfFloors"
              id="floors"
              placeholder="Floors"
              value={noOfFloors || ''}
              onChange={handleChange}
              max="99"
              disabled
              maxLength="3"
              required
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="startRange">Start Floor</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="startFloor"
              id="startFloor"
              placeholder="Start Floor"
              value={startFloor || ''}
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="noOfFlatsPF">Number of {areaType} per Floor</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="noOfFlatsPF"
              id="noOfFlatsPF"
              placeholder={`Number of ${areaType} per Floor`}
              value={noOfFlatsPF || ''}
              onChange={handleChange}
              max="99"
              required
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="noOfFlatsPF">Enter Default Prefix</Label>
            <Input
              type="text"
              name="prefix"
              id="prefix"
              placeholder="Enter Prefix"
              value={prefix || ''}
              onChange={handleChange}
              maxLength="5"
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="12" xs="12">
          <FormGroup>
            <Label for="startRange">Start Range</Label>
            <span className='required'>*</span>
            <Input
              type="text"
              name="startRange"
              id="startRange"
              placeholder="Start Range"
              value={startRange || ''}
              onChange={handleChange}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      {/* <Col>
        <Checkbox onChange={(e) => setGroundFloorCheck(e.target.checked)}
          checked={groundFloorCheck}>
          Check this to set it as default prefix.
        </Checkbox>
      </Col> */}

      {floors.length
        ? floors.map((floor, floorIndex) => {
          return (
            <Row key={floorIndex}>
              {noOfFlatsPF ?
                <>
                  <div style={{
                    width: "100%",
                    padding: "15px",
                    textDecorationLine: 'underline',
                    fontSize: '18px'
                  }}>Floor : {`${parseInt(startFloor) + floorIndex}`}</div>
                  {
                    floor.map((room, roomIndex) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={Math.ceil(12 / noOfFlatsPF)}
                        lg={Math.ceil(12 / noOfFlatsPF)}
                        key={roomIndex}
                      >
                        <FormGroup>
                          <Label>Unit</Label>
                          <Input
                            placeholder={`${areaType} Number`}
                            type="text"
                            name="unit"
                            value={room.unit}
                            onChange={(e) => {
                              EditUnit(floorIndex, roomIndex, 'unit', e, null)
                            }}
                          />
                        </FormGroup>
                        <FormGroup tag="fieldset">
                          <Label>Parking</Label>
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            // defaultunChecked
                            // defaultChecked
                            onChange={(checked, e) => {
                              EditUnit(
                                floorIndex,
                                roomIndex,
                                'isParking',
                                e,
                                checked
                              )
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ))
                  }
                  {
                    floor.length !== 0 ? (
                      <>
                        <div style={{ padding: '10px', margin: '5px' }}>
                          <Tooltip
                            placement="leftBottom"
                            title="Add Flat"
                            color="#108ee9"
                          >
                            <Button
                              className="sd-add-icons"
                              onClick={() => {
                                AddFlatBtn(floorIndex)
                              }}
                            >
                              <img src={Add} alt="add" style={{ width: '20px' }} />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            placement="rightBottom"
                            title="Delete Flat"
                            color="#108ee9"
                          >
                            <Button
                              className="sd-add-icons"
                              onClick={() => {
                                RemoveFlatBtn(floorIndex)
                              }}
                            >
                              <img
                                src={Delete}
                                alt="delete"
                                style={{ width: '20px' }}
                              />
                            </Button>
                          </Tooltip>
                        </div>
                      </>
                    ) : null
                  }
                </>
                :
                null
              }
            </Row>
          )
        })
        : null}

      <Row style={{ justifyContent: 'center', textAlign: "center" }}>
        <Col lg="4" md="4" sm="12" xs="12">
          <Button
            className="cust-button"
            style={{ marginTop: '22px', width: '25%' }}
            onClick={handleSubmit}
            disabled={addedFlatList.isLoading || disableButton()}
          // ref={inputRef}
          >
            {addedFlatList.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Col>
      </Row>
    </div >
  )
}

export default AddCommonAreaFlatList
