import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from 'reactstrap'
import { Spin } from 'antd'
import { Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import Gateway from 'assets/img/Icons/Sidebar/payment-gateway.png'
import { addGateway } from 'redux/actions/addGatewayAction'
import { getGateway, resetGetGateway } from 'redux/actions/getGatewayAction'
import { getSocietyList } from 'redux/actions/getSocietyListAction'
import Util from 'validations'
import axios from 'config/axios'

const GatewayMaster = (props) => {
  const { Option } = Select
  const login = useSelector(({ login }) => login);
  const dispatch = useDispatch();
  const getGatewayList = useSelector(
    ({ getGateway }) => getGateway
  )
  const getSocietyListDrop = useSelector(
    ({ getSocietyList }) => getSocietyList
  )

  const [ownerId, setOwnerId] = useState('');
  const [currency, setCurrency] = useState('');
  const [intName, setIntName] = useState('');
  const [intType, setIntType] = useState('');
  const [societyList, setSocietyList] = useState();
  const [options, setOptions] = useState([]);

  //   const addGatewayListRequest = () => {
  //   const addGatewayListReq = {
  //     initiatedBy: login.data.user.role,
  //     action: "Add",
  //     walletOwnerId: ownerId,
  //     integrationName: intName,
  //     integrationType: intType,
  //     accTerminalCurrency: currency,
  //   }
  //   return dispatch(addGateway(addGatewayListReq))
  // }

  const getGatewayListRequest = () => {
    const getGatewayListReq = {
      initiatedBy: login.data.user.role,
      walletOwnerId: ownerId,
      integrationName: intName.trim(),
      integrationType: intType.trim(),
      accTerminalCurrency: currency.trim(),
      limit: 6
    }
    return dispatch(getGateway(getGatewayListReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'ownerId')
      Util.verifyMobile(value) ? setOwnerId(value) : setOwnerId(ownerId)
    if (name === 'currency')
      Util.alphabetsOnly(value) ? setCurrency(value) : setCurrency(currency)
    if (name === 'intName')
      Util.alphabetsOnly(value) ? setIntName(value) : setIntName(intName)
    if (name === 'intType')
      Util.alphabetsOnly(value) ? setIntType(value) : setIntType(intType)
  }

const getSocietyListReq = () =>{
  const req = {
      initiatedBy: "SuperAdmin",
      societyName: societyList
  }
  return dispatch(getSocietyList(req))
}

useEffect(() =>{
  getSocietyListReq()
},[societyList])

  const handleSubmit = (e) => {
    e.preventDefault()
    // addGatewayListRequest()
    getGatewayListRequest()
    props.setIsSearch()
    return(
      setOwnerId('')
    )
  }

  const currencyDrop = ['INR'];
  // const ownerIdDrop = ['45-DeCash', '24-Kelpay', '23-Repro-Pay', '13-NPCC', '7-DecimalFinance', '4-Transactworld', '1-M-Transact'];
  const IntDrop = ['API', 'Checkout', 'Both'];

  function onSelectOwner(value) {
    setOwnerId(value)
    setSocietyList('')
  }

  const onSearch = (val) => {
    setSocietyList(val)
  }

  function onSelectInt(value) {
    setIntType(value)
  }
  function onSelectCurrency(value) {
    setCurrency(value)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="listcontent">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  {/* <i className={'now-ui-icons ui-1_calendar-60'} /> */}
                  <img
                    src={Gateway}
                    alt="NoticeBoardIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Gateway Master</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="3" md="3">
                    <FormGroup>
                      <label>Society Name</label>
                      <Select
                        type="select"
                        showSearch
                        value={ownerId}
                        placeholder="Select Owner Name"
                        optionFilterProp="children"
                        onChange={onSelectOwner}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Society Name
                        </Option>
                        {getSocietyListDrop.success &&
                          [].concat(getSocietyListDrop.data.societyList).map((d, id) => (
                            <Option
                              key={id}
                              className="loginSelect"
                              value={d.id}
                            >
                              {d.societyName}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="3" md="3">
                    <FormGroup>
                      <label>Currency</label>
                      <Select
                        type="select"
                        showSearch
                        value={currency}
                        placeholder="Select Currency"
                        // optionFilterProp="children"
                        onChange={onSelectCurrency}
                        // onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Currency
                        </Option>
                        {currencyDrop.map((d, id) => (
                          <Option key={id} value={d}>{d}</Option>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col md="12" sm="3" lg="3" xs="12">
                    <Label for="month">Integration Name</Label>
                    <Input
                      type="text"
                      name="intName"
                      id="intName"
                      placeholder="Integration Name"
                      value={intName}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col xs="12" sm="3" md="3">
                    <FormGroup>
                      <label>Integration Type</label>
                      <Select
                        type="select"
                        showSearch
                        value={intType}
                        placeholder="Select Integration Type"
                        optionFilterProp="children"
                        onChange={onSelectInt}
                        // onSearch={onSearch}
                        style={{ width: '100%' }}
                      >
                        <Option className="loginSelect" value="">
                          Select Integration Type
                        </Option>
                        {IntDrop.map((d, id) => (
                          <Option key={id} value={d}>{d}</Option>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    style={{ textAlign: 'center', margin: '20px 0px' }}
                  >
                    <Button
                      className="cust-button"
                      onClick={handleSubmit}
                    // disabled={addNewNotice.isLoading || disabledBtn()}
                    >
                      {getGatewayList.isLoading ? <Spin /> : 'Search'}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default GatewayMaster