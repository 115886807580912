import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
//Components
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Checkbox } from 'antd'
import moment from 'moment'
import Util from 'validations/index'
import { getTransactionList } from 'redux/actions/getGatewayTransactionList'
import { getTransactionDetails } from 'redux/actions/getGatewayTransactionDetailsAction'
import PaginationComponent from 'views/Pagination';
// import PaginationComponent from 'react-reactstrap-pagination'

const ViewTransactionsList = ({ sendData, onTap }) => {
  let { endDate, startDate, status, userId } = sendData
  const [page, setPage] = useState(1)
  const [trackingId, setTrackingId] = useState('')
  const [ids, setIds] = useState('')

  // const [coll, setColl] = useState([])
  const tableRef = useRef(undefined)
  const [limit] = useState(6)

  const login = useSelector(({ login }) => login)
  const transactionList = useSelector(
    ({ getTransactionList }) => getTransactionList
  )

  const totalItems = transactionList.data.count

  const transactionDetail = useSelector(
    ({ getTransactionDetails }) => getTransactionDetails
  )
  const dispatch = useDispatch()

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const getTransactionListRequest = () => {
    // endDate = endDate ? endDate : moment().format('YYYY-MM-DD')
    // const date = new Date()
    // const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    // startDate = startDate ? startDate : moment(firstDay).format('YYYY-MM-DD')
    const getTransactionListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      endDate,
      startDate,
      status,
      userId,
      page,
      limit,
    }
    return dispatch(getTransactionList(getTransactionListReq))
  }

  useEffect(() => {
    const getTransactionListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      endDate,
      startDate,
      status,
      page,
      limit,
    }
    return dispatch(getTransactionList(getTransactionListReq))
  }, [])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  const getTransactionDetailsRequest = () => {
    const getTransactionDetailsReq = {
      initiatedBy: login.data.user.role,
      trackingId,
    }
    return dispatch(getTransactionDetails(getTransactionDetailsReq))
  }

  useEffect(() => {
    if (transactionList.data.status == "OK" && page) {
      getTransactionListRequest()
    }
  }, [page])

  useEffect(() => {
    getTransactionDetailsRequest()
  }, [trackingId])
  // useEffect(() => {
  // }, [window])

  // useEffect(() => {
  //   if (transactionDetail.data.transactionVO) {
  //     if (
  //       transactionDetail.data.transactionVO.transactionResVOList[0]
  //         .trackingId == trackingId
  //     ) {
  //       setColl([...coll, transactionDetail.data.transactionVO])
  //     }
  //   }
  // }, [transactionDetail, trackingId])

  // const collapseObj = {
  //   getTransactionListRequest()
  // }


  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Col>
              <div className="typeHeading" id="onTop">
                Transaction List
              </div>
            </Col>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                <>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Table
                      responsive
                      style={{ textAlign: 'center' }}
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th>Transaction<br />Date</th>
                          <th>Tracking<br />ID</th>
                          {/* <th>Payment Id</th> */}
                          <th>Customer<br />ID</th>
                          <th>Amount</th>
                          <th>Payment<br />Mode</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionList.success &&
                          transactionList.data.transactionList.map((d, idx) => (
                            <React.Fragment key={idx}>
                              <tr
                                key={idx + 1}
                                id={`toggler${idx}`}
                                style={{
                                  backgroundColor: '#e8f5fd',
                                  color: 'black',
                                  fontWeight: 'semi-bold',
                                  boxShadow: 'none',
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  setTrackingId(d.trackingId)
                                  setIds(idx)
                                }}
                              >
                                <td>
                                  {moment(d.timestamp).format('Do MMM,YY')}
                                </td>
                                <td>{d.trackingId}</td>
                                {/* <td>{d.paymentId}</td> */}
                                <td>{d.customerId}</td>
                                <td>₹ {d.amount}</td>
                                <td>{d.paymentMode}</td>
                                <td>{d.status}</td>
                              </tr>

                              <tr className="ml-detail-tr">
                                <td colSpan="6" className="ml-detail-td">
                                  <UncontrolledCollapse
                                    toggler={`toggler${idx}`}
                                  >
                                    {ids == idx ? (
                                      <Row className="ml-detail-row">
                                        <Col md="6">
                                          <h6>Invoice Detail</h6>
                                          <Table
                                            responsive
                                            style={{ textAlign: 'center' }}
                                          >
                                            <thead>
                                              <tr style={{ fontSize: '12px' }}>
                                                <th>Flat</th>
                                                <th>Bill No.</th>
                                                <th>Bill Date</th>
                                                <th>Total Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {transactionDetail.success &&
                                                transactionDetail.data.transactionVO.invoiceResVOList.map(
                                                  (d, idx) => (
                                                    <tr
                                                      key={idx}
                                                      style={{
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      <td>
                                                        {d.area}/{d.unit}
                                                      </td>
                                                      <td>{d.billNumber}</td>
                                                      <td>
                                                        {moment(
                                                          d.billDate
                                                        ).format('Do MMM,YY')}
                                                      </td>
                                                      <td>₹ {d.totalAmount}</td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                        <Col md="6">
                                          <h6>Customer Info</h6>
                                          <Table
                                            responsive
                                            style={{ textAlign: 'center' }}
                                          >
                                            <thead>
                                              <tr style={{ fontSize: '12px' }}>
                                                <th>Name</th>
                                                <th>Email Id</th>
                                                <th>Mobile No.</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {transactionDetail.success &&
                                                transactionDetail.data.transactionVO.userVOList.map(
                                                  (d, idx) => (
                                                    <tr
                                                      key={idx}
                                                      style={{
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      <td>{d.name}</td>
                                                      <td>{d.email}</td>
                                                      <td>{d.mobileNumber}</td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                        <Col md="6">
                                          <h6>Transaction Info</h6>
                                          <Table
                                            responsive
                                            style={{ textAlign: 'center' }}
                                          >
                                            <thead>
                                              <tr style={{ fontSize: '12px' }}>
                                                <th>Tracking Id</th>
                                                {/* <th>Payment Id</th> */}
                                                <th>Transaction Type</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {transactionDetail.success &&
                                                transactionDetail.data.transactionVO.transactionResVOList.map(
                                                  (d, idx) => (
                                                    <tr
                                                      key={idx}
                                                      style={{
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      <td>{d.trackingId}</td>
                                                      {/* <td>{d.paymentId}</td> */}
                                                      <td>
                                                        {d.transactionType}
                                                      </td>
                                                      <td>{d.status}</td>
                                                      <td>₹ {d.amount}</td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                      </Row>
                                    ) : (
                                      ''
                                    )}
                                  </UncontrolledCollapse>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Row>
                    <Col>
                      {transactionList.success && (
                        <div>
                          <PaginationComponent
                            limit={limit}
                            totalItems={totalItems}
                            onSelect={handlePagination}
                            defaultPage={page}
                          />
                          {/* <PaginationComponent
                            totalItems={totalItems}
                            pageSize={limit}
                            maxPaginationNumbers={5}
                            defaultActivePage={page}
                            nextPageText={'Next'}
                            previousPageText={'Previous'}
                            onSelect={handlePagination}
                          /> */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              </Col>

              <Col md="12">
                {transactionList.failed && (
                  <div
                    className="col-12"
                    style={{ textAlign: 'center' }}
                  >
                    <Alert message={transactionList.data.message} type="info" style={{ margin: "20px 0" }} />
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ViewTransactionsList
