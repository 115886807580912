import axios from '../../config/axios'
import {
  GET_AREA_SEARCH_LIST_SUCCESS,
  GET_AREA_SEARCH_LIST_FAILED,
  LOADING_GET_AREA_SEARCH_LIST,
  RESET_GET_AREA_SEARCH_LIST,
  GET_AREA_SEARCH_LIST_NETWORK_ERROR,
} from '../types'

const resetGetAreaSearchList = () => {
  return {
    type: RESET_GET_AREA_SEARCH_LIST,
  }
}

const getAreaSearchList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_AREA_SEARCH_LIST })
  console.log(userData)
  axios
    .post('society/getAreaSearchList', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_AREA_SEARCH_LIST_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_AREA_SEARCH_LIST_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_AREA_SEARCH_LIST_FAILED,
    payload: data,
  }
}

export { resetGetAreaSearchList, getAreaSearchList }
