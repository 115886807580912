import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
} from 'reactstrap'
import { Alert, Popconfirm, Tooltip, Spin, Select, Collapse } from 'antd'
import {
  getComplaintList,
  resetgetComplaintList,
} from 'redux/actions/getComplaintListAction'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addComplaintStatus,
  resetComplaintStatus,
} from 'redux/actions/addComplaintStatusAction'
import {
  addComplaintMessage,
  resetAddComplaintMessage,
  getComplaintMessage,
  resetGetComplaintMessage,
} from 'redux/actions/complaintMessageAction'
import PaginationComponent from 'react-reactstrap-pagination'

import RightIcon from 'assets/img/Icons/options/right.png'
import WrongIcon from 'assets/img/Icons/options/wrong.png'

function Complaints() {
  const [status, setStatus] = useState('pending')
  const [complainId, setComplainId] = useState('')
  const [viewDetails, setViewDetails] = useState(false)
  const complaintList = useSelector(({ complaintList }) => complaintList)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const screenRef = React.createRef()

  const ComplainStatus = useSelector(
    ({ addComplainStatus }) => addComplainStatus
  )
  const getComplaintMessageData = useSelector(
    ({ getComplaintMessage }) => getComplaintMessage
  )
  const addComplaintMessageData = useSelector(
    ({ addComplaintMessage }) => addComplaintMessage
  )
  const { Panel } = Collapse
  // const {
  //   data: { user, society },
  // } = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const [message, setMessage] = useState({
    id: '',
    content: '',
    attachment: '',
  })
  const [msgAtt, setMsgAtt] = useState('')

  const login = useSelector(({ login }) => login)
  const totalItems = complaintList.data.count
  // useEffect(() => {
  //   getComplainListRequest('pending')
  //   return () => {
  //     dispatch(resetgetComplaintList())
  //     dispatch(resetAddComplaintMessage())
  //     dispatch(resetGetComplaintMessage())
  //   }
  // }, [])

  const handleStatus = (status) => {
    setStatus(status)
    getComplainListRequest(status)
  }

  const getComplainListRequest = (status) => {
    console.log(status)
    const getComplainListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      complainStatus: status,
      limit,
      page,
    }
    // console.log('complain list req = ', getComplainListReq)
    return dispatch(getComplaintList(getComplainListReq))
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }
  useEffect(() => {
    getComplainListRequest(status)
  }, [page])

  const handleComplaintStatus = (id, status) => {
    const complaintStatusReq = {
      initiatedBy: login.data.user.role,
      complainId: id,
      complainStatus: status,
    }
    return dispatch(addComplaintStatus(complaintStatusReq))
  }

  const attachmentType = (a) => {
    const myArr = a.split(',')
    switch (myArr[0]) {
      case 'data:image/png;base64':
      case 'data:image/jpeg;base64':
      case 'data:image/jpg;base64':
        return 'image'
      default:
        return 'notImage'
    }
  }

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const scrollHandle = () => {
    const scrollY = window.scrollY

    // console.log(scrollTop)
  }

  useEffect(() => {
    setPage(1)
  }, [complaintList.failed])

  useEffect(() => {
    if (ComplainStatus.success) {
      getComplainListRequest('pending')
      NotificationPopups('success', 'Success', ComplainStatus.data.message)
    }
    if (ComplainStatus.failed) {
      NotificationPopups('error', 'Failed', ComplainStatus.data.message)
    }
    if (ComplainStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetComplaintStatus())
    }
  }, [ComplainStatus.success, ComplainStatus.failed, ComplainStatus.network])

  const getComplaintMessageList = (id) => {
    console.log(id, 'id')
    const req = {
      initiatedBy: login.data.user.role,
      complainId: id,
    }
    dispatch(getComplaintMessage(req))
  }

  const handleAttachments = (filesList) => {
    // let id = filesList[0].id.split('_')[1]
    // let a = { [filesList.id]: filesList.base64 }
    // setMessage({
    //   // ...message,
    //   // attachment: filesList.base64,
    //   attachment: filesList[0].base64,
    // })
    // console.log(filesList.base64)
    setMessage({
      ...message,
      // content: '',
      attachment: filesList.base64,
    })
    // setMessage({ attachment: 'value' })
    // console.log('message in attach = ', message)
  }

  // const handleAttachments = (filesList) => {
  //   console.log(filesList)
  //   // setAttachment(filesList.base64)
  //   setMsgAtt(filesList[0].base64)
  // }

  const handleComplainMessage = (evt, id) => {
    const {
      target: { value },
    } = evt
    if (Util.checkWhiteSpace(value)) {
      setMessage({
        ...message,
        id: id,
        content: value,
        // attachment,
      })
    }
    // console.log('message in content = ', message)
  }

  const handleAddComplainMessage = (id) => {
    if (message.content != '' || message.attachment != '') {
      const req = {
        initiatedBy: login.data.user.role,
        role: login.data.user.role,
        adminId: login.data.user.id,
        content:
          message.id === id && message.content
            ? message.content.trim()
            : 'PLease Check the Image',
        // attachment: message.attachment ? message.attachment : '',
        attachment: message.attachment ? message.attachment : '',
        complainId: id,
      }
      console.log('request = ', req)
      return dispatch(addComplaintMessage(req))
    }
    // getComplaintMessageList(id)
  }

  useEffect(() => {
    console.log('add complain message data =', addComplaintMessageData)
    if (addComplaintMessageData.success) {
      getComplaintMessageList(complainId)
      setMessage({
        id: '',
        content: '',
        attachment: '',
      })
    }
  }, [addComplaintMessageData.success])

  return (
    <Table responsive style={{ textAlign: 'center', fontSize: '12px' }}>
      <thead>
        <tr style={{ fontSize: '15px', fontWeight: 'bold'  }}>
          <th>Raised By</th>
          <th>Complain Title</th>
          {/* <th>Email</th> */}
          {/* <th>Priority</th> */}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {complaintList.success &&
          complaintList.data.complainDetails.map((d, id) => (
            <React.Fragment key={id}>
              <tr
                key={id}
                id={`toggler${id}`}
                onClick={() => {
                  getComplaintMessageList(d.id)
                  setComplainId(d.id)
                  complainId === d.id
                    ? setViewDetails(!viewDetails)
                    : setViewDetails(viewDetails)
                }}
              >
                <td>
                  {d.userName}
                  <br />
                  {d.areaName} / {d.unit}
                  <br />
                  {`(${d.mobileNumber})`}
                </td>
                {/* <td>{d.userName}</td>
                <td>{d.mobileNumber}</td> */}
                <td>{d.complainTitle}</td>
                <td>
                  <Popconfirm
                    title="Resolved Complain ?"
                    onConfirm={() => handleComplaintStatus(d.id, 'resolved')}
                    okText="Resolved"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="leftBottom"
                      title="Resolve"
                      color="#108ee9"
                    >
                      {/* <i className="fas fa-check right-menu"></i> */}
                      <img
                        className="amenity_option_icon"
                        src={RightIcon}
                        alt="RightIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                  {'|'}
                  <Popconfirm
                    title="Reject Complain ?"
                    onConfirm={() => handleComplaintStatus(d.id, 'rejected')}
                    okText="Reject"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="rightBottom"
                      title="Reject"
                      color="#108ee9"
                    >
                      {/* <i className="fas fa-times right-menu"></i> */}
                      <img
                        className="amenity_option_icon"
                        src={WrongIcon}
                        alt="WrongIcon"
                      />
                    </Tooltip>
                  </Popconfirm>
                </td>
              </tr>
              {viewDetails && complainId === d.id && (
                <tr className="ml-detail-tr">
                  <td colSpan="6" className="ml-detail-td">
                    <Row className="ml-detail-row">
                      <Col
                        xs="12"
                        sm="6"
                        md="4"
                        lg="4"
                        style={{
                          padding: '20px',
                        }}
                      >
                        <label>Complain Title:</label>
                        {d.complainTitle}
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        md="4"
                        lg="4"
                        style={{
                          padding: '20px',
                        }}
                      >
                        <label>Message</label>
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        md="4"
                        lg="4"
                        style={{ padding: '20px' }}
                      >
                        <label>Attachment</label>
                      </Col>
                    </Row>
                    <Row
                      className="ml-detail-row"
                      onScroll={scrollHandle}
                      style={{
                        maxHeight: '250px',
                        overflow: 'auto',
                        margin: '0',
                      }}
                    >
                      {getComplaintMessageData.success &&
                        getComplaintMessageData.record[d.id] &&
                        getComplaintMessageData.record[d.id].map(
                          (data, index) => (
                            <>
                              <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="6"
                                style={{
                                  padding: '10px 5px',
                                  borderBottom: '1px solid #ebecf1',
                                  // scroll:
                                }}
                              >
                                <div
                                  className="detail-val"
                                  style={{
                                    float:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  <div
                                    className={`custom-text-message-${
                                      data.role === 'Society' ? 'right' : 'left'
                                    }`}
                                  >
                                    {data.content && data.content}
                                  </div>
                                </div>
                                <div
                                  className="detail-time"
                                  style={{
                                    textAlign:
                                      data.role === 'Society'
                                        ? 'right'
                                        : 'left',
                                  }}
                                >
                                  {Util.formatDateTimeHour(data.timestamp)}
                                </div>
                              </Col>
                              <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="6"
                                style={{ padding: '10px 5px' }}
                              >
                                {data.attachment && (
                                  <div
                                    onClick={() =>
                                      handleOpenFile(data.attachment)
                                    }
                                  >
                                    {attachmentType(data.attachment) ===
                                    'image' ? (
                                      <img
                                        src={data.attachment}
                                        style={{
                                          width: '100px',
                                        }}
                                      />
                                    ) : (
                                      <i
                                        className="far fa-file-alt"
                                        style={{
                                          fontSize: '60px',
                                        }}
                                      ></i>
                                    )}
                                  </div>
                                )}
                              </Col>
                            </>
                          )
                        )}
                    </Row>

                    {d.complainStatus === 'pending' && (
                      <>
                        <div className="detail-val">
                          <Row style={{ justifyContent: 'center' }}>
                            <Col
                              xs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              style={{ padding: '10px' }}
                            >
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  row="1"
                                  name={message.content}
                                  value={
                                    message.id === d.id ? message.content : ''
                                  }
                                  //id={}
                                  placeholder="Reply"
                                  // defaultValue={message[d.complainId] && message[d.complainId].content}
                                  onChange={(e) =>
                                    handleComplainMessage(e, d.id)
                                  }
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <FileBase64
                                multiple={false}
                                name={message.attachment}
                                value={
                                  // message.id === d.id
                                  // ?
                                  msgAtt
                                }
                                id={`remark_${d.id}`}
                                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                onDone={handleAttachments}
                              />
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <Button
                                className="cust-button"
                                style={{ minWidth: '100%' }}
                                onClick={() => handleAddComplainMessage(d.id)}
                              >
                                Send
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <div className="detail-val">
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleComplaintStatus(d.id, 'resolved')
                            }
                          >
                            Resolved
                          </span>
                          {' | '}
                          <span
                            className="right-menu"
                            onClick={() =>
                              handleComplaintStatus(d.id, 'rejected')
                            }
                          >
                            Reject
                          </span>
                        </div>
                      </>
                    )}

                    {d.complainStatus === 'resolved' && (
                      <>
                        <div className="detail-val">
                          <Row style={{ justifyContent: 'center' }}>
                            <Col
                              xs="12"
                              sm="12"
                              md="6"
                              lg="6"
                              style={{ padding: '10px' }}
                            >
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  row="1"
                                  name={message.content}
                                  value={
                                    message.id === d.id ? message.content : ''
                                  }
                                  //id={}
                                  placeholder="Reply"
                                  // defaultValue={message[d.complainId] && message[d.complainId].content}
                                  onChange={(e) =>
                                    handleComplainMessage(e, d.id)
                                  }
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <FileBase64
                                multiple={false}
                                name={message.attachment}
                                value={
                                  // message.id === d.id
                                  // ?
                                  msgAtt
                                }
                                id={`remark_${d.id}`}
                                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                onDone={handleAttachments}
                              />
                              {/* <FileBase64
                                 multiple={false}
                                 // id={type.type}
                                 value={msgAtt}
                                 accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                                 onDone={handleAttachments}
                               /> */}
                            </Col>
                            <Col
                              xs="12"
                              sm="12"
                              md="3"
                              lg="3"
                              style={{ padding: '25px 10px' }}
                            >
                              <Button
                                className="cust-button"
                                style={{ minWidth: '100%' }}
                                onClick={() => handleAddComplainMessage(d.id)}
                              >
                                Send
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}

        {fileModal && (
          <DisplayAttachment
            attachment={attachment}
            fileModal={fileModal}
            toggleModal={toggleFileModal}
          />
        )}
      </tbody>
    </Table>
  )
}

export default Complaints
