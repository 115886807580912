import axios from "../../config/axios"
import {
	GETNOTICETYPE_SUCCESS,
	GETNOTICETYPE_FAILED,
	LOADING_GETNOTICETYPE,
	RESET_GETNOTICETYPE,
	GETNOTICETYPE_NETWORKERROR
} from "../types";

const resetGetNoticeTypes = () => {
    return {
        type: RESET_GETNOTICETYPE,		
    };
};

const getNoticeTypes = userData => dispatch => {
    dispatch({ type: LOADING_GETNOTICETYPE });
		console.log(userData);
    axios
        .post("notice/getNoticeType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETNOTICETYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETNOTICETYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETNOTICETYPE_FAILED,
        payload: data
    };
}

export { resetGetNoticeTypes, getNoticeTypes } ;