import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetDocType,
  getDocUnitType,
} from 'redux/actions/getUnitDocTypeAction'

import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'

function SearchUnitDocumentType(props ) {
  const [residentType, setResidentType] = useState('')
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [searchDocumentType, setSearchDocumentType] = useState('')
  const [isMandatory, setIsMandatory] = useState(false)
  const [isActive, setisActive] = useState(false)

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)
  const docType = useSelector(({ getDocument }) => getDocument)
  const docUnitType = useSelector(({ getUnitDoctype }) => getUnitDoctype)
  const dispatch = useDispatch()

  const { Option } = Select
  function onChangeDocumentType(value) {
    setDocumentTypeId(value[0])
    setDocumentType(value[1])
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  const getDocTypeRequest = () => {
    if (login.data.user.role != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy: login.data.user.role,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [])

  const getUnitDocTypeRequest = () => {
    if (login.data.user.role === 'Society') {
      const getUnitDocTypeReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        residentType,
        limit,
        page,
      }
      return dispatch(getDocUnitType(getUnitDocTypeReq))
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'residentType') setResidentType(value)
    // if (name === 'isMandatory') setIsMandatory(Boolean(value))
    if (name === 'documentType') setDocumentType(value)
  }
  const onChangeSwitch = (checked) => {
    setIsMandatory(checked)
    console.log(`switch to ${checked}`)
  }
  const onChangeSwitchActive = (checked) => {
    setisActive(checked)
  }
  //console.log(isMandatory)
  const selectOptions = [
    { id: 1, residentType: 'Owner' },
    { id: 2, residentType: 'Tenant' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    getUnitDocTypeRequest()
    props.setIsSearch()
  }

  const disabledButton = () => {
    return !residentType
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          {/* <Col md="3" sm="12" lg="4" xs="12">
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Document Type
                </Option>

                {docType.success &&
                  docType.data.activeType.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={[d.id, d.type]}
                    >
                      {d.type}
                    </Option>
                  ))}
                {docType.failed && (
                  <Option className="loginSelect" value=""y>
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col> */}
          <Col md="3" sm="12" lg="4" xs="12">
            <FormGroup>
              <Label for="helperType">Resident Type</Label>
              <Select
                type="select"
                value={residentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeResidentType}
                onSearch={onSearchResidentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Resident Type
                </Option>

                {selectOptions.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={d.residentType}
                  >
                    {d.residentType}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          {/* <Col md="3" sm="12">
            <FormGroup>
              <Label>Mandatory</Label>
              <br />
              <Switch
                defaultChecked={isMandatory}
                name="isMandatory"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
              />
            </FormGroup>
          </Col> */}

          {/* <Col md="3" sm="12">
            <FormGroup>
              <Label>Active</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isMandatory"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChangeSwitchActive}
              />
            </FormGroup>
          </Col> */}
        </Row>

        <Row style={{ justifyContent: 'center' , textAlign:"center"}}>
          <Col md="3" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '70%' }}
              // disabled={disabledButton() || docUnitType.isLoading}
              onClick={handleSubmit}
            >
              {docUnitType.isLoading ? <Spin /> : 'Search'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SearchUnitDocumentType
