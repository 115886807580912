import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import PaginationComponent from 'views/Pagination'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetDashboard,
  getDashboard,
} from 'redux/actions/getDashboardAction'
import { getFeature } from 'redux/actions/getFeatureAction'
import { getSubCategory } from 'redux/actions/getSubCategoryAction'
import {
  resetUpdateFeature,
  updateFeature,
} from 'redux/actions/updateFeatureAction'
import ViewDashboard from './ViewDashboard'
import AddDashboard from './AddDashboard'

function Dashboard({data}) {
  console.log(data,'user DAta')
  const {cardName,cardSize,labelId,labelType,priorityOrder} = data
  const [dashDetail, setDashDetail] = useState('')
  const [labelName, setLabelName] = useState('')
  const [layout, SetLayout] = useState('')
  const [redirect, setRedirect] = useState('')
  const [featureType, setFeatureType] = useState([''])
  const [isActive, setIsActive] = useState(false)
  const [modal, setModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const login = useSelector(({ login }) => login)
  const dashboard = useSelector(({ getDashboard }) => getDashboard)
  const addedDashboard = useSelector(({ addDashboard }) => addDashboard)
  const addedDashboardFeatureType = useSelector(
    ({ addDashboardFeatureType }) => addDashboardFeatureType
  )
  const updatedDashboard = useSelector(({ updateDashboard }) => updateDashboard)
  const dispatch = useDispatch()

  const [limit] = useState(6)
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = dashboard.data.count

  console.log(dashboard.data.count,"dashboard.data.count")

  const getDashboardListRequest = () => {
    if (login.data.user.role != 'Society') {
      const getFDashboardReq = {
        initiatedBy: login.data.user.role,
        cardName,
        cardSize,
        labelId,
        labelType,
        priorityOrder,
        limit,
        page
      }
      return dispatch(getDashboard(getFDashboardReq))
    }
  }
  const getFeatureRequest = () => {
    if (login.data.user.role != 'Society') {
      const getFeatureReq = {
        initiatedBy: login.data.user.role,
        limit,
        page
      }
      return dispatch(getFeature(getFeatureReq))
    }
  }
  const getSubCategoryRequest = () => {
    if (login.data.user.role != 'Society') {
      const getSubCategoryReq = {
        initiatedBy: login.data.user.role,
        limit,
        page
      }
      return dispatch(getSubCategory(getSubCategoryReq))
    }
  }

  useEffect(() => {
    getDashboardListRequest()
    getFeatureRequest()
    getSubCategoryRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }


  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
    updateFaetureRequest()
  }
  const updateFaetureRequest = () => {
    const updateFeatureReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(updateFeature(updateFeatureReq))
  }

  const onChange = (checked) => {
    setIsActive(checked)
  }

  const openEditModal = (list) => {
    setDashDetail(list)
    console.log(list)
    setLabelName(list.label)
    SetLayout(list.layout)
    setFeatureType(list.featureType)
    setRedirect(list.redirect)
    setModal(!modal)
  }

  const toggleDetails = () => {
    setModal(!modal)
  }
  const toogleAdd = () => {
    setAddModal(!addModal)
  }

  useEffect(() => {
    if (addedDashboardFeatureType.success) {
      setModal(false)
      getDashboardListRequest()
    }
    if (addedDashboard.success) {
      setModal(false)
      setAddModal(false)
      getDashboardListRequest()
    }
    if (updatedDashboard.success) {
      setModal(false)
      NotificationPopups('success', 'Success', updatedDashboard.data.message)
    }
    if (updatedDashboard.failed) {
      NotificationPopups('error', 'Failed', updatedDashboard.data.message)
    }
    if (updatedDashboard.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      // dispatch(())
    }
  }, [
    addedDashboardFeatureType.success,
    addedDashboard.success,
    updatedDashboard.success,
    updatedDashboard.failed,
    updatedDashboard.network,
  ])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      User App Home Configuration List
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {dashboard.success && (
                    <>
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th>Label Name</th>
                              <th>Label Type</th>
                              <th>Card Layout</th>
                              <th>Card Size</th>
                              <th>Priority Order</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          {dashboard.success && (
                            <tbody>
                              {dashboard.data?.userDashboardList.map(
                                (d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td>{d.label}</td>
                                      <td>{d.labelType}</td>
                                      <td>{d.cardName}</td>
                                      <td>{d.cardSize}</td>
                                      <td>{d.priorityOrder}</td>
                                      <td>
                                        {d.isActive ? 'Active' : 'Inactive'}
                                      </td>
                                      <td>
                                        <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => {
                                            setModal(!modal)
                                            setDashDetail(d)
                                          }}
                                        >
                                          View Details
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    </>
                  )}

                  <Col>
                    {dashboard.success && (
                        <PaginationComponent
                          limit={limit}
                          totalItems={totalItems}
                          onSelect={handlePagination}
                          defaultPage={page}
                        />
                    )}
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  {dashboard.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={dashboard.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleDetails}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggleDetails}>Dashboard</ModalHeader>
          <ModalBody>
            <ViewDashboard data={dashDetail} />
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Dashboard</ModalHeader>
          <ModalBody>
            <AddDashboard />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default Dashboard
