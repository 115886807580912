import axios from 'config/axios'
import {
  UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAILED,
	LOADING_UPDATE_PROFILE,
	RESET_UPDATE_PROFILE,
	UPDATE_PROFILE_NETWORK_ERROR
} from 'redux/types'

const resetupdateProfile = () => {
  return {
    type: RESET_UPDATE_PROFILE,
  }
}

const updateProfile = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_PROFILE })

  axios
    .post('user/updateAdminProfile', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_PROFILE_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_PROFILE_FAILED,
    payload: data,
  }
}

export { resetupdateProfile, updateProfile }