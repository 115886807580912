import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  FormGroup,
  Form,
  Table,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Alert, Switch } from 'antd'
import randomColor from 'randomcolor'

import { getGuardList, resetGuardList } from 'redux/actions/getGuardListAction'
import { resetUpdateGuard, updateGuard } from 'redux/actions/updateGuardAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import {
  resetGuardAttendance,
  getGuardAttendance,
} from 'redux/actions/getGuardAttendanceAction'
import { addGuard, resetAddGuard } from 'redux/actions/addGuardAction'
import { resetgetGuardDoc, getGuardDoc } from 'redux/actions/getGuardDocAction'
import moment from 'moment'
import Calendar from 'react-calendar'
import AddGuard from '../Guard/AddGuard'
import './Calendar.css'

function GuardList({ data, documentId, onTap }) {

  let { guardPin, mobileNumber, name } = data
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [attachmentsStatus, setattachmentsStatus] = useState(false)
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  // const [editareaType, seteditAreaType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [editGuardName, setGuardName] = useState('')
  const [editGuardPhone, setGuardPhone] = useState('')
  const [editGuardPhoto, setGuardPhoto] = useState('')
  const [photo, setPhoto] = useState('')
  const [fileModal, setFileModal] = useState(false)
  const [isActive, setisActive] = useState()
  const [limit] = useState(10)
  const [page, setPage] = useState(1)
  const [mark, setMark] = useState([])
  const [isActiveDate, setIsActiveDate] = useState('')
  const date = new Date()
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [year, setYear] = useState(date.getFullYear())
  const [viewNav, setViewNav] = useState('Attendance')

  const login = useSelector(({ login }) => login)
  const guard = useSelector(({ addGuardDetail }) => addGuardDetail)
  const guardList = useSelector(({ getGuardList }) => getGuardList)
  const addedGuard = useSelector(({ addGuardDetail }) => addGuardDetail)
  const updateGuards = useSelector(({ updateGuardDetail }) => updateGuardDetail)
  const [initiatedBy, setInitiatedBy] = useState(login.data.user.role)
  const attendance = useSelector(({ getGuardAttendance }) => getGuardAttendance)
  const documentDetail = useSelector(({ getGuardDoc }) => getGuardDoc)
  const dispatch = useDispatch()
  const totalItems = guardList.data.count
  const handleEditChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value)
        ? setGuardName(value)
        : setGuardName(editGuardName)
    if (name === 'mobile') 
    Util.verifyMobile(value)
      ? setGuardPhone(value)
      : setGuardPhone(editGuardPhone)
    //if( name === "isActive") setisActive(value);
  }

  const openEditModal = (d) => {
    setId(d.id)
    setGuardName(d.name)
    setGuardPhone(d.mobileNumber)
    setGuardPhoto(d.photo)
    setisActive(d.isActive)
    setEditModal(!editModal)
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const editModalToggle = () => {
    setEditModal(!editModal)
  }
  const handleAttachments = (filesList) => {
    setprofilePictureStatus(true)
    setattachmentsStatus(true)
    const icon = filesList.base64.split(`,`)
    setPhoto(icon[1])
    setGuardPhoto(filesList.base64)
  }
  const handleOpenFile = () => {
    setFileModal(true)
  }
  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }
  const handleEditGuard = (e) => {
    e.preventDefault()
    editGuard()
  }

  useEffect(() => {
    if (addedGuard.success) {
      NotificationPopups('success', 'Success', addedGuard.data.message)
    }
    if (addedGuard.failed) {
      NotificationPopups('error', 'Failed', addedGuard.data.message)
    }
    if (addedGuard.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddGuard())
    }
  }, [addedGuard.success, addedGuard.failed, addedGuard.network])

  const editGuard = () => {
    const updateGuardReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      name: editGuardName.trim(),
      mobileNumber: editGuardPhone,
      action: 'Update',
      role: 'Guard',
      photo: attachmentsStatus ? editGuardPhoto.split(',')[1] : '',
      isActive,
      id,
    }
    return dispatch(addGuard(updateGuardReq))
  }

  useEffect(() => {
    if (initiatedBy === 'Society' && id != '') {
      const getGuardAttendanceReq = {
        initiatedBy: login.data.user.role,
        guardId: id,
        month,
        year,
      }
      return dispatch(getGuardAttendance(getGuardAttendanceReq))
    }
  }, [id, year, month])
  //calendar
  const tileClassName = ({ date, view }) => {
    if (mark != []) {
      if (mark.find((x) => x === moment(date).format('DD-MM-YYYY'))) {
        return 'highlight'
      }
    }
  }

  useEffect(() => {
    setMonth(moment(isActiveDate.activeStartDate).format('MM'))
    setYear(moment(isActiveDate.activeStartDate).format('YYYY'))
  }, [isActiveDate])

  useEffect(() => {
    if (attendance.success) {
      const attend = attendance.data.guardAttendance.map((d) =>
        moment(d.inTime).format('DD-MM-YYYY')
      )
      setMark(attend)
    }
    if (attendance.failed) {
      setMark([])
    }
    return () => {
      resetGuardAttendance()
    }
  }, [attendance.success, attendance.failed])

  const getGuardListRequest = () => {
    if (initiatedBy === 'Society') {
      const getGuardListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        guardPin,
        limit,
        page,
      }
      return dispatch(getGuardList(getGuardListReq))
    }
  }

  // useEffect(() =>{
  //   getGuardListRequest()
  // },[page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    if (initiatedBy === 'Society') {
      const getGuardListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        name: name,
        // mobileNumber: data.data.mobileNumber,
        guardPin: guardPin,
        limit,
        page,
      }
      return dispatch(getGuardList(getGuardListReq))
    }
  }, [page])

  // const getGuardDocRequest = () => {
  //   if (initiatedBy === 'Society' && id != '') {
  //     const getGuardDocReq = {
  //       initiatedBy: login.data.user.role,
  //       societyId: login.data.society.id,
  //       guardId: id,
  //     }
  //     return dispatch(getGuardDoc(getGuardDocReq))
  //   }
  // }

  useEffect(() => {
    if (initiatedBy === 'Society' && documentId != '') {
      const getGuardDocReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        guardId: documentId,
      }
      return dispatch(getGuardDoc(getGuardDocReq))
    }
  }, [documentId])

  useEffect(() => {
    if (initiatedBy === 'Society' && id != '') {
      const getGuardDocReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        guardId: id,
      }
      return dispatch(getGuardDoc(getGuardDocReq))
    }
  }, [id])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    if (addedGuard.success) {
      setGuardPhoto('')
      setGuardName('')
      setGuardPhone('')
      getGuardListRequest()
      setModal(false)
      setEditModal(false)
    }

    return () => {
      resetUpdateGuard()
    }
  }, [addedGuard.success, addedGuard.failed, addedGuard.network])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setModal(!modal)
                    }}
                  >
                    Add Guard
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    Guard
                  </div>
                </Col>
              </div>

              {guardList.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Profile</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Passcode</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {guardList.success && (
                      <tbody>
                        {guardList.data.activeGuardList.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>
                                <img
                                  src={`${Util.imageUrl}${d.photo}`}
                                  alt="profile"
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                  }}
                                />
                              </td>
                              <td>{d.name}</td>
                              <td>{d.mobileNumber}</td>
                              <td>{d.guardPin}</td>

                              <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    openEditModal(d)
                                  }}
                                >
                                  View Details
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}
              {guardList.success && !guardList.data.activeGuardList.length && (
                <div style={{ textAlign: 'center', padding: '20px 0px' }}>
                  <Alert message="No Active Guards " type="info" />
                </div>
              )}

              <Col>
                {guardList.success && (
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {guardList.failed && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message={guardList.data.message} type="info" style={{ margin: "20px 0" }} />
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={editModalToggle}
          size="lg"
          style={{ maxWidth: '800px', maxHeight: '800px' }}
        >
          <ModalHeader toggle={editModalToggle}>Edit</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Row>
                  <Col md="6" sm="6">
                    <FormGroup>
                      <Label for="name">Guard Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={editGuardName}
                        onChange={handleEditChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6">
                    <FormGroup>
                      <Label for="mobile">Guard Mobile Number</Label>
                      <Input
                        type="text"
                        name="mobile"
                        id="mobile"
                        value={editGuardPhone}
                        onChange={handleEditChange}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col xs="12" sm="12" md="6">
                    <FormGroup>
                      <label>Guard Photo</label>

                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={handleOpenFile}
                      >
                        {editGuardPhoto && (
                          <img
                            className="guard_profile_photo"
                            // style={{ height: '10rem', width: '10rem' }}
                            src={
                              profilePictureStatus
                                ? `${editGuardPhoto}`
                                : `${Util.imageUrl}${editGuardPhoto}`
                            }
                          />
                        )}
                      </div>

                      <div style={{ marginTop: '10px' }}>
                        <FileBase64
                          multiple={false}
                          id="editNoticeFiles"
                          // accept=".jpeg, .png, .doc, .docx, .pdf, .jpg"
                          accept=".jpeg, .png, .jpg"
                          onDone={handleAttachments}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Status</Label>
                      <br />
                      <Switch
                        defaultChecked={isActive}
                        name="isActive"
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        onChange={onChange}
                      // style={{ width: '100%' }}
                      />
                    </FormGroup>
                  </Col>
                  <div style={{ textAlign: 'center' }} className="errorText">
                    {errMessage}
                  </div>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12">
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={handleEditGuard}
                      disabled={updateGuards.isLoading}
                    >
                      {addedGuard.isLoading ? <Spin /> : 'Edit'}
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <Row>
                  <Col md="6" style={{ cursor: 'pointer' }}>
                    {viewNav === 'Attendance' ? (
                      <p
                        onClick={() => {
                          setViewNav('Attendance')
                        }}
                        style={{
                          borderBottom: '2px solid #1890ff',
                          transition: '.2s',
                          textAlign: 'center',
                        }}
                      >
                        Attendance
                      </p>
                    ) : (
                      <p
                        onClick={() => {
                          setViewNav('Attendance')
                        }}
                      >
                        Attendance
                      </p>
                    )}
                  </Col>
                  <Col md="6" style={{ cursor: 'pointer' }}>
                    {viewNav === 'Documents' ? (
                      <p
                        onClick={() => {
                          setViewNav('Documents')
                        }}
                        style={{
                          borderBottom: '2px solid #1890ff',
                          transition: '.2s',
                          textAlign: 'center',
                        }}
                      >
                        Documents
                      </p>
                    ) : (
                      <p
                        onClick={() => {
                          setViewNav('Documents')
                        }}
                      >
                        Documents
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  {viewNav === 'Attendance' && (
                    <Col
                      md="12"
                      style={{
                        maxHeight: '295px',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        // border: '1px solid blue',
                      }}
                    >
                      {/* Attendance Start */}
                      {/* <label>Attendance</label> */}
                      <div style={{ padding: '10' }}>
                        <Calendar
                          tileClassName={tileClassName}
                          onActiveStartDateChange={
                            (action) => setIsActiveDate(action)
                          }
                        />
                      </div>
                      {/* Attendance End */}
                    </Col>
                  )}
                </Row>
                <Row>
                  {viewNav === 'Documents' && (
                    <Col
                      md="12"
                      style={{
                        maxHeight: '250px',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        // border: '1px solid blue',
                      }}
                    >
                      {documentDetail.success && (
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Type</th>
                              <th>Number</th>

                              {/* activeHelperDetail */}
                            </tr>
                          </thead>
                          {documentDetail.success && (
                            <tbody>
                              {documentDetail.data.guardDocumentMasterVOs.map(
                                (d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td>
                                        {/* {d.moment().format('DD-MM-YYYY')} */}
                                        <img
                                          src={`${Util.imageUrl}${d.document}`}
                                          alt="doc"
                                          style={{
                                            width: '5rem',
                                            height: '5rem',
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {/* {d.inTime} */}
                                        {d.documentTypeId}
                                      </td>
                                      <td>
                                        {/* {d.outTime} */}
                                        {d.documentNumber}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          )}
                        </Table>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="sm"
        // style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggle}>Add Guard</ModalHeader>
          <ModalBody>
            <AddGuard />
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default GuardList
