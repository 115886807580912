import axios from "../../config/axios";
import {
		DELETECOMPANY_SUCCESS,
		DELETECOMPANY_FAILED,
		LOADING_DELETECOMPANY,
		RESET_DELETECOMPANY,
		DELETECOMPANY_NETWORKERROR
	} from "../types";

const resetdeleteCompany = () => {
    return {
        type: RESET_DELETECOMPANY,		
    };
};

const deleteCompany = userData => dispatch => {
    dispatch({ type: LOADING_DELETECOMPANY });
		console.log(userData);
    axios
        .post("company/deleteCompany", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: DELETECOMPANY_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: DELETECOMPANY_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: DELETECOMPANY_FAILED,
        payload: data
    };
}

export { resetdeleteCompany, deleteCompany } ;