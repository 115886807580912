import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetaddServiceType,
  addServiceType,
} from '../../redux/actions/addServiceTypeAction'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import FileBase64 from '../../components/Custom/UploadFile'
import Util from 'validations/index'
function AddServiceType() {
  const [profilePictureStatus, setprofilePictureStatus] = useState(false)
  const [updatedPhoto, setUpdatedPhoto] = useState('')
  const [serviceType, setServiceType] = useState('')
  const [isSocietyUser, setIsSocietyUser] = useState(false)
  const [isUser, setIsUser] = useState(false)
  const [isGuard, setIsGuard] = useState(false)
  const [iconImage, setIconImage] = useState('')
  const [isAttendance, setIsAttendance] = useState(false)
  const [isVisitor, setIsVisitor] = useState(false)
  const login = useSelector(({ login }) => login)
  const addedServiceType = useSelector(({ addservicetype }) => addservicetype)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'serviceType')
      Util.alphabetsOnly(value)
        ? setServiceType(value)
        : setServiceType(serviceType)
    if (name === 'isSocietyUser') setIsSocietyUser(checked)
    if (name === 'isUser') setIsUser(checked)
    if (name === 'isGuard') setIsGuard(checked)
  }
  const handleAttachments = (filesList) => {
    const icon = filesList[0].base64.split(`,`)
    setIconImage(icon[1])
    setUpdatedPhoto(filesList[0].base64)
  }
  const addServiceTypeRequest = () => {
    const addServiceTypeReq = {
      initiatedBy: login.data.user.role,
      type: serviceType.trim(),
      isSocietyUser: isSocietyUser,
      isUser: isUser,
      isGuard: isGuard,
      iconImage,
      isAttendance,
      isVisitor,
    }
    return dispatch(addServiceType(addServiceTypeReq))
  }

  const onChangeSwitch = (checked) => {
    setIsAttendance(checked)
    // console.log(`switch to ${checked}`)
  }
  const onChangeSwitch2 = (checked) => {
    setIsVisitor(checked)
    // console.log(`switch to ${checked}`)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addServiceTypeRequest()
  }

  useEffect(() => {
    if (iconImage != '') {
      NotificationPopups('success', 'Success', 'Image Uploaded')
    }
  }, [iconImage])

  useEffect(() => {
    if (addedServiceType.success) {
      setServiceType('')
      setIsGuard(isGuard)
      setIsSocietyUser(isSocietyUser)
      setIsUser(isUser)
      setIconImage('')
      setIsAttendance(isAttendance)
      setIsVisitor(isVisitor)
      NotificationPopups('success', 'Success', addedServiceType.data.message)
    }
    if (addedServiceType.failed) {
      NotificationPopups('error', 'Failed', addedServiceType.data.message)
    }
    if (addedServiceType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetaddServiceType())
    }
  }, [
    addedServiceType.success,
    addedServiceType.failed,
    addedServiceType.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' , width:"100%" , margin:"auto"}}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Service Type</Label>
              <Input
                type="text"
                placeholder="Service Type"
                name="serviceType"
                id="serviceType"
                value={serviceType || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>

          <Col md="6" sm="12" lg="6" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label>Accessed By</Label>
              <Row style={{ padding: '8px 25px' }}>
                <Col md="4" sm="12" lg="4">
                  <Input
                    type="checkbox"
                    name="isSocietyUser"
                    id="isSocietyUser"
                    value="isSocietyUser"
                    onChange={handleChange}
                  />
                  {'  '}
                  Society User
                </Col>
                <Col md="4" sm="12" lg="4">
                  <Input
                    type="checkbox"
                    name="isGuard"
                    id="isGuard"
                    value="isGuard"
                    onChange={handleChange}
                  />
                  {'  '}
                  Guard
                </Col>
                <Col md="4" sm="12" lg="4">
                  <Input
                    type="checkbox"
                    name="isUser"
                    id="isUser"
                    value="isUser"
                    onChange={handleChange}
                  />
                  {'  '}
                  User
                </Col>
                <Col xs={12} sm={12} md="4">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <Label>Attendance</Label>
                    <br />
                    <Switch
                      defaultChecked={isAttendance}
                      name="isAttendance"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch}
                      // style={{ width: '100%' }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md="2">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <Label>Visitor</Label>
                    <br />
                    <Switch
                      defaultChecked={isVisitor}
                      name="isAttendance"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={onChangeSwitch2}
                      // style={{ width: '100%' }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="12" lg="4" xs="12">
                  <FormGroup style={{ margin: '10px 1px' }}>
                    <Label for="serviceType">Add Service Type Icon</Label>
                    <FileBase64
                      multiple={true}
                      id="iconImage"
                      accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                      onDone={handleAttachments}
                    />
                  </FormGroup>
                  {iconImage && (
                    <img
                      // src={iconImage}
                      src={`${updatedPhoto}`}
                      width="40px"
                      height="40px"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  )}
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={addedServiceType.isLoading || !serviceType}
            type="submit"
          >
            {addedServiceType.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddServiceType
