import {
  ADD_PARKING_SUCCESS,
  ADD_PARKING_FAILED,
  ADD_PARKING_NETWORK_ERROR,
  LOADING_ADD_PARKING,
  RESET_ADD_PARKING,
} from 'redux/types'

import axios from 'config/axios'

const resetAddParking = () => {
  return {
    type: RESET_ADD_PARKING,
  }
}

const addParking = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_PARKING })
  axios
    .post('society/addParkingSlot', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(fail(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADD_PARKING_NETWORK_ERROR, payload: err }))
}

function success(data) {
  return {
    type: ADD_PARKING_SUCCESS,
    payload: data,
  }
}
function fail(data) {
  return {
    type: ADD_PARKING_FAILED,
    payload: data,
  }
}

export { resetAddParking, addParking }
