import {
	ADD_MANDATORY_DOC_SUCCESS,
	ADD_MANDATORY_DOC_FAILED,
	LOADING_ADD_MANDATORY_DOC,
	RESET_ADD_MANDATORY_DOC,
	ADD_MANDATORY_DOC_NETWORK_ERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADD_MANDATORY_DOC:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    network: false,
								data: {},
								message: "",
                                networkError: ""
            };
				case ADD_MANDATORY_DOC_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
                
            };
				case ADD_MANDATORY_DOC_FAILED:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: true,
    network: false,
								data: action.payload,
								message: "",
                                networkError: ""
            };
            case ADD_MANDATORY_DOC_NETWORK_ERROR:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: false,
    network: true,
								data: action.payload,
								message: "",
                                networkError: "Network Error"
            };
				case RESET_ADD_MANDATORY_DOC:
            return (state = initialState);

        default:
            return state;
    }
};