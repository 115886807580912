import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch } from 'antd'
import randomColor from 'randomcolor'
import { Tooltip, Popconfirm } from 'antd'
import {
  resetGetNoticeTypes,
  getNoticeTypes,
} from 'redux/actions/getNoticeTypeAction'
import {
  resetupdateNoticeType,
  updateNoticeType,
} from 'redux/actions/updateNoticeTypeAction'
import {
  resetdeletenoticeType,
  deleteNoticeType,
} from 'redux/actions/deleteNoticeTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import PaginationComponent from 'views/Pagination'
import Util from 'validations/index'

function NoticeType() {
  const [modal, setModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [editnoticeType, seteditNoticeType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState()
  const [limit] = useState(20)
  const [page, setPage] = useState(1)

  const addednoticetype = useSelector(({ addnoticetype }) => addnoticetype)
  const deletedNoticeType = useSelector(
    ({ deleteNoticeType }) => deleteNoticeType
  )
  const noticeTypes = useSelector(({ noticeTypes }) => noticeTypes)
  const updatedNoticeType = useSelector(
    ({ updateNoticeType }) => updateNoticeType
  )
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  const dispatch = useDispatch()

  const pageSize = noticeTypes.data.pages
  const totalItems = noticeTypes.data.count

  const getNoticeTypeRequest = () => {
    if (initiatedBy === 'SuperAdmin') {
      const nocTypeReq = {
        initiatedBy: login.data.user.role,
        limit,
        page,
      }
      return dispatch(getNoticeTypes(nocTypeReq))
    }
  }
  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy === 'SuperAdmin') {
      const nocTypeReq = {
        initiatedBy: login.data.user.role,
        limit,
        page,
      }
      return dispatch(getNoticeTypes(nocTypeReq))
    }
  }, [page])

  useEffect(() => {
    getNoticeTypeRequest()
  }, [addednoticetype.success])

  useEffect(() => {
    if (updatedNoticeType.success) {
      seterrMessage('')
      setModal(!modal)
      NotificationPopups('success', 'Success', updatedNoticeType.data.message)
      getNoticeTypeRequest()
    }
    if (updatedNoticeType.failed) {
      seterrMessage(updatedNoticeType.data.message)
      //NotificationPopups('error', 'Failed', updatedNoticeType.data.message)
    }
    if (updatedNoticeType.network || deletedNoticeType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedNoticeType.success) {
      NotificationPopups('success', 'Success', deletedNoticeType.data.message)
      getNoticeTypeRequest()
    }
    if (deletedNoticeType.failed) {
      NotificationPopups('error', 'Failed', deletedNoticeType.data.message)
    }

    return () => {
      dispatch(resetupdateNoticeType())
      dispatch(resetdeletenoticeType())
    }
  }, [
    updatedNoticeType.success,
    updatedNoticeType.failed,
    updatedNoticeType.network,
    deletedNoticeType.success,
    deletedNoticeType.failed,
    deletedNoticeType.network,
  ])

  const handleDelete = (id) => {
    const deleteNoticetypeReq = {
      id: id,
    }
    //return dispatch(deleteNoticeType(deleteNoticetypeReq));
  }

  const openEditModal = (type) => {
    setId(type.id)
    seteditNoticeType(type.type)
    setisActive(type.isActive)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const toogleAdd = () => {
    seterrMessage('')
    setAddModal(!modal)
  }

  const handleChange = ({ target: { name, value } }) => {
    console.log(value)
    if (name === 'editnoticeType') seteditNoticeType(value)
    Util.alphaNumeric(value)
      ? seteditNoticeType(value)
      : seteditNoticeType(editnoticeType)
    console.log(value)
  }

  const onChange = (checked) => {
    setisActive(checked)
    console.log(`switch to ${checked}`)
  }

  const handleEditNoticeType = () => {
    const editNoticeTypeReq = {
      id: id,
      type: editnoticeType.trim(),
      initiatedBy: login.data.user.role,
      isActive,
    }
    return dispatch(updateNoticeType(editNoticeTypeReq))
  }

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {/* <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add Notice
                    </Button>
                  </div> */}
                  <Col>
                    <div className="typeHeading">Notice Type</div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {noticeTypes.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Type</th>

                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {noticeTypes.success && (
                          <tbody>
                            {noticeTypes.data?.activeNoticeType.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td>{d.type}</td>
                                  <td>{d.isActive ? 'Active' : 'Inactive '}</td>
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
              </Row>
              <Col>
                {noticeTypes.failed && (
                  <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                    <Alert message={noticeTypes.data.message} type="info" />
                  </div>
                )}
              </Col>
              <Col>
                {noticeTypes.success && (
                  <PaginationComponent
                  limit={limit}
                  totalItems={totalItems}
                  onSelect={handlePagination}
                  defaultPage={page}
                />
                )}
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          style={{ maxWidth: '250px' , marginLeft:"auto",marginRight:"auto"}}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="editnoticeType">Notice Type</Label>
              <Input
                type="text"
                name="editnoticeType"
                id="editnoticeType"
                value={editnoticeType}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditNoticeType}
              disabled={updatedNoticeType.isLoading || !editnoticeType}
            >
              {updatedNoticeType.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Notice</ModalHeader>
          <ModalBody>{/* <AddFeature /> */}</ModalBody>
        </Modal>
      )}
    </>
  )
}

export default NoticeType
