import {
  ADD_GUARD_SUCCESS,
  ADD_GUARD_FAILED,
  RESET_ADD_GUARD,
  LOADING_ADD_GUARD,
  ADD_GUARD_NETWORK_ERROR,
} from '../types'
import axios from '../../config/axios'

const resetAddGuard = () => {
  return { type: RESET_ADD_GUARD }
}

const addGuard = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_GUARD })
  axios
    .post('guard/addGuard', userData)
    .then((res) => {
      console.log(res)
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: ADD_GUARD_NETWORK_ERROR, payload: err }))
}
function success(data) {
  return {
    type: ADD_GUARD_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: ADD_GUARD_FAILED,
    payload: data,
  }
}
export { resetAddGuard, addGuard }
