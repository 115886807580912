import React, { useState, useEffect } from 'react'
import { Col, Row, FormGroup, Label, Button } from 'reactstrap'
import FileBase64 from '../../components/Custom/UploadFile'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  addMntInvoice,
  resetAddMntInvoice,
} from 'redux/actions/addMntInvoiceAction'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'

const UplaodMntBilling = () => {
  const [file, setFile] = useState('')
  const [type, setType] = useState('')
  const [fileName, setFileName] = useState('')

  const login = useSelector(({ login }) => login)
  const addedMntInvoice = useSelector(({ addMntInvoice }) => addMntInvoice)
  const dispatch = useDispatch()

  const addMntInvoiceRequest = () => {
    const addMntInvoiceReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      type,
      file,
    }
    dispatch(addMntInvoice(addMntInvoiceReq))
  }

  const handleAttachments = (filesList) => {
    const fileType = filesList.name.split('.')
    setType(fileType[1])
    const icon = filesList.base64.split(`,`)
    setFile(icon[1])
    setFileName(filesList.name)
  }

  useEffect(() => {
    if (addedMntInvoice.success) {
      setType('')
      setFile('')
      NotificationPopups('success', 'Success', addedMntInvoice.data.message)
    }
    if (addedMntInvoice.failed) {
      NotificationPopups('error', 'Failed', addedMntInvoice.data.message)
    }
    if (addedMntInvoice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddMntInvoice())
    }
  }, [addedMntInvoice.success, addedMntInvoice.failed, addedMntInvoice.network])

  const handleSubmit = (e) => {
    e.preventDefault()
    addMntInvoiceRequest()
  }

  return (
    <div>
      <Row>
        <Col md="12" sm="12">
          <FormGroup>
            <Label>Upload Attachments</Label>
            {fileName ? (
              <>
                <FileBase64
                  multiple={false}
                  accept=".csv,.xlsx"
                  id="addCompanyFile"
                  onDone={handleAttachments}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span>{fileName}</span>
                </div>
              </>
            ) : (
              <FileBase64
                multiple={false}
                accept=".csv,.xlsx"
                id="addCompanyFile"
                onDone={handleAttachments}
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Button
          className="cust-button"
          onClick={handleSubmit}
          // disabled={disabledButton() || addedCompany.isLoading}
          style={{ margin: 'auto' }}
        >
          {addedMntInvoice.isLoading ? <Spin /> : 'Upload'}
        </Button>
      </Row>
    </div>
  )
}

export default UplaodMntBilling
