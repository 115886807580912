import {
    DELETECOMPANYTYPE_SUCCESS,
    DELETECOMPANYTYPE_FAILED,
    LOADING_DELETECOMPANYTYPE,
    RESET_DELETECOMPANYTYPE,
    DELETECOMPANYTYPE_NETWORKERROR,
} from '../types'

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: '',
    networkError: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_DELETECOMPANYTYPE:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                data: {},
                message: '',
                networkError: '',
            }
        case DELETECOMPANYTYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: true,
                network: false,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case DELETECOMPANYTYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                message: '',
                networkError: '',
            }
        case DELETECOMPANYTYPE_NETWORKERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: '',
                networkError: 'Network Error',
            }
        case RESET_DELETECOMPANYTYPE:
            return (state = initialState)

        default:
            return state
    }
}
