import axios from '../../config/axios'
import {
  ADD_FEATURE_TYPE_SUCCESS,
  ADD_FEATURE_TYPE_FAILED,
  ADD_FEATURE_TYPE_NETWORK_ERROR,
  LOADING_ADD_FEATURE_TYPE,
  RESET_ADD_FEATURE_TYPE,
} from '../types'

const resetAddFeatureType = () => {
  return {
    type: RESET_ADD_FEATURE_TYPE,
  }
}

const addFeatureType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_ADD_FEATURE_TYPE })
  axios
    .post('dashboard/addFeatureType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: ADD_FEATURE_TYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: ADD_FEATURE_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: ADD_FEATURE_TYPE_FAILED,
    payload: data,
  }
}

export { resetAddFeatureType, addFeatureType }
