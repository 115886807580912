import {
  GETBUILDINGNAME_SUCCESS,
  GETBUILDINGNAME_FAILED,
  LOADING_GETBUILDINGNAME,
  RESET_GETBUILDINGNAME,
  GETBUILDINGNAME_NETWORKERROR,
} from '../types'

const initialState = {
  isLoading: false,
  success: false,
  network: false,
  failed: false,
  data: {},
  message: '',
  networkError: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_GETBUILDINGNAME:
      return {
        ...state,
        isLoading: true,
        success: false,
        failed: false,
        network: false,
        data: {},
        message: '',
        networkError: '',
      }
    case GETBUILDINGNAME_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        failed: true,
        network: false,
        data: action.payload,
        search: action.payload.activeType,
        message: '',
        networkError: '',
      }
    case GETBUILDINGNAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        failed: false,
        network: false,
        data: action.payload,
        message: '',
        networkError: '',
      }
    case GETBUILDINGNAME_NETWORKERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        network: true,
        failed: false,
        data: action.payload,
        message: '',
        networkError: 'Network Error',
      }
    case RESET_GETBUILDINGNAME:
      return (state = initialState)

    default:
      return state
  }
}
