import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import Util from 'validations/index'
import FileBase64 from 'components/Custom/UploadFile'
import DisplayAttachment from 'components/Custom/DisplayAttachment'
import PaginationComponent from 'views/Pagination'
import axios from 'config/axios'
import { getFeature,resetGetFeature } from 'redux/actions/getFeatureAction'
import {
  resetUpdateFeature,
  updateFeature,
} from 'redux/actions/updateFeatureAction'
import AddFeature from './AddFeature'

function Feature(onTap) {
  const [name, setName] = useState('')
  const [redirect, setRedirect] = useState('')
  const [modal, setmodal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [isActive, setisActive] = useState()
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const addedFeature = useSelector(({ addFeature }) => addFeature)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const updatedFeature = useSelector(({ updateFeature }) => updateFeature)

  const limit = 6
  const [page, setPage] = useState(1)
  const [initiatedBy] = useState(login.data.user.role)
  const totalItems = getFeatureCat.data.count

  const getFeatureRequest = () => {
    const getFeatureReq = {
      initiatedBy,
      limit,
      page
    }
    return dispatch(getFeature(getFeatureReq))
  }
  useEffect(() => {
    getFeatureRequest()
  }, [page])

  useEffect(() =>{
    dispatch(resetGetFeature())
  },[])

  useEffect(() =>{
    setPage(1)
  },[onTap])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
    if (name === 'name') setName(value)
    if (name === 'redirect') setRedirect(value)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
    updateFaetureRequest()
  }
  const updateFaetureRequest = () => {
    const updateFeatureReq = {
      initiatedBy: login.data.user.role,
      id,
      name,
      redirect,
      isActive,
    }
    return dispatch(updateFeature(updateFeatureReq))
  }

  const onChange = (checked) => {
    setisActive(checked)
  }

  const openEditModal = (type) => {
    console.log(type)
    setId(type.id)
    setName(type.name)
    setRedirect(type.redirect)
    setmodal(!modal)
    setisActive(type.isActive)
  }

  const toggle = () => {
    seterrMessage('')
    setmodal(!modal)
  }

  const toogleAdd = () => {
    setAddModal(!addModal)
  }

  useEffect(() => {
    if (addedFeature.success) {
      setAddModal(false)
      getFeatureRequest()
    }
    if (updatedFeature.success) {
      setId('')
      setName('')
      setRedirect('')
      setmodal(!modal)
      getFeatureRequest()
      NotificationPopups('success', 'Success', updatedFeature.data.message)
    }
    if (updatedFeature.failed) {
      NotificationPopups('error', 'Failed', updatedFeature.data.message)
    }
    if (updatedFeature.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateFeature())
    }
  }, [
    addedFeature.success,
    updatedFeature.success,
    updatedFeature.failed,
    updatedFeature.network,
  ])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add Feature Category
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Feature Category List
                    </div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {getFeatureCat.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>App Screen</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        {getFeatureCat.success && (
                          <tbody>
                            {getFeatureCat.data?.featureCategory.map(
                              (d, id) => (
                                <React.Fragment key={id}>
                                  <tr key={id} id={`toggler${id}`}>
                                    <td>{d.name}</td>
                                    <td>{d.redirect}</td>
                                    <td>
                                      {d.isActive ? 'Active' : 'Inactive '}
                                    </td>
                                    <td>
                                      <Button
                                        color="secondary"
                                        className="modalCustomButton"
                                        onClick={() => {
                                          openEditModal(d)
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            )}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
                <Col>
                  {getFeatureCat.success && (
                    <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {getFeatureCat.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={getFeatureCat.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size="sm"
          style={{ maxWidth: '250px' , marginRight:"auto" , marginLeft:"auto"}}
        >
          <ModalHeader toggle={toggle}>Edit</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="helptype">Name</Label>
              <Input
                type="text"
                name="name"
                id="helptype"
                value={name}
                onChange={handleEditChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="helptype">App Screen</Label>
              <Input
                type="text"
                name="redirect"
                id="helptype"
                value={redirect}
                onChange={handleEditChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <br />
              <Switch
                defaultChecked={isActive}
                name="isActive"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={onChange}
                // style={{ width: '100%' }}
              />
            </FormGroup>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              color="secondary"
              className="modalCustomButton"
              onClick={handleEditFeature}
              disabled={updatedFeature.isLoading || !name || !redirect}
            >
              {updatedFeature.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Button
              color="secondary"
              style={{ float: 'right' }}
              className="modalCustomButton"
              onClick={toggle}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Feature</ModalHeader>
          <ModalBody>
            <AddFeature />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default Feature
