import axios from '../../config/axios'
import {
  GETSERVICETYPE_SUCCESS,
  GETSERVICETYPE_FAILED,
  LOADING_GETSERVICETYPE,
  RESET_GETSERVICETYPE,
  GETSERVICETYPE_NETWORK_ERROR,
} from '../types'

const resetgetServiceType = () => {
  return {
    type: RESET_GETSERVICETYPE,
  }
}

const getServiceType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETSERVICETYPE })
  console.log(userData)
  axios
    .post('helper/getServiceType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETSERVICETYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GETSERVICETYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETSERVICETYPE_FAILED,
    payload: data,
  }
}

export { resetgetServiceType, getServiceType }
