import axios from '../../config/axios'
import {
  UPDATE_DOCMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILED,
  UPDATE_DOCUMENT_NETWORK_ERROR,
  LOADING_UPDATE_DOCUMENT,
  RESET_UPDATE_DOCUMENT,
} from '../types'

const resetUpdateDocument = () => {
  return {
    type: RESET_UPDATE_DOCUMENT,
  }
}

const updateDocument = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_DOCUMENT })
  console.log(userData)
  axios
    .post('society/updateDocument', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: UPDATE_DOCUMENT_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: UPDATE_DOCMENT_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: UPDATE_DOCUMENT_FAILED,
    payload: data,
  }
}

export { resetUpdateDocument, updateDocument }
