import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import FileBase64 from 'components/Custom/UploadFile'
import { addFeature } from 'redux/actions/addFeatureAction'
import { resetAddFeature } from 'redux/actions/addFeatureAction'
import { getFeature } from 'redux/actions/getFeatureAction'

function SearchFeature(props) {
  const [featureName, setFeatureName] = useState('')
  const [featurePriority, setFeaturePriority] = useState('')
  const [redirect, setRedirect] = useState('')
  const login = useSelector(({ login }) => login)
  const addedFeature = useSelector(({ addFeature }) => addFeature)
  const getFeatureCat = useSelector(({ getFeature }) => getFeature)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'featureName') setFeatureName(value)
    if (name === 'featurePriority') setFeaturePriority(value)
    if (name === 'redirect') setRedirect(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getFeatureRequest()
    props.setIsSearch()
  }
  const getFeatureRequest = () => {
    const getFeatureReq = {
      initiatedBy: login.data.user.role,
      name: featureName,
      redirect,
      limit:6
    }
    return dispatch(getFeature(getFeatureReq))
  }
  const disabledBtn = () => {
    return !(featureName, redirect)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' , width:"100%", margin:"auto"}}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="featureName"
                id="serviceType"
                value={featureName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">App Screen Name</Label>
              <Input
                type="text"
                placeholder="App Screen Name"
                name="redirect"
                id="serviceType"
                value={redirect || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          {/* <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Add Feature Icon</Label>
              <FileBase64
                multiple={true}
                id="iconImage"
                accept=".jpeg, .png, .doc, .docx, .pdf, .jpg, .svg"
                onDone={handleAttachments}
              />
            </FormGroup>
            {featureIcon && (
              <img
                src={featureIcon}
                width="40px"
                height="40px"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            )}
          </Col> */}
        </Row>
        <Row style={{ justifyContent: 'center'}}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            // disabled={disabledBtn}
            type="submit"
          >
            {getFeatureCat.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchFeature
