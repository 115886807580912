import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
	Label,
} from "reactstrap";
import { resetaddVehicleType, addVehicleType } from "redux/actions/addVehicleTypeAction";

function AddVehicleType() {

	const [vehicleType, setVehicleType] = useState("");
	const dispatch = useDispatch();

	const handleChange = ({ target: { name, value } }) => {
		if( name === "vehicleType" ) setVehicleType(value);
	}

	const addVehicleTypeRequest = () => {

		const addVehicleTypeReq = {
			vehicleType : {
        type: vehicleType
			}
		}

		console.log(addVehicleTypeReq)
		return dispatch(addVehicleType(addVehicleTypeReq));
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		addVehicleTypeRequest()
	}


	return (
		<div style={{marginTop: "20px"}}>
			<Form onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col md="4" sm="12">
                        <FormGroup>
                          <Label for="vehicleType">Vehicle Type</Label>
                          <Input type="text" 
                            name="vehicleType" 
                            id="vehicleType"
                            placeholder="Vehicle Type" 
                            value={vehicleType || ""}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="12">
                          <Button className='cust-button' style={{marginTop: "22px", width: "70%"}}
                          disabled={!vehicleType}
                      onClick={handleSubmit}
                      >Add</Button>
                      </Col>
                    </Row>
                  </Form>
		</div>
	)
}

export default AddVehicleType;