import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Spin } from 'antd'
import {
  resetresendSignupOTP,
  resendSignupOTP,
} from 'redux/actions/resendSignupOTPAction'
import {
  resetValidateSignupOtp,
  validateSignupOtp,
} from 'redux/actions/validateSignupOtpAction'
import { sha256 } from 'js-sha256'
import OTPInput from 'otp-input-react'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import RegisterSociety from './RegisterSociety'
import { adminSignup, resetAdminSignup } from 'redux/actions/adminSignupAction'
import { Link } from 'react-router-dom'
import Util from '../../validations/index'
import ShowPwd from 'assets/img/Icons/Privacy/show_pwd.png'
import HidePwd from 'assets/img/Icons/Privacy/hide_pwd.png'

const inputStyles = {
  width: '35px',
  height: '35px',
}

function AdminSignup({ toggle }) {
  const [activeTab, setActiveTab] = useState('2')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [isPwValid, setisPwValid] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [emailOTP, setEmailOTP] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [errMessage, seterrMessage] = useState('')
  const [initiatedBy, setInitiatedBy] = useState('Society')

  const dispatch = useDispatch()
  const adminRegister = useSelector(({ adminSignup }) => adminSignup)
  const resetOTP = useSelector(({ resetOTP }) => resetOTP)
  const validateSignUpOTP = useSelector(
    ({ validateSignupOTP }) => validateSignupOTP
  )
  const linkSociety = useSelector(({ linkSociety }) => linkSociety)

  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('SuperAdmin')) {
    setInitiatedBy('SuperAdmin')
  }
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'fullName') {
      Util.alphabetsOnly(value) ? setFullName(value) : setFullName(fullName)
    }
    if (name === 'email') {
      setIsEmailValid(Util.verifyEmail(value))
      setEmail(value.trim())
    }
    if (name === 'phone') {
      Util.verifyMobile(value) ? setPhone(value) : setPhone(phone)
    }
    if (name === 'password') {
      setisPwValid(Util.verifyPassword(value))
      setPassword(value.trim())
    }
    if (name === 'confirmPassword') setConfirmPassword(value)
  }

  const adminSignupRequest = () => {
    const adminSignupReq = {
      name: fullName.trim(),
      email,
      mobileNumber: parseInt(phone),
      password: sha256(password),
      role: initiatedBy,
      initiatedBy,
    }
    return dispatch(adminSignup(adminSignupReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    adminSignupRequest()
  }

  const Modaltoggle = () => {
    setOpenModal(!openModal)
  }

  useEffect(() => {
    if (adminRegister.success) {
      setOpenModal(!openModal)
      seterrMessage('')
    }
  }, [adminRegister.success])

  useEffect(() => {
    if (linkSociety.success) {
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      setPhone('')
      setOpenModal(false)
      setEmailOTP('')
    }
  }, [linkSociety.success])

  useEffect(() => {
    if (adminRegister.network || validateSignUpOTP.network)
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )

    if (adminRegister.failed)
      NotificationPopups('error', 'Failed', adminRegister.data.message)

    return () => {
      dispatch(resetAdminSignup())
      dispatch(resetresendSignupOTP())
      dispatch(resetValidateSignupOtp())
    }
  }, [adminRegister.network, adminRegister.failed, validateSignUpOTP.network])

  useEffect(() => {
    if (validateSignUpOTP.success) {
      setOpenModal(false)
    }
    if (validateSignUpOTP.failed) {
      seterrMessage(validateSignUpOTP.data.message)
      //console.log("inside vaidate")
      setEmailOTP('')
      return () => {
        dispatch(resetresendSignupOTP())
      }
    }
  }, [validateSignUpOTP.success, validateSignUpOTP.failed])

  useEffect(() => {
    if (resetOTP.failed || resetOTP.success) {
      seterrMessage(resetOTP.data.message)
      setEmailOTP('')
      //console.log("inside resend")
      return () => {
        dispatch(resetValidateSignupOtp())
      }
    }
  }, [resetOTP.failed, resetOTP.success])

  const otpValidateRequest = () => {
    const otpValidateReq = {
      initiatedBy,
      id: adminRegister.data.user.id,
      validateOtp: emailOTP,
    }
    return dispatch(validateSignupOtp(otpValidateReq))
  }

  const handleresendOTP = () => {
    const resendOTPReq = {
      initiatedBy,
      id: adminRegister.data.user.id,
    }
    return dispatch(resendSignupOTP(resendOTPReq))
  }

  const handleOTP = (e) => {
    e.preventDefault()
    otpValidateRequest()
  }

  const handlepassword = () => {
    setShowPassword(!showPassword)
  }

  const handleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword)
  }

  if (validateSignUpOTP.success) {
    return <RegisterSociety />
  }
  //console.log(emailOTP.toString.length)
  const disableButton = () => {
    return !(
      email &&
      isEmailValid &&
      password &&
      isPwValid &&
      password === confirmPassword &&
      phone &&
      phone.length === 10
    )
  }

  return (
    <>
      <Card>
        <Row>
          <Col>
            <CardHeader>Sign Up</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup style={{ margin: '20px 0' }}>
                  <label>FULL NAME *</label>
                  <Input
                    required
                    // invalid={!(email && isEmailValid)}
                    placeholder="Fullname"
                    type="text"
                    name="fullName"
                    value={fullName || ''}
                    onChange={handleChange}
                  />
                  {/* <FormFeedback style={{ textAlign: 'center' }}>
                    {email && !isEmailValid ? 'Invalid Email' : ''}
                  </FormFeedback> */}
                </FormGroup>
                <FormGroup style={{ margin: '20px 0' }}>
                  <label>EMAIL *</label>
                  <Input
                    required
                    invalid={!(email && isEmailValid)}
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={email || ''}
                    onChange={handleChange}
                  />
                  <FormFeedback style={{ textAlign: 'center' }}>
                    {email && !isEmailValid ? 'Invalid Email' : ''}
                  </FormFeedback>
                </FormGroup>
                <FormGroup style={{ margin: '20px 0' }}>
                  <label>PASSWORD *</label>
                  <InputGroup>
                    <Input
                      required
                      invalid={!(password && isPwValid)}
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={password || ''}
                      onChange={handleChange}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        style={{
                          background: 'rgba(150, 146, 146, 0.3)',
                          color: 'black',
                          border: 'none',
                          borderRadius: '30px',
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                        }}
                        onClick={handlepassword}
                      >
                        {showPassword ? (
                          <img
                            src={ShowPwd}
                            style={{
                              marginLeft: '12px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={HidePwd}
                            style={{
                              marginLeft: '12px',
                              width: '16px',
                            }}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormFeedback style={{ textAlign: 'center' }}>
                      {password && !isPwValid
                        ? 'Password should be 8 to 20 characters and should have numbers and special characters'
                        : ''}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ margin: '20px 0' }}>
                  <label>CONFIRM PASSWORD *</label>
                  <InputGroup>
                    <Input
                      required
                      invalid={!(password === confirmPassword)}
                      placeholder="Confirm Password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={confirmPassword || ''}
                      onChange={handleChange}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        style={{
                          background: 'rgba(150, 146, 146, 0.3)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '30px',
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                        }}
                        onClick={handleConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <img
                            src={ShowPwd}
                            style={{
                              marginLeft: '12px',
                              width: '16px',
                            }}
                          />
                        ) : (
                          <img
                            src={HidePwd}
                            style={{
                              marginLeft: '12px',
                              width: '16px',
                            }}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormFeedback style={{ textAlign: 'center' }}>
                      {confirmPassword && !(password === confirmPassword)
                        ? 'Passwords do not match'
                        : ''}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ margin: '20px 0' }}>
                  <label>PHONE *</label>
                  <Input
                    required
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    value={phone || ''}
                    maxLength="10"
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  style={{ margin: '54px 0px 0px', textAlign: 'center', cursor: "pointer" }}

                >
                  <Button
                    onClick={handleSubmit}
                    disabled={adminRegister.isLoading || disableButton()}
                  >
                    {adminRegister.isLoading ? <Spin /> : 'Sign Up'}
                  </Button>
                </FormGroup>
              </Form>
              <div className="forgot-password">
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle('1')
                  }}
                >
                  Sign In 
                </NavLink>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>

      {openModal && (
        <Modal
          isOpen={openModal}
          toggle={Modaltoggle}
          backdrop="static"
          size="sm"
          style={{ maxWidth: '375px' }}
        >
          <ModalHeader toggle={Modaltoggle}>Verify OTP</ModalHeader>
          <ModalBody style={{ textAlign: 'center', padding: '10px' }}>
            <Form>
              <label style={{ padding: '10px' }}>Enter Email OTP *</label>
              <OTPInput
                style={{
                  display: 'block',
                  // marginLeft: '40px',
                  // marginBotton: '20px',
                  padding: '40px',
                  aliginItems: 'center',
                }}
                value={emailOTP}
                onChange={setEmailOTP}
                autoFocus
                OTPLength={6}
                // otpType="number"
                disabled={false}
                inputStyles={inputStyles}
              />
              <p className="errorText">{errMessage}</p>
              <Button
                color="secondary"
                className="modalCustomButton"
                disabled={
                  validateSignUpOTP.isLoading ||
                  emailOTP.length !== 6 ||
                  resetOTP.isLoading
                }
                onClick={handleOTP}
              >
                {validateSignUpOTP.isLoading ? <Spin /> : 'Verify'}
              </Button>
            </Form>
            <div>
              <span onClick={handleresendOTP} className="resendOTP">
                Resend OTP
              </span>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default AdminSignup
