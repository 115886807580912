import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Table,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Alert, Tooltip, Popconfirm, Select, Spin } from 'antd'
import {
  resetgetCompanyList,
  getCompanyList,
} from 'redux/actions/getCompanyListAction'
import {
  resetgetServiceType,
  getServiceType,
} from '../../redux/actions/getServiceTypeAction'
import {
  resetgetHelperType,
  getHelperType,
} from '../../redux/actions/getHelperTypeAction'
import axios from 'config/axios'
import Util from 'validations/index'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import { getGuestList } from '../../redux/actions/getGuestListAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import moment from 'moment'

function SearchGuest(props) {

  const login = useSelector(({ login }) => login)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [limit] = useState(6)
  const [loginStatus, setLoginStatus] = useState('')
  const [names, setNames] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')
  const [areaType, setareaType] = useState('Building')
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [flatName, setFlatName] = useState('')
  const [searchFlat, setSearchFlat] = useState('')
  const [buildingId, setBuildingId] = useState()
  const [flatid, setflatid] = useState()
  const [status, setStatus] = useState('pending')

  const { Option } = Select

  const getGuestListRes = useSelector(({ getGuestList }) => getGuestList)

  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)

  const dispatch = useDispatch()

  const getGuestListRequest = () => {
    const getGuestListReq = {
      initiatedBy: "User",
      societyId: login.data.society.id,
      name: names.trim(),
      mobileNumber:mobileNumber,
      startDate,
      endDate,
      limit
    }
    return dispatch(getGuestList(getGuestListReq))
  }

  function onChangeLoginStatus(value) {
    setLoginStatus(value)
  }
  function onSearchLoginStatus(val) {
    setLoginStatus(val)
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'names')
      Util.alphabetsOnly(value)
        ? setNames(value)
        : setNames(names)

    if (name === 'mobileNumber')
      Util.verifyMobile(value)
        ? setMobileNumber(value)
        : setMobileNumber(mobileNumber)

    if (name === 'startDate') setStartDate(value)
    if (name === 'endDate') setEndDate(value)

  }

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.status === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions2(res.data.activeAreaList)
      })
    }
  }, [])

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
  }

  useEffect(() => {
    setBuildingId('')
    setBldgName('')
    setFlatName('')
    setflatid('')
  }, [status])

  function onSearch(value) {
    setSearchAreaName(value)
  }
  function onChangeSearchFlatId(value) {
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  const send = (value) => {
    props.searchFilter(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getGuestListRequest()
    props.setIsSearch()
    setMobileNumber('')
    setNames('')
    const obj = {
      name: names,
      mobileNumber: mobileNumber,
      startDate,
      endDate,
      limit
    }
    send(obj)
    // NotificationPopups('success', 'Success', getGuestListRes.data.message)
  }

  // useEffect(() => {
  //   const date = new Date()
  //   const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  //   const startDate = moment(firstDay).format('YYYY-MM-DD')
  //   setStartDate(startDate)
  //   const endDate = moment().format('YYYY-MM-DD')
  //   setEndDate(endDate)
  // }, [])

  return (
    <div style={{ marginTop: '20px' }}>
      <Form>
        <Row>
          <Col xs="12" sm="12" md="3">
            <FormGroup>
              <Label>Start Date</Label>
              <span className="sub-label"></span>
              <Input
                name="startDate"
                value={startDate || ''}
                type="date"
                placeholder="dd/mm/yyyy"
                onChange={handleChange}
              // min={moment().format('YYYY-MM-DD')}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="12" md="3">
            <FormGroup>
              <Label>End Date</Label>
              <span className="sub-label"></span>
              <Input
                name="endDate"
                value={endDate || ''}
                type="date"
                onChange={handleChange}
              // min={moment().format('YYYY-MM-DD')}
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup>
              <Label for="serviceType">Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="names"
                // id="serviceType"
                value={names || ''}
                onChange={handleChange}
              // required
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="12" lg="3" xs="12">
            <FormGroup>
              <Label for="serviceType">Phone No.</Label>
              <Input
                type="text"
                placeholder="Phone No."
                name="mobileNumber"
                // id="serviceType"
                maxLength="10"
                value={mobileNumber || ''}
                onChange={handleChange}
              // required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
          // disabled={!helperTyperId || getCompany.isLoading}
          >
            {getGuestListRes.isLoading ? <Spin /> : 'Search'}
            {/* Search */}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchGuest
