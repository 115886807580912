import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetGetProductServiceSubCategory,
  getProductServiceSubCategory,
} from 'redux/actions/getProductServiceSubCategoryAction'
import PaginationComponent from 'views/Pagination'
import Util from 'validations/index'
import UpdateProductServiceSubCategory from './UpdateProductServiceSubCategory'
import AddProductServiceSubCategory from './AddProductServiceSubCategory'

function ProductServiceSubCategoryList({ sendData, onTap }) {

  let { name, productCategoryId, type } = sendData
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editdocType, seteditdocType] = useState('')
  const [data, setData] = useState('')
  const [id, setId] = useState('')
  const [isActive, setisActive] = useState()
  const [isMandatory, setIsMandatory] = useState('')
  const [errMessage, seterrMessage] = useState('')

  const [helperType, setHelperType] = useState()
  const [serviceTypeId, setServiceTypeId] = useState('')

  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const servType = useSelector(({ getservicetype }) => getservicetype)
  const helperTypeList = useSelector(({ getHelperType }) => getHelperType)
  const productServiceCat = useSelector(
    ({ getProductServiceCategory }) => getProductServiceCategory
  )
  const addedProductServCat = useSelector(
    ({ addProductServiceSubCategory }) => addProductServiceSubCategory
  )
  const productServiceSubCat = useSelector(
    ({ getProductServiceSubCategory }) => getProductServiceSubCategory
  )
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)
  console.log(productServiceCat)
  const dispatch = useDispatch()
  const pageSize = productServiceSubCat.data.pages
  const totalItems = productServiceSubCat.data.count

  const getProductServiceCategoryRequest = () => {
    const getProductServiceCategoryRequest = {
      initiatedBy: login.data.user.role,
      name,
      productCategoryId,
      type,
      limit,
      page,
    }
    return dispatch(
      getProductServiceSubCategory(getProductServiceCategoryRequest)
    )
  }
  useEffect(() => {
    getProductServiceCategoryRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  useEffect(() => {
    setPage(1)
  }, [onTap])

  useEffect(() => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        helperTypeId: helperType,
        limit,
        page,
      }
      //   return dispatch(getHelperDocMandatory(docTypeReq))
    }
  }, [page])

  useEffect(() => {
    if (addedProductServCat.success) {
      setModal(false)
      setEditModal(false)
      getProductServiceCategoryRequest()
    }
    return () => {
      //   dispatch(resetUpdateHelperDoc())
    }
  }, [addedProductServCat.success])

  const openEditModal = (type) => {
    setEditModal(!editModal)
    setData(type)
    setId(type.id)

    seteditdocType(type.type)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setModal(!modal)
                    }}
                  >
                    Add
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    Product and Service Sub Category List
                  </div>
                </Col>
              </div>
              {productServiceSubCat.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Icon</th>
                        <th>Name </th>
                        <th>Product Category </th>
                        <th>Type</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {productServiceSubCat.success && (
                      <tbody>
                        {productServiceSubCat.data.productSubCategoryList.map(
                          (d, id) => (
                            <React.Fragment key={id}>
                              <tr key={id} id={`toggler${id}`}>
                                <td>
                                  <img
                                    src={`${Util.imageUrl}${d.iconUrl}`}
                                    alt="icon"
                                    style={{
                                      height: '40px',
                                      width: '40px',
                                    }}
                                  />
                                </td>
                                <td>{d.name}</td>
                                <td>{d.productCategory}</td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {d.type}
                                </td>
                                <td>{d.isActive ? 'Active' : 'Inactive'}</td>
                                <td>
                                  <Button
                                    color="secondary"
                                    className="modalCustomButton"
                                    onClick={() => {
                                      openEditModal(d)
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}
              <Col md="12">
                {productServiceSubCat.failed && (
                  <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Alert
                      message={productServiceSubCat.data.message}
                      type="info"
                    />
                  </div>
                )}
              </Col>

              <Col>
                {productServiceSubCat.success && (
                  <PaginationComponent
                    limit={limit}
                    totalItems={totalItems}
                    onSelect={handlePagination}
                    defaultPage={page}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="sm"
          style={{ maxWidth: '350px' }}
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <UpdateProductServiceSubCategory data={data} />
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggle}>Add </ModalHeader>
          <ModalBody>
            <AddProductServiceSubCategory />
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default ProductServiceSubCategoryList
