import {
  GET_MAINTENANCE_TYPE_SUCCESS,
  GET_MAINTENANCE_TYPE_FAILED,
  LOADING_GET_MAINTENANCE_TYPE,
  RESET_GET_MAINTENANCE_TYPE,
  GET_MAINTENANCE_TYPE_NETWORK_ERROR,
} from 'redux/types'
import axios from '../../config/axios'

const resetgetMaintenanceType = () => {
  return {
    type: RESET_GET_MAINTENANCE_TYPE,
  }
}
const getMaintenanceType = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_MAINTENANCE_TYPE })
  console.log(userData)
  axios
    .post('mnt/getMntType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_MAINTENANCE_TYPE_NETWORK_ERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GET_MAINTENANCE_TYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_MAINTENANCE_TYPE_FAILED,
    payload: data,
  }
}

export { resetgetMaintenanceType, getMaintenanceType }
