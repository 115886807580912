import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin, Alert, Switch, Tooltip, Popconfirm, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import PaginationComponent from 'views/Pagination'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import AddMutiLanguageType from './AddMutilLanguageType'
import {
  addTypeLanguage,
  resetAddTypeLanguage,
} from 'redux/actions/addTypeLanguageAction'
import {
  resetGetTypeLanguage,
  getTypeLanguage,
} from 'redux/actions/getTypeLanguageAction'
import EditMultiLanguageType from './EditMultiLanguageType'

function MultiLangaugeTypeList() {
  const [addModal, setAddModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [data, setData] = useState('')
  const [isActive, setIsActive] = useState(false)
  const dispatch = useDispatch()
  const login = useSelector(({ login }) => login)
  const addedLan = useSelector(({ addLanguage }) => addLanguage)
  const getTypeLan = useSelector(({ getTypeLanguage }) => getTypeLanguage)
  const [limit] = useState(10)
  const [page, setPage] = useState(1)

  const totalItems = getTypeLan.data.count
  const [initiatedBy] = useState(login.data.user.role)
  // const totalItems = dashboard.data.count

  const getTypeLanguageRequest = () => {
    const getTypeLanguageReq = {
      initiatedBy,
      limit,
      page,
    }
    return dispatch(getTypeLanguage(getTypeLanguageReq))
  }
  useEffect(() => {
    getTypeLanguageRequest()
  }, [page])

  const handlePagination = (selectedPage) => {
    setPage(selectedPage)
  }

  const handleEditChange = ({ target: { name, value, checked } }) => {
    console.log(value)
  }
  const handleEditFeature = (e) => {
    e.preventDefault()
  }

  const onChange = (checked) => {
    setIsActive(checked)
  }

  const openEditModal = (data) => {
    setModal(!modal)
    setData(data)
  }

  const toggleDetails = () => {
    setModal(!modal)
  }
  const toogleAdd = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    if (addedLan.success) {
      setAddModal(false)
      setModal(false)
      getTypeLanguageRequest()
      NotificationPopups('success', 'Success', addedLan.data.message)
    }
    if (addedLan.failed) {
      NotificationPopups('error', 'Failed', addedLan.data.message)
    }
    if (addedLan.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddTypeLanguage())
    }
  }, [addedLan.success, addedLan.failed, addedLan.network])

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <Button
                      color="secondary"
                      className="modalCustomButton"
                      onClick={() => {
                        setAddModal(!addModal)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <Col>
                    <div className="typeHeading">Multi Language Type</div>
                  </Col>
                </div>

                <Col md="12" style={{ padding: '20px' }}>
                  {getTypeLan.success && (
                    <Col md="12" style={{ padding: '20px' }}>
                      <Table responsive style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>English</th>
                            <th>Hindi</th>
                            <th>Marathi</th>
                            {/* <th>Status</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        {getTypeLan.success && (
                          <tbody>
                            {getTypeLan.data?.languageList.map((d, id) => (
                              <React.Fragment key={id}>
                                <tr key={id} id={`toggler${id}`}>
                                  <td style={{ textTransform: 'capitalize' }}>
                                    {d.type}
                                  </td>
                                  <td>{d.eng}</td>
                                  <td>{decodeURI(d.hin)}</td>
                                  <td>{decodeURI(d.mar)}</td>
                                  {/* <td>{d.isActive ? 'Active' : 'Inactive '}</td> */}
                                  <td>
                                    <Button
                                      color="secondary"
                                      className="modalCustomButton"
                                      onClick={() => {
                                        openEditModal(d)
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {getTypeLan.failed && (
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                      <Alert message={getTypeLan.data.message} type="info" />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {getTypeLan.success && (
                    <PaginationComponent
                      limit={limit}
                      totalItems={totalItems}
                      onSelect={handlePagination}
                      defaultPage={page}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleDetails}
          size="lg"
          centered
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggleDetails}>Edit</ModalHeader>
          <ModalBody>
            <EditMultiLanguageType data={data} />
          </ModalBody>
        </Modal>
      )}
      {addModal && (
        <Modal
          isOpen={addModal}
          toggle={toogleAdd}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toogleAdd}> Add Language </ModalHeader>
          <ModalBody>
            <AddMutiLanguageType />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default MultiLangaugeTypeList
