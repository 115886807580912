import React, { useState, useEffect, Component } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'
import moment from 'moment'

function GetComplainLineChartDashboard({
  counterValue,
  chartBtn,
  dataCounterBtn,
}) {
  const [chartData, setChartData] = useState({})
  console.log({ counterValue, chartBtn, dataCounterBtn }, 'complainlinechart')
  const Chart = () => {
    var dateArray = []

    var counterDataResolvedArray = []
    var counterDataRejectedArray = []
    var counterDataPendingArray = []
    if (dataCounterBtn == 'weekly' || dataCounterBtn == 'monthly') {
      counterValue.forEach(function (element, index) {
        !element.toDate
          ? dateArray.push(moment(element.fromDate).format('DD MMM'))
          : dateArray.push(
            moment(element.toDate).format('DD MMM') +
            ' - ' +
            moment(element.fromDate).format('DD MMM')
          )

        counterDataPendingArray.push(element.pendingCount)
        counterDataRejectedArray.push(element.rejectedCount)
        counterDataResolvedArray.push(element.resolvedCount)
      })
    } else {
      counterValue.forEach(function (element, index) {
        dateArray.push(
          element.month.slice(0, 3) +
          ' ' +
          Number(String(element.year).slice(2, 4))
        )

        counterDataPendingArray.push(element.pendingCount)
        counterDataRejectedArray.push(element.rejectedCount)
        counterDataResolvedArray.push(element.resolvedCount)
      })
    }

    setChartData({
      labels: Object.values(dateArray),
      datasets: [
        {
          label: 'Pending',
          data: counterDataPendingArray,
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: 'rgba(240, 135, 0)',
          borderWidth: 2,
        },
        {
          label: 'Rejected',
          data: counterDataRejectedArray,
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: 'rgba(211, 63, 73,1)',
          borderWidth: 2,
        },
        {
          label: 'Resolved',
          data: counterDataResolvedArray,
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: 'rgba(66, 147, 51, 1)',
          borderWidth: 1,
        },
      ],
    })
  }

  useEffect(() => {
    Chart()
  }, [])

  useEffect(() => {
    Chart()
  }, [counterValue])

  return (
    <>
      <div className="App">
        <div>
          <Line
            data={chartData}
            options={{
              responsive: true,
              title: { text: 'THICCNESS SCALE', display: true },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              title: {
                display: true,
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default GetComplainLineChartDashboard
