import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import { Spin, Switch, Select } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetAddUnitDocType,
  addUnitDocType,
} from 'redux/actions/addUnitDocTypeAction'
import { resetGetDocument, getDocument } from 'redux/actions/getDocumentAction'

function AddUnitDocumentType() {
  const [residentType, setResidentType] = useState('')

  const [documentTypeId, setDocumentTypeId] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [searchDocumentType, setSearchDocumentType] = useState('')
  const [isMandatory, setIsMandatory] = useState(false)

  const login = useSelector(({ login }) => login)
  const docType = useSelector(({ getDocument }) => getDocument)
  const addedUnitDocType = useSelector(({ addUnitDocType }) => addUnitDocType)
  const dispatch = useDispatch()

  const { Option } = Select
  function onChangeDocumentType(value) {
    const val = value.split(',')
    setDocumentTypeId(val[0])
    setDocumentType(val[1])
  }
  function onSearchDocumentType(value) {
    setSearchDocumentType(value)
  }
  function onChangeResidentType(value) {
    setResidentType(value)
  }
  function onSearchResidentType(value) {
    setResidentType(value)
  }

  const getDocTypeRequest = () => {
    if (login.data.user.role != 'SuperAdmin') {
      const docTypeReq = {
        // initiatedBy: login.data.user.role,
        action: 'Status',
        initiatedBy: login.data.user.role,
        isActive: true,
      }
      return dispatch(getDocument(docTypeReq))
    }
  }
  useEffect(() => {
    getDocTypeRequest()
  }, [])

  const addUnitDocTypeRequest = () => {
    const addUnitDocTypeReq = {
      societyId: login.data.society.id,
      residentType: residentType,
      documentTypeId,
      documentType,
      isMandatory: isMandatory,
      initiatedBy: login.data.user.role,
      societyUserId: login.data.user.id,
    }
    //console.log(addUnitDocTypeReq)
    return dispatch(addUnitDocType(addUnitDocTypeReq))
  }

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'residentType') setResidentType(value)
    // if (name === 'isMandatory') setIsMandatory(Boolean(value))
    if (name === 'documentType') setDocumentType(value)
  }
  const onChangeSwitch = (checked) => {
    setIsMandatory(checked)
    console.log(`switch to ${checked}`)
  }

  //console.log(isMandatory)
  const selectOptions = [
    { id: 1, residentType: 'Owner' },
    { id: 2, residentType: 'Tenant' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()
    addUnitDocTypeRequest()
    console.log('clicked')
  }

  useEffect(() => {
    if (addedUnitDocType.success) {
      setResidentType('')
      setIsMandatory(isMandatory)
      setDocumentType('')
      NotificationPopups('success', 'Success', addedUnitDocType.data.message)
    }
    if (addedUnitDocType.failed) {
      NotificationPopups('error', 'Failed', addedUnitDocType.data.message)
    }
    if (addedUnitDocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddUnitDocType())
    }
  }, [
    addedUnitDocType.success,
    addedUnitDocType.failed,
    addedUnitDocType.network,
  ])

  const disabledButton = () => {
    return !(documentTypeId && residentType && isMandatory.toString())
  }
  console.log({ addedUnitDocType })
  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup>
              <Label for="helperType">Document Type</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                value={documentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeDocumentType}
                onSearch={onSearchDocumentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Document Type
                </Option>

                {docType.success &&
                  docType.data.activeType.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.type}`}
                    >
                      {d.type}
                    </Option>
                  ))}
                {docType.failed && (
                  <Option className="loginSelect" value="" disabled>
                    No Data Found
                  </Option>
                )}
              </Select>
            </FormGroup>
          </Col>

          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup>
              <Label for="helperType">Resident Type</Label>
              <span className='required'>*</span>
              <Select
                type="select"
                value={residentType}
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={onChangeResidentType}
                onSearch={onSearchResidentType}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Resident Type
                </Option>

                {selectOptions.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={d.residentType}
                  >
                    {d.residentType}
                  </Option>
                ))}
              </Select>
            </FormGroup>
          </Col>

          <Col xs={12} sm={12} md="4">
            <FormGroup>
              <Label>Mandatory</Label>
              <br />
              <Switch
                defaultChecked={isMandatory}
                name="isMandatory"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={onChangeSwitch}
              />
            </FormGroup>
          </Col>
          <Col md="4" sm="12">
            <Button
              className="cust-button"
              style={{ marginTop: '22px', width: '70%' }}
              disabled={disabledButton() || addUnitDocType.isLoading}
              onClick={handleSubmit}
            >
              {addUnitDocType.isLoading ? <Spin /> : 'Add'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddUnitDocumentType
