import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import PaginationComponent from 'views/Pagination'
import { Alert, Spin } from 'antd'

import { useSelector, useDispatch } from 'react-redux'

import { getAllSecurityAlert } from '../../redux/actions/getAllSecurityAlertListAction'

const ViewSecurityAlert = ({ sendData, onTap }) => {

  let { guardId, unitId, requesttime } = sendData

  // const [unit, setUnit] = useState(unitId)

  console.log(sendData, "sendData")

  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const [ids, setIds] = useState('')

  const allSecurityAlertList = useSelector(({ getAllSecurityAlertList }) => getAllSecurityAlertList)
  const login = useSelector(({ login }) => login)

  const totalItems = allSecurityAlertList.data.count

  const dispatch = useDispatch()

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }

  const getSecurityAlertRequest = () => {
    const getSecurityAlertReq = {
      initiatedBy: "Society",
      societyId: login.data.society.id,
      unitId,
      guardId,
      limit,
      requesttime,
      page
    }
    return dispatch(getAllSecurityAlert(getSecurityAlertReq))
  }

  useEffect(() => {
    getSecurityAlertRequest()
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [onTap])

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Col>
              <div className="typeHeading" id="onTop">Security Alert List</div>
            </Col>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                <>
                  <Col md="12" style={{ padding: '20px' }}>
                    <Table
                      responsive
                      style={{ textAlign: 'center' }}
                    >
                      <thead>
                        <tr>
                          <th>Building Name</th>
                          <th>Flat No</th>
                          {/* <th>Security Alert</th> */}
                          <th>Allocated to Guard</th>
                          {/* <th>Alert Message</th> */}
                          <th>Date</th>
                          <th>Request Time</th>
                          <th>Seen Time</th>
                        </tr>
                      </thead>
                      {allSecurityAlertList.success && (
                        <tbody>
                          {allSecurityAlertList.data.securityAlertVOList &&
                            allSecurityAlertList.data.securityAlertVOList.map((d, idx) => (
                              <React.Fragment key={idx}>
                                <tr key={idx + 1}
                                  id={`toggler${idx}`}
                                  style={{
                                    backgroundColor: '#e8f5fd',
                                    color: 'black',
                                    fontWeight: 'semi-bold',
                                    boxShadow: 'none',
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setIds(idx)
                                    // setUnit(d.unit)
                                  }}
                                >
                                  <td>{d.areaName}</td>
                                  <td>{d.unit}</td>
                                  {/* <td>{d.issue}</td> */}
                                  <td>{d.guardName}</td>
                                  {/* <td>-</td> */}
                                  <td>{d.requesttime.split(" ")[0]}</td>
                                  <td>{d.requesttime.split(" ")[1]}</td>
                                  <td>{d.timestamp.split(" ")[1]}</td>
                                </tr>

                                <tr className="ml-detail-tr">
                                  <td colSpan="6" className="ml-detail-td">
                                    <UncontrolledCollapse
                                      toggler={`toggler${idx}`}
                                    >
                                      {ids === idx ? (
                                        <>
                                          <Row>
                                            <Col md="6">
                                              <b>Alert Type</b>
                                              {allSecurityAlertList.data.securityAlertVOList &&
                                                allSecurityAlertList.data.securityAlertVOList.map((d, idx) => (
                                                  idx === ids ?
                                                    <p key={idx}>{d.issue}</p> : null
                                                ))}
                                            </Col>
                                            <Col md="6">
                                              <b>Comment</b>
                                              {allSecurityAlertList.data.securityAlertVOList &&
                                                allSecurityAlertList.data.securityAlertVOList.map((d, idx) => (
                                                  idx === ids ?
                                                    <p key={idx}>{d.content}</p> : null
                                                ))}
                                            </Col>
                                          </Row>
                                        </>
                                      )
                                        :
                                        (' ')
                                      }

                                    </UncontrolledCollapse>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      )}
                    </Table>
                  </Col>
                  <Col md="12">
                    {allSecurityAlertList.data.statusCode === 405 && (
                      <div
                        className="col-12"
                        style={{ textAlign: 'center' }}
                      >
                        <Alert message={"Not Found"} type="info" style={{ margin: "20px 0px 30px 0px" }} />
                      </div>
                    )}
                  </Col>
                  <Row>
                    <Col>
                      {allSecurityAlertList.success && (
                        <PaginationComponent
                          limit={limit}
                          totalItems={totalItems}
                          onSelect={handlePagination}
                          defaultPage={page}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ViewSecurityAlert
