import React, { useState, useEffect, useRef, memo, use } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FileViewer from 'react-file-viewer'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap'
import { Tooltip, Popconfirm, Spin, Select } from 'antd'
import Util from 'validations/index'
import FileBase64 from '../../components/Custom/UploadFile'
import {
  resetupdateNotice,
  updateNotice,
} from 'redux/actions/updateNoticeAction'

import { addNotice, resetAddNotice } from 'redux/actions/addNoticeAction'
import {
  resetdeleteNotice,
  deleteNotice,
} from 'redux/actions/deleteNoticeAction'
//import FileBase64 from 'react-file-base64';
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import moment from 'moment'

function NoticeDetails({ details, modal, toggle, errMessage }) {
  const noticeTypes = useSelector(({ noticeTypes }) => noticeTypes)
  const dispatch = useDispatch()
  const { Option } = Select

  const [editnoticeTitle, seteditnoticeTitle] = useState(details.noticeTitle)
  const [editnoticeContent, seteditnoticeContent] = useState(
    details.noticeContent
  )
  const [noticeFor, setNoticeFor] = useState(details.noticeFor)
  const [helperUpdatePhoto, setHelperUpdatePhoto] = useState('')
  const [editattachment, seteditattachment] = useState(details.attachment)
  const [editstartDate, seteditstartDate] = useState(details.startDate)
  const [editendDate, seteditendDate] = useState(details.endDate)
  const [editnoticetype, seteditnoticetype] = useState(details.noticeType)
  const [fileModal, setFileModal] = useState(false)
  const [isActive, setisActive] = useState()
  const login = useSelector(({ login }) => login)
  const addNewNotice = useSelector(({ addNotice }) => addNotice)
  const deletedNotice = useSelector(({ deleteNotice }) => deleteNotice)
  const updatedNotice = useSelector(({ updateNotice }) => updateNotice)
  const [attachmentsStatus, setattachmentsStatus] = useState(false)
  const handleChange = ({ target: { name, value } }) => {
    console.log(name, ' = ', value)
    if (name === 'editnoticeTitle')
      Util.alphaNumeric(value)
        ? seteditnoticeTitle(value)
        : seteditnoticeTitle(editnoticeTitle)
    if (name === 'editnoticeContent') seteditnoticeContent(value)
    if (name === 'editstartDate') seteditstartDate(value)
    if (name === 'editendDate') seteditendDate(value)
    if (name === 'editnoticetype') seteditnoticetype(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    //addNoticeRequest()
  }

  const handleOpenFile = () => {
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  const handleAttachments = (filesList) => {
    console.log("filesList", filesList)
    setattachmentsStatus(true)
    let img = filesList.base64.split(`,`)
    setHelperUpdatePhoto(filesList.base64)
    seteditattachment(filesList.base64)
  }

  const handleDeleteNotice = () => {
    const deleteNoticeReq = {
      id: details.id,
      initiatedBy: login.data.user.role,
    }
    return dispatch(deleteNotice(deleteNoticeReq))
  }

  const handleEditSubmit = (e) => {
    e.preventDefault()
    handleEditNotice()
  }
  const handleEditNotice = () => {
    const editNoticeReq = {
      action: 'update',
      id: details.id,
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      noticeTitle: editnoticeTitle.trim(),
      noticeContent: editnoticeContent.trim(),
      attachment: attachmentsStatus ? editattachment.split(',')[1] : '',
      startDate: editstartDate ? editstartDate : null,
      endDate: editendDate ? editendDate : null,
      noticeType: editnoticetype,
      isActive,
    }
    //console.log(editNoticeReq)
    return dispatch(addNotice(editNoticeReq))
  }

  const disabledButn = () => {
    return !(
      (editnoticeTitle.trim() && editnoticeContent.trim() && editnoticetype)
      // editstartDate &&
      // editendDate
    )
  }
  useEffect(() => {
    if (addNewNotice.success) {
      NotificationPopups('success', 'Success', addNewNotice.data.message)
    }

    if (addNewNotice.failed) {
      NotificationPopups('error', 'Failed', addNewNotice.data.message)
    }
    if (addNewNotice.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    return () => {
      dispatch(resetAddNotice())
    }
  }, [addNewNotice.success, addNewNotice.failed, addNewNotice.network])

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: '900px' }}
      >
        <ModalHeader toggle={toggle}>Notice</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" sm="6" md="8">
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormGroup>
                    <label>Notice Title<samll className="required">*</samll></label>
                    <Input
                      name="editnoticeTitle"
                      value={editnoticeTitle || ''}
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4" md="4">
                  <FormGroup>
                    <label>Start Date</label>{' '}
                    <span className="sub-label">(optional)</span>
                    <Input
                      name="editstartDate"
                      value={editstartDate || ''}
                      type="date"
                      onChange={handleChange}
                      min={moment().format('YYYY-MM-DD')}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4" md="4">
                  <FormGroup>
                    <label>End Date</label>{' '}
                    <span className="sub-label">(optional)</span>
                    <Input
                      name="editendDate"
                      value={editendDate || ''}
                      type="date"
                      onChange={handleChange}
                      min={
                        editstartDate
                          ? editstartDate
                          : moment().format('YYYY-MM-DD')
                      }
                    />
                    {!editstartDate ||(editstartDate <= editendDate) ? '' : <p style={{ color: "red", fontSize: '12px' }}>Date must be {moment(editstartDate).format("L")} or later.</p>}
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4" md="4">
                  <FormGroup>
                    <label>Notice For</label>
                    <Input
                      name="noticeFor"
                      value={noticeFor || ''}
                      type="text"
                      disabled
                    // onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="4" md="4">
                  <FormGroup>
                    <label>Notice Event Type</label>
                    <Select
                      type="select"
                      showSearch
                      value={editnoticetype}
                      placeholder="Select Notice Type"
                      optionFilterProp="children"
                      onChange={handleChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: '100%' }}
                      name="editnoticetype"
                    >
                      <Option className="loginSelect" value="">
                        Select Notice Type
                      </Option>
                      {noticeTypes.success &&
                        noticeTypes.data.activeNoticeType.map((d, id) => (
                          <Option key={id} className="loginSelect" value={d.type}>
                            {d.type}
                          </Option>
                        ))}
                    </Select>
                  </FormGroup>
                </Col>
                {/* {noticeFor === 'Unit' && (
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    style={{
                      maxHeight: '250px',
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // border: '1px solid blue',
                    }}
                  >
                    <Table>
                      <tr>
                        <th>Area Name</th>
                        <th>Unit</th>
                      </tr>
                      {details.unitList.map((unit) => (
                        <tr>
                          <td>{unit.areaName}</td>
                          <td>{unit.unit}</td>
                        </tr>
                      ))}
                    </Table>
                  </Col>
                )} */}
                <Col>
                  <label>Send Notice : </label>

                  {details.noticeFor === 'Unit' ? (
                    <span style={{ marginTop: '5px' , marginLeft:'5px'}}>
                      {details.unitList.map((d, idx) => (
                        <label key={idx}>{`${d.areaName}/${d.unit}`}{idx !== details.unitList.length - 1 ? ',' : ''} </label>
                      ))}
                    </span>
                  ) : (
                    <span style={{ marginTop: '5px' , marginLeft:'5px'}}>
                      <label>All Society Members</label>
                    </span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormGroup>
                    <label>Content<samll className="required">*</samll></label>
                    <Input
                      style={{ maxHeight: '200px' }}
                      cols="80"
                      value={editnoticeContent}
                      rows="8"
                      type="textarea"
                      name="editnoticeContent"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs="12" sm="6" md="4">
              <label>Attachments</label>

              {helperUpdatePhoto ? (
                <img
                  src={helperUpdatePhoto}
                  width="50%"
                  height="150"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleFileModal(helperUpdatePhoto)}
                />
              ) : details.attachment ? (
                <img
                  src={`${Util.imageUrl}${details.attachment}`}
                  // alt={`${Util.imageUrl}${details.attachment}`}
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    toggleFileModal(`${Util.imageUrl}${details.attachment}`)
                  }
                />
              ) : (
                ''
              )}
              {!details.attachment && !helperUpdatePhoto.length && <p>No attachment found</p>}
              <div style={{ marginTop: '10px' }}>
                <FileBase64
                  multiple={false}
                  id="addhelperPhoto"
                  accept=".jpeg, .png, .jpg"
                  onDone={handleAttachments}
                />
              </div>
            </Col>
          </Row>

          <span>
            <div style={{ textAlign: 'center' }} className="errorText">
              {errMessage}
            </div>
            <Button
              style={{ margin: '5px' }}
              color="secondary"
              disabled={
                disabledButn() ||
                deletedNotice.isLoading ||
                updatedNotice.isLoading || (!editstartDate || editstartDate <= editendDate) ? false : true
              }
              className="modalCustomButton"
              onClick={handleEditSubmit}
            >
              {addNewNotice.isLoading ? <Spin /> : 'Edit'}
            </Button>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={handleDeleteNotice}
              okText="Delete"
              cancelText="No"
            >
              <Button
                style={{ margin: '5px' }}
                color="secondary"
                className="modalCustomButton"
                onClick={handleDeleteNotice}
                disabled={deletedNotice.isLoading || updatedNotice.isLoading}
              >
                {deletedNotice.isLoading ? <Spin /> : 'Delete'}
              </Button>
            </Popconfirm>
          </span>
          <Button
            style={{ float: 'right', margin: '5px' }}
            color="secondary"
            className="modalCustomButton"
            onClick={toggle}
          >
            Close
          </Button>
        </ModalBody >
      </Modal >

      {fileModal && (
        <DisplayAttachment
          attachment={`${Util.imageUrl}${editattachment}`}
          fileModal={fileModal}
          toggleModal={toggleFileModal}
        />
      )
      }
    </>
  )
}

export default NoticeDetails
