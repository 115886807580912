import axios from '../../config/axios'
import {
  GETAMENITYTYPE_SUCCESS,
  GETAMENITYTYPE_FAILED,
  LOADING_GETAMENITYTYPE,
  RESET_GETAMENITYTYPE,
  GETAMENITYTYPE_NETWORKERROR,
} from '../types'

const resetgetAmenityTypeList = () => {
  return {
    type: RESET_GETAMENITYTYPE,
  }
}

const getAmenityTypeList = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GETAMENITYTYPE })
  console.log(userData)
  axios
    .post('amenity/getAmenityType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GETAMENITYTYPE_NETWORKERROR, payload: err })
    )
}

function success(data) {
  return {
    type: GETAMENITYTYPE_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GETAMENITYTYPE_FAILED,
    payload: data,
  }
}

export { resetgetAmenityTypeList, getAmenityTypeList }
