import {
  GET_GATEWAY_SUCCESS,
  GET_GATEWAY_FAILED,
  RESET_GET_GATEWAY,
  LOADING_GET_GATEWAY,
  GET_GATEWAY_NETWORK_ERROR,
} from '../types'
import axios from 'config/axios'

const resetGetGateway = () => {
  return { type: RESET_GET_GATEWAY }
}

const getGateway = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_GATEWAY })
  axios
    .post('paymentGateway/getGateway', userData)
    .then((res) => {
      console.log(res)
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GET_GATEWAY_NETWORK_ERROR, payload: err }))
}
function success(data) {
  return {
    type: GET_GATEWAY_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_GATEWAY_FAILED,
    payload: data,
  }
}
export { resetGetGateway, getGateway }
