import React, { useState } from 'react'
import PanelHeader from 'components/PanelHeader/PanelHeader'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import MntBilling from 'assets/img/Icons/Maintaince/MntBilling.png'
import SearchTransactionsDetails from './SearchTransactionDetails'
import ViewTransactionsDetails from './ViewTransactionDetails'
import TransactionImg from 'assets/img/Icons/Transactionslist/transactionDetails.png'
const Transactions = () => {
  const [sendData, setSendData] = useState('')
  const [isSearch , setIsSearch] = useState(false)
  const searchFilter = (value) => {
    setSendData(value)
  }
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  <img
                    src={TransactionImg}
                    alt="MntBilling"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span className="head-title">Transaction Details</span>
              </CardHeader>
              <CardBody>
                <SearchTransactionsDetails searchFilter={searchFilter} setIsSearch={() => setIsSearch(!isSearch)}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ViewTransactionsDetails sendData={sendData} onTap={isSearch}/>
      </div>
    </>
  )
}

export default Transactions
