import axios from 'config/axios'
import {
  GET_HELPER_TYPE_SUCCESS,
  GET_HEPLER_TYPE_FAILED,
  LOADING_GET_HEPLER_TYPE,
  RESET_GET_HELPER_TYPE,
  GET_HELPER_TYPE_NETWORK_ERROR,
} from 'redux/types'

const resetgetHelperType = () => {
  return {
    type: RESET_GET_HELPER_TYPE,
  }
}

const getHelperType = (userData) => (dispatch) => {
  dispatch({
    type: LOADING_GET_HEPLER_TYPE,
  })
  axios
    .post('helper/getHelperType', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) =>
      dispatch({ type: GET_HELPER_TYPE_NETWORK_ERROR, payload: err })
    )
}
function success(data) {
  return {
    type: GET_HELPER_TYPE_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: GET_HEPLER_TYPE_FAILED,
    payload: data,
  }
}

export { resetgetHelperType, getHelperType }
