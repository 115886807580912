import axios from "../../config/axios"
import {
	UPDATE_DOCTYPE_SUCCESS,
	UPDATE_DOCTYPE_FAILED,
	LOADING_UPDATE_DOCTYPE,
	RESET_UPDATE_DOCTYPE,
	UPDATE_DOCTYPE_NETWORK_ERROR
} from "../types";

const resetupdateDocType = () => {
    return {
        type: RESET_UPDATE_DOCTYPE
    };
};

const updateDocType = userData => dispatch => {
    dispatch({ type: LOADING_UPDATE_DOCTYPE });
		console.log(userData);
    axios
        .post("society/updateSocietyDocumentType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: UPDATE_DOCTYPE_NETWORK_ERROR, payload: err }));

};

function success(data) {
    return {
        type: UPDATE_DOCTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: UPDATE_DOCTYPE_FAILED,
        payload: data
    };
}

export { resetupdateDocType, updateDocType } ;