import {
	ADD_SOCIETYDOC_SUCCESS,
	ADD_SOCIETYDOC_FAILED,
	LOADING_ADD_SOCIETYDOC,
	RESET_ADD_SOCIETYDOC,
	ADD_SOCIETYDOC_NETWORKERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ADD_SOCIETYDOC:
            return {
                ...state,
                isLoading: true,
								success: false,
								failed: false,
    						network: false,
								data: {},
								message: "",
                networkError: ""
            };
				case ADD_SOCIETYDOC_NETWORKERROR:
            return {
                ...state,
								isLoading: false,
								success: false,
								failed: false,
    						network: true,
								data: action.payload,
								message: "",
								networkError: "Network Error"          
            };
            case ADD_SOCIETYDOC_SUCCESS:
            return {
                ...state,
								isLoading: false,
								success: true,
								failed: false,
    						network: false,
								data: action.payload,
								message: "",
                networkError: ""
                
            };
				case ADD_SOCIETYDOC_FAILED:
            return {
                ...state,
                isLoading: false,
								success: false,
								failed: true,
    						network: false,
								data: action.payload,
								message: "",
                networkError: ""
            };
				case RESET_ADD_SOCIETYDOC:
            return (state = initialState);

        default:
            return state;
    }
};