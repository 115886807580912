import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert } from 'antd'
import { Spin, Switch } from 'antd'
import {
  resetAddProfessionType,
  addProfessionType,
} from 'redux/actions/addProfessionTypeAction'
import Util from 'validations/index'

function AddProfessionType() {
  const [featureName, setFeatureName] = useState('')
  const [profession, setProfession] = useState('')
  const login = useSelector(({ login }) => login)
  const addedProfessionType = useSelector(
    ({ addProfessionType }) => addProfessionType
  )
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'Profession')
      Util.alphabetsOnly(value)
        ? setProfession(value)
        : setProfession(profession)
  }

  const addProfessionRequest = () => {
    const addFeatureReq = {
      initiatedBy: login.data.user.role,
      profession:profession.trim(),
      action: 'add',
    }
    return dispatch(addProfessionType(addFeatureReq))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    addProfessionRequest()
  }
  const disabledBtn = () => {
    return !profession
  }
  useEffect(() => {
    if (addedProfessionType.success) {
      setProfession('')
      // NotificationPopups('success', 'Success', addedProfessionType.data.message)
    }
    if (addedProfessionType.failed) {
      // NotificationPopups('error', 'Failed', addedProfessionType.data.message)
    }
    if (addedProfessionType.network) {
      // NotificationPopups(
      //   'error',
      //   'Network Error',
      //   'Check your internet connection'
      // )
    }
    return () => {
      dispatch(resetAddProfessionType())
    }
  }, [
    addedProfessionType.success,
    addedProfessionType.failed,
    addedProfessionType.network,
  ])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="4" sm="12" lg="4" xs="12">
            <FormGroup style={{ margin: '10px 1px' }}>
              <Label for="serviceType">Enter Profession Name</Label>
              <Input
                type="text"
                placeholder="Name"
                name="Profession"
                id="serviceType"
                value={profession || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={disabledBtn()}
            type="submit"
          >
            {addedProfessionType.isLoading ? <Spin /> : 'Add'}
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default AddProfessionType
