import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
// reactstrap components
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'

function VisitorTable({ counterValue, chartBtn, dataCounterBtn }) {
  // console.log(counterValue, 'cv')
  // const arr = []
  // counterValue.map((data, id) =>
  //   Object.keys(data).forEach((key) => {
  //     arr.push({ key: [key], value: data[key] })
  //   })
  // )

  return (
    <Row>
      {dataCounterBtn == 'monthly' ? (
        <Table responsive style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Date</th>
              {/* <th>To Date</th> */}
              <th>Count</th>
            </tr>
          </thead>

          <tbody>
            {counterValue.map((d, id) => (
              // Object.entries(item, (key, value, id) => (
              <React.Fragment key={id}>
                <tr key={id} id={`toggler${id}`}>
                  {/* <td>{d.fromDate}</td> */}
                  <td>{`${moment(d.toDate).format('Do MMM,YYYY')} - ${moment(d.fromDate).format('Do MMM,YYYY')}`}</td>
                  {/* <td>{d.toDate}</td> */}
                  <td>{d.count ? d.count : '0'}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : dataCounterBtn == 'weekly' ? (
        <Table responsive style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Count</th>
            </tr>
          </thead>
          {/* {visitorDetail.success && ( */}
          <tbody>
            {counterValue.map((d, id) => (
              // Object.entries(item, (key, value, id) => (
              <React.Fragment key={id}>
                <tr key={id} id={`toggler${id}`}>
                  <td>{`${moment(d.fromDate).format('Do MMM,YYYY')}`}</td>
                  <td>{d.count ? d.count : '0'}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table responsive style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              {/* <th>Year</th> */}
              <th>Month</th>
              <th>Count</th>
            </tr>
          </thead>
          {/* {visitorDetail.success && ( */}
          <tbody>
            {counterValue.map((d, id) => (
              // Object.entries(item, (key, value, id) => (
              <React.Fragment key={id}>
                <tr key={id} id={`toggler${id}`}>
                  {/* <td>{d.year}</td> */}
                  <td>
                    {d.month} - {d.year}
                  </td>
                  <td>{d.count ? d.count : '0'}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </Row>
  )
}

export default VisitorTable
