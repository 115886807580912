import {
  UPDATE_HELPER_DOC_SUCCESS,
  UPDATE_HELPER_DOC_FAILED,
  UPDATE_HELPER_DOC_NETWORK_ERROR,
  LOADING_UPDATE_HELPER_DOC,
  RESET_UPDATE_HELPER_DOC,
} from 'redux/types'
import axios from 'config/axios'

const resetUpdateHelperDoc = () => {
  return {
    type: RESET_UPDATE_HELPER_DOC,
  }
}
const updateHelperDoc = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UPDATE_HELPER_DOC })
  axios
    .post('helper/updateHelperTypeDocument', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_HELPER_DOC_NETWORK_ERROR, payload: err })
    })
}
function success(data) {
  return {
    type: UPDATE_HELPER_DOC_SUCCESS,
    payload: data,
  }
}
function failed(data) {
  return {
    type: UPDATE_HELPER_DOC_FAILED,
    payload: data,
  }
}

export { resetUpdateHelperDoc, updateHelperDoc }
