import axios from "../../config/axios"
import {
	GETEMERGENCYLIST_SUCCESS,
	GETEMERGENCYLIST_FAILED,
	LOADING_GETEMERGENCYLIST,
	RESET_GETEMERGENCYLIST,
    GETEMERGENCYLIST_NETWORKERROR
} from "../types";

const resetgetEmergencyNumbers = () => {
    return {
        type: RESET_GETEMERGENCYLIST
    };
};

const getEmergencyNumbers = userData => dispatch => {
    dispatch({ type: LOADING_GETEMERGENCYLIST });
		console.log(userData);
    axios
        .post("society/getEmergencyNumber", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETEMERGENCYLIST_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETEMERGENCYLIST_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETEMERGENCYLIST_FAILED,
        payload: data
    };
}

export { resetgetEmergencyNumbers, getEmergencyNumbers } ;