import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Table,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Switch } from 'antd'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetHelperDoc,
  getHelperDoc,
} from 'redux/actions/getHelperDocAction'
import {
  resetUpdateHelperDoc,
  updateHelperDoc,
} from 'redux/actions/updateHelperDocAction'
import PaginationComponent from 'views/Pagination'
import UpdateHelperDoc from './UpdateHelperDoc'
import AddHelperDoc from './AddHelperDoc'

function HelperDocList() {
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editdocType, seteditdocType] = useState('')
  const [data, setData] = useState('')
  const [id, setId] = useState('')
  const [isActive, setisActive] = useState()
  const [isMandatory, setIsMandatory] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const [limit] = useState(10)
  const [page, setPage] = useState(1)

  const docType = useSelector(({ getHelperDoc }) => getHelperDoc)
  const addeddocType = useSelector(({ addHelperDoc }) => addHelperDoc)
  const updatedDocType = useSelector(({ updateHelperDoc }) => updateHelperDoc)
  const login = useSelector(({ login }) => login)
  const [initiatedBy] = useState(login.data.user.role)

  const dispatch = useDispatch()
  const pageSize = docType.data.pages
  const totalItems = docType.data.count

  const getDocTypeRequest = () => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        limit,
        page,
      }
      return dispatch(getHelperDoc(docTypeReq))
    }
  }
  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    if (initiatedBy != 'SuperAdmin') {
      const docTypeReq = {
        initiatedBy,
        societyId: login.data.society.id,
        limit,
        page,
      }
      return dispatch(getHelperDoc(docTypeReq))
    }
  }, [page])

  useEffect(() => {
    if (addeddocType.success) {
      setModal(false)
      getDocTypeRequest()
    }
    if (updatedDocType.success) {
      seterrMessage('')
      setModal()
      setEditModal(false)
      NotificationPopups('success', 'Success', updatedDocType.data.message)
      getDocTypeRequest()
    }
    if (updatedDocType.failed) {
      seterrMessage(updatedDocType.data.message)
    }
    if (updatedDocType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateHelperDoc())
    }
  }, [
    addeddocType.success,
    updatedDocType.success,
    updatedDocType.failed,
    updatedDocType.network,
  ])

  const openEditModal = (type) => {
    setEditModal(!editModal)
    setData(type)
    setId(type.id)
    seteditdocType(type.type)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ marginRight: '15px' }}>
                  <Button
                    color="secondary"
                    className="modalCustomButton"
                    onClick={() => {
                      setModal(!modal)
                    }}
                  >
                    Add Document
                  </Button>
                </div>
                <Col>
                  <div className="typeHeading" id="onTop">
                    Helper Document
                  </div>
                </Col>
              </div>

              {docType.success && (
                <Col md="12" style={{ padding: '20px' }}>
                  <Table responsive style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Document </th>
                        <th>Category</th>
                        <th>Mandatory</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    {docType.success && (
                      <tbody>
                        {docType.data.activeHelperDocList.map((d, id) => (
                          <React.Fragment key={id}>
                            <tr key={id} id={`toggler${id}`}>
                              <td>{d.documentName}</td>
                              <td>{d.helperType}</td>
                              <td>{d.mandatory ? 'Yes' : 'No'}</td>
                              <td>{d.active ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Button
                                  color="secondary"
                                  className="modalCustomButton"
                                  onClick={() => {
                                    openEditModal(d)
                                  }}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </Col>
              )}

              <Col>
                {docType.success && (
                  <PaginationComponent
                  limit={limit}
                  totalItems={totalItems}
                  onSelect={handlePagination}
                  defaultPage={page}
                />
                )}
              </Col>
              <Col md="12">
                {docType.failed && (
                  <div style={{ textAlign: 'center' }}>
                    <Alert message={docType.data.message} type="info" style={{margin:'20px 0'}}/>
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <Modal
          isOpen={editModal}
          toggle={toggleEdit}
          size="sm"
          style={{ maxWidth: '350px' }}
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <UpdateHelperDoc id={id} data={data} />
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: '1000px', maxHeight: '500px' }}
        >
          <ModalHeader toggle={toggle}>Add Helper Document </ModalHeader>
          <ModalBody>
            <AddHelperDoc />
          </ModalBody>
        </Modal>
      )}
    </Row>
  )
}

export default HelperDocList
