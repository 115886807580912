import axios from "../../config/axios"
import {
	ADDSOCIETY_SUCCESS,
	ADDSOCIETY_FAILED,
	LOADING_ADDSOCIETY,
	RESET_ADDSOCIETY,
    ADDSOCIETY_NETWORKERROR
} from "../types";

const resetAddSociety = () => {
    return {
        type: RESET_ADDSOCIETY
    };
};

const addSociety = userData => dispatch => {
    dispatch({ type: LOADING_ADDSOCIETY });
		console.log(userData);
    axios
        .post("society/addSociety", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: ADDSOCIETY_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: ADDSOCIETY_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: ADDSOCIETY_FAILED,
        payload: data
    };
}

export { resetAddSociety, addSociety } ;