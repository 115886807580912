import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap'
import { Tooltip, Popconfirm, Alert, Select } from 'antd'
import { Spin, Switch } from 'antd'
import randomColor from 'randomcolor'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'

import FileBase64 from '../../components/Custom/UploadFile'
import Util from 'validations/index'
//Action files
import {
  resetGetSocietyMnt,
  getSocietyMnt,
} from 'redux/actions/getSocietyMntTypeAction'
import {
  resetgetMaintenanceType,
  getMaintenanceType,
} from 'redux/actions/getMntTypeAction'
import { createKeywordTypeNode } from 'typescript'
function SearchMntType({ screen }) {
  const [names, setName] = useState('')
  const [columnNumber, setColumnNumber] = useState('')
  const [mntTypeId, setMntTypeId] = useState('')
  const login = useSelector(({ login }) => login)
  const mntType = useSelector(({ getMntType }) => getMntType)
  const dispatch = useDispatch()

  const { Option } = Select

  const handleChange = ({ target: { name, value, checked } }) => {
    if (name === 'name')
      Util.alphabetsOnly(value) ? setName(value) : setName(names)
    if (name === 'columnNumber')
      Util.alphabetsOnly(value)
        ? setColumnNumber(value)
        : setColumnNumber(columnNumber)
  }

  const getMntTypeRequest = () => {
    const getMntTypeReq = {
      initiatedBy: login.data.user.role,
      action: 'fee',
      isFee: screen === 'fee' ? true : false,
    }
    return dispatch(getMaintenanceType(getMntTypeReq))
  }

  const getSocietyMntRequest = () => {
    const getSocietyMntReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      columnType: screen,
      name: names.trim(),
      columnNumber:columnNumber.trim(),
      mntTypeId,
      isFee: true,
      limit:5
    }
    return dispatch(getSocietyMnt(getSocietyMntReq))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getSocietyMntRequest()
  }

  const onChangeMntTypeId = (value) => {
    setMntTypeId(value)
  }
  const onSearchMntTypeId = (val) => {
    setMntTypeId(val)
  }
  useEffect(() => {
    getMntTypeRequest()
    console.log('exe')
  }, [screen])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="3" sm="12" lg="3" xs="12">
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={names}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>Master Data Name</Label>
              <Select
                type="select"
                showSearch
                value={mntTypeId}
                placeholder="Select Notice Type"
                optionFilterProp="children"
                onChange={onChangeMntTypeId}
                onSearch={onSearchMntTypeId}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  Select Master Data Name
                </Option>
                {mntType.success &&
                  mntType.data.maintenanceTypeList.map((data, id) => (
                    <Option key={id} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
              </Select>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Button className="cust-button" onClick={handleSubmit} type="submit">
            {/* {addedServiceType.isLoading ? <Spin /> : 'Search'} */}
            Search
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default SearchMntType
