import axios from '../../config/axios'
import {
  GET_PARKING_SUCCESS,
  GET_PARKING_FAILED,
  GET_PARKING_NETWORK_ERROR,
  LOADING_GET_PARKING,
  RESET_GET_PARKING,
} from 'redux/types'

const resetGetParking = () => {
  return {
    type: RESET_GET_PARKING,
  }
}

const getParking = (userData) => (dispatch) => {
  dispatch({ type: LOADING_GET_PARKING })
  console.log(userData)
  axios
    .post('society/getParkingSlot', userData)
    .then((res) => {
      switch (res.data.statusCode) {
        case 200:
          dispatch(success(res.data))
          break
        default:
          dispatch(failed(res.data))
          break
      }
    })
    .catch((err) => dispatch({ type: GET_PARKING_NETWORK_ERROR, payload: err }))
}

function success(data) {
  return {
    type: GET_PARKING_SUCCESS,
    payload: data,
  }
}

function failed(data) {
  return {
    type: GET_PARKING_FAILED,
    payload: data,
  }
}

export { resetGetParking, getParking }
