import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
} from 'reactstrap'
import { Spin } from 'antd'
import randomColor from 'randomcolor'
import { Tooltip, Popconfirm, Alert } from 'antd'
import {
  resetgetCompanyType,
  getComapnyType,
} from 'redux/actions/getCompanyTypeAction'
import {
  resetDeleteCompanyType,
  deleteCompanyType,
} from 'redux/actions/deleteCompanyTypeAction'
import {
  resetupdateCompanyType,
  updateCompanyType,
} from 'redux/actions/updateCompanyTypeAction'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import loginReducer from 'redux/reducers/loginReducer'

function CompanyType() {
  const [modal, setModal] = useState(false)
  const [editcompanyType, seteditCompanyType] = useState('')
  const [id, setId] = useState('')
  const [errMessage, seterrMessage] = useState('')
  const companyType = useSelector(({ companyType }) => companyType)
  const updatedCompanyType = useSelector(
    ({ updateCompanyType }) => updateCompanyType
  )
  const addedcompanyType = useSelector(({ addcompanyType }) => addcompanyType)
  const deletedCompanyType = useSelector(
    ({ deleteCompanyType }) => deleteCompanyType
  )
  const login = useSelector(({ login }) => login)
  const dispatch = useDispatch()

  const getCompanyTypeRequest = () => {
    const getCompanyTypeReq = {
      initiatedBy: login.data.user.role,
    }
    return dispatch(getComapnyType(getCompanyTypeReq))
  }

  useEffect(() => {
    getCompanyTypeRequest()
  }, [addedcompanyType.success])

  useEffect(() => {
    if (updatedCompanyType.success) {
      seterrMessage('')
      setModal(!modal)
      NotificationPopups('success', 'Success', updatedCompanyType.data.message)
      getCompanyTypeRequest()
    }
    if (updatedCompanyType.failed) {
      seterrMessage(updatedCompanyType.data.message)
      //NotificationPopups('error', 'Failed', updatedCompanyType.data.message)
    }
    if (updatedCompanyType.network || deletedCompanyType.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    if (deletedCompanyType.success) {
      NotificationPopups('success', 'Success', deletedCompanyType.data.message)
      getCompanyTypeRequest()
    }
    if (deletedCompanyType.failed) {
      NotificationPopups('error', 'Failed', deletedCompanyType.data.message)
    }
    return () => {
      dispatch(resetupdateCompanyType())
      dispatch(resetDeleteCompanyType())
    }
  }, [
    updatedCompanyType.success,
    updatedCompanyType.failed,
    updatedCompanyType.network,
    deletedCompanyType.success,
    deletedCompanyType.failed,
    deletedCompanyType.network,
  ])

  const handleDelete = (id) => {
    const deleteCompanytypeReq = {
      id: id,
    }
    //return dispatch(deleteCompanyType(deleteCompanytypeReq));
  }

  const openEditModal = (type) => {
    setId(type.id)
    seteditCompanyType(type.type)
    setModal(!modal)
  }

  const toggle = () => {
    seterrMessage('')
    setModal(!modal)
  }

  const handleChange = ({ target: { name, value } }) => {
    console.log(value)
    if (name === 'editcompanyType') seteditCompanyType(value)
    console.log(value)
  }

  const handleEditCompanyType = () => {
    const editCompanyTypeReq = {
      id: id,
      type: editcompanyType.trim(),
    }
    return dispatch(updateCompanyType(editCompanyTypeReq))
  }

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Row>
              <Col md="12" style={{ padding: '20px' }}>
                <div>
                  <div className="typeHeading">Company Type</div>
                  <Row>
                    {companyType.success &&
                      companyType.data.companyType.map((d, id) => (
                        <Col sm="12" md="3" lg="3" key={id}>
                          <Card
                            className="typeContent"
                            style={{
                              background: randomColor({
                                luminosity: 'light',
                                alpha: 0.5,
                              }),
                            }}
                          >
                            <CardBody>
                              <CardText>
                                <span
                                  style={{
                                    textTransform: 'capitalize',
                                    fontWeight: '600',
                                  }}
                                >
                                  {' '}
                                  {d.type}
                                </span>
                              </CardText>
                              <CardText>
                                <span onClick={() => openEditModal(d)}>
                                  <Tooltip
                                    placement="leftBottom"
                                    title="Edit"
                                    color="#108ee9"
                                  >
                                    <i className="far fa-edit"></i>
                                  </Tooltip>
                                </span>
                                <span>
                                  <Popconfirm
                                    title="Are you sure to delete?"
                                    onConfirm={() => handleDelete(d.id)}
                                    okText="Delete"
                                    cancelText="No"
                                  >
                                    <Tooltip
                                      placement="rightBottom"
                                      title="Delete"
                                      color="#108ee9"
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </Tooltip>
                                  </Popconfirm>
                                </span>
                              </CardText>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>

                  {companyType.failed && (
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                      <Alert message={companyType.data.message} type="info" />
                    </div>
                  )}

                  {modal && (
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      size="sm"
                      style={{ maxWidth: '250px' }}
                    >
                      <ModalHeader toggle={toggle}>Edit</ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label for="editcompanyType">Company Type</Label>
                          <Input
                            type="text"
                            name="editcompanyType"
                            id="editcompanyType"
                            value={editcompanyType}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <div
                          style={{ textAlign: 'center' }}
                          className="errorText"
                        >
                          {errMessage}
                        </div>
                        <Button
                          color="secondary"
                          className="modalCustomButton"
                          onClick={handleEditCompanyType}
                          disabled={
                            updatedCompanyType.isLoading || !editcompanyType
                          }
                        >
                          {updatedCompanyType.isLoading ? <Spin /> : 'Edit'}
                        </Button>
                        <Button
                          color="secondary"
                          style={{ float: 'right' }}
                          className="modalCustomButton"
                          onClick={toggle}
                        >
                          Close
                        </Button>
                      </ModalBody>
                    </Modal>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CompanyType
