import axios from '../../config/axios'
import {
    GET_GUEST_LIST_SUCCESS,
    GET_GUEST_LIST_FAILED,
    LOADING_GET_GUEST_LIST,
    RESET_GET_GUEST_LIST,
    GET_GUEST_LIST_NETWORKERROR,
} from '../types'

const resetGetGuestList = () => {
    return {
        type: RESET_GET_GUEST_LIST,
    }
}

const getGuestList = (userData) => (dispatch) => {
    dispatch({ type: LOADING_GET_GUEST_LIST })
    axios
        .post('household/getGuestDetails', userData)
        .then((res) => {
            switch (res.data.statusCode) {
                case 200:
                    dispatch(success(res.data))
                    break
                default:
                    dispatch(failed(res.data))
                    break
            }
        })
        .catch((err) => dispatch({ type: GET_GUEST_LIST_NETWORKERROR, payload: err }))
}

function success(data) {
    return {
        type: GET_GUEST_LIST_SUCCESS,
        payload: data,
    }
}

function failed(data) {
    return {
        type: GET_GUEST_LIST_FAILED,
        payload: data,
    }
}

export { resetGetGuestList, getGuestList }
