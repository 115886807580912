import axios from "../../config/axios"
import {
	GETDAILYHELPTYPE_SUCCESS,
	GETDAILYHELPTYPE_FAILED,
	LOADING_GETDAILYHELPTYPE,
	RESET_GETDAILYHELPTYPE,
	GETDAILYHELPTYPE_NETWORKERROR
} from "../types";

const resetgetDailyHelp = () => {
    return {
        type: RESET_GETDAILYHELPTYPE,		
    };
};

const getDailyHelp = userData => dispatch => {
    dispatch({ type: LOADING_GETDAILYHELPTYPE });
		console.log(userData);
    axios
        .post("superAdmin/getDailyHelperType", userData)
        .then(res => {
            switch (res.data.statusCode) {

                case 200: 
                    dispatch(success(res.data));
                    break;
                default:
                    dispatch(failed(res.data));
                    break;
            }
        })
        .catch(err => dispatch({ type: GETDAILYHELPTYPE_NETWORKERROR, payload: err }));

};

function success(data) {
    return {
        type: GETDAILYHELPTYPE_SUCCESS,
        payload: data
    };
}

function failed(data) {
    return {
        type: GETDAILYHELPTYPE_FAILED,
        payload: data
    };
}

export { resetgetDailyHelp, getDailyHelp } ;