import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap'

import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import SearchProductServiceCategory from './ProductServiceCategory/SearchProductServiceCategory'
import ProductServiceCategoryList from './ProductServiceCategory/ProductServiceCategoryList'
import SearchProductServiceSubCategory from './ProductServiceSubCategory/SearchProductServiceSubCategory'
import ProductServiceSubCategoryList from './ProductServiceSubCategory/ProductServiceSubCategoryList'
import ProductServices from 'assets/img/Icons/ProductAndServices/productAndService.png'

function ProductAndServices() {

  const [showScreen, setshowScreen] = useState('Product Service Category')
  const [isSerach, setIsSearch] = useState(false)
  const [sendData, setSendData] = useState('')

  const searchFilter = (value) => {
    setSendData(value)
  }

  const showDrawer = (a) => {
    setshowScreen(a)
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="head-icon">
                  <img
                    src={ProductServices}
                    alt="SeviceTypeIcon"
                    style={{ height: '50%', width: '50%' }}
                  />
                </div>
                <span>
                  {showScreen === 'Product Service Sub Category' && (
                    <>
                      <span className="head-title">
                        Product and Service Sub Category
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() => showDrawer('Product Service Category')}
                      >
                        Product and Service Category
                      </span>
                    </>
                  )}
                  {showScreen === 'Product Service Category' && (
                    <>
                      <span className="head-title">
                        Product and Service Category
                      </span>
                      <span
                        style={{ float: 'right', paddingTop: '10px' }}
                        className="right-menu"
                        onClick={() =>
                          showDrawer('Product Service Sub Category')
                        }
                      >
                        Product and Service Sub Category
                      </span>
                    </>
                  )}
                </span>
              </CardHeader>
              <CardBody>
                {showScreen === 'Product Service Category' && (
                  <SearchProductServiceCategory setIsSearch={() => setIsSearch(!isSerach)} searchFilter={searchFilter} />
                )}
                {showScreen === 'Product Service Sub Category' && (
                  <SearchProductServiceSubCategory setIsSearch={() => setIsSearch(!isSerach)} searchFilter={searchFilter} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showScreen === 'Product Service Category' && (
          <ProductServiceCategoryList onTap={isSerach} sendData={sendData} />
        )}
        {showScreen === 'Product Service Sub Category' && (
          <ProductServiceSubCategoryList onTap={isSerach} sendData={sendData} />
        )}
      </div>
    </>
  )
}

export default ProductAndServices
