import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch } from 'antd'

// reactstrap components
import { Button, FormGroup, Form, Input, Row, Col, Label } from 'reactstrap'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import { Spin, Select } from 'antd'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import { addBuilding, resetAddBuilding } from 'redux/actions/addBuildingAction'
import {
  resetAddUnitFlatList,
  addUnitFlatList,
} from 'redux/actions/addUnitFlatListAction'
import axios from 'config/axios'
import { getAreaSearchList } from 'redux/actions/getAreaSearchListAction'

function SearchList({ atype }) {
  const [areaName, setAreaName] = useState('')

  // console.log(isParking, 'parking')
  const login = useSelector(({ login }) => login)
  const getAreaSearchListData = useSelector(
    ({ getAreaSearchList }) => getAreaSearchList
  )
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'areaName') setAreaName(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getAreaSearchListRequest()
  }

  const disableButton = () => {
    return !areaName.trim()
  }

  const getAreaSearchListRequest = () => {
    const getAreaSearchListReq = {
      initiatedBy: login.data.user.role,
      societyId: login.data.society.id,
      areaType: atype,
      areaName,
    }
    return dispatch(getAreaSearchList(getAreaSearchListReq))
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Form onSubmit={handleSubmit}>
        <Row style={{ justifyContent: 'center' }}>
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="areaName">{atype}</Label>
              <Input
                type="text"
                name="areaName"
                id="areaName"
                placeholder={atype}
                value={areaName || ''}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="cust-button"
            onClick={handleSubmit}
            disabled={disableButton()}
          >
            {getAreaSearchListData.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SearchList
