import {
    GET_AMENITY_MESSAGE_SUCCESS,
    GET_AMENITY_MESSAGE_FAILED,
    LOADING_GET_AMENITY_MESSAGE,
    RESET_GET_AMENITY_MESSAGE,
    GET_AMENITY_MESSAGE_NETWORK_ERROR
} from "../types";

const initialState = {
    isLoading: false,
    success: false,
    failed: false,
    network: false,
    data: {},
    message: "",
    networkError: "",
    record: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_GET_AMENITY_MESSAGE:
            return {
                ...state,
                isLoading: true,
                success: false,
                failed: false,
                network: false,
                data: {},
                message: "",
                networkError: "",
                record: { ...state.record }
            };

        case GET_AMENITY_MESSAGE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: true,
                network: false,
                data: action.payload,
                message: "",
                networkError: "",
                record: { ...state.record }
            };

        case GET_AMENITY_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                failed: false,
                network: false,
                data: action.payload,
                message: "",
                networkError: "",
                record: {
                    ...state.record,
                    [action.payload.amenityRecord[0].amenityId]: action.payload.amenityRecord
                },
            };

        case GET_AMENITY_MESSAGE_NETWORK_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                failed: false,
                network: true,
                data: action.payload,
                message: "",
                networkError: "Network Error",
                record: { ...state.record }
            };

        case RESET_GET_AMENITY_MESSAGE:
            return (state = initialState);

        default:
            return state;
    }
};