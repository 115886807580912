import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import { Provider, useSelector } from 'react-redux'
import { store, persistor } from 'redux/store'
import { PersistGate } from 'redux-persist/integration/react'

const env = require("dotenv");
env.config();
if (process.env.NODE_ENV === "production") {
  console.log = function () { };
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
