import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Table,
} from 'reactstrap'
import { Tooltip, Popconfirm } from 'antd'
import randomColor from 'randomcolor'
import { Alert } from 'antd'
import EditEmergencyNumber from './EditEmergencyNumber'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetdeleteEmergencyNumber,
  deleteEmergencyNumber,
} from 'redux/actions/deleteEmergencyNoAction'
import {
  getEmergencyNumbers,
  resetgetEmergencyNumbers,
} from 'redux/actions/getEmergencyNumberAction'
import PaginationComponent from 'views/Pagination'

function EmergencyNumberList() {
  const dispatch = useDispatch()
  const [details, setDetails] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [errMessage, seterrMessage] = useState('')
  const [limit] = useState(15)
  const [page, setPage] = useState(1)

  const login = useSelector(({ login }) => login)

  const addedEmergencyNumber = useSelector(
    ({ addEmergencyNumber }) => addEmergencyNumber
  )
  const getEmergencyNumber = useSelector(
    ({ getEmergencyNumber }) => getEmergencyNumber
  )
  const deletedEmergencyNumber = useSelector(
    ({ deleteEmergencyNumber }) => deleteEmergencyNumber
  )
  const updatedEmergencyNumber = useSelector(
    ({ updateEmergencyNumber }) => updateEmergencyNumber
  )

  const pageSize = getEmergencyNumber.data.pages
  const totalItems = getEmergencyNumber.data.count

  useEffect(() => {
    getEmergencyListRequest()
  }, [addedEmergencyNumber.success])

  const getEmergencyListRequest = () => {
    const getEmergencyNumberReq = {
      societyId: login.data.society.id,
      initiatedBy: login.data.user.role,
      limit,
      page,
    }
    return dispatch(getEmergencyNumbers(getEmergencyNumberReq))
  }

  const handlePagination = (selectedPage) => {
    const element = document.getElementById('onTop')
    element.scrollIntoView(selectedPage)
    setPage(selectedPage)
  }
  useEffect(() => {
    const getEmergencyNumberReq = {
      societyId: login.data.society.id,
      initiatedBy: login.data.user.role,
      limit,
      page,
    }
    return dispatch(getEmergencyNumbers(getEmergencyNumberReq))
  }, [page])
  useEffect(() => {
    return () => {
      dispatch(resetgetEmergencyNumbers())
    }
  }, [])

  useEffect(() => {
    if (updatedEmergencyNumber.success) {
      seterrMessage('')
      setOpenModal(false)
      getEmergencyListRequest()
      NotificationPopups(
        'success',
        'Success',
        updatedEmergencyNumber.data.message
      )
    }

    if (updatedEmergencyNumber.failed) {
      seterrMessage(updatedEmergencyNumber.data.message)
    }
    if (updatedEmergencyNumber.network || deletedEmergencyNumber.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }

    if (deletedEmergencyNumber.success) {
      getEmergencyListRequest()
      NotificationPopups(
        'success',
        'Success',
        deletedEmergencyNumber.data.message
      )
    }

    if (deletedEmergencyNumber.failed) {
      NotificationPopups('error', 'Failed', deletedEmergencyNumber.data.message)
    }

    return () => {
      dispatch(resetdeleteEmergencyNumber())
    }
  }, [
    updatedEmergencyNumber.success,
    updatedEmergencyNumber.failed,
    updatedEmergencyNumber.network,
    deletedEmergencyNumber.success,
    deletedEmergencyNumber.failed,
    deletedEmergencyNumber.network,
  ])

  const handleDelete = (id) => {
    const deleteEmergencyContact = {
      id: id,
    }
    return dispatch(deleteEmergencyNumber(deleteEmergencyContact))
  }

  const toggleModal = () => {
    seterrMessage('')
    setOpenModal(!openModal)
  }

  const openEditModal = (d) => {
    console.log('clikd')
    setDetails(d)
    setOpenModal(true)
  }

  return (
    // <>
    //   <div className="listcontent">
    //     <Card className="emergencyL">
    //       <div style={{ padding: '25px' }}>
    //         <div style={{ paddingTop: '35px' }}>
    //           <Row style={{ margin: '10px' }}>
    //             {getEmergencyNumber.success &&
    //               getEmergencyNumber.data.emergencyNumberList.map((d) => (
    //                 <Col sm="12" md="4" key={d.id}>
    //                   <Card
    //                     style={{
    //                       background: randomColor({
    //                         luminosity: 'light',
    //                         alpha: 0.5,
    //                       }),
    //                     }}
    //                   >
    //                     <CardBody>
    //                       <CardTitle className="e-for">{d.type}</CardTitle>
    //                       <CardTitle className="e-name">
    //                         <i className="fas fa-user-tie"></i> {d.name}
    //                       </CardTitle>
    //                       <CardSubtitle
    //                         style={{ fontSize: '14px', letterSpacing: '1px' }}
    //                         className="mb-2"
    //                       >
    //                         <i className="fas fa-phone-alt"></i>{' '}
    //                         <span style={{ textDecoration: 'underline' }}>
    //                           {d.mobileNumber}
    //                         </span>
    //                       </CardSubtitle>
    //                       <CardSubtitle
    //                         style={{ fontSize: '14px', letterSpacing: '1px' }}
    //                         className="mb-2"
    //                       >
    //                         <i className="far fa-envelope"></i>{' '}
    //                         <span style={{ textDecoration: 'underline' }}>
    //                           {d.email}
    //                         </span>
    //                       </CardSubtitle>
    //                       <CardText>
    //                         <i className="far fa-address-card"></i> {d.address}
    //                       </CardText>

    //                       <CardText style={{ textAlign: 'center' }}>
    //                         <span onClick={() => openEditModal(d)}>
    //                           <Tooltip
    //                             placement="leftBottom"
    //                             title="Edit"
    //                             color="#108ee9"
    //                           >
    //                             <i className="far fa-edit"></i>
    //                           </Tooltip>
    //                         </span>
    //                         <span>
    //                           <Popconfirm
    //                             title="Are you sure to delete?"
    //                             onConfirm={() => handleDelete(d.id)}
    //                             okText="Delete"
    //                             cancelText="No"
    //                           >
    //                             <Tooltip
    //                               placement="rightBottom"
    //                               title="Delete"
    //                               color="#108ee9"
    //                             >
    //                               <i className="far fa-trash-alt"></i>
    //                             </Tooltip>
    //                           </Popconfirm>
    //                         </span>
    //                       </CardText>
    //                     </CardBody>
    //                   </Card>
    //                 </Col>
    //               ))}
    //           </Row>
    //           {getEmergencyNumber.failed && (
    //             <div style={{ textAlign: 'center', marginTop: '40px' }}>
    //               <Alert
    //                 message={getEmergencyNumber.data.message}
    //                 type="info"
    //               />
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </Card>
    //   </div>

    //
    // </>
    <>
      <div className="listcontent" id="onTop">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="typeHeading" id="onTop">
                      Emergency Contacts
                    </div>
                  </Col>

                  <Col md="12" style={{ padding: '20px' }}>
                    {getEmergencyNumber.success && (
                      <Col md="12" style={{ padding: '20px' }}>
                        <Table responsive style={{ textAlign: 'center' }}>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          {getEmergencyNumber.success && (
                            <tbody>
                              {getEmergencyNumber.data.emergencyNumberList.map(
                                (d, id) => (
                                  <React.Fragment key={id}>
                                    <tr key={id} id={`toggler${id}`}>
                                      <td>{d.type}</td>
                                      <td>{d.name}</td>
                                      <td>{d.mobileNumber}</td>
                                      <td>{d.email}</td>
                                      <td>{d.address}</td>
                                      <td>
                                        {d.isActive ? 'Active' : 'Inactive'}
                                      </td>
                                      <td>
                                        {' '}
                                        <Button
                                          color="secondary"
                                          className="modalCustomButton"
                                          onClick={() => openEditModal(d)}
                                        >
                                          Edit
                                        </Button>
                                      </td>
                                    </tr>

                                    <CardText
                                      style={{ textAlign: 'center' }}
                                    ></CardText>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          )}
                        </Table>
                      </Col>
                    )}
                    {getEmergencyNumber.success && (
                      <PaginationComponent
                        limit={limit}
                        totalItems={totalItems}
                        onSelect={handlePagination}
                        defaultPage={page}
                      />
                    )}
                  </Col>
                  <Col>
                    {getEmergencyNumber.failed && (
                      <div style={{ textAlign: 'center' }}>
                        <Alert
                          style={{ margin: "20px 0" }}
                          message={getEmergencyNumber.data.message}
                          type="info"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {openModal && (
        <EditEmergencyNumber
          details={details}
          modal={openModal}
          toggle={toggleModal}
          errMessage={errMessage}
        />
      )}
    </>
  )
}

export default EmergencyNumberList
