import React, { useState, useEffect } from "react";
import AddSociety from "./AddSociety";
import SocietyList from "./SocietyList";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { resetgetSocietyList, getSocietyList } from "redux/actions/getSocietyListAction";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

// ant design components
import { Tooltip } from 'antd';

import SocietyListIcons from 'assets/img/Icons/Sidebar/societydetail1.png'

function Society() {

  const [showScreen, setshowScreen] = useState("socList");

  useEffect(() => {
    resetgetSocietyList();
  }, [])

  const showDrawer = (a) => {
    setshowScreen(a);
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content society-details-container">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className='head-icon'>
                  {/* <i className={"now-ui-icons business_bank"} /> */}
                  <img src={SocietyListIcons} alt="SocietyListIcons" style={{width:"50%", height:"50%"}} />
                </div>
                <span>
                  {showScreen === "socList" && <><span className="head-title">Society List</span>
                    <span style={{ float: "right", paddingTop: "10px" }} className="right-menu" onClick={() => showDrawer("addSociety")}>
                      Add Society
                    </span></>}
                  {showScreen === "addSociety" && <> <span className="head-title">Add Society</span>
                    <span style={{ float: "right", paddingTop: "10px" }} className="right-menu" onClick={() => showDrawer("socList")}>
                      Society List
                    </span></>}
                </span>
              </CardHeader>
              <CardBody>
                {showScreen === "socList" && <SocietyList />}
                {showScreen === "addSociety" && <AddSociety />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default Society;
